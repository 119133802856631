import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import '../../styles/fix/input-dark.scss';
import { useParams } from "react-router-dom";
import { HttpStatus } from 'src/constant/responseStatus';
import { FavoriteService } from 'src/services/favoriteService';
import { PageDocument } from 'src/constant/stringConstants';
import { FavoriteResponseModel } from 'src/models/responses/favoriteModel';
import GalleryFavoriteCollectItems from 'src/components/galleryCollection/GalleryFavoriteCollectionItems';

export default function FavoriteCollection() {
  const [favorite, setFavorite] = useState<FavoriteResponseModel>({} as FavoriteResponseModel);
  const { id } = useParams();

  useEffect(() => {
    document.title = `Favorite Collection - ${PageDocument.Title}`;
    if (id) {
      onGetFavoriteByIdAsync();
    }
  }, [id]);

  const onGetFavoriteByIdAsync = async () => {
    const { data: responseData, status: responseStatus } =
      await new FavoriteService().getFavoriteByIdAsync(id!);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setFavorite(responseData);
  }

  return (
    <div className='search pt-5'>
      <div className='container'>
        {
          favorite && (
            <>
              <h1>Favorite collection: {favorite.name}</h1>
              <Row align="middle">
                <Col span={24}>
                  {
                    favorite.favoriteItems && (
                      <>
                        <div className="gallery">
                          <div className='gallery'>
                          {favorite.favoriteItems.map((favItem, index) => (
                            <GalleryFavoriteCollectItems favItem={favItem} index={index} />
                          ))}
                        </div>
                          <div className="box-gradient" />
                        </div>
                      </>
                    )
                  }
                </Col>
              </Row>
            </>
          )
        }
      </div>
    </div>
  );
}