import { Row, Col, Form, Input, Button, Divider, Typography, Result } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { RouteNames } from '../../routes/routeName';
import { HttpStatus } from '../../constant/responseStatus';
import { AccountService } from '../../services/accountService';
import { useEffect, useState } from 'react';
import MessageModal from 'src/components/modal/messageModal';
import { PageDocument } from 'src/constant/stringConstants';
import TranslationKey from 'src/i18n/translation';

const { Title, Text } = Typography;

const year = new Date().getFullYear();

export default function ForgotPassword() {
  const logo = require('../../assets/images/logo/white@3x.png');
  const bannerImg = require('../../assets/images/banner/Image.png');
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [childModal, setChildModal] = useState<JSX.Element>(<></>);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${TranslationKey('forgotPassword.title')} - ${PageDocument.Title}`;
  }, []);

  const handleCloseModal = () => setIsOpenModal(false);

  const onForgotPassword = async (value: any) => {
    const response = await new AccountService().getForgotPassword(value.email);
    console.log(response);

    if (response.status !== HttpStatus.OK) {
      errorPopup(
        'Your email is incorrect.',
        <Button size='large' type='primary' shape='round' className='btn-primary' onClick={handleCloseModal}>
          Try again
        </Button>
      );

      return;
    }

    successPopup(
      'Reset password success. Please check your email',
      <Button size='large' type='primary' shape='round' className='btn-primary' onClick={() => navigate(RouteNames.login)}>
        Back to login
      </Button>
    );
  };

  const errorPopup = (msg: string, extra: JSX.Element) => {
    setChildModal(<Result status='error' title={msg} className='result-message' extra={extra} />);
    setIsOpenModal(true);
    setLoading(false);
  };

  const successPopup = (msg: string, extra: JSX.Element) => {
    setChildModal(<Result status='success' title={msg} className='result-message' extra={extra} />);
    setIsOpenModal(true);
    setLoading(false);
  };

  return (
    <>
      <Content className='login'>
        <Row>
          <Col xs={0} xl={12}>
            <Link to={RouteNames.default}>
              <img src={logo} alt='alive logo' className='img-logo' />
            </Link>
            <p className='copy-right'>All contents &copy; copyright {year} Alive United v1.0.56</p>
            <img src={bannerImg} alt='alive banner' className='img' />
          </Col>
          <Col xs={24} xl={12} className='flex-center-items'>
            <Form
              name='form_item_path'
              layout='vertical'
              onFinish={onForgotPassword}
              className='form-input'
              requiredMark={false}
            >
              <Title level={2} className='text-light mb-0'>
              {TranslationKey('forgotPassword.title')}
              </Title>
              <Text className='text-gray'>{TranslationKey('forgotPassword.pleaseEnter')}</Text>
              <Form.Item
                name='email'
                className='mt-4 text-light'
                label={TranslationKey('email')}
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}
              >
                <Input className='input-radius-dark w-100' placeholder='Email' size='large' />
              </Form.Item>
              <Row justify='center'>
                <Button
                  shape='round'
                  type='primary'
                  className='btn btn-primary w-100'
                  htmlType='submit'
                  size='large'
                  loading={loading}
                >
                  {TranslationKey('forgotPassword.resetPassword')}
                </Button>
              </Row>
              <Divider style={{ borderColor: '#262626' }} />
              <div className='text-center mt-4'>
                <Link to={RouteNames.login} className='px-1 btn-link'>
                {TranslationKey('forgotPassword.backToLogin')}
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Content>
      <MessageModal isModalOpen={isOpenModal} onClose={handleCloseModal} children={childModal} />
    </>
  );
}