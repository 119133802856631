import SelectDistrict from 'src/components/address/SelectDistrict';
import SelectProvince from 'src/components/address/SelectProvince';
import SelectSubDistrict from 'src/components/address/SelectSubDistrict';
import { Button, Col, Divider, Form, Input, Row, Checkbox, Result } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Typography from 'antd/es/typography';
import '../../styles/fix/input-dark.scss';
import { useCallback, useEffect, useState } from 'react';
import { MasterDataService } from '../../services/masterDataService';
import { MasterDataType } from '../..//constant/masterDataConstants';
import { HttpStatusCode } from 'axios';
import { MasterDataResponse } from '../../models/responses/MasterDataResponse';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DateLength } from '../../constant/constants';
import { useSessionContext } from '../../context/session-context';
import { AccountService } from 'src/services/accountService';
import { ArtistCreatingModel, ArtistProfileModel } from 'src/models/accountModel';
import { ArtistStatus } from 'src/constant/statusConstants';
import { HttpStatus } from 'src/constant/responseStatus';
import { ArtistService } from 'src/services/artistService';
import { RouteNames } from 'src/routes/routeName';
import { useNavigate } from 'react-router-dom';
import { RoleType } from 'src/constant/roleType';
import { PageDocument } from 'src/constant/stringConstants';
import saleforceService from 'src/services/saleforceService';
import ArtistTermOfService from '../artist/term-of-service';
import FullScreenMessageModal from 'src/components/modal/fullScreenModal';
import ArtistPrivacyPolicy from '../artist/privacy-policy';
import { CloseOutlined } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

const { Title, Text } = Typography;

export default function RegisterArtist() {
  const [postcode] = useState('');
  const [districtId, setDistrictId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [subDistrictId, setSubDistrictId] = useState(0);
  const [styleOfWork, setStyleOfWork] = useState<MasterDataResponse[]>([]);
  const [platForm, setPlatForm] = useState<MasterDataResponse[]>([]);
  const [isShowOtherStyleOfWork, setIsShowOtherStyleOfWork] = useState(false);
  const [isShowOtherPlatform, setIsShowOtherPlatform] = useState(false);
  const [isShowMysitePlatform, setIsShowMysitePlatform] = useState(false);
  const [artist, setArtist] = useState<ArtistProfileModel>({} as ArtistProfileModel);
  const [isSecondRegister, setIsSecondRegister] = useState(false);
  const { userProfile } = useSessionContext();
  const [isAccept, setIsAccept] = useState(false);
  const [isOpenTerm, setIsOpenTerm] = useState(false);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [isCanRegister, setIsCanRegister] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${TranslationKey('registerArtist.title')} - ${PageDocument.Title}`;
  }, []);

  useEffect(() => {
    if(artist && artist.artist && artist.artist.updatedInfo && artist.artist.updatedInfo.at){
      const updateArtistDate = new Date(artist.artist.updatedInfo.at);
      const dataDiff = new DateLength().dayDiff(updateArtistDate, new Date());
      if (artist.artist.status === ArtistStatus.Rejected && dataDiff >= DateLength.DAY_THREE_MONTH) {
        setIsCanRegister(true);
        setIsSecondRegister(true);
        onDefaultValue();
      }
    }
    else{
      setIsCanRegister(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artist]);

  const getStyleOfWorkAsync = useCallback(async () => {
    const { data, status } = await new MasterDataService().getGeneticAsync<MasterDataResponse[]>(
      MasterDataType.STYLE_OF_WORK
    );

    if (status !== HttpStatusCode.Ok) {
      return;
    }

    setStyleOfWork(data);
  }, []);

  const getPlatformAsync = useCallback(async () => {
    const { data, status } = await new MasterDataService().getGeneticAsync<MasterDataResponse[]>(
      MasterDataType.PLATFORM
    );

    if (status !== HttpStatusCode.Ok) {
      return;
    }

    setPlatForm(data);
  }, []);

  const getArtistAsync = useCallback(async () => {
    const { data, status } = await new AccountService().getArtistById(userProfile.userId);

    if (status !== HttpStatusCode.Ok) {
      return;
    }

    setArtist(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStyleOfWorkAsync();
    getPlatformAsync();
    getArtistAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.roles.filter(r => r === RoleType.ARTIST).length > 0) {
        navigate(RouteNames.dashboard)
      }
      onDefaultValue();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const validateCustomField = useCallback(
    (isRequired: boolean, key: string) => {
      if (isRequired) {
        form.setFields([
          {
            name: key,
            errors: [`${key} is required`],
          },
        ]);
      } else {
        form.setFields([
          {
            name: key,
            errors: undefined,
          },
        ]);
      }
    },
    [form]
  );

  const onFinish = (values: any) => {
    validateCustomField(!values.ProvinceId, 'ProvinceId');
    validateCustomField(!values.DistrictId, 'DistrictId');
    validateCustomField(!values.SubDistrictId, 'SubDistrictId');

    const validateAddress = !values.ProvinceId || !values.DistrictId || !values.SubDistrictId;
    const validateSocial = values.facebook || values.instagram || values.tiktok || values.other;

    if (!validateSocial || validateAddress) {
      return;
    }

    //TODO: submit to api
    const artistCreating = {
      nameInfoEN_Firstname: values.firstname,
      nameInfoEN_Lastname: values.lastname,
      nameInfoEN_Nickname: values.nickname,
      nameInfoTH_Firstname: values.firstnameTh,
      nameInfoTH_Lastname: values.lastnameTh,
      nameInfoTH_Nickname: values.nicknameTh,
      email: values.email,
      phone: values.phone,
      addressInfo_Address: values.address,
      addressInfo_Postcode: values.Postcode,
      addressInfo_ProvinceCode: values.ProvinceId,
      addressInfo_DistrictCode: values.DistrictId,
      addressInfo_SubDistrictCode: values.SubDistrictId,
      portfolio: values.portfolio,
      socialMediaInfo_Facebook: values.facebook,
      socialMediaInfo_Instagram: values.instagram,
      socialMediaInfo_Tiktok: values.tiktok,
      socialMediaInfo_Other: values.other,
      aboutYourSelf: values.aboutYourSelf,
      selfPromotion: values.selfPromotion,
      styleOfWork: JSON.stringify(values.styleOfWork),
      styleOfWork_Other: values.styleOfWorkOther,
      platform: JSON.stringify(values.platForm),
      platform_Mysite: values.platFormMySite,
      platform_Other: values.platFormOther,
      isAccept: true,
      Status: ArtistStatus.Pending,
    } as ArtistCreatingModel;

    onRegisterArtist(artistCreating);
  };

  const onRegisterArtist = async (data: ArtistCreatingModel) => {
    if (isSecondRegister) {
      const response = await new ArtistService().UpdateArtistStatus(artist.artist.id, data);

      if (response.status !== HttpStatus.OK) {
        return;
      }
      await saleforceService.postCreateArtistSaleforceAsync(data);
      window.location.reload();
    }

    const response = await new AccountService().RegisterNewArtist(data, userProfile.userId);
    if (response.status !== HttpStatus.CREATED) {
      return;
    }

    await saleforceService.postCreateArtistSaleforceAsync(data);
    window.location.reload();
  };

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    form.setFieldValue('ProvinceId', provinceId);
    validateCustomField(!provinceId, 'ProvinceId');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    form.setFieldValue('DistrictId', districtId);
    validateCustomField(!districtId, 'DistrictId');
  };

  const onSubDistrictChange = (SubDistrictId: number) => {
    setSubDistrictId(SubDistrictId);
    form.setFieldValue('SubDistrictId', SubDistrictId);
    validateCustomField(!SubDistrictId, 'SubDistrictId');
  };

  const onValidateSocial = () => {
    const facebook: boolean = form.getFieldValue('facebook');
    const instagram: boolean = form.getFieldValue('instagram');
    const tiktok: boolean = form.getFieldValue('tiktok');
    const other: boolean = form.getFieldValue('other');

    const validateSocial: boolean = facebook || instagram || tiktok || other;

    validateCustomFieldError(!validateSocial, 'SocialGroup', 'Choose at least one answer');

    return validateSocial;
  };

  const validateCustomFieldError = useCallback(
    (isRequired: boolean, key: string, error: string) => {
      if (isRequired) {
        form.setFields([
          {
            name: key,
            errors: [`${error}`],
          },
        ]);
      } else {
        form.setFields([
          {
            name: key,
            errors: undefined,
          },
        ]);
      }
    },
    [form]
  );

  const onStyleOfWorkChange = useCallback(
    (value: CheckboxChangeEvent) => {
      const targetId = value.target.value;
      const isChecked = value.target.checked;
      const object = styleOfWork.find((x) => x.value === targetId);

      if (object && object.name === 'Other') {
        setIsShowOtherStyleOfWork(isChecked);
      }
    },
    [styleOfWork]
  );

  const onPlatformChange = useCallback(
    (value: CheckboxChangeEvent) => {
      const targetId = value.target.value;
      const isChecked = value.target.checked;
      const object = platForm.find((x) => x.value === targetId);

      if (object && object.name === 'Other') {
        setIsShowOtherPlatform(isChecked);
      }

      if (object && object.name === 'My Site') {
        setIsShowMysitePlatform(isChecked);
      }
    },
    [platForm]
  );

  const onDefaultValue = () => {
    form.setFieldValue('email', userProfile.email);
    form.setFieldValue('firstname', userProfile.firstname);
    form.setFieldValue('lastname', userProfile.lastname);

    if(artist && artist.artist){
      form.setFieldValue('email', artist.artist.email);
      form.setFieldValue('phone', artist.artist.phone);
      form.setFieldValue('firstname', artist.artist.nameInfoEn.firstname);
      form.setFieldValue('lastname', artist.artist.nameInfoEn.lastname);
      form.setFieldValue('nickname', artist.artist.nameInfoEn.nickname);
      form.setFieldValue('firstnameTh', artist.artist.nameInfoTh.firstname);
      form.setFieldValue('lastnameTh', artist.artist.nameInfoTh.lastname);
      form.setFieldValue('nicknameTh', artist.artist.nameInfoTh.nickname);
      form.setFieldValue('address', artist.artist.addressInfo.address);
      form.setFieldValue('ProvinceId', artist.artist.addressInfo.provinceCode);
      form.setFieldValue('DistrictId', artist.artist.addressInfo.districtCode);
      form.setFieldValue('SubDistrictId', artist.artist.addressInfo.subDistrictCode);
      form.setFieldValue('Postcode', artist.artist.addressInfo.postcode);
      form.setFieldValue('portfolio', artist.artist.portfolio);
      form.setFieldValue('facebook', artist.artist.socialMediaInfo.facebook);
      form.setFieldValue('instagram', artist.artist.socialMediaInfo.instagram);
      form.setFieldValue('tiktok', artist.artist.socialMediaInfo.tiktok);
      form.setFieldValue('other', artist.artist.socialMediaInfo.other);
      form.setFieldValue('aboutYourSelf', artist.artist.aboutYourSelf);
      form.setFieldValue('selfPromotion', artist.artist.selfPromotion);
      form.setFieldValue('styleOfWork', JSON.parse(artist.artist.styleOfWork));
      form.setFieldValue('styleOfWorkOther', artist.artist.styleOfWork_Other);
      form.setFieldValue('platForm', JSON.parse(artist.artist.platform));
      form.setFieldValue('platFormMySite', artist.artist.platform_Mysite);
      form.setFieldValue('platFormOther', artist.artist.platform_Other);

      if(artist.artist.addressInfo.provinceCode){
        setProvinceId(Number(artist.artist.addressInfo.provinceCode));
      }
      if(artist.artist.addressInfo.districtCode){
        setDistrictId(Number(artist.artist.addressInfo.districtCode));
      }
      if(artist.artist.addressInfo.subDistrictCode){
        setSubDistrictId(Number(artist.artist.addressInfo.subDistrictCode));
      }
    }
  };

  const textTOS = () => {
    return (
      <>
      <Row className='d-flex justify-content-end'>
          <Col>
            <Button
                shape='round'
                type='text'
                className='text-primary'
                size='large'
                onClick={() => setIsOpenTerm(false)}
              >
                <CloseOutlined style={{fontSize: '2em'}} />
              </Button>
          </Col>
        </Row>
        <ArtistTermOfService />
        <Row className='d-flex justify-content-center'>
          <Col span={16}>
            <Button
              shape='round'
              type='primary'
              className='btn btn-primary w-100'
              size='large'
              onClick={() => setIsOpenTerm(false)}
            >
              I accept the above terms of services
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  const textPolicy = () => {
    return (
      <>
        <Row className='d-flex justify-content-end'>
          <Col>
            <Button
                shape='round'
                type='text'
                className='text-primary'
                size='large'
                onClick={() => setIsOpenPolicy(false)}
              >
                <CloseOutlined style={{fontSize: '2em'}} />
              </Button>
          </Col>
        </Row>
        <ArtistPrivacyPolicy />
        <Row className='d-flex justify-content-center'>
          <Col span={16}>
            <Button
              shape='round'
              type='primary'
              className='btn btn-primary w-100'
              size='large'
              onClick={() => setIsOpenPolicy(false)}
            >
              I accept the above privacy and policy
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <div className='search pt-5'>
      <div className='container'>
        {
          ((isSecondRegister && isCanRegister) || !artist.userId) ? (
            <Row className='pt-5' justify='center'>
              <Col span={24}>
                <div className='bg-outline-gray mt-2 radius-32 px-5 py-5'>
                  <Title className='text-primary text-center mb-0' level={1}>
                    {TranslationKey('registerArtist.register')}
                     <span className='text-primary-50'>
                    {TranslationKey('registerArtist.artist')}
                      </span>
                  </Title>
                  <Form
                    form={form}
                    name='form_item_path'
                    layout='vertical'
                    className='form-input-contact'
                    onFinish={onFinish}
                  >
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          name='email'
                          className='mt-4 text-light'
                          label={TranslationKey('email')}
                          rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder={TranslationKey('email')} size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name='phone'
                          className='mt-4 text-light'
                          label={TranslationKey('phoneNumber')}
                          rules={[{ required: true, message: 'Please input your phone number!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder='Phone Number' size='large' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          name='firstname'
                          className='mt-4 text-light'
                          label={TranslationKey('firstName')}
                          rules={[{ required: true, message: 'Please input your firstname!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder={TranslationKey('firstName')} size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name='lastname'
                          className='mt-4 text-light'
                          label={TranslationKey('lastName')}
                          rules={[{ required: true, message: 'Please input your lastname!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder={TranslationKey('lastName')} size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name='nickname'
                          className='mt-4 text-light'
                          label={TranslationKey('nickName')}
                          rules={[{ required: true, message: 'Please input nickname!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder={TranslationKey('nickName')} size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name='firstnameTh'
                          className='mt-4 text-light'
                          label='ชื่อ'
                          rules={[{ required: true, message: 'Please input ชื่อ!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder='ชื่อ' size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name='lastnameTh'
                          className='mt-4 text-light'
                          label='นามสกุล'
                          rules={[{ required: true, message: 'Please input นามสกุล!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder='นามสกุล' size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name='nicknameTh'
                          className='mt-4 text-light'
                          label='ชื่อเล่น'
                          rules={[{ required: true, message: 'Please input ชื่อเล่น!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder='ชื่อเล่น' size='large' />
                        </Form.Item>
                      </Col>
                      <Divider className='text-primary'>{TranslationKey('artist.artistAddress')}</Divider>
                      <Col span={24}>
                        <Form.Item
                          name='address'
                          className='mt-4 text-light'
                          label={TranslationKey('address')}
                          rules={[{ required: true, message: 'Please input address!' }]}
                        >
                          <Input className='input-radius-dark w-100' placeholder={TranslationKey('address')} size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name='ProvinceId'
                          label={TranslationKey('province')}
                          rules={[{ required: true, message: 'Please input Province!' }]}
                        >
                          <SelectProvince className="input-radius-dark non-radius" provinceID={provinceId} 
                          sendProvinceID={onProvinceChange} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name='DistrictId'
                          label={TranslationKey('district')}
                          rules={[{ required: true, message: 'Please input District!' }]}
                        >
                          <SelectDistrict
                            className="input-radius-dark non-radius"
                            districtID={districtId}
                            provinceID={provinceId}
                            sendProvinceID={(id) => {
                              setProvinceId(id);
                            }}
                            sendDistrictID={onDistrictChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name='SubDistrictId'
                          label={TranslationKey('subDistrict')}
                          rules={[{ required: true, message: 'Select Sub District!' }]}
                        >
                          <SelectSubDistrict
                            className="input-radius-dark non-radius"
                            postCode={postcode}
                            districtID={districtId}
                            provinceID={provinceId}
                            subDistrictID={subDistrictId}
                            sendPostCode={(value) => {
                              form.setFieldValue('Postcode', value);
                            }}
                            sendSubDistrictID={onSubDistrictChange}
                            sendDistrictID={(id, subId) => {
                              setDistrictId(id);
                              setSubDistrictId(subId);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name='Postcode' label='Postcode'>
                          <Input placeholder='Postcode' readOnly className='input-radius-dark non-radius' size='large' />
                        </Form.Item>
                      </Col>
                      <Divider className='text-primary'>{TranslationKey('artist.artistDescription')}</Divider>
                      <Col span={8}>
                        <Form.Item
                          name='portfolio'
                          className='mt-4 text-light'
                          label = {`${TranslationKey('artist.portfolio')} (e.g. https://www.google.com)`}
                          rules={[
                            { required: true, message: 'Please input your portfolio!' },
                            {type: 'url', message: 'Please input url!'}]}>
                          <Input type='url' className='input-radius-dark w-100' placeholder='Portfolio' size='large' />
                        </Form.Item>
                      </Col>
                      <Col className='mt-4' span={16}>
                      <Text className=' text-light requiredSpecial'>
                      {TranslationKey('social')}
                      </Text>
                        <Form.Item className='mt-4 text-light' name='SocialGroup'
                            rules={[{ required: !onValidateSocial(), message: 'Choose at least one answer!' }]}>
                          <Form.Item
                            name='facebook'
                            style={{ display: 'inline-block', width: 'calc(25% - 8px)' }}
                            rules={[{ required: false, message: 'Please input your social!' }]}
                          >
                            <Input
                              className='input-radius-dark'
                              placeholder= {TranslationKey('facebook')}
                              size='large'
                              onChange={() => onValidateSocial()}
                            />
                          </Form.Item>
                          <Form.Item
                            name='instagram'
                            style={{
                              display: 'inline-block',
                              width: 'calc(25% - 8px)',
                              marginLeft: 8,
                            }}
                            rules={[{ required: false, message: 'Please input your social!' }]}
                          >
                            <Input
                              className='input-radius-dark'
                              placeholder= {TranslationKey('instagram')}
                              size='large'
                              onChange={() => onValidateSocial()}
                            />
                          </Form.Item>
                          <Form.Item
                            name='tiktok'
                            style={{
                              display: 'inline-block',
                              width: 'calc(25% - 8px)',
                              marginLeft: 8,
                            }}
                            rules={[{ required: false, message: 'Please input your social!' }]}
                          >
                            <Input
                              className='input-radius-dark'
                              placeholder= {TranslationKey('tiktok')}
                              size='large'
                              onChange={() => onValidateSocial()}
                            />
                          </Form.Item>
                          <Form.Item
                            name='other'
                            style={{
                              display: 'inline-block',
                              width: 'calc(25% - 8px)',
                              marginLeft: 8,
                            }}
                            rules={[{ required: false, message: 'Please input your social!' }]}
                          >
                            <Input
                              className='input-radius-dark'
                              placeholder={TranslationKey('other')}
                              size='large'
                              onChange={() => onValidateSocial()}
                            />
                          </Form.Item>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name='aboutYourSelf'
                          className='mt-4 text-light'
                          label={TranslationKey('artist.wouldYouLikeToShare')}
                          rules={[{ required: true, message: 'Please input your message!' }]}
                        >
                          <TextArea className='input-radius-dark w-100' rows={2} style={{ borderRadius: '4px' }} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name='selfPromotion'
                          className='mt-4 text-light'
                          label={TranslationKey('artist.definitions')}
                          rules={[{ required: true, message: 'Please input your message!' }]}
                        >
                          <TextArea className='input-radius-dark w-100' rows={2} style={{ borderRadius: '4px' }} />
                        </Form.Item>
                      </Col>
                      <Divider className='text-primary'>{TranslationKey('artist.styleOfWork')}</Divider>
                      <Col span={12}>
                        <Form.Item 
                        name='styleOfWork' 
                        className='mt-4 text-light' 
                        label={TranslationKey('registerArtist.youCanChoose')}
                        rules={[{ required: true, message: 'Please choose minimum 1!' }]}
                        >
                          <Checkbox.Group>
                            <Row>
                              {styleOfWork
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((item) => (
                                  <Col key={item.sequence} span={24}>
                                    <Checkbox onChange={onStyleOfWorkChange} className='text-white' value={item.value}>
                                      {item.name}
                                    </Checkbox>
                                  </Col>
                                ))}
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                          name='styleOfWorkOther'
                          className={`text-light small-input ${isShowOtherStyleOfWork ? '' : 'hide'}`}
                        >
                          <Input className='input-radius-dark' placeholder='Other' size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name='platForm'
                          className='mt-4 text-light'
                          label=
                          {TranslationKey('registerArtist.doYouSellYourWork')}
                          rules={[{ required: true, message: 'Please choose minimum 1!' }]}
                        >
                          <Checkbox.Group>
                            <Row>
                              {platForm
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((item) => (
                                  <Col key={item.sequence} span={24}>
                                    <Checkbox onChange={onPlatformChange} className='text-white' value={item.value}>
                                      {item.name}
                                    </Checkbox>
                                  </Col>
                                ))}
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                          name='platFormMySite'
                          className={`text-light small-input ${isShowMysitePlatform ? '' : 'hide'}`}
                        >
                          <Input className='input-radius-dark' placeholder='MySite' size='large' />
                        </Form.Item>
                        <Form.Item
                          name='platFormOther'
                          className={`text-light small-input ${isShowOtherPlatform ? '' : 'hide'}`}
                        >
                          <Input className='input-radius-dark' placeholder='Other' size='large' />
                        </Form.Item>
                      </Col>
                      <Col span={24} className='text-center'>
                        <Checkbox value={isAccept} className='my-3' onChange={() => setIsAccept(!isAccept)}>
                          <span className='text-light'>
                            {TranslationKey('iAccept')}{' '}
                            <Button type='text' className='btn-link px-1' onClick={() => setIsOpenTerm(true)}>
                            {TranslationKey('hTermOfService')}
                            </Button>{' '}
                            {TranslationKey('and')}{' '}
                            <Button type='text' className='btn-link px-1' onClick={() => setIsOpenPolicy(true)}>
                            {TranslationKey('hPrivacyPolicy')}
                            </Button>
                          </span>
                        </Checkbox>
                      </Col>
                      <Col span={8} offset={8}>
                        <Button
                          shape='round'
                          type='primary'
                          className='btn btn-primary w-100'
                          htmlType='submit'
                          size='large'
                          onClick={onValidateSocial}
                          disabled={!isAccept}
                        >
                            {TranslationKey('button.submit')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {
                artist && artist.artist && (
                  artist.artist.status === ArtistStatus.Rejected ? (
                    <Row className='pt-5' justify='center'>
                      <Col span={24}>
                        <Result
                          status='warning'
                          title='Rejected Registration'
                          subTitle='We regret to inform you that your application has been unsuccessful. You are welcome to reapply after 3 months.'
                          className='result-order'
                          extra={[
                            <a href='/' className='btn-orange-round' style={{ textDecoration: 'none' }}>
                              {TranslationKey('goToHome')}
                            </a>,
                          ]}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row className='pt-5' justify='center'>
                      <Col span={24}>
                        <Result
                          status='success'
                          title='Registration completed successfully'
                          subTitle='We will review in 1-2 days.'
                          className='result-order'
                          extra={[
                            <a href='/' className='btn-orange-round' style={{ textDecoration: 'none' }}>
                              {TranslationKey('goToHome')}
                            </a>,
                          ]}
                        />
                      </Col>
                    </Row>
                  )
                )
              }
            </>
          )
        }
      </div>
      <FullScreenMessageModal
        isModalOpen={isOpenTerm}
        onClose={() => setIsOpenTerm(false)}
        children={textTOS()} />
      <FullScreenMessageModal
        isModalOpen={isOpenPolicy}
        onClose={() => setIsOpenPolicy(false)}
        children={textPolicy()} />
    </div>
  );
}
