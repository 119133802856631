export const enum AssetStatus {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
}

export const enum AssetStatusName {
  DRAFT = 'Draft',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
}

export enum AssetType {
  IMAGE = 'Image',
  VIDEO = 'Video',
}

export const enum OrientationType {
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical',
}

export class AssetTypeConstants {
  static image: string = 'Image';
  static video: string = 'Video';
}

export const enum FillterAssetType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  All = 'All',
}

export const enum AssetComposition {
  INDOOR = 'Indoor',
  OUTDOOR = 'Outdoor',
}

export const enum AssetUsage {
  Commercial = 'Commercial',
  Editorial = 'Editorial',
  AiGenerated = 'AiGenerated',
}

export const enum DownloadFileName {
  Prefix = 'AliveUnited_'
}

export const enum AnimationSliderClassName {
  Slow = 'slider-track-slow',
  Fast = 'slider-track-fast'
}