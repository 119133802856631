import { createContext, useContext } from 'react';
import { ResponseSignIn, SignInFacebookModel, SignInGoogleModel, SignInModel } from '../models/accountModel';
import { AssetPublishModel } from 'src/models/assetModel';
import { UserProfile } from 'src/models/responses/userProfile';
import { SessionStatus } from 'src/constant/sessionStatus';
import { IArtistResponse } from '@/src/models/responses/artistResponse';

export type SessionContentType = {
  signInAsync: (model: SignInModel) => Promise<ResponseSignIn>,
  signInGoogleAsync: (model: SignInGoogleModel) => Promise<ResponseSignIn>,
  signInFacebookAsync: (model: SignInFacebookModel) => Promise<ResponseSignIn>,
  getOrderAssets: () => AssetPublishModel[],
  countOrder: number,
  userProfile: UserProfile,
  updateOrderAssets: (orders: AssetPublishModel[]) => void,
  validateTokenExpire: () => void,
  isAuthenticated: SessionStatus,
  signOut: () => void
  artistProfile: IArtistResponse,
  avatarUrl: string,
  getUserProfileAsync: () => void,
  RemoveOrderAssets: () => void,
  isShowSearch: boolean,
  setDisableSearch: () => void,
  getCountOrder: () => void,
}

export const SessionContext = createContext<SessionContentType>({} as SessionContentType);

export const useSessionContext = () => useContext(SessionContext);