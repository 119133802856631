import { Row, Col, Typography, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CardStandardLicenses from "../../components/card/CardStandardLicenses";
import CardAdditionalLicenses from "../../components/card/CardAdditionalLicenses";
import { useEffect, useState } from "react";
import { PageDocument } from "src/constant/stringConstants";
import { LicenseService } from "src/services/licenseService";
import { HttpStatus } from "src/constant/responseStatus";
import { AssetType } from "src/constant/assetConstants";
import { ExtendedLicenseModel, StandardLicenseModel } from "src/models/LicenseModel";
import TranslationKey from "src/i18n/translation";
const { Title, Text } = Typography;

const imgLarge = require("../../assets/images/img-large.png");
const imgSmall = require("../../assets/images/img-small.png");
export default function Pricing() {
  const [standardLicenseImg, setStandardLicenseImg] = useState<StandardLicenseModel[]>();
  const [extendedLicenseImg, setExtendedLicenseImg] = useState<ExtendedLicenseModel[]>();
  const [standardLicenseVdo, setStandardLicenseVdo] = useState<StandardLicenseModel[]>();
  const [, setExtendedLicenseVdo] = useState<ExtendedLicenseModel[]>();

  useEffect(() => {
    document.title = `Pricing - ${PageDocument.Title}`;
    onGetLicenseImgAsync();
    onGetLicenseVdoAsync();
  }, []);

  const onGetLicenseImgAsync = async () => {
    const { data: responseStdData, status: responseStdStatus } =
      await new LicenseService().GetStandardLicenseByType(AssetType.IMAGE);

    if (responseStdStatus !== HttpStatus.OK) {
      return;
    }

    const { data: responseExtData, status: responseExtStatus } =
      await new LicenseService().GetExtendedLicenseByType(AssetType.IMAGE);

    if (responseExtStatus !== HttpStatus.OK) {
      return;
    }

    setStandardLicenseImg(responseStdData);
    setExtendedLicenseImg(responseExtData);
  };

  const onGetLicenseVdoAsync = async () => {
    const { data: responseStdData, status: responseStdStatus } =
      await new LicenseService().GetStandardLicenseByType(AssetType.VIDEO);

    if (responseStdStatus !== HttpStatus.OK) {
      return;
    }

    const { data: responseExtData, status: responseExtStatus } =
      await new LicenseService().GetExtendedLicenseByType(AssetType.VIDEO);

    if (responseExtStatus !== HttpStatus.OK) {
      return;
    }

    setStandardLicenseVdo(responseStdData);
    setExtendedLicenseVdo(responseExtData);
  };

  return (
    <div className="licensing pt-5 banner-margin-t-20">
      <div className="container">
        <Title className="text-light text-center mb-0" level={1}>
          {TranslationKey('pricing.ourTermsOf')}{" "}
          <span className="text-gradient">in a nutshell</span>
        </Title>

        <Row className="mt-5" justify="center">
          <Col xs={24} lg={14} className="flex-center-items">
            <Row justify="end">
              <Col md={24} lg={12} className="flex-center-items">
                <div className="text-center">
                  <img src={imgSmall} alt="" width={150} />
                  <br />
                  <Text className="text-light text-gray">72 Dpi</Text>
                </div>
              </Col>
              <Col md={24} lg={12} className="flex-center-items">
                <div className="text-center">
                  <img src={imgLarge} alt="" width={230} className="ms-3" />
                  <br />
                  <Text className="text-light text-gray">300 Dpi</Text>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={10}>
            <Text className="text-light">
              {TranslationKey('pricing.allOfOurImages')}
            </Text>
            <ul className="ul text-gray">
              <li>{TranslationKey('pricing.allRoyalty')}</li>
              <li>{TranslationKey('pricing.simplePricing')}</li>
              <li>{TranslationKey('pricing.unlimited')}</li>
              <li>{TranslationKey('pricing.globalAccessibility')}</li>
              <li>{TranslationKey('pricing.exclusiveContents')}</li>
              <li>{TranslationKey('pricing.noMonthly')}</li>
              <li>{TranslationKey('pricing.noMinimum')}</li>
              <li>{TranslationKey('pricing.noDaily')}</li>
              <li>{TranslationKey('pricing.noSeat')}</li>
              <li>{TranslationKey('pricing.noHidden')}</li>
            </ul>
          </Col>
        </Row>

        <Title className="text-light text-center mb-0" level={1}>
        {TranslationKey('pricing.title')}, 
        <span className="text-gradient">{TranslationKey('pricing.pricingMade')}</span>
        </Title>

        <Row className="mt-5" justify="center">
          <Col xs={24} lg={20}>
            <div className="bg-outline-gray mt-2 radius-32 card-pricing">
              <Title className="text-primary text-center mb-0" level={4}>
              {TranslationKey('pricing.standard')}{" "} 
              <span className="text-primary-50">{TranslationKey('pricing.Licenses')} </span>
              </Title>
              <div className="div-licenses-main">
                <div className="div-licenses">
                  <CardStandardLicenses
                    head="Photos"
                    title="Fresh Alive Image for you everyday needs"
                    price={
                      standardLicenseImg?.filter(x => x.code === 'SM')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    type1={
                      standardLicenseImg?.filter(x => x.code === 'SM')[0].name
                    }
                    priceType1={
                      standardLicenseImg?.filter(x => x.code === 'SM')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    type2={
                      standardLicenseImg?.filter(x => x.code === 'XL')[0].name
                    }
                    priceType2={
                      standardLicenseImg?.filter(x => x.code === 'XL')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    icon="img"
                  />
                  <Title className="text-primary text-center my-3" level={4}>
                  {TranslationKey('pricing.Licenses')}
                  </Title>
                  <CardStandardLicenses
                    head="Videos"
                    title="Moment Alive Video for you Passion needs"
                    price={
                      standardLicenseVdo?.filter(x => x.code === 'HD')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    type1={
                      standardLicenseVdo?.filter(x => x.code === 'HD')[0].name
                    }
                    priceType1={
                      standardLicenseVdo?.filter(x => x.code === 'HD')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    type2={
                      standardLicenseVdo?.filter(x => x.code === 'UHD')[0].name
                    }
                    priceType2={
                      standardLicenseVdo?.filter(x => x.code === 'UHD')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    icon="vdo"
                  />
                </div>
              </div>

              <Divider orientation="center" className="border-light">
                <div className="circle-50">
                  <PlusOutlined className="text-light" />
                </div>
              </Divider>

              <Title className="text-primary text-center mb-0" level={4}>
              {TranslationKey('pricing.additionalExtended')}{" "}
                <span className="text-primary-50">{TranslationKey('pricing.Licenses')}</span>
              </Title>

              <Title className="text-light text-center mb-0" level={4}>
              {TranslationKey('pricing.possibility')}
              </Title>

              <Row gutter={16} className="mt-3">
                <Col className="gutter-row mb-2" xs={12} md={8} lg={4}>
                  <CardAdditionalLicenses
                    head={
                      extendedLicenseImg?.filter(x => x.code === 'MPFR')[0].name
                    }
                    price={
                      extendedLicenseImg?.filter(x => x.code === 'MPFR')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    contact={false}
                  />
                </Col>
                <Col className="gutter-row mb-2" xs={12} md={8} lg={4}>
                  <CardAdditionalLicenses
                    head={
                      extendedLicenseImg?.filter(x => x.code === 'EX6M')[0].name
                    }
                    price={
                      extendedLicenseImg?.filter(x => x.code === 'EX6M')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    contact={false}
                  />
                </Col>
                <Col className="gutter-row mb-2" xs={12} md={8} lg={4}>
                  <CardAdditionalLicenses
                    head={
                      extendedLicenseImg?.filter(x => x.code === 'EX1Y')[0].name
                    }
                    price={
                      extendedLicenseImg?.filter(x => x.code === 'EX1Y')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    contact={false}
                  />
                </Col>
                <Col className="gutter-row mb-2" xs={12} md={8} lg={4}>
                  <CardAdditionalLicenses
                    head={
                      extendedLicenseImg?.filter(x => x.code === 'EX2Y')[0].name
                    }
                    price={
                      extendedLicenseImg?.filter(x => x.code === 'EX2Y')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    contact={false}
                  />
                </Col>
                <Col className="gutter-row mb-2" xs={12} md={8} lg={4}>
                  <CardAdditionalLicenses
                    head={
                      extendedLicenseImg?.filter(x => x.code === 'EX3Y')[0].name
                    }
                    price={
                      extendedLicenseImg?.filter(x => x.code === 'EX3Y')[0].price.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })
                    }
                    contact={false}
                  />
                </Col>
                <Col className="gutter-row mb-2" xs={12} md={8} lg={4}>
                  <CardAdditionalLicenses
                    head={
                      extendedLicenseImg?.filter(x => x.code === 'EX3PLUS')[0].name
                    }
                    contact={true}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
