import { Form, Select } from 'antd';

interface Props {
  label?: string;
  name?: string;
  SizeType: 'large' | 'middle' | 'small';
  className?: string;
}

export default function Selecter({ name, label, SizeType = 'middle', className }: Props) {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <Form.Item name={name} label={label}>
      <Select
        defaultValue="lucy"
        size={SizeType}
        className={className}
        onChange={handleChange}
        options={[
          {
            value: 'jack',
            label: 'Jack',
          },
          {
            value: 'lucy',
            label: 'Lucy',
          },
        ]}
      />
    </Form.Item>
  );
}