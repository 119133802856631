import { PageDocument } from "src/constant/stringConstants";
import { Col, Divider, Row } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import TranslationKey from "src/i18n/translation";

export default function TermOfService() {
    useEffect(() => {
        document.title = `Term of Service - ${PageDocument.Title}`;
    }, []);

  return (
    <div className="container py-5 banner-margin-t-50">
      <Row className="d-flex justify-content-center">
        <Col span={16}>
        <Title className="text-light" level={3}>
            {TranslationKey("termOfService.theAliveUnited")}
        </Title>
        <Title className="text-light" level={3}>
            {TranslationKey("termOfService.whereas.title")}
        </Title>
        <ol className="ol-item-spacing">
            <li>
                {TranslationKey("termOfService.whereas.1")}
            </li>
            <li>
                {TranslationKey("termOfService.whereas.2")}
            </li>
        </ol>
        <Title className="text-light" level={3}>
            {TranslationKey("termOfService.nowThereFore")}
        </Title>
          <Divider />

          <dl>
            <dt>
    <Title className="text-primary" level={3}>
        1. {TranslationKey("termOfService.definitions.title")}
    </Title>
          </dt>
          <dd className="dd-indent">
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.aliveUNitedService")}
              </span> 
              {TranslationKey("termOfService.definitions.meanTHeDistribution")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.artist")}
              </span> 
              {TranslationKey("termOfService.definitions.meansTHeLegalOwner")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.artsAndContents")}
              </span> 
              {TranslationKey("termOfService.definitions.meansAnyPhotographs")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.company")}
              </span> 
              {TranslationKey("termOfService.definitions.meansShots")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.customer")}
              </span> 
              {TranslationKey("termOfService.definitions.meansTheCompany")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.customerWork")}
              </span> 
              {TranslationKey("termOfService.definitions.meansANyOriginal")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.partner")}
              </span> 
              {TranslationKey("termOfService.definitions.meansAnyPersons")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.tosForArtist")}
              </span> 
              {TranslationKey("termOfService.definitions.meansTheTermsAndCondition")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.website")}
              </span> 
              {TranslationKey("termOfService.definitions.meansTheCompanyAndItsAffiliates")}
              <br /><br />
              <span className="fw-bold text-primary">
                  {TranslationKey("termOfService.definitions.websiteUser")}
              </span> 
              {TranslationKey("termOfService.definitions.meansAnyPersonOrLegalEntityWhoAccesses")}
          </dd>
                <dt>
              <Title className="text-primary" level={3}>
                2. {TranslationKey("termOfService.ownershipOfTheArts.title")}
              </Title>
            </dt>
            <dd className="dd-indent">
              <ol className="ol-item-spacing">
                <li>{TranslationKey("termOfService.ownershipOfTheArts.theCustomerAcknowledges")}</li>
                <li>{TranslationKey("termOfService.ownershipOfTheArts.exceptAsOtherwiseExpressly")}</li>
              </ol>
            </dd>
            <dt>
            <Title className="text-primary" level={3}>
              3. {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.title")}
            </Title>
          </dt>
          <dd className="dd-indent">
            <Title className="text-primary" level={4}>
              3.1 {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.title")}
            </Title>
            <div style={{marginLeft: 30}}>
              <ol className="ol-item-spacing">
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.1")}
                  <br/><br/>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.forAvoidanceOfDoubt")}
                </li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.2")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.3")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.4")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.standardLicense.5")}</li>
              </ol>
            </div>
          </dd>
          <dd className="dd-indent">
            <Title className="text-primary" level={4}>
              3.2 {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.title")}
            </Title>
            <div style={{marginLeft: 30}}>
              <ol className="ol-item-spacing">
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.1")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.2")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.3")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.4")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.forAvoidanceOfDoubt")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.5")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.exclusiveMarketFreezeLicense.6")}</li>
              </ol>
            </div>
          </dd>
          <dd className="dd-indent">
            <Title className="text-primary" level={4}>
              3.3 {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.compositeLayoutAccess.title")}
            </Title>
            <div style={{marginLeft: 30}}>
              <ol className="ol-item-spacing">
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.compositeLayoutAccess.1")}
                </li>
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.compositeLayoutAccess.2")}
                </li>
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.compositeLayoutAccess.3")}
                </li>
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.compositeLayoutAccess.4")}
                </li>
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.compositeLayoutAccess.5")}
                </li>
              </ol>
            </div>
          </dd>
          <dd className="dd-indent">
            <Title className="text-primary" level={4}>
              3.4 {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.transferOfCopyrightedWorks.title")}
            </Title>
            <div style={{marginLeft: 30}}>
              <ol className="ol-item-spacing">
                <li>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.transferOfCopyrightedWorks.1")}
                  <br/><br/>
                  {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.transferOfCopyrightedWorks.theCustomerAcknowledges")}
                </li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.transferOfCopyrightedWorks.2")}</li>
              </ol>
            </div>
          </dd>
          <dd className="dd-indent">
            <Title className="text-primary" level={4}>
             3.5 {TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.merchandiseAndProductForSaleOrPromotionalDistributionLicense.title")}
            </Title>
            <div style={{marginLeft: 30}}>
              <ol className="ol-item-spacing">
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.merchandiseAndProductForSaleOrPromotionalDistributionLicense.1")}</li>
                <li>{TranslationKey("termOfService.subLicensingAccessingCopyrightTransferOfTheArtsAndContents.merchandiseAndProductForSaleOrPromotionalDistributionLicense.2")}</li>
              </ol>
            </div>
          </dd>
            <dt>
            <Title className="text-primary" level={3}>
            4. Restrictions of Use
              </Title>
            </dt>
            <dd className="dd-indent">
            <p>4.1{TranslationKey("termOfService.restrictionsOfUse.theUseOfTheArtsAndContents")}</p>
            </dd>
            <dd className="dd-indent">
            <p>4.2{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.title")}</p>
            <ol className="ol-item-spacing">
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.1")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.2")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.3")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.4")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.5")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.6")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.7")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.8")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.9")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.10")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.11")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.12")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToAny.13")}</li>
            </ol>
          </dd>
          <dd className="dd-indent">
            <p>4.3 {TranslationKey("termOfService.restrictionsOfUse.inRelationToTheCreditAttributions.title")}</p>
            <ol className="ol-item-spacing">
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToTheCreditAttributions.1")}</li>
              <li>{TranslationKey("termOfService.restrictionsOfUse.inRelationToTheCreditAttributions.2")}</li>
            </ol>
            <p>{TranslationKey("termOfService.restrictionsOfUse.waiverOfCreditAndCopyRight")}</p>
          </dd>
            <dt>
            <Title className="text-primary" level={3}>
        5. {TranslationKey("termOfService.customerAccount.title")}
      </Title>
      <dd className="dd-indent">
        <ol className="ol-item-spacing">
          <li>{TranslationKey("termOfService.customerAccount.1")}</li>
          <li>{TranslationKey("termOfService.customerAccount.2")}</li>
          <li>{TranslationKey("termOfService.customerAccount.3")}</li>
        </ol>
      </dd>
      <dt>
        <Title className="text-primary" level={3}>
         6. {TranslationKey("termOfService.taxAndPayment.title")}
        </Title>
      </dt>
      <dd className="dd-indent">
        <ol className="ol-item-spacing">
          <li>{TranslationKey("termOfService.taxAndPayment.1")}</li>
          <li>{TranslationKey("termOfService.taxAndPayment.2")}</li>
          <li>{TranslationKey("termOfService.taxAndPayment.3")}</li>
        </ol>
      </dd>
      <dt>
        <Title className="text-primary" level={3}>
         7. {TranslationKey("termOfService.representationsAndWarranties.title")}
        </Title>
      </dt>
          <dd className="dd-indent">
            <p>
              7.1 {TranslationKey("termOfService.representationsAndWarranties.71.title")}
            </p>
            <ol className="ol-item-spacing">
              <li>{TranslationKey("termOfService.representationsAndWarranties.71.1")}</li>
              <li>{TranslationKey("termOfService.representationsAndWarranties.71.2")}</li>
              <li>{TranslationKey("termOfService.representationsAndWarranties.71.3")}</li>
            </ol>
            <p>
              7.2 {TranslationKey("termOfService.representationsAndWarranties.72.title")}
            </p>
            <ol className="ol-item-spacing">
              <li>{TranslationKey("termOfService.representationsAndWarranties.72.1")}</li>
              <li>{TranslationKey("termOfService.representationsAndWarranties.72.2")}</li>
              <li>{TranslationKey("termOfService.representationsAndWarranties.72.3")}</li>
            </ol>
            <p>{TranslationKey("termOfService.representationsAndWarranties.inTheEventThatThePersonEntering")}</p>
          </dd>
          <Title className="text-primary" level={3}>
            8. {TranslationKey("termOfService.indemnification.title")}
          </Title>
            </dt>
            <dd className="dd-indent">
        <ol className="ol-item-spacing">
          <li>{TranslationKey("termOfService.indemnification.1")}</li>
          <li>{TranslationKey("termOfService.indemnification.2")}</li>
          <li>{TranslationKey("termOfService.indemnification.3")}</li>
          <li>{TranslationKey("termOfService.indemnification.4")}</li>
          <li>{TranslationKey("termOfService.indemnification.5")}</li>
          <li>{TranslationKey("termOfService.indemnification.6")}</li>
        </ol>
      </dd>
      <dt>
        <Title className="text-primary" level={3}>
          9. {TranslationKey("termOfService.termination.title")}
        </Title>
      </dt>
      <dd className="dd-indent">
        <ol className="ol-item-spacing">
          <li>{TranslationKey("termOfService.termination.1")}</li>
          <li>{TranslationKey("termOfService.termination.2")}</li>
          <li>{TranslationKey("termOfService.termination.3")}</li>
        </ol>
      </dd>
      <dt>
        <Title className="text-primary" level={3}>
         10. {TranslationKey("termOfService.otherAgreements.title")}
        </Title>
      </dt>
      <dd className="dd-indent">
        <ol className="ol-item-spacing">
          <li>{TranslationKey("termOfService.otherAgreements.1")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.2")} <a href="/privacypolicy">{TranslationKey('here')}</a>.</li>
          <li>{TranslationKey("termOfService.otherAgreements.3")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.4")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.5")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.6")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.7")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.8")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.9")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.10")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.11")}</li>
          <li>{TranslationKey("termOfService.otherAgreements.12")}</li>
        </ol>
      </dd>
            <dt>
              <Title className="text-primary" level={3}>
              {TranslationKey("termOfService.effectiveDate.title")}
              </Title>
            </dt>
            <dd className="dd-indent">
            {TranslationKey("termOfService.effectiveDate.date")}
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              {TranslationKey("termOfService.lastUpdatedDate.title")}
              </Title>
            </dt>
            <dd className="dd-indent">
            {TranslationKey("termOfService.lastUpdatedDate.date")}
            </dd>
          </dl>
          <Divider/>
          <Title className="text-light" level={3}>
          {TranslationKey("termOfService.termsOfUseForWebsiteUser.title")}
          </Title>
          <p>{TranslationKey("termOfService.termsOfUseForWebsiteUser.thisTermsOfUseForWebsite")} </p>
          <p>{TranslationKey("termOfService.termsOfUseForWebsiteUser.byAccessingBrowsing")}</p>
          <p>{TranslationKey("termOfService.termsOfUseForWebsiteUser.thisTermsOfUseMay")}</p>
          <Divider/>
          <Title className="text-light" level={3}>
            Now, therefore, the Website User further agrees as follows:
          </Title>
          <Divider/>
          <dl>
            <dt>
            <Title className="text-primary" level={3}>
                1. {TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.title')}
            </Title>
            </dt>
            <dd className="dd-indent">
            <ol className="ol-item-spacing">
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.1')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.2')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.3')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.4')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.5')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.6')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.7')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.8')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.9')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.10')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.11')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.12')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.1.13')}</li>
            </ol>
          </dd>
          <dt>
            <Title className="text-primary" level={3}>
              2. {TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.2.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
            <ol className="ol-item-spacing">
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.2.1')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.2.2')}</li>
            </ol>
          </dd>
          <dt>
            <Title className="text-primary" level={3}>
              3. {TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.3.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
            <ol className="ol-item-spacing">
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.3.1')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.3.2')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.3.3')} <a href="/contactus">{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.3.4')}</a></li>
            </ol>
          </dd>
          <dt>
            <Title className="text-primary" level={3}>
            4. {TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.4.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
            <ol className="ol-item-spacing">
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.4.1')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.4.2')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.4.3')}</li>
            </ol>
          </dd>
          <dt>
            <Title className="text-primary" level={3}>
             5.  {TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.5.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
            <ol className="ol-item-spacing">
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.5.1')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.5.2')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.5.3')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.5.4')}</li>
            </ol>
          </dd>
          <dt>
            <Title className="text-primary" level={3}>
             6. {TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.6.title')}
            </Title>
          </dt>
          <dd className="dd-indent">
            <ol className="ol-item-spacing">
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.6.1')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.6.2')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.6.3')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.6.4')}</li>
              <li>{TranslationKey('termOfService.nowThereforeTheWebsiteUserFurtherAgreeAsFollows.6.5')}</li>
            </ol>
          </dd>
            <dt>
            <Title className="text-primary" level={3}>
             {TranslationKey('termOfService.effectiveDate.title')}
              </Title>
            </dt>
            <dd className="dd-indent">
            {TranslationKey('termOfService.effectiveDate.date')}
            </dd>
            <dt>
            <Title className="text-primary" level={3}>
            {TranslationKey('termOfService.lastUpdatedDate.title')}
              </Title>
            </dt>
            <dd className="dd-indent">
            {TranslationKey('termOfService.lastUpdatedDate.date')}
            </dd>
          </dl>
        </Col>
      </Row>
    </div>
  );
}
