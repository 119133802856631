import { RouteNames } from 'src/routes/routeName';
import { Button, Col, Result, Row, Space } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HttpStatus } from 'src/constant/responseStatus';
import { OrderService } from 'src/services/orderService';
import { OrderDetailModel, PaymentInfoPromptpayModel } from 'src/models/OrderModel';
import { OrderStatus } from 'src/constant/statusConstants';
import { PaymentType } from 'src/constant/PaymentType';
import Title from 'antd/es/typography/Title';
import Countdown from 'react-countdown';
import sessionStore from '../../store/sessionStore';
import { Session } from '../../constant/sesstion';
import TranslationKey from 'src/i18n/translation';

const signalR = require('@microsoft/signalr');

const path = process.env.REACT_APP_API_PATH;

export default function Payment() {
  const [searchParams] = useSearchParams();
  const [promptPay, setPromptPay] = useState<PaymentInfoPromptpayModel>({} as PaymentInfoPromptpayModel);
  const [orderDetail, setOrderDetail] = useState<OrderDetailModel>({} as OrderDetailModel);
  const [isExpire, setIsExpire] = useState(true);
  const [orderId, setOrderId] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const session = sessionStore.get(Session.SESSION_ACCESS_TOKEN);
    const accessToken = `${session.token_type} ${session.access_token}`;
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${path}ws/payment`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => accessToken,
      })
      .build();

    hubConnection.start().then(() => {
      console.log('SignalR connected');
    });

    hubConnection.on(
      'PaymentPending',
      (paymentId: string, referenceId: string, chargeId?: string, additionalData?: string) => {
        console.log('PaymentPending => ', paymentId, referenceId, chargeId, additionalData);
      }
    );

    hubConnection.on(
      'PaymentCompleted',
      (paymentId: string, referenceId: string, chargeId?: string, additionalData?: string) => {
        console.log('PaymentCompleted => ', paymentId, referenceId, chargeId, additionalData);
        navigate(RouteNames.successOrder);
      }
    );

    hubConnection.on(
      'PaymentFailed',
      (paymentId: string, referenceId: string, chargeId?: string, additionalData?: string) => {
        console.log('PaymentFailed => ', paymentId, referenceId, chargeId, additionalData);
        navigate(RouteNames.failedOrder);
      }
    );

    hubConnection.on(
      'PaymentRefunded',
      (paymentId: string, referenceId: string, chargeId?: string, additionalData?: string) => {
        console.log('PaymentRefunded => ', paymentId, referenceId, chargeId, additionalData);
        navigate(RouteNames.failedOrder);
      }
    );

    return () => {
      hubConnection.stop();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const orderId = searchParams.get('id');

    if (orderId) {
      setOrderId(orderId);
      onGetOrderByIdAsync(orderId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onGetOrderByIdAsync = async (orderId: string) => {
    const { data: responseData, status: responseStatus } = await new OrderService().getOrderByIdAsync(orderId);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    if (responseData.paymentMethod === PaymentType.CreditCard || responseData.status !== OrderStatus.Pending) {
      navigate(RouteNames.successOrder);
      return;
    }

    setOrderDetail(responseData);
    await onGetOrderPromptPayByIdAsync(orderId);
  };

  const onGetOrderPromptPayByIdAsync = async (orderId: string) => {
    const { data: responseData, status: responseStatus } = await new OrderService().getOrderPendingByIdAsync(orderId);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    const dateNow = new Date();
    const expireAt = new Date(responseData.expireAt);

    if (dateNow < expireAt && responseData.status !== 'Completed') {
      setIsExpire(false);
      setPromptPay(responseData);
    }
  };

  if (isExpire) {
    return (
      <Row className='mt-4 mb-4'>
        <Col
          span={16}
          offset={4}
        >
          <Result
            status='warning'
            title={TranslationKey('orderPayment.orderIsExpired')}
            subTitle={TranslationKey('orderPayment.pleaseTryToBuyAsset')}
            className='result-order'
            extra={[
              <a
                href={RouteNames.default}
                className='btn-orange-round'
                style={{ textDecoration: 'none' }}
              >
                {TranslationKey('goToHome')}
              </a>,
            ]}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row className='mt-4 mb-4'>
        <Col
          span={16}
          offset={4}
        >
          {promptPay.paymentId && !isExpire && (
            <div className='result-order text-center p-4'>
              <Space
                direction='vertical'
                size={'large'}
              >
                <Title className='text-primary mb-4'>{TranslationKey('orderPayment.pleaseDoNotClose')}</Title>
                <Title className='text-light mb-4'>
                  <Countdown
                    date={promptPay.expireAt}
                    onComplete={() => onGetOrderByIdAsync(orderDetail.id)}
                    renderer={(props) => (
                      <div>
                        {props.hours}:{props.minutes}:{props.seconds}
                      </div>
                    )}
                  />
                </Title>
                <Title
                  className='text-light mb-4 mt-4'
                  level={4}
                >{`${TranslationKey('orderPayment.orderNo')} ${orderDetail.code}`}</Title>
                <img
                  src={promptPay.qrCodeUri}
                  width={400}
                  alt='alive-payment'
                />
                <Title className='text-primary mt-4'>
                  {`${promptPay.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}`}
                </Title>
              </Space>
              <div className='mt-4'>
                <Button
                  size='large'
                  type='default'
                  onClick={() => onGetOrderByIdAsync(orderId)}
                  style={{ textDecoration: 'none' }}
                >
                  {TranslationKey('orderPayment.clickForConfirmPayment')}
                </Button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
