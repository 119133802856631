import { SearchOutlined } from '@ant-design/icons';
import { Input, Modal, Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SetNavigateParams } from '../../helper/SetNavigateParams';

const queryToString = require("querystring");

export default function SearchInput() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');
  const [searchTag, setSearchTag] = useState(searchParams.get('k') || '');
  const [searchColor, setSearchColor] = useState(searchParams.get('c') || '');
  const [searchArtis, setSearchArtis] = useState(searchParams.get('a') || '');
  const [searchOrientation, ] = useState(searchParams.get('o') || '');
  const [searchAssetType, ] = useState(searchParams.get('t') || '');
  const [searchAssetUsage, ] = useState(searchParams.get('u') || '');

  useEffect(() => {
    const querySearch = searchParams.get("q");
    const queryTag = searchParams.get("k");
    const queryColor = searchParams.get("c");
    const queryArtis = searchParams.get("a");

    form.setFieldValue('search', querySearch);

    setSearchTag(queryTag ?? '');
    setSearchColor(queryColor ?? '');
    setSearchArtis(queryArtis ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSearch = useCallback(() => {
    if(!getFindValueFromArrayTag(searchTag, searchQuery) && !getFindValueFromArrayTag(searchArtis, searchQuery)){
      const queryParams = new SetNavigateParams().setNavbarQueryString(searchQuery, searchTag, searchColor, searchArtis, searchOrientation, searchAssetType, searchAssetUsage);
      const stringQuery = queryToString.stringify(queryParams);

      setSearchParams(queryParams);
      navigate(`/search?${stringQuery}`);
    } else{
      setSearchQuery('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchTag, searchColor, searchArtis]);

  const getFindValueFromArrayTag = (values: string, value: string) => {
    const array = values.split(',');

    return array.findIndex(x => x === value) >= 0;
  };

  return (
    <>
      <Form
        form={form}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
      >
        <Form.Item name="search"
          style={{
            marginBottom: 0,
            marginLeft: '5%',
            marginRight: '5%'
            }}>
          <Input
            placeholder="Search by keywords, or artists"
            className="input-radius-dark py-2"
            prefix={<SearchOutlined className="icon pe-3 ps-1" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            size='large'
          />
        </Form.Item>
      </Form>
      <Modal
        title="Basic Modal"
        style={{ backgroundColor: '#0A0A09' }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </>
  );
}
