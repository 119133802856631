import Axios from '../helper/Axios';
import { ArtistCreatingModel } from '../models/accountModel';
import { ContactUsModel } from '../models/contactUsModel';

const SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_URL;
const SALESFORCE_OID = process.env.REACT_APP_SALESFORCE_OID;

function postCreateUserSaleforceAsync(email: string) {

  var body = new FormData();
  body.append('oid', SALESFORCE_OID ? SALESFORCE_OID.toString() : '');
  body.append('retURL', 'https://not-theshots.co/');
  body.append('00N2v00000UbJ37', 'Client');
  body.append('first_name', '');
  body.append('last_name', '');
  body.append('title', '');
  body.append('email', email);
  body.append('phone', '');
  body.append('company', '');
  body.append('website', '');
  body.append('country', '');
  body.append('message', '');

  return Axios({
    baseURL: SALESFORCE_URL,
    method: 'post',
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function postCreateArtistSaleforceAsync(data: ArtistCreatingModel) {
  var body = new FormData();
  body.append('oid', SALESFORCE_OID ? SALESFORCE_OID.toString() : '');
  body.append('retURL', 'https://not-theshots.co/');
  body.append('00N2v00000UbJ37', 'Contributor');
  body.append('first_name', data.nameInfoEN_Firstname);
  body.append('last_name', data.nameInfoEN_Lastname);
  body.append('email', data.email);
  body.append('phone', data.phone);
  body.append('website', data.platform_Mysite);
  body.append('country', '');
  body.append('00N2u000000eKdr', data.socialMediaInfo_Facebook);
  body.append('00N2u000000eKdw', data.socialMediaInfo_Instagram);

  return Axios({
    baseURL: SALESFORCE_URL,
    method: 'post',
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function postContactSaleforceAsync(data: ContactUsModel) {
  var body = new FormData();
  body.append('oid', SALESFORCE_OID ? SALESFORCE_OID.toString() : '');
  body.append('retURL', 'https://not-theshots.co/');
  body.append('00N2v00000UbJ37', 'Client');
  body.append('first_name', data.firstname);
  body.append('last_name', data.lastname ? data.lastname : '');
  body.append('title', '');
  body.append('email', data.email);
  body.append('phone', data.phone);
  body.append('company', '');
  body.append('website', '');
  body.append('country', '');
  body.append('00N2v00000UbJ2y', data.message);

  return Axios({
    baseURL: SALESFORCE_URL,
    method: 'post',
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postCreateUserSaleforceAsync,
  postCreateArtistSaleforceAsync,
  postContactSaleforceAsync,
}