import { Row, Col, Tabs, TabsProps, Button, Typography } from "antd";
import CollectionAll from "../components/collection/CollectionAll";
import Banner from "../components/banner/Banner";
import GallerysTrending from "../components/gallery/GallerysTrending";
import Recommended from "../components/recommended";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSessionContext } from "../context/session-context";
import { SessionStatus } from "../constant/sessionStatus";
import { RouteNames } from "../routes/routeName";
import NewsUpdate from "../components/news-update";
import { FillterAssetType } from "../constant/assetConstants";
import { PageDocument } from "../constant/stringConstants";
import TranslationKey from "../i18n/translation";

export default function Home() {
  const { isAuthenticated } = useSessionContext();
  const [tabValue, setTabValue] = useState('All');

  const items: TabsProps["items"] = [
    {
      key: FillterAssetType.All,
      label: `All`,
    },
    {
      key: FillterAssetType.IMAGE,
      label: `Photos`,
    },
    {
      key: FillterAssetType.VIDEO,
      label: `Videos`,
    },
  ];

  useEffect(() => {
    document.title = `${PageDocument.Title} - Exclusive Gallery Driven by Passion`;
  }, []);

  const onChange = (key: string) => {
    setTabValue(key);
  };

  return (
    <div className="home">
      <Row className="banner banner-margin-t-70">
        <Col span={24}>
          <Banner />
        </Col>
      </Row>

      <div className="container">
        <Typography.Title className="text-light my-3" level={1}>
          {TranslationKey('home.trending')}
        </Typography.Title>
        <Tabs
          tabBarExtraContent={
            <Link to="/search" className="text-light">{TranslationKey('home.viewAll')}</Link>
          }
          defaultActiveKey={FillterAssetType.All}
          items={items}
          onChange={onChange}
        />
        <GallerysTrending key={'gallerys-trending-page-index'} assetType={tabValue} />
      </div>

      <div className="container my-5">
        <Typography.Title className="text-light my-5" level={1}>
        {TranslationKey('home.collections')}
        </Typography.Title>
        <div className="collection">
          <Row justify="space-between" gutter={16}>
            <CollectionAll />
          </Row>
        </div>
      </div>

      <div className="container mt-5">
        <Typography.Title className="text-light my-5" level={1}>
        {TranslationKey('home.recommended')}
        </Typography.Title>
      </div>
      <Recommended />

      <div className="container my-5">
        <Typography.Title className="text-light my-5" level={1}>
        {TranslationKey('home.contentUpdate')}
        </Typography.Title>
        <div className="artist-trust ">
          <div className="box-gradient-bottom" />
          <div className="">
            <NewsUpdate />
          </div>
        </div>
      </div>

      <div className="container my-5 py-5">
        <div className="our-artist">
          <div className="text">
            <h1 className="text-light">{TranslationKey('home.becomeOurArtist')}</h1>
            <p className="text-light">
            {TranslationKey('home.uploadEarnGetRecognized')}
            </p>
            {
              isAuthenticated === SessionStatus.UNAUTHENTICATED ? (
                <a href="/register">
                  <Button type="primary" shape="round" className="dark" size="large">
                    {TranslationKey('register.title')}
                  </Button>
                </a>
              ) :
              <>
                  <Row justify={'center'}>
                    <div style={{margin: 5}} >
                      <a href={RouteNames.welcomeArtist}>
                          <Button type="primary" shape="round" className="dark" size="large">
                          {TranslationKey('register.title')}
                          </Button>
                        </a>
                    </div>
                  </Row>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}