import { Badge, Col, Menu, MenuProps, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSessionContext } from 'src/context/session-context';
import { BellOutlined } from '@ant-design/icons';
import { RouteNames } from 'src/routes/routeName';
import NotificationAlive from '../drawer/notification';



type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function CustomHeader() {
  const { userProfile, signOut } = useSessionContext();
  const navigate = useNavigate();
  const [isOpenNotify, setIsOpenNotify] = useState(false);
  const [countNotify, setCountNotify] = useState(0);

  const customMenus: MenuProps['items'] = [
    getItem(
      <>
        <Badge count={countNotify} size="small" className='badge-notify'>
          <BellOutlined
            style={{ fontSize: "18px" }}
            className="icon-only text-light"
          />
        </Badge>
      </>
      , 'notify'
      , null),
    getItem(userProfile.email, RouteNames.accountInfo, null),
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key !== 'logout' && e.key !== 'notify') {
      navigate(e.key);

      return;
    }
    else if (e.key === 'notify') {
      onClickNotify();
    }
    else {
      signOut();
    }
  };

  const onClickNotify = () => {
    setIsOpenNotify(true);
  };

  const handleCloseNotify = () => setIsOpenNotify(false);

  return (
    <div>
      <Menu
        theme="dark"
        mode="horizontal"
        // style={{ float: "right" }}
        disabledOverflow={true}
        onClick={onClick}
        selectable={false}
        items={customMenus}
      >
      </Menu>
      <NotificationAlive isOpen={isOpenNotify} onClose={handleCloseNotify} countNotify={setCountNotify} />
    </div>
  );
}
