import { RouteNames } from '../../routes/routeName';
import { Col, Result, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { AccountService } from '../../services/accountService';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import TranslationKey from 'src/i18n/translation';

export default function ConfirmChangeEmail() {
    const accountService = new AccountService();
    const [isVerify, setIsVerify] = useState(false);
    const [searchParams] = useSearchParams();

    const confirmEmailAsync = async (userId: string, newEmail: string, code: string) => {
        const response = await accountService.confirmChangeEmailAsync(userId, newEmail, code);

        if (response.status !== HttpStatus.OK) {
            setIsVerify(false);
            return;
        } else if (response.status === HttpStatus.OK) {
            setIsVerify(true);
            return;
        }
    };

    useEffect(() => {
        const userId = searchParams.get('userId');
        const newEmail = searchParams.get('newEmail');
        const code = searchParams.get('code');
        confirmEmailAsync(userId!, newEmail!, code!);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
            <Row className='mt-4 mb-4'>
                <Col span={16} offset={4}>
                    {isVerify ? (
                        <Result
                            status='success'
                            title={TranslationKey('confirmChangeEmail.successfully')}
                            subTitle={TranslationKey('confirmChangeEmail.welcome')}
                            className='result-order'
                            extra={[
                                <a href={RouteNames.login} className='btn-orange-round' style={{ textDecoration: 'none' }}>
                                    {TranslationKey('button.signIn')}
                                </a>,
                            ]}
                        />
                    ) : (
                        <Result
                            status='error'
                            title={TranslationKey('confirmChangeEmail.activateAccountFailed')}
                            subTitle={TranslationKey('confirmChangeEmail.something')}
                            className='result-order'
                            extra={[
                                <a
                                    href={RouteNames.default}
                                    className='btn-orange-round'
                                    style={{ textDecoration: 'none' }}
                                >
                                    {TranslationKey('hHome')}
                                </a>,
                            ]}
                        />
                    )}
                </Col>
            </Row>
    );
}