import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Col, Form, Input, InputRef, Row } from 'antd';

interface ModalInterface {
  isModalOpen: boolean;
  onClose: any;
  sharedUrl: string;
}

export default function ShareModal({ isModalOpen = false, onClose, sharedUrl }: ModalInterface) {
  const textInputRef = useRef<InputRef>(null);
  const [btnText, setBtnText] = useState("Copy to Share");
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('shareUrl', sharedUrl);  
  }, [sharedUrl]);

  useEffect(() => {
    if (isModalOpen) {
      setBtnText("Copy to Share");
    }
  }, [isModalOpen]);

  const onFinish = (values: any) => {
    textInputRef.current!.focus({ cursor: 'all', });
    navigator.clipboard.writeText(sharedUrl);
    setBtnText("Copied to Clipboard");
  };

  return (
    <Modal size='lg' show={isModalOpen} onHide={onClose} dialogClassName='fav-modal'>
      <Modal.Header>Share to My Friends</Modal.Header>
      <Modal.Body>
        <Row>
          <Col span={24}>
            <Form form={form} name='form_item_path' layout='vertical' onFinish={onFinish} className='form-input'>
              <Row gutter={[3, 1]}>
                <Col span={24} lg={18}>
                  <Form.Item
                    name='shareUrl'
                    className='text-light'
                    rules={[{ required: false, message: 'Please input your name!' }]}
                  >
                    <Input ref={textInputRef} className='input-radius-dark w-100' readOnly size='large' />
                  </Form.Item>
                </Col>
                <Col span={24} lg={6}>
                  <Button type='primary' size='large' shape='round' className='btn-primary' htmlType='submit' block>
                    {btnText}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
