import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { SaveArtistTaxInfoModel } from '../models/TaxCenterModel';

export class ArtistTaxInfoService {
    private _qs: any = require('qs');
    private apiController = '/ArtistTaxInfo';

    createArtistTaxInfoAsync(
      model: SaveArtistTaxInfoModel): Promise<AxiosResponse> {
        return Axios.post<any>(`${this.apiController}`, JSON.stringify(model));
    }

    updateArtistTaxInfoAsync(
      artistTaxInfoId: string,
      model: SaveArtistTaxInfoModel): Promise<AxiosResponse> {
        return Axios.put<any>(`${this.apiController}/${artistTaxInfoId}`, JSON.stringify(model));
    }

    getTaxInfoByIdAsync(artistTaxInfoId: string): Promise<AxiosResponse> {
      return Axios.get<any>(`${this.apiController}/${artistTaxInfoId}`);
    }

    getTaxInfoByAllTypeAsync(artistId: string, artistType: string, nationalityType: string): Promise<AxiosResponse<SaveArtistTaxInfoModel>> {
      return Axios.get<SaveArtistTaxInfoModel>(`${this.apiController}/artistId/${artistId}/artistType/${artistType}/nationalityType/${nationalityType}`);
    }
}