import { AssetType } from '../../constant/assetConstants';
import { HeartOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { AssetPublishModel } from 'src/models/assetModel';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSessionContext } from 'src/context/session-context';
import { ExtendedLicense } from 'src/constant/licenseConstants';
import { ExtendedLicenseModel, StandardLicenseModel } from 'src/models/LicenseModel';
import MyFavoriteModal from '../modal/myFavoriteModal';
import { RecommendAssetItemModel } from 'src/models/BannerModel';

interface RecommentItemInterface {
    sliderId: string,
    recommentAssetItemIndex: number;
    recommentAssetItem: RecommendAssetItemModel;
}

export default function RecommendItem(
    { 
        sliderId,
        recommentAssetItem,
        recommentAssetItemIndex,
    }: RecommentItemInterface) {
    const navigate = useNavigate();
    const { getOrderAssets, updateOrderAssets, getCountOrder, userProfile } = useSessionContext();
    const [isOpenFavorite, setIsOpenFavorite] = useState(false);

    const onClickItem = (assetId: string) => {
        window.location.href = '/asset/' + assetId;
    };

    const onClickFavorite = () => {
        if (userProfile && userProfile.userId) {
            setIsOpenFavorite(true);

            return;
        }

        message.open({
            type: 'error',
            content: 'Please login to use this feature.',
        });
    };

    const handleCloseFav = () => {
        setIsOpenFavorite(false);
    };

    const addToCard = (asset: AssetPublishModel) => {
        let orders = getOrderAssets();

        if (!orders) {
            orders = [];
        }
        //Check dubplicate
        const dubIndex = orders.findIndex((order) => order.id === asset.id);

        const isExtendedLicense = ExtendedLicense.NONE;

        //Check Nolicense
        const nolicenseIndex = orders.findIndex(
            (order) =>
                order.id === asset.id &&
                order.standardLicense.id === asset.standardLicense.id &&
                order.extendedLicense.code === ExtendedLicense.NONE
        );

        //Check has Extendedlicense
        const hasExtlicense =
            orders.filter(
                (order) =>
                    order.id === asset.id &&
                    order.standardLicense.id === asset.standardLicense.id &&
                    order.extendedLicense.code !== ExtendedLicense.NONE
            ).length > 0;

        //Check has add ExtendedLicense but not noneExtenededlicense.
        if (isExtendedLicense && nolicenseIndex > -1) {
            orders.splice(nolicenseIndex, 1);
        }

        //Check is select none but has extendedlicense or duplicate
        if ((!isExtendedLicense && hasExtlicense) || dubIndex > -1) {
            orders.splice(dubIndex, 1);
        }

        let extendedLicenseModel: ExtendedLicenseModel;
        let standardLicenseModel: StandardLicenseModel;

        if (asset.assetType === AssetType.IMAGE) {
            extendedLicenseModel = {
                id: 'e855df98-108d-4da3-a86a-bae81d326eca',
                assetType: AssetType.IMAGE,
                code: 'NONE',
                name: 'None Extended License',
                price: 0,
                liftTimeType: 'DAY',
                liftTime: 0,
                sequence: 1,
            };

            standardLicenseModel = {
                assetType: AssetType.IMAGE,
                code: asset.hasXLLicense ? 'XL' : 'SM',
                id: asset.hasXLLicense ? '133bb17e-d809-49e9-8008-596b95aa9dfa' : '1b8ac529-0b0a-4547-99c0-98faf1143070',
                name: asset.hasXLLicense ? 'XLarge' : 'Small',
                price: asset.hasXLLicense ? 5000 : 500,
                sequence: asset.hasXLLicense ? 2 : 1,
            };
        } else {
            extendedLicenseModel = {
                id: '6976291a-f0bf-4732-96f5-58ada620da51',
                assetType: AssetType.VIDEO,
                code: 'NONE',
                name: 'None Extended License',
                price: 0,
                liftTimeType: 'DAY',
                liftTime: 0,
                sequence: 1,
            };

            standardLicenseModel = {
                assetType: AssetType.VIDEO,
                code: 'HD',
                id: 'a05aa75d-9757-4788-8389-8e43b8a51a40',
                name: 'HD',
                price: 7000,
                sequence: 1,
            };
        }

        let assetModified: AssetPublishModel;
        assetModified = {
            id: asset.id,
            assetType: asset.assetType,
            code: asset.code,
            name: asset.name,
            description: asset.description,
            thumbnailImageUrl: asset.thumbnailImageUrl,
            thumbnailVideoUrl: asset.thumbnailVideoUrl,
            thumbnailVideoCoverUrl: asset.thumbnailVideoCoverUrl,
            hasSMLicense: asset.hasSMLicense,
            hasXLLicense: asset.hasXLLicense,
            hasHDLicense: asset.hasHDLicense,
            hasUHDLicense: asset.hasUHDLicense,
            publishDate: asset.publishDate,
            hasCollection: asset.hasCollection,
            keywords: asset.keywords,
            userId: asset.userId,
            standardLicense: standardLicenseModel,
            extendedLicense: extendedLicenseModel,
            price: standardLicenseModel.price,
            isDisabled: asset.isDisabled,
            isHasLicense: true,
            imageOrientation: asset.imageOrientation,
            usage: asset.usage,
        };

        orders.push(assetModified);
        updateOrderAssets(orders);

        message.open({
            type: 'success',
            content: 'Add to cart success.',
        });

        getCountOrder();
    }

    const onClickToArtist = (userId: string) => {
        navigate(`/artist/${userId}`)
    };

    return (
        <>
             <div className="containerImage">
                <div className='slider' key={`slider-main-${sliderId}${recommentAssetItemIndex}`}>
                    <div className='slider' key={`slider-main-item-${sliderId}${recommentAssetItemIndex}`}
                        onClick={() => onClickItem(recommentAssetItem.asset.id)}>
                        <img
                            key={`slider-main-item-image-${sliderId}${recommentAssetItemIndex}`}
                            src={
                                recommentAssetItem.asset.assetType === AssetType.VIDEO
                                    ? recommentAssetItem.asset.thumbnailVideoCoverUrl
                                    : recommentAssetItem.asset.thumbnailImageUrl
                            }
                            className='gallery-img'
                            alt={recommentAssetItem.asset.name}
                        />
                        <div className='overlay'></div>
                    </div>
                </div>
                <HeartOutlined size={30} className='favorite-rec' onClick={() => onClickFavorite()} />
                <ShoppingCartOutlined size={30} className='fa-download-rec' onClick={() => addToCard(recommentAssetItem.asset)} />
                <div 
                    className='artist-rec'
                    onClick={() => onClickToArtist(recommentAssetItem.asset.userId)}>
                    {
                        recommentAssetItem.asset &&
                        recommentAssetItem.asset.artistFirstname + ' ' + recommentAssetItem.asset.artistLastname
                    }
                </div>
                <MyFavoriteModal
                    isFavoriteModalOpen={isOpenFavorite}
                    assetId={recommentAssetItem.asset.id!}
                    onCloseFavoriteModal={handleCloseFav} />
            </div>
        </>
    );
}