import { Layout, Drawer, Button } from 'antd';
import CustomSider from './sider';
import CustomContent from './content';
import { Loading } from 'src/components/loaging/Loading';
import { Outlet } from 'react-router-dom';
import GuardedRoute from '../../routes/GuardRoute';
import { useEffect, useState } from 'react';
import CustomHeader from './header';
import { MenuFoldOutlined, MenuUnfoldOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MenuSider from './MenuSider';

const imageLogoMobile = require('../../assets/images/logo/logo-mobile.png');

interface Prop {
  forceAuthentication?: boolean;
}

export default function CustomLayout({ forceAuthentication = false }: Prop) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const { Header, Sider } = Layout;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setIsLoading(true);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={isCollapsed}>
          <CustomSider isCollapsed={isCollapsed} />
        </Sider>

        <Layout className='main-layout'>
          <Header className='ch-nav'>
            <div className='ch-icon'>
              <Link to="/" className='ch-logo-small'>
                <img src={String(imageLogoMobile)} alt="alive united plus" />
              </Link>
              <MenuOutlined onClick={() => setOpen(!open)} className='btn-open-drawer' />
              <MenuFoldOutlined className={`${isCollapsed ? 'd-none' : 'btn-collapsed'} `} onClick={() => setCollapsed(!isCollapsed)} />
              <MenuUnfoldOutlined className={`${!isCollapsed ? 'd-none' : 'btn-collapsed'}`} onClick={() => setCollapsed(!isCollapsed)} />
            </div>
            <CustomHeader />
          </Header>
          <CustomContent>
            <GuardedRoute forceAuthentication={forceAuthentication}>
              <Outlet />
            </GuardedRoute>
          </CustomContent>
        </Layout>
      </Layout>

      <Drawer
        placement="left"
        width="80%"
        onClose={() => setOpen(false)}
        open={open}
        destroyOnClose
        className='drawer-menu-mobile'
      >
        <MenuSider onCloseDrawer={() => setOpen(false)} />
      </Drawer>
    </>
  );
}
