import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import Inter from '../../../../assets/fonts/Inter/Inter.ttf';

interface ReportInterface {
  order: IOrderHistoryResponse;
}

Font.register({
  family: 'Inter',
  src: Inter,
});

const styles = StyleSheet.create({
    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportTitle:{
        fontSize: 8,
        textAlign: 'left',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
    },
    reportText:{
      fontSize: 10,
      textAlign: 'left',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
  },
    container: {
      flexDirection: 'row',
      marginTop: 10,
      paddingTop: 0,
      marginBottom: 0,
      fontFamily: 'Inter'
    },
    title1: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
        paddingBottom: 0,
        textAlign: 'left',
        fontWeight: 'extrabold',
        fontSize: 16,
    },
    title2: {
      width: '100%',
      marginTop: 5,
      marginBottom: 5,
      paddingBottom: 0,
      textAlign: 'left',
      fontWeight: 'extrabold',
      fontSize: 12
  },
    text:{
      fontSize: 10,
      fontWeight: 'black',
      textAlign: 'left',
    },
    viewNetTotal: {
        width: '100%',
        marginTop: 0,
        paddingBottom: 0,
        textAlign: 'right',
        alignItems: 'flex-end',
    },
    textNetTotal: {
      width: '100%',
      fontSize: 18,
    },
    border: {
      borderBottom: 1,
      marginBottom: 10
    },
    marginBottom: {
      marginBottom: 10,
    }
  });

export default function ThankYouMsg({order}: ReportInterface)  {
  return (
    <View style={styles.border}>
      <View style={styles.container}>
        <View>
          <Text style={styles.title1}>Total :</Text>
          <Text style={styles.title2}>Payment Method :</Text>
          <Text style={styles.text}>Name Account</Text>
          <Text style={styles.text}>Bank Account</Text>
        </View>
        <View style={styles.viewNetTotal}>
          <Text style={styles.textNetTotal}>
            ฿{order.netTotal.toLocaleString(undefined, { minimumFractionDigits: 2, })}
          </Text>
        </View>
      </View>
      <View style={styles.marginBottom}>
          <Text style={styles.title2}>Terms & Conditions :</Text>
          <Text style={styles.reportText}>
                The terms and restrictions apply to use. Every detail complies with the agreement.
          </Text>
            <Text style={styles.reportText}>
              After payment has been received successfully, use will end.Please provide the firm notice of any
            </Text>
          <Text style={styles.reportText}>
            errors in the item within three days; if not, the company will not be held accountable.
          </Text>
        </View>
    </View>
  );
}
