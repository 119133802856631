import { AssetPublishModel } from 'src/models/assetModel';
import { useEffect, useState } from 'react';
import assetSearchModel from 'src/models/responses/assetSearchModel';
import GallerySearchItem from '../galleryItem/GallerySearchItem';
import { ExtendedLicenseModel, StandardLicenseModel } from 'src/models/LicenseModel';
import { AssetType } from 'src/constant/assetConstants';
import { StandardLicense } from 'src/constant/licenseConstants';

interface TypeInterface {
  searchModel: assetSearchModel[];
}

export default function GallerySearch({ searchModel }: TypeInterface) {
  const [assets, setAssets] = useState<AssetPublishModel[]>([]);

  useEffect(() => {
    onSetAssetPublishModel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchModel]);

  const onSetAssetPublishModel = () => {
    const datas: AssetPublishModel[] = searchModel.map<AssetPublishModel>((x) => ({
      id: x.id,
      assetType: x.assetType,
      code: x.code!,
      name: x.name,
      description: x.description!,
      thumbnailImageUrl: x.thumbnailImageUrl!,
      thumbnailVideoUrl: x.thumbnailVideoUrl!,
      thumbnailVideoCoverUrl: x.thumbnailVideoCoverUrl!,
      hasSMLicense: x.hasSMLicense,
      hasXLLicense: x.hasXLLicense,
      hasHDLicense: x.hasHDLicense,
      hasUHDLicense: x.hasUHDLicense,
      publishDate: new Date(),
      hasCollection: false,
      keywords: x.keywords!,
      userId: '',
      artistFirstname: x.artistName,
      artistLastname: x.artistName,
      artistNameEn: x.artistNameEn,
      standardLicense: {
        id: 'string',
        assetType: AssetType.IMAGE,
        code: StandardLicense.SM,
        name: 'string',
        price: 0,
        sequence: 0,
      } as StandardLicenseModel,
      extendedLicense: {
        id: 'string',
        assetType: AssetType.IMAGE,
        code: StandardLicense.SM,
        name: 'string',
        price: 0,
        liftTimeType: 0,
        liftTime: 0,
        sequence: 0,
      } as ExtendedLicenseModel,
      price: 0,
      isDisabled: false,
      isHasLicense: false,
      imageOrientation: x.imageOrientation!,
    }));

    setAssets(datas);
  };

  return (
    <>
    <div className='gallery'>
     {assets.map((d, index) => (
      <div className='containerImage'>
        <GallerySearchItem isUseContainerImage= {false} asset={d} index={index} />
      </div>
        ))}
      </div>
    </>
  );
}