import { RouteNames } from 'src/routes/routeName';
import { Button, Card, Col, Empty, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AccountService } from 'src/services/accountService';
import { HttpStatus } from 'src/constant/responseStatus';
import CollectionItem from 'src/components/collection/CollectionItem';
import { CollectionAssetModel } from 'src/models/CollectionModel';
import TranslationKey from 'src/i18n/translation';

export default function MyCollections() {
  const [collections, setCollections] = useState<CollectionAssetModel[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getMyCollectionsAsync();
  }, []);

  const getMyCollectionsAsync = async () => {
    const response = await new AccountService().getListMyCollections();

    if (response.status !== HttpStatus.OK) {
      return;
    }

    setCollections(response.data);
  };

  const onDetail = (id?: string) => {
    if (id) {
      navigate(`${RouteNames.myCollectionDetail}?id=${id}`);
    } else {
      navigate(RouteNames.myCollectionDetail);
    }
  };

  const onClickItem = (collectionId: string) => {
    navigate(`${RouteNames.myCollectionDetail}?id=${collectionId}`);
  };

  return (
    <>
      <Card
        title={TranslationKey('myCollection.title')}
        bordered={false}
        extra={
          <>
            <Button type='primary' onClick={() => onDetail(undefined)} size='large'>
            {TranslationKey('myCollection.addNewCollection')}
            </Button>
          </>
        }
      >
        {
          <div className="collection">
            <Row gutter={16}>
              {
                collections ? (
                  collections.map((d, index) =>
                    <Col span={24}
                      md={12}
                      lg={8}
                      xl={6}
                      className="mt-1"
                      key={index}
                    >
                      <CollectionItem collectionIndex={index} collectionId={d.id} collectionName={d.name} collectionItems={d.collectionItems} width={300} height={200} nameColor="#333" onClickItem={onClickItem} />
                    </Col>
                  )
                ) : (
                  <Col span={24}>
                    <Empty />
                  </Col>
                )
              }
            </Row>
          </div>
        }
      </Card>
    </>
  );
}