interface Props {
  icon: JSX.Element;
  title: string;
  description: string;
  details: string[];
  showBulletPoint: boolean;
}

export default function FileSpecDetail({ icon, title, description, details, showBulletPoint }: Props) {
  let content = <p>{details[0]}</p>

  if (showBulletPoint) {
    content = <ul>
      {details.map((detail, detailIndex) => (
        <li key={detailIndex} className={`${showBulletPoint ? "" : "li-bullet-none"}`}>
          {detail}
        </li>
      ))}
    </ul>
  }

  return (
    <>
      <h4>{icon} {title}</h4>
      <h5>{description}</h5>
      {content}
    </>
  );
}