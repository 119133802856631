import { CameraOutlined, MobileOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Space } from "antd";
import FileSpecDetail from "./file-spec-detail";
import TranslationKey from 'src/i18n/translation';

export default function FileSpec() {
  const fileTitle = TranslationKey('upload.fileSpecifications');
  
  const fileSpec = [TranslationKey('upload.uploadsLimits')];

  const photoTitle = TranslationKey('photos');
  const photoDesc = TranslationKey('upload.weAcceptJPEG');
  const photoSpec = [
    TranslationKey('upload.photos.filesType'),
    TranslationKey('upload.photos.filesSetInRGB'),
    TranslationKey('upload.photos.minimum6Megapixel'),
    TranslationKey('upload.photos.maxSizeFor'),
    TranslationKey('upload.photos.filmScan'),
    TranslationKey('upload.photos.retouch'),
    TranslationKey('upload.photos.photosMustBeAbleToTell'),
    TranslationKey('upload.photos.allContentsMustBeYourOwnWorks')
  ];

  const videoTitle = TranslationKey('videos');
  const videoDesc = TranslationKey('upload.videos.weAcceptQuickTime');
  const videoSpec = [
    TranslationKey('upload.videos.fileTypeInMP4'),
    TranslationKey('upload.videos.noSoundEffects'),
    TranslationKey('upload.videos.videosLength'),
    TranslationKey('upload.videos.resolution'),
    TranslationKey('upload.videos.MinimumResolution'),
    TranslationKey('upload.videos.retouch'),
    TranslationKey('upload.videos.videosMustBeAbleToTellStories'),
    TranslationKey('upload.videos.allContents'),
  ];
  
  const mobileDeviceTitle = TranslationKey('upload.mobileDevice.title');
  const mobileDeviceDesc = TranslationKey('upload.mobileDevice.weWillAcceptPhoto');
  const mobileDeviceSpec = [
    TranslationKey('upload.mobileDevice.whenTransferring'),
    TranslationKey('upload.mobileDevice.pleaseKeepInMind')
  ];

  return (
    <div className="mt-1 p-4 bg-grey">
      <Space direction="vertical">
        <FileSpecDetail icon={<></>} title={fileTitle} description={""} details={fileSpec} showBulletPoint={false} />
        <FileSpecDetail icon={<CameraOutlined />} title={photoTitle} description={photoDesc} details={photoSpec} showBulletPoint={true} />
        <FileSpecDetail icon={<VideoCameraOutlined />} title={videoTitle} description={videoDesc} details={videoSpec} showBulletPoint={true} />
        <FileSpecDetail icon={<MobileOutlined />} title={mobileDeviceTitle} description={mobileDeviceDesc} details={mobileDeviceSpec} showBulletPoint={true} />
      </Space>
    </div>
  );
}