import { useSessionContext } from 'src/context/session-context';
import { Button, Card, Col, Divider, Form, Input, Radio, Row, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { ArtistTaxInfoAttachmentModel, SaveArtistTaxInfoModel } from 'src/models/TaxCenterModel';
import { TaxAddressInfo } from 'src/models/addressModel';
import countryList from 'src/constant/country.json';
import Title from 'antd/es/typography/Title';
import { SaveOutlined } from '@ant-design/icons';
import IndividualTaxCenter from 'src/components/taxCenterForm/IndividualTaxCenter';
import CompanyTaxCenter from 'src/components/taxCenterForm/CompanyTaxCenter';
import { ArtistTaxInfoAttachmentInit, ArtistTaxInfoFileType, ArtistTaxInfoProperty, ArtistType, NationalityType } from 'src/constant/artistTaxInfoConstants';
import { ArtistTaxInfoFormModel } from 'src/models/TaxCenterModel';
import { ArtistTaxInfoService } from 'src/services/artistTaxInfoService';
import { HttpStatus } from 'src/constant/responseStatus';
import dayjs from 'dayjs';
import { StringConstants } from 'src/constant/stringConstants';
import TranslationKey from 'src/i18n/translation';

const taxTypeOptions = [
  {
    value: ArtistType.Individual,
    label: ArtistType.Individual
  },
  {
    value: ArtistType.Company,
    label: ArtistType.Company
  },
];

const nationTypeOptions = [
  {
    value: NationalityType.Thai,
    label: NationalityType.Thai
  },
  {
    value: NationalityType.Foreigner,
    label: NationalityType.Foreigner
  },
];

export default function Index() {
  const artistTaxInfoService = new ArtistTaxInfoService();
  const { userProfile } = useSessionContext();
  const [taxCenter, setTaxCenter] = useState<ArtistTaxInfoFormModel>({
    artistType: ArtistType.Individual,
    nationalityType: NationalityType.Thai,
  } as ArtistTaxInfoFormModel);
  const [residenceAddress, setResidenceAddress] = useState<TaxAddressInfo>({} as TaxAddressInfo);
  const [mailingAddress, setMailingAddress] = useState<TaxAddressInfo>({} as TaxAddressInfo);
  const [taxInfoId, setTaxInfoId] = useState('');
  const [form] = Form.useForm();
  const [idCardFile, setIdCardFile] = useState<ArtistTaxInfoAttachmentModel>(
    {
      fileName: StringConstants.EMPTY,
      fileNameOriginal: StringConstants.EMPTY,
      url: StringConstants.EMPTY 
    });
  const [certificationFile, setCertificationFile] = useState<ArtistTaxInfoAttachmentModel>(
    {
      fileName: StringConstants.EMPTY,
      fileNameOriginal: StringConstants.EMPTY,
      url: StringConstants.EMPTY 
    });

  const [compVatRegisterCertificateFile, setCompVatRegisterCertificateFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compCerRegIssueFile, setCompCerRegIssueFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compCommercialRegFile, setCompCommercialRegFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compListOfShareholdersFile, setCompListOfShareholdersFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [compIdCardAuthorizedPersonFile, setCompIdCardAuthorizedPersonFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);

  useEffect(() => {
    if(!(taxCenter && taxCenter.id)){
      getArtistTaxInfo(taxCenter.artistType, taxCenter.nationalityType);

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxCenter]);

  const setFormValue = (data: SaveArtistTaxInfoModel) => {
    setTaxInfoId(data.id);

    var isIndividualThai = taxCenter.artistType === ArtistType.Individual && taxCenter.nationalityType === NationalityType.Thai;
    var isIndividualForeigner = taxCenter.artistType === ArtistType.Individual && taxCenter.nationalityType === NationalityType.Foreigner;
    var isCompanyThai = taxCenter.artistType === ArtistType.Company && taxCenter.nationalityType === NationalityType.Thai;
    var isCompanyForeigner = taxCenter.artistType === ArtistType.Company && taxCenter.nationalityType === NationalityType.Foreigner;

    form.setFieldValue(ArtistTaxInfoProperty.artistId, data.artistId);
    form.setFieldValue(ArtistTaxInfoProperty.artistType, data.artistType);
    form.setFieldValue(ArtistTaxInfoProperty.nationalityType, data.nationalityType);
    form.setFieldValue(ArtistTaxInfoProperty.fullName, data.fullName);
    
    setResidenceAddress({ 
      address1: data.residenceAddress?.address1,
      address2: data.residenceAddress?.address2,
      city: data.residenceAddress?.city,
      country: data.residenceAddress?.country,
      postcode: data.residenceAddress?.postcode,
    });

    setMailingAddress({ 
      address1: data.mailingAddress?.address1,
      address2: data.mailingAddress?.address2,
      city: data.mailingAddress?.city,
      country: data.mailingAddress?.country,
      postcode: data.mailingAddress?.postcode,
    });

    if (isIndividualThai) {
      form.setFieldValue(ArtistTaxInfoProperty.idCardNumber, data.idCardNumber);
      form.setFieldValue(ArtistTaxInfoProperty.phone, data.phone);
      form.setFieldValue(ArtistTaxInfoProperty.email, data.email);
      form.setFieldValue(ArtistTaxInfoProperty.address1, data.taxAddress?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.address2, data.taxAddress?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.city, data.taxAddress?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcode, data.taxAddress?.postcode);

      if (data.idCardAttachment) {
        setIdCardFile(data.idCardAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentUrl, data.idCardAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileName, data.idCardAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileNameOriginal, data.idCardAttachment.fileNameOriginal);
      }
    }

    if (isIndividualForeigner || isCompanyForeigner) {
      form.setFieldValue(ArtistTaxInfoProperty.countryOfCitizenship, data.countryOfCitizenship);
      form.setFieldValue(ArtistTaxInfoProperty.poBoxType, data.poBoxType);
      form.setFieldValue(ArtistTaxInfoProperty.referenceNumber, data.referenceNumber);
      form.setFieldValue(ArtistTaxInfoProperty.taxIdNumber, data.taxIdNumber);
      form.setFieldValue(ArtistTaxInfoProperty.claimOfTax, data.claimOfTax);
      form.setFieldValue(ArtistTaxInfoProperty.usTaxPayerType, data.usTaxPayer?.type);
      form.setFieldValue(ArtistTaxInfoProperty.usTaxPayerNumber, data.usTaxPayer?.number);
      form.setFieldValue(ArtistTaxInfoProperty.address1, data.taxAddress?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.address2, data.taxAddress?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.city, data.taxAddress?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcode, data.taxAddress?.postcode);
      form.setFieldValue(ArtistTaxInfoProperty.mailingAddress1, data.taxMailingAddress?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.mailingAddress2, data.taxMailingAddress?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.mailingCity, data.taxMailingAddress?.city);
      form.setFieldValue(ArtistTaxInfoProperty.mailingPostcode, data.taxMailingAddress?.postcode);

      if (data.certificationAttachment) {
        setCertificationFile(data.certificationAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentUrl, data.certificationAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileName, data.certificationAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileNameOriginal, data.certificationAttachment.fileNameOriginal);
      }

      if (data.dateOfBirth) {
        form.setFieldValue(ArtistTaxInfoProperty.dateOfBirth, dayjs(data.dateOfBirth));
      }
    }

    if (isCompanyThai) {
      form.setFieldValue(ArtistTaxInfoProperty.juristicPersonNameTh, data.juristicPersonNameTh);
      form.setFieldValue(ArtistTaxInfoProperty.juristicPersonNameEn, data.juristicPersonNameEn);
      form.setFieldValue(ArtistTaxInfoProperty.taxIdNumber, data.taxIdNumber);
      form.setFieldValue(ArtistTaxInfoProperty.companyContactEmail, data.companyContactEmail);
      form.setFieldValue(ArtistTaxInfoProperty.companyContactPhone, data.companyContactPhone);

      form.setFieldValue(ArtistTaxInfoProperty.companyAddress1Th, data.juristicPersonAddressTh?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.companyAddress2Th, data.juristicPersonAddressTh?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.cityTh, data.juristicPersonAddressTh?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcodeTh, data.juristicPersonAddressTh?.postcode);
      
      form.setFieldValue(ArtistTaxInfoProperty.companyAddress1En, data.juristicPersonAddressEn?.address1);
      form.setFieldValue(ArtistTaxInfoProperty.companyAddress2En, data.juristicPersonAddressEn?.address2);
      form.setFieldValue(ArtistTaxInfoProperty.cityEn, data.juristicPersonAddressEn?.city);
      form.setFieldValue(ArtistTaxInfoProperty.postcodeEn, data.juristicPersonAddressEn?.postcode);

      if (data.compVatRegisterCertificateAttachment) {
        setCompVatRegisterCertificateFile(data.compVatRegisterCertificateAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentUrl, data.compVatRegisterCertificateAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileName, data.compVatRegisterCertificateAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileNameOriginal, data.compVatRegisterCertificateAttachment.fileNameOriginal);
      }

      if (data.compCerRegIssueAttachment) {
        setCompCerRegIssueFile(data.compCerRegIssueAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentUrl, data.compCerRegIssueAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileName, data.compCerRegIssueAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileNameOriginal, data.compCerRegIssueAttachment.fileNameOriginal);
      }
      
      if (data.compCommercialRegAttachment) {
        setCompCommercialRegFile(data.compCommercialRegAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentUrl, data.compCommercialRegAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileName, data.compCommercialRegAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileNameOriginal, data.compCommercialRegAttachment.fileNameOriginal);
      }
      
      if (data.compListOfShareholdersAttachment) {
        setCompListOfShareholdersFile(data.compListOfShareholdersAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentUrl, data.compListOfShareholdersAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileName, data.compListOfShareholdersAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileNameOriginal, data.compListOfShareholdersAttachment.fileNameOriginal);
      }
      
      if (data.compIdCardAuthorizedPersonAttachment) {
        setCompIdCardAuthorizedPersonFile(data.compIdCardAuthorizedPersonAttachment);

        form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentUrl, data.compIdCardAuthorizedPersonAttachment.url);
        form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileName, data.compIdCardAuthorizedPersonAttachment.fileName);
        form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileNameOriginal, data.compIdCardAuthorizedPersonAttachment.fileNameOriginal);
      }
    }
  }

  const defaultFormValue = () => {
    setResidenceAddress({ 
      address1: StringConstants.EMPTY,
      address2: StringConstants.EMPTY,
      city: StringConstants.EMPTY,
      country: StringConstants.EMPTY,
      postcode: StringConstants.EMPTY,
    });

    setMailingAddress({ 
      address1: StringConstants.EMPTY,
      address2: StringConstants.EMPTY,
      city: StringConstants.EMPTY,
      country: StringConstants.EMPTY,
      postcode: StringConstants.EMPTY,
    });

    setIdCardFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    setCertificationFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    setCompVatRegisterCertificateFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    setCompCerRegIssueFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    setCompCommercialRegFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    setCompListOfShareholdersFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    setCompIdCardAuthorizedPersonFile({ url: StringConstants.EMPTY, fileName: StringConstants.EMPTY, fileNameOriginal: StringConstants.EMPTY });
    
    if (taxCenter.nationalityType === NationalityType.Foreigner) {
      form.setFieldValue(ArtistTaxInfoProperty.poBoxType, true);
      form.setFieldValue(ArtistTaxInfoProperty.usTaxPayerType, 'SSN');
    }
  }

  const getArtistTaxInfo = async (artistType: string, nationalityType: string) => {
    form.resetFields();
    defaultFormValue();
    setTaxInfoId('');

    const { data, status} = await artistTaxInfoService.getTaxInfoByAllTypeAsync(userProfile.userId, artistType, nationalityType);

    if (status !== HttpStatus.OK) {
      return;
    }

    setFormValue(data);
  };

  const getSaveModel = (formData: ArtistTaxInfoFormModel) => {
    const model: SaveArtistTaxInfoModel = {
      id: taxCenter.id,
      artistId: userProfile.userId,
      artistType: taxCenter.artistType,
      nationalityType: taxCenter.nationalityType,
      fullName: formData.fullName,
      residenceAddress: {
        address1: residenceAddress.address1,
        address2: residenceAddress.address2,
        city: residenceAddress.city,
        country: residenceAddress.country,
        postcode: residenceAddress.postcode,
      },
      mailingAddress: {
        address1: mailingAddress.address1,
        address2: mailingAddress.address2,
        city: mailingAddress.city,
        country: mailingAddress.country,
        postcode: mailingAddress.postcode,
      },
    }

    var isIndividualThai = taxCenter.artistType === ArtistType.Individual && taxCenter.nationalityType === NationalityType.Thai;
    var isIndividualForeigner = taxCenter.artistType === ArtistType.Individual && taxCenter.nationalityType === NationalityType.Foreigner;
    var isCompanyThai = taxCenter.artistType === ArtistType.Company && taxCenter.nationalityType === NationalityType.Thai;
    var isCompanyForeigner = taxCenter.artistType === ArtistType.Company && taxCenter.nationalityType === NationalityType.Foreigner;
    
    if (isIndividualThai) {
      model.idCardNumber = formData.idCardNumber;
      model.phone = formData.phone;
      model.email = formData.email;
      model.taxAddress = {
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        postcode: formData.postcode,
      };

      if (formData.idCardAttachmentUrl) {
        model.idCardAttachment = {
          url: formData.idCardAttachmentUrl,
          fileName: formData.idCardAttachmentFileName,
          fileNameOriginal: formData.idCardAttachmentFileNameOriginal,
        }
      } else {
        model.idCardAttachment = undefined;
      }
    }

    if (isIndividualForeigner || isCompanyForeigner) {
      model.countryOfCitizenship = formData.countryOfCitizenship;
      model.poBoxType = formData.poBoxType;
      model.referenceNumber = formData.referenceNumber;
      model.taxIdNumber = formData.taxIdNumber;

      if (formData.dateOfBirth) {
        model.dateOfBirth = new Date(formData.dateOfBirth);
      }

      model.claimOfTax = formData.claimOfTax;

      if (formData.certificationAttachmentUrl) {
        model.certificationAttachment = {
          url: formData.certificationAttachmentUrl,
          fileName: formData.certificationAttachmentFileName,
          fileNameOriginal: formData.certificationAttachmentFileNameOriginal,
        }
      }

      model.usTaxPayer = {
        type: formData.usTaxPayerType,
        number: formData.usTaxPayerNumber,
      };
      
      model.taxAddress = {
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        postcode: formData.postcode,
      };

      model.taxMailingAddress = {
        address1: formData.mailingAddress1,
        address2: formData.mailingAddress2,
        city: formData.mailingCity,
        postcode: formData.mailingPostcode,
      };
    }

    if (isCompanyThai) {
      model.juristicPersonNameTh = formData.juristicPersonNameTh;
      model.juristicPersonNameEn = formData.juristicPersonNameEn;
      model.taxIdNumber = formData.taxIdNumber;
      model.companyContactEmail = formData.companyContactEmail;
      model.companyContactPhone = formData.companyContactPhone;
      
      if (formData.compVatRegisterCertificateAttachmentUrl) {
        model.compVatRegisterCertificateAttachment = {
          url: formData.compVatRegisterCertificateAttachmentUrl,
          fileName: formData.compVatRegisterCertificateAttachmentFileName,
          fileNameOriginal: formData.compVatRegisterCertificateAttachmentFileNameOriginal,
        }
      }

      if (formData.compCerRegIssueAttachmentUrl) {
        model.compCerRegIssueAttachment = {
          url: formData.compCerRegIssueAttachmentUrl,
          fileName: formData.compCerRegIssueAttachmentFileName,
          fileNameOriginal: formData.compCerRegIssueAttachmentFileNameOriginal,
        }
      }
      
      if (formData.compCommercialRegAttachmentUrl) {
        model.compCommercialRegAttachment = {
          url: formData.compCommercialRegAttachmentUrl,
          fileName: formData.compCommercialRegAttachmentFileName,
          fileNameOriginal: formData.compCommercialRegAttachmentFileNameOriginal,
        }
      }

      if (formData.compListOfShareholdersAttachmentUrl) {
        model.compListOfShareholdersAttachment = {
          url: formData.compListOfShareholdersAttachmentUrl,
          fileName: formData.compListOfShareholdersAttachmentFileName,
          fileNameOriginal: formData.compListOfShareholdersAttachmentFileNameOriginal,
        }
      }
      
      if (formData.compIdCardAuthorizedPersonAttachmentUrl) {
        model.compIdCardAuthorizedPersonAttachment = {
          url: formData.compIdCardAuthorizedPersonAttachmentUrl,
          fileName: formData.compIdCardAuthorizedPersonAttachmentFileName,
          fileNameOriginal: formData.compIdCardAuthorizedPersonAttachmentFileNameOriginal,
        }
      }

      model.juristicPersonAddressTh = {
        address1: formData.companyAddress1Th,
        address2: formData.companyAddress2Th,
        city: formData.cityTh,
        postcode: formData.postcodeTh,
      };
  
      model.juristicPersonAddressEn = {
        address1: formData.companyAddress1En,
        address2: formData.companyAddress2En,
        city: formData.cityEn,
        postcode: formData.postcodeEn,
      };
    }

    return model;
  }

  const submitForm = async (formData: ArtistTaxInfoFormModel) => {
    const model = getSaveModel(formData);

    if (taxInfoId === '') {
      const { status } = await new ArtistTaxInfoService().createArtistTaxInfoAsync(model);

      if (status !== HttpStatus.CREATED) {
        message.error('Create Artist tax center failed.');
      }
      else {
        message.success('Create Artist tax center success.');
      }
      
      getArtistTaxInfo(model.artistType, model.nationalityType);

      return;
    }
    else {
      const { status } = await new ArtistTaxInfoService().updateArtistTaxInfoAsync(taxInfoId, model);
    
      if (status !== HttpStatus.OK) {
        message.error('Update Artist tax center failed.');
      }
      else {
        message.success('Update Artist tax center success.');
      }
    }
  }

  const onUploadFileChanged = (
    fileType: ArtistTaxInfoFileType,
    file: ArtistTaxInfoAttachmentModel) =>
    {
      switch(fileType) { 
        case ArtistTaxInfoFileType.idCardAttachment: { 
          form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.idCardAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        } 
        case ArtistTaxInfoFileType.certification: { 
          form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.certificationAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        }
        case ArtistTaxInfoFileType.compVatRegisterCertificate: { 
          form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.compVatRegisterCertificateAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        }
        case ArtistTaxInfoFileType.compCerRegIssue: { 
          form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.compCerRegIssueAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        }
        case ArtistTaxInfoFileType.compCommercialReg: { 
          form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.compCommercialRegAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        }
        case ArtistTaxInfoFileType.compListOfShareholders: { 
          form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.compListOfShareholdersAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        }
        case ArtistTaxInfoFileType.compIdCardAuthorizedPerson: { 
          form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentUrl, file.url);
          form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileName, file.fileName);
          form.setFieldValue(ArtistTaxInfoProperty.compIdCardAuthorizedPersonAttachmentFileNameOriginal, file.fileNameOriginal);
          break; 
        }
    } 
  }

  return (
    <>
    <Form
      form={form}
      onFinish={submitForm}
      layout='vertical' >
      <Card
        title='Tax Profile'
        bordered={false}
        extra={
          <Button
            type='primary'
            size='large'
            htmlType='submit'
            icon={<SaveOutlined />}>
              {TranslationKey('button.submit')}
          </Button>
        }
        >
        <Row>
          <Col md={8} sm={24}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={TranslationKey('taxCenter.areYourContributing')}>
                    <Radio.Group
                      options={taxTypeOptions}
                      value={taxCenter.artistType}
                      onChange={(val) => setTaxCenter({...taxCenter, artistType: val.target.value})}
                      optionType="button"
                      buttonStyle="solid"
                      size='large' />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={TranslationKey('taxCenter.forThailand')}>
                    <Radio.Group
                      options={nationTypeOptions}
                      value={taxCenter.nationalityType}
                      onChange={(val) => setTaxCenter({...taxCenter, nationalityType: val.target.value})}
                      optionType="button"
                      buttonStyle="solid"
                      size='large' />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={8} sm={24}>
          <Title level={4}>{TranslationKey('taxCenter.recentDocuments')}</Title>
          </Col>
          <Col md={8} sm={24} className='d-flex flex-column gap-2'>
            <Title level={4}>{TranslationKey('taxCenter.helpCenter')}</Title>
            <span>{TranslationKey('taxCenter.beforeWeCanPayYou')}</span>
            <span>{TranslationKey('taxCenter.whileWeCannot')}</span>
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col span={24}>
            {
              taxCenter.artistType === ArtistType.Individual
                ? 
                ( 
                  <IndividualTaxCenter
                    onUploadFileChanged={onUploadFileChanged}
                    idCardAttachment={idCardFile}
                    certificationAttachment={certificationFile}
                    nationalityType={taxCenter.nationalityType} /> 
                )
                : 
                (
                  taxCenter.nationalityType === NationalityType.Thai
                    ? 
                    ( 
                      <CompanyTaxCenter
                        onUploadFileChanged={onUploadFileChanged}
                        compVatRegisterCertificateAttachment={compVatRegisterCertificateFile}
                        compCerRegIssueAttachment={compCerRegIssueFile}
                        compCommercialRegAttachment={compCommercialRegFile}
                        compListOfShareholdersAttachment={compListOfShareholdersFile}
                        compIdCardAuthorizedPersonAttachment={compIdCardAuthorizedPersonFile}
                        nationalityType={taxCenter.nationalityType} /> 
                    )
                    : 
                    (
                      <IndividualTaxCenter 
                        onUploadFileChanged={onUploadFileChanged}
                        idCardAttachment={idCardFile}
                        certificationAttachment={certificationFile}
                        nationalityType={taxCenter.nationalityType} /> 
                    )
                )
            }
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col md={12} sm={24}>
            <Row>
              <Col span={24}>
                <Title level={4}>{TranslationKey('taxCenter.residentialAddress')}</Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Address 01">
                    <Input
                      name="address01"
                      value={residenceAddress.address1}
                      onChange={(a) => setResidenceAddress({...residenceAddress, address1: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Address 02">
                    <Input
                      value={residenceAddress.address2}
                      onChange={(a) => setResidenceAddress({...residenceAddress, address2: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="City">
                    <Input
                      value={residenceAddress.city}
                      onChange={(a) => setResidenceAddress({...residenceAddress, city: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Country">
                    <Select
                    value={residenceAddress.country ?? ''}
                    onChange={(val) => setResidenceAddress({...residenceAddress, country: val})}
                    size='large'
                    className='w-100'
                    >
                      <Select.Option value={''}>
                        Select Country
                      </Select.Option>
                      {
                        countryList.map((data, index) => (
                          <Select.Option value={data.name} key={data.code}>
                            {data.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Zipcode">
                    <Input
                      value={residenceAddress.postcode}
                      onChange={(a) => setResidenceAddress({...residenceAddress, postcode: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={12} sm={24}>
            <Row>
              <Col span={24}>
                <Title level={4}>Mailing Address</Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Address 01">
                    <Input
                      value={mailingAddress.address1}
                      onChange={(a) => setMailingAddress({...mailingAddress, address1: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Address 02">
                    <Input
                      value={mailingAddress.address2}
                      onChange={(a) => setMailingAddress({...mailingAddress, address2: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="City">
                    <Input
                      value={mailingAddress.city}
                      onChange={(a) => setMailingAddress({...mailingAddress, city: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Country">
                    <Select
                    value={mailingAddress.country ?? ''}
                    onChange={(val) => setMailingAddress({...mailingAddress, country: val})}
                    size='large'
                    className='w-100'
                    >
                      <Select.Option value={''}>
                        Select Country
                      </Select.Option>
                      {
                        countryList.map((data, index) => (
                          <Select.Option value={data.name} key={data.code}>
                            {data.name}
                          </Select.Option>
                        ))
                      }
                    </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Zipcode">
                    <Input
                      value={mailingAddress.postcode}
                      onChange={(a) => setMailingAddress({...mailingAddress, postcode: a.target.value})}
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Form>
    </>
  );
}
