import SearchInput from '../../search-input/search-input';
import { ShoppingCartOutlined, CloseOutlined } from '@ant-design/icons';
import { Badge, Drawer, Menu, MenuProps, Button, Select } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionContext } from '../../../context/session-context';
import { useCallback, useEffect, useState } from 'react';
import { SessionStatus } from 'src/constant/sessionStatus';
import { RouteNames } from 'src/routes/routeName';
import { RoleType } from 'src/constant/roleType';
import { useTranslation } from 'react-i18next';
import { LanguageConstants } from 'src/constant/language';
import classStore from 'src/store/classStore';
import TranslationKey from 'src/i18n/translation';

interface NavbarInterface {
  isShowSearch: boolean;
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function Navbar({ isShowSearch = true }: NavbarInterface) {
  const { i18n } = useTranslation();

  const imageLogo = require('../../../assets/images/logo/white@3x.png');
  const imageLogoMobile = require('../../../assets/images/logo/logo-mobile.png');
  const { countOrder, getUserProfileAsync, isAuthenticated, userProfile, signOut, getCountOrder } = useSessionContext();

  const [menus, setMenus] = useState<MenuProps['items']>([]);
  const navigate = useNavigate();

  const [lang, setLang] = useState('');

  useEffect(() => {
    getCountOrder();
    getUserProfileAsync();
    setNavMenus(countOrder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countOrder, lang, setLang]);

  useEffect(() => {
    setNavMenus(countOrder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    getLanguage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getLanguage = useCallback(
    () => {
     var language = classStore.get(LanguageConstants.key) as string;

      if(language === null || language === undefined){
        language = LanguageConstants.en;
      }

      setLang(language);
      i18n.changeLanguage(language);
      classStore.set(LanguageConstants.key, language);

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setNavMenus = useCallback(
    (orderCount: number) => {
      const mainMenus: MenuProps['items'] = [
        getItem(
          <>
            <a href={RouteNames.cart}>
              <Badge count={orderCount} offset={[10, 20]} style={{ borderColor: '#18191C' }}>
                <ShoppingCartOutlined style={{ fontSize: '1.6em' }} className='icon-only text-light' />
              </Badge>
            </a>
          </>,
          RouteNames.cart,
          null
        ),
        getItem(
          <a href={RouteNames.pricing} style={{ textDecoration: 'none' }}>
            {TranslationKey('hPricing')}
          </a>,
          RouteNames.pricing,
          null
        ),
      ];

      if (
        isAuthenticated === SessionStatus.AUTHENTICATED &&
        userProfile &&
        userProfile.userId &&
        userProfile.roles.length > 0
      ) {
        if (userProfile.roles.filter((r) => r === RoleType.ARTIST).length > 0) {
          setMenus([...mainMenus!, ...loginArtistMenus!]);
        } else {
          setMenus([...mainMenus!, ...loginMenus!]);
        }
      } else {
        setMenus([...mainMenus!, ...signinMenus!]);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, userProfile]
  );

  const signinMenus: MenuProps['items'] = [
    getItem(
      <>
        <span className='btn-signin' style={{ textDecoration: 'none' }}>
          Sign In
        </span>
      </>,
      RouteNames.login,
      null
    ),
  ];

  const loginMenus: MenuProps['items'] = [
    getItem(userProfile.email, 'user', null, [
      getItem(
        <Link to={RouteNames.myAsset} style={{ textDecoration: 'none' }}>
          Profile
        </Link>,
        RouteNames.myAsset,
        null
      ),
      getItem('Sign out', 'logout', null),
    ]),
  ];

  const loginArtistMenus: MenuProps['items'] = [
    getItem(userProfile.email, 'user', null, [
      getItem(
        <Link to={RouteNames.dashboard} style={{ textDecoration: 'none' }}>
          Profile
        </Link>,
        RouteNames.dashboard,
        null
      ),
      getItem('Sign out', 'logout', null),
    ]),
  ];

  const onClick: MenuProps['onClick'] = async (e) => {
    if (e.key !== 'logout') {
      navigate(e.key);

      return;
    }

    await window.localStorage.clear();
    signOut();
  };
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <>
      <Header className='navbar-fix'>
        <div className='logo'>
          <Link to='/'>
            {isMobile ? (
              <img src={String(imageLogoMobile)} alt='alive united plus' width={50} />
            ) : (
              <img src={String(imageLogo)} alt='alive united plus' />
            )}
          </Link>
        </div>
        <div className='menuCon'>
          {isShowSearch && (
            <div className='navbar-search'>
              <SearchInput />
            </div>
          )}
        </div>
        <div className='right-menu'>
          <Menu
            theme='dark'
            mode='horizontal'
            style={{ float: 'right' }}
            disabledOverflow={true}
            onClick={onClick}
            selectable={false}
            items={menus}
          ></Menu>
        </div>
        <Button size='large' className='bars-menu' type='primary' onClick={() => setVisible(true)}>
          <span className='barsBtn'></span>
          
        </Button>
        <Drawer
          title={
            <div onClick={() => setVisible(false)} className='flex-end'>
              <CloseOutlined className='text-light fs-3 pe-3' />
            </div>
          }
          placement='right'
          closable={false}
          onClose={() => setVisible(false)}
          className='nav'
          visible={visible}
        >
          <Menu
            theme='dark'
            mode='horizontal'
            disabledOverflow={true}
            onClick={onClick}
            selectable={false}
            items={menus}
          ></Menu>
        </Drawer>
        <Select
              className="input-radius-dark non-radius"
              value={lang}
              style={{ width: 120 }}
              onChange={(value) => {
                setLang(value);
                i18n.changeLanguage(value);
                classStore.set(LanguageConstants.key, value);
                window.location.reload();
              }}
              options={[
                { value: LanguageConstants.en, label: LanguageConstants.labelEn },
                { value: LanguageConstants.th, label: LanguageConstants.labelTh },
              ]}
            />
      </Header>
    </>
  );
}
