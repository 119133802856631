import {  Helmet } from 'react-helmet-async';

interface HelmetModel {
    title?: string;
    description?: string;
    imageUrl?: string;
    url?: string;
  }
  export default function HelmetComponent({ title, description, imageUrl, url }: HelmetModel) {
    const urlWeb = url ?? `${process.env.REACT_APP_REDIRECT_PATH}`;

    return(
        <>
        <Helmet prioritizeSeoTags = {true}>
            <title>{title ?? 'Alive United plus'}</title>
            <meta name="description" content={description ?? 'Alive United Plus'} />
            <meta content="website" property="og:type"/>
            <meta property="og:title" content={description ?? 'Alive United Plus'}/>
            <meta property="og:description" content={description ?? 'Exclusive Gallery Driven by Passion'} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:url" content={imageUrl} />
            <meta property="og:url" content={url ?? urlWeb} />
            <meta content="600" property="og:image:width" />
            <meta content="400" property="og:image:height" /> 
           

            <meta name="twitter:title" content={title ?? 'Alive United plus'} />
            <meta name="twitter:description" content={description ?? 'Exclusive Gallery Driven by Passion'} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:card" content="Alive United Plus Image" />
            </Helmet>
        </>
    );
};