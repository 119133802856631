import { Avatar, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../constant/responseStatus';
import { Link } from 'react-router-dom';
import { AssetType } from 'src/constant/assetConstants';
import Title from 'antd/es/typography/Title';
import { CollectionAssetItemModel } from 'src/models/CollectionModel';
import { AccountService } from 'src/services/accountService';
import { ArtistProfileModel } from 'src/models/accountModel';

interface IPropsCollection {
    collectionItems: CollectionAssetItemModel[];
    collectionName: string;
    collectionId: string;
    collectionIndex: number;
    width?: number;
    height?: number;
    nameColor?: string;
    artistId?: string;
    onClickItem: (collectionId: string) => void;
}

export default function CollectionItem({
    collectionId,
    collectionName,
    collectionItems,
    collectionIndex,
    width,
    height,
    nameColor = '#FFF',
    artistId,
    onClickItem,
}: IPropsCollection) {
    const [artist, setArtist] = useState<ArtistProfileModel>({} as ArtistProfileModel);
    collectionItems = collectionItems.sort((a, b) => a.coverSeq! - b.coverSeq!);

    useEffect(() => {
        if (artistId) {
            getArtistAsync(artistId);
        }
    }, [artistId]);

    const getArtistAsync = async (id: string) => {
        const response = await new AccountService().getArtistById(id);

        if (response.status !== HttpStatus.OK) {
            return;
        }

        setArtist(response.data);
    };

    function rederItems(items: CollectionAssetItemModel[]) {
        const rows = [];
        for (let index = 1; index < 4; index++) {
            if (items[index]) {
                rows.push(
                    <div key={items[index].id} className='img' >
                        <img
                            key={items[index].id}
                            src={items[index].asset.thumbnailImageUrl}
                            alt={items[index].asset.name}
                        />
                    </div>
                );
            } else {
                rows.push(
                    <div key={index} className='img' >
                        <Skeleton.Image key={index} />
                    </div>
                );
            }
        }

        return rows;
    }

    function rederEmptyItem() {
        return (
            <>
                <Skeleton.Image key={1} style={{ height: 80 }} className='me-1' />
                <Skeleton.Image key={2} style={{ height: 80 }} className='me-1' />
                <Skeleton.Image key={3} style={{ height: 80 }} />
            </>
        );
    }

    return (
        <>
            {collectionItems.length > 0 ? (
                <div style={{ cursor: 'pointer' }}>
                    <div
                        className='box-collection'
                        onClick={() => onClickItem(collectionId)}
                    >
                        {collectionItems[0].asset.assetType === AssetType.IMAGE ? (
                            <img
                                key={collectionItems[0].asset.id}
                                src={collectionItems[0].asset.thumbnailImageUrl}
                                alt={collectionItems[0].asset.name}
                            />
                        ) : collectionItems[0].asset.thumbnailVideoCoverUrl ? (
                            <img
                                key={collectionItems[0].asset.id}
                                src={collectionItems[0].asset.thumbnailVideoCoverUrl}
                                alt={collectionItems[0].asset.name}
                            />
                        ) : (
                            <Skeleton.Image key={1} />
                        )}
                    </div>
                    <div
                        className='box-collection-sm'
                        onClick={() => onClickItem(collectionId)}
                    >
                        {rederItems(collectionItems)}
                    </div>
                    <div className='box-collection-detail mt-2'>
                        <Title level={5} style={{ color: nameColor }} onClick={() => onClickItem(collectionId)} className='my-3'>
                            {collectionName}
                        </Title>
                    </div>
                </div>
            ) : (
                <div style={{ cursor: 'pointer' }} onClick={() => onClickItem(collectionId)}>
                    <div className='box-collection'>
                        <Skeleton.Image />
                    </div>
                    <div className='box-collection-sm'>
                        {rederEmptyItem()}
                    </div>
                    <div className='box-collection-detail mt-2' style={{ color: nameColor }}>
                        <Title level={5}>{collectionName}</Title>
                        {artist && artistId && (
                            <>
                                <Avatar size='large' src={<img src={artist.avatarUrl} alt={artist.artist.nameInfoEn.firstname + ' ' + artist.artist.nameInfoEn.lastname} />} />
                                <Title level={4} className='text-light my-0 me-3 ms-3'>
                                    <Link to={`/artist/${artist.userId}`} className='text-light'>
                                        {artist.artist &&
                                            artist.artist.nameInfoEn.firstname +
                                            ' ' +
                                            artist.artist.nameInfoEn.lastname}
                                    </Link>
                                </Title>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
