import { RouteNames } from "src/routes/routeName";
import {
  Col,
  Result,
  Row,
} from "antd";
import TranslationKey from "src/i18n/translation";

export default function SuccessOrder() {

  return (
    <>
    <Row className="mt-4 mb-4">
      <Col span={16} offset={4}>
        <Result
            status="success"
            title={TranslationKey('successOrder.successfullyPurchased')}
            subTitle={TranslationKey('successOrder.thanksYourFor')}
            className="result-order"
            extra={[
              <a
                  href={RouteNames.orderHistory}
                  className="btn-orange-round"
                  style={{ textDecoration: "none" }}
                >
                  {TranslationKey('successOrder.goToOrders')}
                </a>
            ]}
          />
      </Col>
    </Row>
    </>
  );
}
