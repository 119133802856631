import { RouteNames } from 'src/routes/routeName';
import { Button, Card, Col, Row, Statistic } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionContext } from 'src/context/session-context';
import { AssetStatus } from 'src/constant/assetConstants';
import Table, { ColumnsType } from 'antd/es/table';
import { AssetService } from 'src/services/assetService';
import { HttpStatus } from 'src/constant/responseStatus';
import { useEffect, useState } from 'react';
import { ArtistService } from 'src/services/artistService';
import { MostSellingModel } from '@/src/models/DashboardModel';
import { PageDocument } from 'src/constant/stringConstants';
import TranslationKey from 'src/i18n/translation';

export default function Dashboard() {
    const navigate = useNavigate();
    const { userProfile, getUserProfileAsync } = useSessionContext();
    const [cntPublishAsset, setCntPublishAsset] = useState(0);
    const [cntDraftAsset, setCntDraftAsset] = useState(0);
    const [cntPendingAsset, setCntPendingAsset] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [mostSellingDatas, setMostSellingDatas] = useState<MostSellingModel[]>([]);

    const columns: ColumnsType<MostSellingModel> = [
        {
            title: 'Image',
            dataIndex: 'image',
            width: 60,
            align: 'center',
            render: (value, record, index) => (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                    src={record.imageUrl}
                    style={{ position: 'relative', height: 50, width: 50, objectFit: 'cover', borderRadius: 5 }}
                />
            ),

        },
        {
            title: 'Code',
            dataIndex: 'code',
            width: '70%',
            render: (value, record, index) => (
                <Link to={RouteNames.assetDetail.replace(':id', record.id)}>{record.code}</Link>
            ),
        },
        {
            title: 'CountOrder',
            dataIndex: 'countOrder',
            align: 'right',
            width: 50,
        },
    ];

    useEffect(() => {
        document.title = `Profile - ${PageDocument.Title}`;
        if (userProfile && userProfile.userId) {
            getCountPublishAssetAsync();
            getCountDraftAssetAsync();
            getCountPendingAssetAsync();
            onGetListMostDownload();
            onGetBalance(userProfile.userId);
        }
        else {
            getUserProfileAsync();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    const getCountPublishAssetAsync = async () => {
        const response = await new AssetService().getMyUpload(AssetStatus.COMPLETED);

        if (response.status !== HttpStatus.OK) {
            return;
        }

        setCntPublishAsset(response.data.length);
    };

    const getCountDraftAssetAsync = async () => {
        const response = await new AssetService().getMyUpload(AssetStatus.DRAFT);

        if (response.status !== HttpStatus.OK) {
            return;
        }

        setCntDraftAsset(response.data.length);
    };

    const getCountPendingAssetAsync = async () => {
        const response = await new AssetService().getMyUpload(AssetStatus.PENDING);

        if (response.status !== HttpStatus.OK) {
            return;
        }

        setCntPendingAsset(response.data.length);
    };

    const onGetBalance = async (id: string) => {
        const { data, status } = await new ArtistService().getBalanceAsync(id);

        if (status !== HttpStatus.OK) {
            return;
        }

        setTotalBalance(data.totalDebit);
    };

    const onGetListMostDownload = async () => {
        const { data, status } = await new ArtistService().getListMostDownloadAsync();

        if (status !== HttpStatus.OK) {
            return;
        }

        setMostSellingDatas(data);
    };

    return (
        <>
            <Row gutter={[16, 16]} >
                <Col span={24} md={12} lg={6} >
                    <Card bordered={false}>
                        <Statistic
                            title={TranslationKey('dashboard.publishProducts')}
                            value={cntPublishAsset}
                            valueStyle={{ color: '#52c41a', fontWeight: 'bold' }}
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            type='primary'
                            shape='round'
                            onClick={() => navigate(RouteNames.myPublish)}
                            size='large'
                        >
                            {TranslationKey('button.view')}
                        </Button>
                    </Card>
                </Col>
                <Col span={24} md={12} lg={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={TranslationKey('dashboard.waitingForSubmit')}
                            value={cntDraftAsset}
                            valueStyle={{ color: '#13c2c2', fontWeight: 'bold' }}
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            type='primary'
                            shape='round'
                            onClick={() => navigate(RouteNames.myUpload)}
                            size='large'
                        >
                             {TranslationKey('button.view')}
                        </Button>
                    </Card>
                </Col>
                <Col span={24} md={12} lg={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={TranslationKey('dashboard.waitingForApprove')}
                            value={cntPendingAsset}
                            valueStyle={{ color: '#faad14', fontWeight: 'bold' }}
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            type='primary'
                            shape='round'
                            onClick={() => navigate(`${RouteNames.myUpload}?status=${AssetStatus.PENDING}`)}
                            size='large'
                        >
                            {TranslationKey('button.view')}
                        </Button>
                    </Card>
                </Col>
                <Col span={24} md={12} lg={6}>
                    <Card bordered={false}>
                        <Statistic
                            title={TranslationKey('totalBalance')}
                            value={totalBalance}
                            valueStyle={{ color: '#1677ff', fontWeight: 'bold' }}
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            type='primary'
                            shape='round'
                            onClick={() => navigate(RouteNames.financial)}
                            size='large'
                        >
                            {TranslationKey('button.payout')}
                        </Button>
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} className='mt-4'>
                <Col span={24}>
                    <Card title={TranslationKey('dashboard.mostDownload')} bordered={false}>
                        <Table columns={columns} dataSource={mostSellingDatas} size='small' pagination={false} bordered={false} />
                    </Card>
                </Col>
            </Row>
        </>
    );
}
