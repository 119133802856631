import { Typography, Card } from 'antd';
import { NewsContentModel } from '../../models/BannerModel';
import Meta from 'antd/es/card/Meta';

const { Title } = Typography;

interface AssetInterface {
    index: number;
    content: NewsContentModel;
}

export default function CardContent({ index, content }: AssetInterface) {
    const onClickItem = (url: string) => {
        window.location.href = url;
    };
    
    return (
        <>
            <Card
                key={index}
                hoverable
                onClick={() => onClickItem(content.redirectTo)}
                cover={
                    <img
                        alt={content.subject}
                        src={content.coverUrl}
                        style={{ borderRadius: 10, objectFit: 'cover', height: 300 }}
                    />
                }
            >
                <Meta
                    title={
                        <Title level={4} className='text-light' style={{ whiteSpace: 'pre-wrap' }}>
                            {content.subject}
                        </Title>
                    }
                    description={<p className='text-ellipsis-3'>{content.description}</p>}
                />
            </Card>
        </>
    );
}