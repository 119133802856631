export const enum MasterDataType {
  CAMERA_TYPE = 0,
  CAMERA_BRAND = 1,
  ETHNIC = 2,
  KEYWORD = 3,
  STYLE_OF_WORK = 4,
  PLATFORM = 5,
  REPORTASSET = 9,
}

export const enum TopicReportAsset {
  Other = '27aa5221-09aa-4520-9512-7479579bf3b5'
}