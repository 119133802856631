import { createTheme, ThemeProvider } from '@mui/material';
import './App.css';
import AppRoutes from './routes';
import 'swiper/css';
import 'swiper/css/bundle';
import "swiper/css/pagination";
import SessionComponent from './components/session/sessionComponent';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HelmetProvider } from 'react-helmet-async';
import HelmetComponent from './components/helmet/HelmetComponent';
import './i18n/i18nConfig';

export default function App() {

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#fcfcfc',
      },
    },
  });

  const imageURl = `${process.env.REACT_APP_REDIRECT_PATH}/images/logo/logo_alive_square.png`;
  
  return (
    <>
    <HelmetProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}>
        <ThemeProvider theme={lightTheme}>
          <div className="App">
            <HelmetComponent imageUrl = {imageURl} />
            <SessionComponent>
              <AppRoutes />
            </SessionComponent>
          </div>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </HelmetProvider>
    </>
  );
}