import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { IOrderDetail, IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import moment from 'moment';
import { DateFormat } from 'src/constant/DateFormatConstants';

interface ReportInterface {
  order: IOrderHistoryResponse;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderTop: 1,
    alignItems: 'center',
    height: 24,
    padding: 0,
    margin: 0,
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  description: {
    height: '100%',
    width: '50%',
    textAlign: 'left',
    paddingLeft: 5,
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  total: {
    height: '100%',
    width: '50%',
    textAlign: 'right',
    paddingRight: 8,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});

export default function TableFooter({ order }: ReportInterface) {
  return (
    <Fragment>
      <View style={styles.row}>
        <View style={styles.description}>
          <Text>Discount :</Text>
        </View>
        <View style={styles.total}>
          <Text>
          ฿{order.discountTotal.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </Text>
        </View>
      </View>
    </Fragment>
  );
}
