import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableBlankSpec from './TableBlankSpec';
import TableFooter from './TableFooter';

interface ReportInterface {
  order: IOrderHistoryResponse;
}

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    borderTop: 1,
    borderBottom: 1,
  },
});

export default function ItemTable({ order }: ReportInterface) {
  const tableRowsCount = 8;
  return (
    <View style={styles.tableContainer}>
      <TableHeader order = {order}/>
      <TableRow orderItems={order.orderDetails} />
      <TableBlankSpec rowsCount={tableRowsCount - order.orderDetails.length} />
      <TableFooter order={order} />
    </View>
  );
}
