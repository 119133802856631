import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { ExtendedLicenseModel, StandardLicenseModel } from '../models/LicenseModel';

export class LicenseService {
    private _qs: any = require('qs');
    private getStandardLicensePath = '/License/StandardLicenses';
    private getExtendedLicensePath = '/License/ExtendedLicenses';

    GetStandardLicenseByType(assetType: string): Promise<AxiosResponse<StandardLicenseModel[]>> {
        return Axios.get<StandardLicenseModel[]>(this.getStandardLicensePath, { params: { assetType: assetType } });
    }

    GetExtendedLicenseByType(assetType: string): Promise<AxiosResponse<ExtendedLicenseModel[]>> {
        return Axios.get<ExtendedLicenseModel[]>(this.getExtendedLicensePath, { params: { assetType: assetType } });
    }
}