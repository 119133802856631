import { Col, Row, Typography, Button, Divider, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import CartItem from '../../components/card/CartItem';
import { useSessionContext } from '../../context/session-context';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import { AssetPublishModel } from 'src/models/assetModel';
import { PageDocument } from 'src/constant/stringConstants';
import { SessionStatus } from 'src/constant/sessionStatus';
import { ExtendedLicenseModel } from 'src/models/LicenseModel';
import { ExclamationCircleFilled } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';


const { Title, Text } = Typography;

export default function Cart() {
    const [assets, setAssets] = useState<AssetPublishModel[]>([]);
    const { getOrderAssets, updateOrderAssets } = useSessionContext();
    const navigate = useNavigate();
    const { isAuthenticated } = useSessionContext();
    const [messageApi, contextHolder] = message.useMessage();
    const { confirm } = Modal;

    useEffect(() => {
        onGetOrders();
        document.title = `Cart - ${PageDocument.Title}`;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
    }, [assets]);

    const showGrandTotal = (assets: AssetPublishModel[]) => {
        var total = 0;
        var vat = 0;
        var grandTotal = 0;

        if (assets.length === 0) {
            return { total, vat, grandTotal, };
        }

        total = assets.reduce((sum, current) => (sum = sum + current.price), 0);
        vat = (total * 7) / 100;
        grandTotal = total + vat;
        return { total, vat, grandTotal, };
    }

    const onGetOrders = () => {
        let orders = getOrderAssets();
        if (!orders) {
            return;
        }

        const orderGrpItemStd = _(orders)
            .groupBy((item) => {
                return item['id'] + item.standardLicense['id'];
            })
            .map(function (orders, orderid) {
                return { orderid: orderid, orders: orders };
            })
            .value();

        for (let i = 0; i < orders.length; i++) {
            const hasExtendedLicenses =
                orderGrpItemStd.findIndex(
                    (g) => g.orderid === orders[i].id + orders[i].standardLicense.id && g.orders.length > 1
                ) > -1;

            if (hasExtendedLicenses) {
                const fisrtExtId = orderGrpItemStd.filter(
                    (g) => g.orderid === orders[i].id + orders[i].standardLicense.id && g.orders.length > 0
                )[0].orders[0].extendedLicense.id;

                if (orders[i].extendedLicense.id !== fisrtExtId) {
                    orders[i].price = orders[i].extendedLicense.price;
                }
            }
        }

        setAssets(orders);
    };

    const onDelete = (id: string, stdId: string, extId: string) => {
        let orders = getOrderAssets();

        if (!orders) {
            orders = [];
        }

        const oldIndex = orders.findIndex(
            (order) => order.id === id && order.standardLicense.id === stdId && order.extendedLicense.id === extId
        );

        if (oldIndex > -1) {
            orders.splice(oldIndex, 1);
        }

        updateOrderAssets(orders);
        onGetOrders();
    };

    const onUpdateExtendedLicense = (assetId: string, extLicense: ExtendedLicenseModel) => {
        let orders = getOrderAssets();

        if (!orders) {
          orders = [];
        }

        orders.forEach(o => {
          if(o.id === assetId){
              o.extendedLicense = extLicense;
              o.price = o.standardLicense.price + extLicense.price;
          }
        });

        updateOrderAssets(orders);

        onGetOrders();
    };

    const onCheckout = () => {
        if (assets.length === 0) {
            messageApi.open({
                type: 'warning',
                content: 'Please add to cart',
            });

            return;
        }

        if (isAuthenticated === SessionStatus.UNAUTHENTICATED) {
            navigate(RouteNames.login);

            return;
        }

        confirm({
            title: 'Do you want to checkout?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                navigate(RouteNames.order);
            },
          });
    };

    return (
        <>
            {contextHolder}
            <div className='container order-summary pt-5 banner-margin-t-20'>
                <Title level={2} className='text-light'>
                    {TranslationKey('cart.title')}
                </Title>
                <Row gutter={16}>
                    <Col className='gutter-row' xs={24} xl={16}>
                        <Row>
                            <Col span={24}>
                                <div className='bg-outline-gray mt-2' style={{ borderRadius: 10 }}>
                                    <>
                                        {assets.map((asset, index) => (
                                            <>
                                                <CartItem 
                                                key={index} 
                                                    index={index} 
                                                    asset={asset} onDelete={onDelete} 
                                                    onUpdateExtendedLicense={onUpdateExtendedLicense}
                                                    isUpdateExtendedLicense={false}
                                                    isUpdateStandardLicense={false}
                                                    />
                                                {index !== assets.length - 1 && (
                                                    <Divider
                                                        style={{
                                                            border: '1px solid',
                                                            borderImage:
                                                                'linear-gradient(rgba(49, 51, 89, 0.318), rgba(49, 51, 89, 1), rgba(49, 51, 89, 0)) 1',
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ))}
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='gutter-row' xs={24} xl={8}>
                        <div className='bg-outline-gray mt-2 pt-' style={{ borderRadius: 10 }}>
                            <Row justify='space-between'>
                                <Col span={12}>
                                    <Title level={5} className='text-light'>
                                        {TranslationKey('cart.total')}
                                        
                                    </Title>
                                </Col>
                                <Col span={12} className='text-end'>
                                    <Text className='text-light'>
                                        {/* THB{total.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                        })} */}
                                        THB{showGrandTotal(assets).total
                                            .toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                            })}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={12}>
                                    <Title level={5} className='text-light'>
                                    {TranslationKey('cart.vat')} (7%)
                                    </Title>
                                </Col>
                                <Col span={12} className='text-end'>
                                    <Text className='text-light'>
                                        {/* THB{totalVat.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                        })} */}
                                        THB{showGrandTotal(assets).vat
                                            .toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                            })}
                                    </Text>
                                </Col>
                            </Row>
                            <hr className='hr-gray' />
                            <Row justify='space-between'>
                                <Col span={12}>
                                    <Title level={4} className='text-light'>
                                    {TranslationKey('cart.grandTotal')}
                                    </Title>
                                </Col>
                                <Col span={12} className='text-end'>
                                    <Title level={4} className='text-light'>
                                        THB{showGrandTotal(assets).grandTotal
                                            .toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                            })}
                                    </Title>
                                </Col>
                            </Row>
                            <Button
                                type='primary'
                                htmlType='submit'
                                shape='round'
                                className='btn-primary w-100 mt-5'
                                size='large'
                                onClick={() => onCheckout()}
                                block
                            >
                                {TranslationKey('button.checkOut')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}
