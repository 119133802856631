import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { IOrderDetail, IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import NotoSerifThai from '../../../../assets/fonts/Noto_Serif_Thai/NotoSerifThai.ttf';
import Inter from '../../../../assets/fonts/Inter/Inter.ttf';
import { AssetType } from 'src/constant/assetConstants';

Font.register({
  family: 'NotoSerifThai',
  src: NotoSerifThai,
});

Font.register({
  family: 'Inter',
  src: Inter,
});

interface ReportInterface {
  orderItems: IOrderDetail[];
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: 48,
    padding: 0,
    margin: 0,
    fontFamily: 'Inter',
  },
  descriptionStart: {
    height: '100%',
    width: '30%',
    textAlign: 'left',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  std: {
    height: '100%',
    width: '50%',
    textAlign: 'center',
    paddingRight: 8,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  amount: {
    height: '100%',
    width: '20%',
    textAlign: 'right',
    paddingRight: 8,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  logo: {
    height: 36,
    width: 36,
    marginRight: 0,
  },
  assetName: {
    display: 'flex',
    flexDirection: 'column',
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  fontNoto: {
    fontFamily: 'NotoSerifThai',
  },
});

export default function TableRow({ orderItems }: ReportInterface) {
  return (
    <Fragment>
      {orderItems.map((item, index) => (
        <View
          style={styles.row}
          key={index}>
          <View style={styles.descriptionStart}>
            <Text>{item.asset.code}</Text>
          </View>
          <View style={styles.std}>
            <Text>{item.standardLicenseName}</Text>
            <Text>{item.extendedLicenseName}</Text>
          </View>
          <View style={styles.amount}>
            <Text>
            ฿
              {item.afterDiscountTotal.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </Text>
          </View>
        </View>
      ))}
    </Fragment>
  );
}
