import { Form, Input } from 'antd';
import { SearchOutlined, BgColorsOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { ChangeEvent, KeyboardEvent } from 'react';

interface Props {
  placeholder?: string;
  label?: string;
  type?: string;
  className?: string;
  name: string;
  SizeType: 'large' | 'middle' | 'small';
  icon: boolean;
  iconName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}


function generateIcon(icon: string) {
  switch (icon) {
    case 'arrow-download':
      return <DownOutlined className='icon pe-3 ps-1' />
    case 'user':
      return <UserOutlined className='icon pe-3 ps-1' />
    case 'color':
      return <BgColorsOutlined className='icon pe-3 ps-1' />
    case 'search':
      return <SearchOutlined className='icon pe-3 ps-1' />
    default:
    // code block
  }
}


function InputControl({ label, placeholder, icon, className, name, SizeType = 'middle', iconName, onChange, onKeyPress }: Props) {
  return (
    <>
      <Form.Item name={name} label={label} >
        <Input
          placeholder={placeholder}
          size={SizeType}
          className={className}
          prefix={icon ? icon && generateIcon(iconName ?? '') : ''}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </Form.Item>
    </>
  );
}

export default InputControl;