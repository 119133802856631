import { Card, Row, Col } from "antd";
import FileSpec from "./file-spec";
import FileUpload from "./file-upload";

export default function ArtistUpload() {
  return (
    <Card title="Upload" bordered={false}>
      <Row gutter={[16, 16]} className="artist-upload">
        <Col span={24} lg={12}>
          <FileUpload />
        </Col>
        <Col span={24} lg={12}>
          <FileSpec />
        </Col>
      </Row>
    </Card>
  );
}