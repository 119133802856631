import { PageDocument } from "src/constant/stringConstants";
import { Col, Divider, Row } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import TranslationKey from "src/i18n/translation";

export default function ArtistTermOfService() {
  const releaseFormModel = require('src/assets/form/model_release_form.pdf');
  const releaseFormProperty = require('src/assets/form/property_release_form.pdf');

    useEffect(() => {
        document.title = `Term of Service - ${PageDocument.Title}`;
    }, []);

  return (
    <div className="container py-5 banner-margin-t-50">
      <Row className="d-flex justify-content-center">
        <Col span={16}>
          <Title className="text-light" level={3}>
          {TranslationKey('termOfServiceArtist.theAliveUnited')}
          </Title>
          <Title className="text-light" level={3}>
            {TranslationKey('termOfServiceArtist.whereas.title')}
            </Title>
            <ol className="ol-item-spacing">
            <li>
                {TranslationKey('termOfServiceArtist.whereas.1')}
            </li>
            <li>
                {TranslationKey('termOfServiceArtist.whereas.2')}
            </li>
            <li>
                {TranslationKey('termOfServiceArtist.whereas.3')}
            </li>
            </ol>
          <Title className="text-light" level={3}>
            {TranslationKey('termOfServiceArtist.nowThereFore')}
          </Title>
          <Divider />

          <dl>
          <dt>
            <Title className="text-primary" level={3}>
                1. {TranslationKey('termOfServiceArtist.1.title')}
            </Title>
        </dt>
        <dd className="dd-indent">
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.aLIVEUNITEDServices.title')}</span> {TranslationKey('termOfServiceArtist.1.aLIVEUNITEDServices.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.artist.title')}</span> {TranslationKey('termOfServiceArtist.1.artist.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.artsAndContents.title')}</span> {TranslationKey('termOfServiceArtist.1.artsAndContents.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.company.title')}</span> {TranslationKey('termOfServiceArtist.1.company.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.customer.title')}</span> {TranslationKey('termOfServiceArtist.1.customer.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.customerWork.title')}</span> {TranslationKey('termOfServiceArtist.1.customerWork.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.partner.title')}</span> {TranslationKey('termOfServiceArtist.1.partner.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.tOSForArtist.title')}</span> {TranslationKey('termOfServiceArtist.1.tOSForArtist.desc')}
            <br /><br />
            <span className="fw-bold text-primary">{TranslationKey('termOfServiceArtist.1.website.title')}</span> {TranslationKey('termOfServiceArtist.1.website.desc')}
        </dd>
        <dt>
        <Title className="text-primary" level={3}>2. {TranslationKey('termOfServiceArtist.2.title')}</Title>
        </dt>
        <dd className="dd-indent">
        <p>{TranslationKey('termOfServiceArtist.2.desc')}</p>

        <ol className="ol-item-spacing">
            <li>
            {TranslationKey('termOfServiceArtist.2.1.title')}
            <ol type="a">
                <li>{TranslationKey('termOfServiceArtist.2.1.a')}</li>
                <li>{TranslationKey('termOfServiceArtist.2.1.b')}</li>
                <li>{TranslationKey('termOfServiceArtist.2.1.c')}</li>
            </ol>
            </li>
            <li>
            {TranslationKey('termOfServiceArtist.2.2.title')}
            <ol type="a">
                <li>{TranslationKey('termOfServiceArtist.2.2.a')}</li>
                <li>{TranslationKey('termOfServiceArtist.2.2.b')}</li>
                <li>{TranslationKey('termOfServiceArtist.2.2.c')}</li>
                <li>{TranslationKey('termOfServiceArtist.2.2.d')}</li>
                <li>{TranslationKey('termOfServiceArtist.2.2.e')}</li>
            </ol>
            </li>
            <li>{TranslationKey('termOfServiceArtist.2.3')}</li>
            <li>{TranslationKey('termOfServiceArtist.2.4')}</li>
        </ol>
        </dd>
        <dt>
            <Title className="text-primary" level={3}>3. {TranslationKey('termOfServiceArtist.3.title')}</Title>
            </dt>
            <dd className="dd-indent">
            <ol className="ol-item-spacing">
                <li>
                {TranslationKey('termOfServiceArtist.3.1.title')}
                <ol>
                    <li>{TranslationKey('termOfServiceArtist.3.1.1')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.2')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.3')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.4')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.5')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.6')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.7')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.8')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.9')}</li>
                    <li>{TranslationKey('termOfServiceArtist.3.1.10')}</li>
                </ol>
                </li>
                <li>{TranslationKey('termOfServiceArtist.3.2')}</li>
            </ol>
            </dd>
            <dt>
  <Title className="text-primary" level={3}>4. {TranslationKey('termOfServiceArtist.4.title')}</Title>
</dt>
            <dd className="dd-indent">
            <ol className="ol-item-spacing">
                <li>
                {TranslationKey('termOfServiceArtist.4.1.title')}
                <a href="/term-of-service-artist">EN</a>
                {TranslationKey('termOfServiceArtist.4.1.desc1')}
                <a target="_blank" href="/privacy-policy-artist">{TranslationKey('termOfServiceArtist.4.1.dataProtectionNoticeForWebsiteUsers')}</a>
                {TranslationKey('termOfServiceArtist.4.1.desc2')}
                
                </li>
                <li>{TranslationKey('termOfServiceArtist.4.2')}</li>
                <li>{TranslationKey('termOfServiceArtist.4.3')}</li>
                <li>{TranslationKey('termOfServiceArtist.4.4')}</li>
                <li>
                {TranslationKey('termOfServiceArtist.4.5.desc1')}
                <br />
                {TranslationKey('termOfServiceArtist.4.5.desc2')}
                <br />
                {TranslationKey('termOfServiceArtist.4.5.desc3')}
                </li>
                <li>{TranslationKey('termOfServiceArtist.4.6')}</li>
                <li>{TranslationKey('termOfServiceArtist.4.7')}</li>
                <li>{TranslationKey('termOfServiceArtist.4.8')}</li>
            </ol>
            </dd>
            <dt>
            <Title className="text-primary" level={3}>5. {TranslationKey('termOfServiceArtist.5.title')}</Title>
            </dt>
            <dd className="dd-indent">
            <ol className="ol-item-spacing">
                <li>
                {TranslationKey('termOfServiceArtist.5.1.desc1')}
                <a target="_blank" rel="noreferrer" href={releaseFormModel}>{TranslationKey('termOfServiceArtist.5.1.desc2')}</a>.
                {TranslationKey('termOfServiceArtist.5.1.desc3')}
                </li>
                <li>{TranslationKey('termOfServiceArtist.5.2')}</li>
                <li>{TranslationKey('termOfServiceArtist.5.3')}</li>
                <li>
                {TranslationKey('termOfServiceArtist.5.4.desc1')}
                <br />
                {TranslationKey('termOfServiceArtist.5.4.desc2')}
                </li>
            </ol>
            </dd>
            <dt>
            <Title className="text-primary" level={3}>6. {TranslationKey('termOfServiceArtist.6.title')}</Title>
            </dt>
            <dd className="dd-indent">
            <ol className="ol-item-spacing">
                <li>
                {TranslationKey('termOfServiceArtist.6.1.desc1')}
                <a target="_blank" rel="noreferrer" href={releaseFormProperty}>{TranslationKey('termOfServiceArtist.6.1.link')}</a>.
                <br />
                {TranslationKey('termOfServiceArtist.6.1.desc2')}
                </li>
                <li>{TranslationKey('termOfServiceArtist.6.2')}</li>
                <li>{TranslationKey('termOfServiceArtist.6.3')}</li>
                <li>
                {TranslationKey('termOfServiceArtist.6.4.desc1')}
                <br />
                {TranslationKey('termOfServiceArtist.6.4.desc2')}
                <br />
                {TranslationKey('termOfServiceArtist.6.4.desc3')}
                </li>
            </ol>
            </dd>
            <dt>
            <Title className="text-primary" level={3}>7. {TranslationKey('termOfServiceArtist.7.title')}</Title>
            </dt>
            <dd className="dd-indent">
            <ol className="ol-item-spacing">
                <li>
                {TranslationKey('termOfServiceArtist.7.1')}
                </li>
                <li>
                {TranslationKey('termOfServiceArtist.7.2')}
                </li>
                <li>
                {TranslationKey('termOfServiceArtist.7.3')}
                </li>
                <li>
                {TranslationKey('termOfServiceArtist.7.4')}
                </li>
            </ol>
            </dd>
            <dt>
                <Title className="text-primary" level={3}>8. {TranslationKey('termOfServiceArtist.8.title')}</Title>
                </dt>
                <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.8.1')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.8.2')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.8.3')}
                    </li>
                </ol>
                </dd>
                <dt>
                    <Title className="text-primary" level={3}>9. {TranslationKey('termOfServiceArtist.9.title')}</Title>
                </dt>
                <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.9.1.title')}
                    <ol type="a">
                        <li>
                        {TranslationKey('termOfServiceArtist.9.1.a1')}
                        <br/>
                        {TranslationKey('termOfServiceArtist.9.1.a2')}
                        </li>
                        <li>
                        {TranslationKey('termOfServiceArtist.9.1.b1')}
                        <br/>
                        {TranslationKey('termOfServiceArtist.9.1.b2')}
                        </li>
                    </ol>
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.2')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.3.desc1')}
                    <br/>
                    {TranslationKey('termOfServiceArtist.9.3.desc2')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.4')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.5')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.6')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.7')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.8')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.9')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.10')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.11')}
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.9.12')}
                    </li>
                </ol>
                </dd>

            <dt>
              <Title className="text-primary" level={3}>
              10. {TranslationKey('termOfServiceArtist.10.title')}
              </Title>
            </dt>
            <dd className="dd-indent">
                <p>
                {TranslationKey('termOfServiceArtist.10.desc')}                </p>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              11. {TranslationKey('termOfServiceArtist.11.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <p>
                {TranslationKey('termOfServiceArtist.11.desc1')} 
                    <br/>
                    {TranslationKey('termOfServiceArtist.11.desc2')} 
                </p>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              12. {TranslationKey('termOfServiceArtist.12.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.12.1')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.12.2')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.12.3')} 
                    </li>
                </ol>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              13. {TranslationKey('termOfServiceArtist.13.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.13.1')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.13.2')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.13.3')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.13.4')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.13.5')} 
                    </li>

                </ol>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              14. {TranslationKey('termOfServiceArtist.14.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <p>
                {TranslationKey('termOfServiceArtist.14.desc')} 
                </p>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              15. {TranslationKey('termOfServiceArtist.15.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.15.1')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.15.2')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.15.3')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.15.4')} 
                    </li>
                </ol>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              16. {TranslationKey('termOfServiceArtist.16.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.16.1')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.16.2')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.16.3')}   <a href="mailto:artist_support@aliveunited.com
                        ">artist_support@aliveunited.com
                    </a>
                    </li>
                </ol>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              17. {TranslationKey('termOfServiceArtist.17.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.17.1')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.17.2')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.17.3')} 
                    </li>
                </ol>
            </dd>
            <dt>
              <Title className="text-primary" level={3}>
              18. {TranslationKey('termOfServiceArtist.18.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
                <ol className="ol-item-spacing">
                    <li>
                    {TranslationKey('termOfServiceArtist.18.1')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.2')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.3')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.4')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.5')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.6')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.7')} 
                    </li>
                    <li>
                    {TranslationKey('termOfServiceArtist.18.8')} 
                    </li>
                </ol>
            </dd>
            <dt>
            <Title className="text-primary" level={3}>
            {TranslationKey('termOfServiceArtist.effectiveDate.title')} 
              </Title>
            </dt>
            <dd className="dd-indent">
            {TranslationKey('termOfServiceArtist.effectiveDate.date')} 
            </dd>
            <dt>
            <Title className="text-primary" level={3}>
              {TranslationKey('termOfServiceArtist.lastUpdatedDate.title')} 
            </Title>
            </dt>
            <dd className="dd-indent">
            {TranslationKey('termOfServiceArtist.lastUpdatedDate.date')} 
            </dd>
          </dl>
        </Col>
      </Row>
    </div>
  );
}
