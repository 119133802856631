import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Col, Divider, Form, InputNumber, Row, Space, Typography } from 'antd';
import { FinancialConstant } from 'src/constant/financialConstants';

const { Text } = Typography;

interface ModalInterface {
  isModalOpen: boolean;
  onClose: any;
  unpaidAmount: number;
  onSubmit: (reqAmount: number) => void;
}

export default function PayoutRequestModal({ isModalOpen = false, onClose, unpaidAmount, onSubmit }: ModalInterface) {
  const [form] = Form.useForm();
  const [maxAmount, setMaxAmount] = useState(unpaidAmount);

  useEffect(() => {
    let poyoutVal = unpaidAmount;

    if (unpaidAmount > FinancialConstant.payoutMax) {
      setMaxAmount(FinancialConstant.payoutMax);
      poyoutVal = FinancialConstant.payoutMax;
    }

    form.setFieldValue('reqAmt', poyoutVal);
  }, [unpaidAmount]);

  const onFinish = (values: any) => {
    if (values.reqAmt >= FinancialConstant.payoutMin) {
      onSubmit(values.reqAmt);
      onClose();
    }
  };

  return (
    <Modal
      size='lg'
      show={isModalOpen}
      dialogClassName='payout-modal'>
      <Modal.Header>
        <Modal.Title>Payout Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          form={form}
          name='form_item_path'
          layout='vertical'
          onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                name='reqAmt'
                label='Request Amount'
                rules={[{ required: true, message: 'Please input your request amount!' }]}>
                <InputNumber
                  min={FinancialConstant.payoutMin}
                  addonAfter='THB'
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  size='large'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col span={24}>
              <Space direction='vertical'>
                <Text>The minimum withdrawal amount is {FinancialConstant.payoutMinText} baht and the maximum withdrawal is {FinancialConstant.payoutMaxText} baht (One withdrawal is permitted each month.)</Text>
                <Text type='danger'>*The system requires a time of +7 business days to check the status.</Text>
                <Text type='danger'>There is a 30 baht fee per transaction for transfers under 2 million baht.</Text>
                <Text type='danger'>**3% withholding tax for individuals to be filed with the tax authority</Text>
                <Text type='danger'>Payment cutoff every 15th of the month at 18:00.</Text>
                <Text type='danger'>Processing time takes 3-5 business days.</Text>
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row style={{ float: 'right' }}>
            <Col span={24}>
              <Space>
                <Button
                  type='default'
                  shape='round'
                  size='large'
                  onClick={onClose}>
                  Close
                </Button>
                <Button
                  type='primary'
                  shape='round'
                  size='large'
                  htmlType='submit'>
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
