import { CollectionAssetModel } from '@/src/models/CollectionModel';
import GallerySearchItem from '../galleryItem/GallerySearchItem';

interface IProps {
    collectionAssetModel: CollectionAssetModel;
    isUseContainerImage?: boolean;
}

export default function GallerysCollection({ collectionAssetModel, isUseContainerImage }: IProps) {
    return (
        <div className='gallery'>
            {collectionAssetModel.collectionItems.slice(0, 7).map((item, index) => (
                <>
                    <GallerySearchItem isUseContainerImage={isUseContainerImage ?? false} asset={item.asset} index={index} />
                </>
            ))}
        </div>
    );
}
