import Swal from 'sweetalert2';

export const enum alertType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}


const showAsync = (
  options: {
    type: alertType | string,
    message?: string,
    theme?: Theme | string,
    buttonText?: string,
    title?: string,
  }) => {
  let title = options.title;

  if (!title) {
    switch (options.type) {
      case alertType.SUCCESS:
        title = `Success`;
        break;
      case alertType.ERROR:
        title = `Failed`;
        break;
      case alertType.WARNING:
        title = `Warn`;
        break;
      default:
        title = `Information`;
        break;
    }
  }

  const customAlert = Swal.mixin({
    customClass: {
      popup: `popup ${options.theme ? options.theme : Theme.LIGHT}`,
      icon: 'icon',
      title: 'title',
      htmlContainer: 'htmlContainer',
      actions: 'actions d-grid mx-4',
      confirmButton: `btn btn-${options.type === 'error' ? 'outline-danger' : 'secondary'}`,
    },
    buttonsStyling: false,
  });

  return customAlert.fire({
    title: title,
    text: options.message,
    iconHtml: `<Image src='/icons/${options.type}-alert.svg'>`,
    showConfirmButton: true,
    confirmButtonText: options.buttonText ? options.buttonText : 'Confirm',
    allowOutsideClick: false,
  });
};

const confirmAsync = async (
  options: {
    message: string | HTMLElement,
    theme?: Theme | string,
    textBody?: string,
  }) => {
  const customAlert = Swal.mixin({
    customClass: {
      popup: `popup ${options.theme ? options.theme : Theme.LIGHT}`,
      icon: 'icon',
      title: 'title my-5',
      htmlContainer: 'htmlContainer',
      actions: 'actions row w-100 d-flex justify-content-center',
      confirmButton: 'btn btn-secondary col-5 ms-2',
      cancelButton: 'btn btn-outline-secondary col-5 me-2 text-decoration-none',
    },
    buttonsStyling: false,
  });

  const alert = await customAlert.fire({
    title: options.message,
    text: options.textBody ?? '',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirm`,
    cancelButtonText: `Cancel`,
    reverseButtons: true,
  });

  return alert.isConfirmed;
};

const confirmDeleteAsync = async (
  options: {
    title: string,
    message: string,
    type: alertType | string,
    theme?: Theme | string,
  }) => {
  const customAlert = Swal.mixin({
    customClass: {
      popup: `popup ${options.theme ? options.theme : Theme.LIGHT}`,
      icon: 'icon',
      title: 'title my-2',
      confirmButton: 'btn btn-secondary px-5',
      cancelButton: 'btn px-5',
      htmlContainer: 'htmlContainer',
    },
  });

  const alert = await customAlert.fire({
    title: options.title,
    text: options.message,
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: `Confirm`,
    cancelButtonText: `Cancel`,
    reverseButtons: true,
    iconHtml: `<Image src='/icons/${options.type}-alert.svg'>`,
  });

  return alert.isConfirmed;
};

export default {
  showAsync,
  confirmAsync,
  confirmDeleteAsync,
};