import axios, { AxiosHeaders } from 'axios';
import { Session } from '../constant/sesstion';
import sessionStore from '../store/sessionStore';
import { SignInResponseModel } from '../models/accountModel';

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

Axios.interceptors.request.use(
  async (request) => {
    const session = sessionStore.get(Session.SESSION_ACCESS_TOKEN) as SignInResponseModel;

    if (request.headers === undefined) {
      request.headers = new AxiosHeaders();
    }

    if (session !== undefined && session.access_token !== undefined) {
      if (!request.headers.Authorization) {
        request.headers.Authorization = `Bearer ${session.access_token}`;
      }
    }

    return request;
  },
  error => {
    return error.response;
  },
);

Axios.interceptors.response.use(
  response => response,
  async (error) => {
    return error.response;
  },
);

export default Axios;