import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import '@fontsource/inter';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Inter,Noto_Serif_Thai',
        colorBgBase: '#ffffff',
        colorTextBase: '#000000',
        colorBorder: '#cecece',
        colorBorderSecondary: '#cecece',
        colorTextPlaceholder: 'rgba(250, 250, 250, 0.3)',
      },
    }}
  >
    <App />
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
