import { useEffect, useRef, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { Button, Col, Form, Input, InputRef, Row } from 'antd';

interface FullScreenModalInterface {
  isModalOpen: boolean;
  onClose: any;
  children: JSX.Element;
  size?: ModalProps["size"];
}

export default function FullScreenMessageModal({ isModalOpen = false, onClose, children, size ="lg" }: FullScreenModalInterface) {
  return (
    <Modal 
      fullscreen={true} 
      size={size} 
      show={isModalOpen}
      onHide={onClose} 
      dialogClassName='dark-modal'>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}
