import { Breadcrumb, Layout, Space, theme } from 'antd';
import BreadcrumbAlive from './breadcrumb';

const { Content } = Layout;

interface Prop {
  children: JSX.Element;
}

export default function CustomContent({ children }: Prop) {

  return (
    <Content className='ch-main-layout'>
      <BreadcrumbAlive />
      {children}
    </Content>
  );
}
