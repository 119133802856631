import { Content } from 'antd/es/layout/layout';
import Foot from './footer/foot';
import Navbar from './navbar/navbar';
import { Loading } from 'src/components/loaging/Loading';
import { Outlet } from 'react-router-dom';
import GuardedRoute from '../../routes/GuardRoute';
import { useEffect, useState } from 'react';
import { Button, Row, Tooltip } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { LinkConstants } from 'src/constant/linkConstants';

interface IProps {
  isShowSearch?: boolean;
  forceAuthentication?: boolean;
}

export default function AppLayout({ forceAuthentication = true, isShowSearch = true }: IProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setIsLoading(true);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
    <Row justify={'end'} className='sticky-position'>
      <Tooltip
              color={'#108ee9'}
              title="Feel Free to chat with Alive United">
                    <Button
                        onClick={() => window.open(LinkConstants.FacebookMessage, '_blank')}
                        type='primary'
                        size='large'
                        className='btn-icon- ms-2 message-margin'
                        shape='circle'
                        icon={<MessageOutlined />}
                      />
        </Tooltip>
    </Row>
      <Navbar isShowSearch={isShowSearch} />
      <Content className='content-fixed-height'>
        <GuardedRoute forceAuthentication={forceAuthentication}>
          <>
            <Loading isLoading={isLoading} />
            <Outlet />
          </>
        </GuardedRoute>
      </Content>
      <Foot />
    </>
  );
}
