import { NewsContentModel } from '../../models/BannerModel';
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card, Skeleton } from 'antd';
import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import CardContent from '../card/CardContent';

const { Meta } = Card;

export default function NewsUpdate() {
  const [newsContents, setNewsContents] = useState<NewsContentModel[]>([]);
  const [activeNewContent, setActiveNewContent] = useState(false);

  useEffect(() => {
    onGetNewsContentsAsync();
  }, []);

  const onGetNewsContentsAsync = async () => {
    const { data: responseData, status: responseStatus } = await new BannerService().GetActiveNewsContent(12);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setNewsContents(responseData);
    setActiveNewContent(true);
  };
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        loop={false}
        spaceBetween={30}
        className='mySwiper flex'
      >
        {activeNewContent ? newsContents.map((content, index) =>
          <SwiperSlide className='card-art' key={index}>
            <CardContent index={index} content={content} />
          </SwiperSlide>
        ) :
          Array.from({ length: 12 }).map((_, index) => (
            <SwiperSlide className='card-art skeleton' key={index}>
              <Card
                hoverable
                className='img-big'
                cover={<Skeleton.Image active />}
              >
                <Meta
                  title={
                    <Skeleton active />
                  }
                />
              </Card>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  );
}
