import { AxiosResponse } from "axios";
import Axios from "../helper/Axios";
import IDownloadHistoryRequest from "../models/request/downloadHistoryRequest";
import { UserAssetLicenseResponse } from "../models/responses/userAssetLicenseResponse";

const api = '/UserAssetLicense';

export class UserAssetLicenseService {

    getAllAsync(sort: string): Promise<AxiosResponse<UserAssetLicenseResponse[]>> {
        return Axios.get<UserAssetLicenseResponse[]>(`${api}`);
    }

    getByIdAsync(id: string): Promise<AxiosResponse> {
        return Axios.get<any>(`${api}/${id}`);
    }

    getByAssetIdAsync(id: string): Promise<AxiosResponse<UserAssetLicenseResponse[]>> {
        return Axios.get<UserAssetLicenseResponse[]>(`${api}/asset/${id}`);
    }

    getListAsync(search: IDownloadHistoryRequest, page: number, size: number): Promise<AxiosResponse> {
      const params = {
        "AssetCode": search.assetCode,
        "DownloadFrom": search.downloadFrom,
        "DownloadUntil": search.downloadUntil,
        "Page": page,
        "Size": size,
    }
        return Axios.get(`${api}/download-history`, { params: params });
    }

    donwloadByIdAsync(id: string): Promise<AxiosResponse> {
        return Axios.get(`${api}/download/${id}`,
        {
          responseType: 'blob',
        });
    }

    downloadByAssetIdAsync(assetId: string): Promise<AxiosResponse> {
        console.log('assetId', assetId);
        return Axios.get(`${api}/download/asset/${assetId}`,
        {
          responseType: 'blob',
        });
    }

    getAssetByUserLicenseIdAsync(assetId: string): Promise<AxiosResponse> {
        return Axios.get<any>(`${api}/asset/${assetId}`);
    }

    downloadVipByIdAsync(id: string, stdId: string, extId: string): Promise<AxiosResponse> {
        const params = {
            "StandardLicenseId": stdId,
            "ExtendedLicenseId": extId,
        }
        return Axios.get(`${api}/download/${id}/vip`,
        {
          params: params
        , responseType: 'blob'
       });
    }

    tryDownloadAsync(assetId: string): Promise<AxiosResponse> {
        return Axios.get(`${api}/download/try/${assetId}`, { responseType: 'blob' });
    }

    getCalculateExclusiveAsync(assetId: string): Promise<AxiosResponse> {
        return Axios.get<any>(`${api}/${assetId}/calculate-exclusive`);
    }
}