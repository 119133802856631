import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Col, Form, Input, InputRef, Row } from 'antd';

interface ModalInterface {
  isModalOpen: boolean;
  onClose: any;
  children: JSX.Element;
}

export default function MessageModal({ isModalOpen = false, onClose, children }: ModalInterface) {
  
  return (
    <Modal size='lg' show={isModalOpen} onHide={onClose} dialogClassName='dark-modal'>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}
