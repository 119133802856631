import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { CollectionAssetCreatingModel, CollectionAssetItemCreatingModel, CollectionAssetModel } from '../models/CollectionModel';

export class CollectionService {
    private _qs: any = require('qs');
    private getCollectionByAssetPath = '/AssetCollection';

    GetCollectionByAssetPath(assetId: string): Promise<AxiosResponse<CollectionAssetModel[]>> {
        return Axios.get<CollectionAssetModel[]>(`${this.getCollectionByAssetPath}/Asset/${assetId}`);
    }

    GetCollectionByAssetId(assetId: string): Promise<AxiosResponse<CollectionAssetModel>> {
        return Axios.get<CollectionAssetModel>(`${this.getCollectionByAssetPath}/AssetId/${assetId}`);
    }

    GetCollectionById(id: string): Promise<AxiosResponse<CollectionAssetModel>> {
        return Axios.get<CollectionAssetModel>(`${this.getCollectionByAssetPath}/${id}`);
    }

    UpdateCollection(collection: CollectionAssetModel): Promise<AxiosResponse<CollectionAssetModel>> {
        return Axios.put<CollectionAssetModel>(`${this.getCollectionByAssetPath}/${collection.id}`, JSON.stringify(collection));
    }

    DeleteCollectionItem(collectionId: string, itemId: string): Promise<AxiosResponse> {
        return Axios.delete(`${this.getCollectionByAssetPath}/${collectionId}/CollectionAssetItem/${itemId}`);
    }

    DeleteCollection(collectionId: string): Promise<AxiosResponse> {
      return Axios.delete(`${this.getCollectionByAssetPath}/${collectionId}`);
    }

    CreateCollection(collection: CollectionAssetCreatingModel): Promise<AxiosResponse<CollectionAssetModel>> {
        return Axios.post<CollectionAssetModel>(`${this.getCollectionByAssetPath}`, JSON.stringify(collection));
    }

    getByIdAsync(id: string): Promise<AxiosResponse<CollectionAssetModel>> {
        return Axios.get<CollectionAssetModel>(`${this.getCollectionByAssetPath}/${id}`);
    }

    addCollectionItem(collectionId: string, data: CollectionAssetItemCreatingModel): Promise<AxiosResponse<CollectionAssetModel>> {
        return Axios.post<CollectionAssetModel>(`${this.getCollectionByAssetPath}/${collectionId}/item`, JSON.stringify(data));
    }
}