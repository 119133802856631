import { AssetType } from '../../constant/assetConstants';
import HoverVideoPlayer from 'react-hover-video-player';
import { Button, Skeleton } from 'antd';
import { CloseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { AssetPublishModel } from 'src/models/assetModel';
import { useEffect } from 'react';

interface AssetInterface {
  index: number;
  asset: AssetPublishModel;
  width?: string;
  height?: string;
  onClickItem: (assetId: string) => void;
  onDelete?: (assetId: string) => void;

  onClickItemMyPublish?: (assetId: string) => void;


  btnDelmarginRight?: number;
  btnDelmarginTop?: number;
  iconsize?: string;
}

export default function GalleryItem({ 
  index, asset, width = '100%', height = '100%', onDelete, onClickItem, onClickItemMyPublish,
  btnDelmarginRight = 10, btnDelmarginTop = 10, iconsize = "64px" }: AssetInterface) {
  return (
    <>
      {asset.assetType === AssetType.IMAGE ? (
        <div 
        onClick={() => onClickItemMyPublish!(asset.id)}
        className='img-gallery-item'>
          <img
            key={index}
            src={asset.thumbnailImageUrl ?? asset.thumbnailImageInfo?.imageUrl}
            onClick={() => onClickItem(asset.id)}
            alt={asset.name}
            width={width}
            height={height}
            style={{ objectFit: 'cover', borderRadius: 10, cursor: 'pointer' }}
          />
          {
            onDelete && (
              <Button
                type='primary'
                className='btn-gallery-delete'
                size='small'
                icon={<CloseOutlined className='icon-fix' />}
                onClick={() => onDelete?.(asset.id)}
                style={{
                  letterSpacing: 0,
                  top: 2,
                  marginRight: btnDelmarginRight,
                  borderRadius: 8,
                }}
                danger
              ></Button>
            )
          }
        </div>
      ) : (
        <div className='img-gallery-item'>
          <HoverVideoPlayer
            key={index}
            onClick={() => onClickItem(asset.id)}
            videoSrc={asset.thumbnailVideoUrl}
            restartOnPaused
            overlayTransitionDuration={500}
            style={{
              position: 'relative',
              width: width,
              height: height,
              borderRadius: 10,
            }}
            videoStyle={{
              position: 'relative',
              width: width,
              height: height,
              objectFit: 'cover',
              cursor: 'pointer',
              borderRadius: 10,
            }}
            pausedOverlay={
              asset.thumbnailVideoCoverUrl ? (
                <>
                  <img
                    key={index}
                    src={asset.thumbnailVideoCoverUrl}
                    className='gallery-img'
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: 10,
                    }}
                    alt={asset.name}
                  />
                  <PlayCircleOutlined
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      color: '#FFF',
                      fontSize: '3em',
                      opacity: 0.7,
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </>
              ) : (
                <Skeleton.Node>
                  <PlayCircleOutlined style={{ fontSize: iconsize, color: '#bfbfbf' }} />
                </Skeleton.Node>
              )
            }
            loadingOverlay={
              <div key={index} className='loading-overlay'>
                Loading...
              </div>
            }
          />
          {
            onDelete && (
              <Button
                type='primary'
                className='btn-gallery-delete'
                size='large'
                icon={<CloseOutlined className='icon-fix' />}
                onClick={() => onDelete?.(asset.id)}
                style={{ marginTop: btnDelmarginTop, marginRight: btnDelmarginRight }}
                danger
              ></Button>
            )
          }
        </div>
      )}
    </>
  );
}
