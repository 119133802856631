import { Layout } from "antd";

export default function AccountInfo() {
  return (
    <>
      <Layout style={{minHeight:"100vh"}}>
      </Layout>
    </>
  );
}
