import {
  Row,
  Col,
  Form,
  Tag,
  Typography,
  Flex,
  Select,
  Button,
  Result,
} from 'antd';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import InputControl from '../../components/controls/InputControl';
import '../../styles/fix/input-dark.scss';
import { AssetService } from 'src/services/assetService';
import { HttpStatus } from 'src/constant/responseStatus';
import GallerySearch from 'src/components/gallerySearch/GallerySearch';
import AssetSearchModel from 'src/models/responses/assetSearchModel';
import { AssetType, AssetUsage, OrientationType } from 'src/constant/assetConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SetNavigateParams } from '../../helper/SetNavigateParams';
import { SearchRequestModel } from '../../models/assetModel';
import { PageDocument, StringConstants } from 'src/constant/stringConstants';
import { IAutoCompleteOption, IInputOption } from '../../models/InputControl';
import { MasterDataService } from '../../services/masterDataService';
import { MasterDataType } from '../../constant/masterDataConstants';
import { LoadingOnDemand } from 'src/components/loaging/LoadingOnDemand';
import TranslationKey from 'src/i18n/translation';

const queryToString = require('querystring');

const enum TagKey {
  search = 'q',
  keyword = 'k',
  colors = 'c',
  artists = 'a',
  orientation = 'o',
  assetType = 't',
  assetUsage = 'u',
}

interface ITagQuery {
  key: string;
  value: string[];
}

export default function Search() {
  const [form] = Form.useForm<{ name: string; age: number }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const masterDataService = new MasterDataService();
  const [page, setPage] = useState(1);
  const [size] = useState(100);
  const [isCanNext, setIsCanNext] = useState(true);
  const [countItems, setCountItems] = useState(0);
  const [showAllTags, setShowAllTags] = useState(false);
  const [, setInputOption] = useState<IAutoCompleteOption[]>([]);
  const [tagQuery, setTagQuery] = useState<ITagQuery[]>([
    {
      key: TagKey.search,
      value: [searchParams.get(TagKey.search) || StringConstants.EMPTY],
    },
  ]);
  const [searchResult, setSearchResult] = useState<AssetSearchModel[]>([]);
  const [onLoadSeeMore, setOnLoadSeeMore] = useState<boolean>(false);

  useEffect(() => {
    setTagFormSearchParameter(searchParams);

    const querySearch = searchParams.get(TagKey.search);
    const queryTag = searchParams.get(TagKey.keyword);
    const queryColor = searchParams.get(TagKey.colors);
    const queryArtis = searchParams.get(TagKey.artists);
    const queryOrientation = searchParams.get(TagKey.orientation);
    const queryAssetType = searchParams.get(TagKey.assetType);
    const queryAssetUsage = searchParams.get(TagKey.assetUsage);

    onSearch(
      querySearch ? querySearch : StringConstants.EMPTY,
      queryTag ? queryTag : StringConstants.EMPTY,
      queryColor ? queryColor : StringConstants.EMPTY,
      queryArtis ? queryArtis : StringConstants.EMPTY,
      queryOrientation ? queryOrientation : StringConstants.EMPTY,
      queryAssetType ? queryAssetType : StringConstants.EMPTY,
      queryAssetUsage ? queryAssetUsage : StringConstants.EMPTY
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    document.title = `Search - ${PageDocument.Title}`;
    getInputOptionAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page > 1) {
      const querySearch = searchParams.get(TagKey.search);
      const queryTag = searchParams.get(TagKey.keyword);
      const queryColor = searchParams.get(TagKey.colors);
      const queryArtis = searchParams.get(TagKey.artists);
      const queryOrientation = searchParams.get(TagKey.orientation);
      const queryAssetType = searchParams.get(TagKey.assetType);
      const queryAssetUsage = searchParams.get(TagKey.assetUsage);

      onNextSearch(
        querySearch ? querySearch : StringConstants.EMPTY,
        queryTag ? queryTag : StringConstants.EMPTY,
        queryColor ? queryColor : StringConstants.EMPTY,
        queryArtis ? queryArtis : StringConstants.EMPTY,
        queryOrientation ? queryOrientation : StringConstants.EMPTY,
        queryAssetType ? queryAssetType : StringConstants.EMPTY,
        queryAssetUsage ? queryAssetUsage : StringConstants.EMPTY,
        page
      );
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getInputOptionAsync = useCallback(async () => {
    const { data, status } = await masterDataService.getGeneticAsync<IInputOption[]>(MasterDataType.KEYWORD);

    if (status !== HttpStatus.OK) {
      return;
    }

    data.forEach((item) => {
      setInputOption((prevInputOption) => [...prevInputOption, { value: item.name }]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTagFormSearchParameter = useCallback((searchParams: URLSearchParams) => {
    const stringSearchParams = searchParams.toString();

    if (!stringSearchParams) {
      setTagQuery([]);

      return;
    }

    searchParams.forEach((value, key) => {
      const values = value.split(',');

      if (key === TagKey.orientation) {
        setFormValue(key, value);
      }

      values.forEach((item) => setQueryTag(key, item));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFormValue = useCallback((key: string, value: string) => {
    form.setFieldValue(key, value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQueryTag = useCallback((key: string, value: string) => {
    setTagQuery((prevTagQuery) => {
      const index = prevTagQuery.findIndex((item) => item.key === key);

      if (index < 0) {
        // key does not exist, add it
        return [...prevTagQuery, { key, value: [value] }];
      }

      const itemValue = prevTagQuery[index].value;
      const valueIndex = itemValue.indexOf(value);
      if (valueIndex < 0) {
        if (key === TagKey.search || key === TagKey.orientation || key === TagKey.assetType || key === TagKey.assetUsage) {
          const newValue = value ? [value] : [];

          return [
            ...prevTagQuery.slice(0, index),
            {
              key,
              value: newValue,
            },
            ...prevTagQuery.slice(index + 1),
          ];
        }

        // value does not exist, add it
        return [
          ...prevTagQuery.slice(0, index),
          {
            key,
            value: [...itemValue, value],
          },
          ...prevTagQuery.slice(index + 1),
        ];
      }

      return [
        ...prevTagQuery.slice(0, index),
        {
          key,
          value: [...itemValue.slice(0, valueIndex), value, ...itemValue.slice(valueIndex + 1)],
        },
        ...prevTagQuery.slice(index + 1),
      ];
    });
  }, []);

  const removeQueryTag = useCallback((key: string, value: string) => {
    if (!value) {
      return;
    }

    setTagQuery((prevState) => {
      const index = prevState.findIndex((item) => item.key === key);
      if (index < 0) {
        return prevState;
      }

      const tagQueryValue = prevState[index].value;
      return [
        ...prevState.slice(0, index),
        {
          key,
          value: tagQueryValue.filter((x) => x !== value),
        },
        ...prevState.slice(index + 1),
      ];
    });
  }, []);

  const onNavigate = useCallback(
    (
      searchQuery: string,
      searchKeywords: string,
      searchColor: string,
      searchArtis: string,
      searchOrientation: string,
      searchAssetType: string,
      searchAssetUsage: string
    ) => {
      const queryParams = new SetNavigateParams().setNavbarQueryString(
        searchQuery,
        searchKeywords,
        searchColor,
        searchArtis,
        searchOrientation,
        searchAssetType,
        searchAssetUsage
      );
      const stringQuery = queryToString.stringify(queryParams);
      setSearchParams(queryParams);
      navigate(`/search?${stringQuery}`);
    },
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSearch = async (
    search: string,
    keywords: string,
    colors: string,
    artist: string,
    orientation: string,
    assetType: string,
    assetUsage: string
  ) => {
    setOnLoadSeeMore(true);

    const request: SearchRequestModel = {
      search: search !== StringConstants.EMPTY ? search : undefined,
      keywords: keywords !== StringConstants.EMPTY ? keywords.split(',') : undefined,
      colors: colors !== StringConstants.EMPTY ? colors.split(',') : undefined,
      artist: artist !== StringConstants.EMPTY ? artist : undefined,
      orientation: orientation !== StringConstants.EMPTY ? orientation : undefined,
      assetType: assetType !== StringConstants.EMPTY ? assetType : undefined,
      assetUsage: assetUsage !== StringConstants.EMPTY ? assetUsage : undefined,
    };

    setPage(1);

    const {
      data: responseData,
      status: responseStatus
    } = await new AssetService().getSnapshots(request, 1, size);

    if (responseStatus !== HttpStatus.OK) {
      setIsCanNext(false);
      setSearchResult([]);
      closeSeeMoreLoading();

      return;
    }

    setSearchResult(responseData.data);
    setCountItems(responseData.totalItem);

    if (responseData.data.length > 0) {
      if (responseData.data.length !== size) {
        setIsCanNext(false);
      } else {
        setIsCanNext(true);
      }
    } else {
      setIsCanNext(false);
    }

    closeSeeMoreLoading();
  };

  const closeSeeMoreLoading = () => {
    setOnLoadSeeMore(false);
  }

  const onNextSearch = async (
    search: string,
    keywords: string,
    colors: string,
    artist: string,
    orientation: string,
    assetType: string,
    assetUsage: string,
    nextPage: number
  ) => {
    setOnLoadSeeMore(true);

    const request: SearchRequestModel = {
      search: search !== StringConstants.EMPTY ? search : undefined,
      keywords: keywords !== StringConstants.EMPTY ? keywords.split(',') : undefined,
      colors: colors !== StringConstants.EMPTY ? colors.split(',') : undefined,
      artist: artist !== StringConstants.EMPTY ? artist : undefined,
      orientation: orientation !== StringConstants.EMPTY ? orientation : undefined,
      assetType: assetType !== StringConstants.EMPTY ? assetType : undefined,
      assetUsage: assetUsage !== StringConstants.EMPTY ? assetUsage : undefined,
    };

    setPage(nextPage);

    const {
      data: responseData,
      status: responseStatus
    } = await new AssetService().getSnapshots(request, nextPage, size);

    if (responseStatus !== HttpStatus.OK) {
      setIsCanNext(false);
      closeSeeMoreLoading();
      return;
    }

    if (responseData.data.length > 0) {
      responseData.data.forEach((item) => {
        setSearchResult((prev) => [...prev, item as AssetSearchModel]);
      });

      if (responseData.data.length !== size) {
        setIsCanNext(false);
      } else {
        setIsCanNext(true);
      }

      closeSeeMoreLoading();
    }
    else {
      setIsCanNext(false);
      closeSeeMoreLoading();
    }
  };

  const onChangeOrientation = (key: string) => {
    setQueryTag(TagKey.orientation, key);

    const query = getValueFromArrayTag(tagQuery, TagKey.search);
    const keyword = getValueFromArrayTag(tagQuery, TagKey.keyword);
    const color = getValueFromArrayTag(tagQuery, TagKey.colors);
    const artist = getValueFromArrayTag(tagQuery, TagKey.artists);
    const assetType = getValueFromArrayTag(tagQuery, TagKey.assetType);
    const assetUsage = getValueFromArrayTag(tagQuery, TagKey.assetUsage);

    onNavigate(query, keyword, color, artist, key, assetType, assetUsage);
  };

  const onChangeAssetType = (key: string) => {
    setQueryTag(TagKey.assetType, key);

    const query = getValueFromArrayTag(tagQuery, TagKey.search);
    const keyword = getValueFromArrayTag(tagQuery, TagKey.keyword);
    const color = getValueFromArrayTag(tagQuery, TagKey.colors);
    const artist = getValueFromArrayTag(tagQuery, TagKey.artists);
    const orientation = getValueFromArrayTag(tagQuery, TagKey.orientation);
    const assetUsage = getValueFromArrayTag(tagQuery, TagKey.assetUsage);
    onNavigate(query, keyword, color, artist, orientation, key, assetUsage);
  };

  const onChangeAssetUsage = (key: string) => {
    setQueryTag(TagKey.assetUsage, key);

    const query = getValueFromArrayTag(tagQuery, TagKey.search);
    const keyword = getValueFromArrayTag(tagQuery, TagKey.keyword);
    const color = getValueFromArrayTag(tagQuery, TagKey.colors);
    const artist = getValueFromArrayTag(tagQuery, TagKey.artists);
    const orientation = getValueFromArrayTag(tagQuery, TagKey.orientation);
    const assetType = getValueFromArrayTag(tagQuery, TagKey.assetType);

    onNavigate(query, keyword, color, artist, orientation, assetType, key);
  };

  const closeTag = (key: string, value: string) => {
    removeQueryTag(key, value);
    setFormValue(key, StringConstants.EMPTY);

    const query = getValueFromArrayTag(tagQuery, TagKey.search);
    const keyword = getValueFromArrayTag(tagQuery, TagKey.keyword);
    const color = getValueFromArrayTag(tagQuery, TagKey.colors);
    const artist = getValueFromArrayTag(tagQuery, TagKey.artists);
    const orientation = getValueFromArrayTag(tagQuery, TagKey.orientation);
    const assetType = getValueFromArrayTag(tagQuery, TagKey.assetType);
    const assetUsage = getValueFromArrayTag(tagQuery, TagKey.assetUsage);

    switch (key) {
      case TagKey.search:
        onNavigate(StringConstants.EMPTY, keyword, color, artist, orientation, assetType, assetUsage);
        break;
      case TagKey.keyword:
        onNavigate(query, StringConstants.EMPTY, color, artist, orientation, assetType, assetUsage);
        break;
      case TagKey.colors:
        onNavigate(query, keyword, StringConstants.EMPTY, artist, orientation, assetType, assetUsage);
        break;
      case TagKey.artists:
        onNavigate(query, keyword, color, StringConstants.EMPTY, orientation, assetType, assetUsage);
        break;
      case TagKey.orientation:
        onNavigate(query, keyword, color, artist, StringConstants.EMPTY, assetType, assetUsage);
        break;
      case TagKey.assetType:
        onNavigate(query, keyword, color, artist, orientation, StringConstants.EMPTY, assetUsage);
        break;
      case TagKey.assetUsage:
        onNavigate(query, keyword, color, artist, orientation, assetType, StringConstants.EMPTY);
        break;
    }
  };

  const closeAllTag = useCallback(() => {
    form.setFieldValue(TagKey.artists, StringConstants.EMPTY);
    form.setFieldValue(TagKey.keyword, StringConstants.EMPTY);
    form.setFieldValue(TagKey.orientation, StringConstants.EMPTY);
    form.setFieldValue(TagKey.assetType, StringConstants.EMPTY);
    form.setFieldValue(TagKey.assetUsage, StringConstants.EMPTY);

    setTagQuery([]);
    onNavigate(StringConstants.EMPTY, StringConstants.EMPTY, StringConstants.EMPTY, StringConstants.EMPTY, StringConstants.EMPTY, StringConstants.EMPTY, StringConstants.EMPTY);
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { Title } = Typography;

  useEffect(() => {
    const query = getValueFromArrayTag(tagQuery, TagKey.search);
    const keyword = getValueFromArrayTag(tagQuery, TagKey.keyword);
    const color = getValueFromArrayTag(tagQuery, TagKey.colors);
    const artist = getValueFromArrayTag(tagQuery, TagKey.artists);
    const orientation = getValueFromArrayTag(tagQuery, TagKey.orientation);
    const assetType = getValueFromArrayTag(tagQuery, TagKey.assetType);
    const assetUsage = getValueFromArrayTag(tagQuery, TagKey.assetUsage);

    if (query || keyword || color || artist || orientation || assetType || assetUsage) {
      onNavigate(query, keyword, color, artist, orientation, assetType, assetUsage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagQuery]);

  const onInputKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = event.currentTarget.id;
    const value = event.currentTarget.value;

    if (event.key === 'Enter') {
      if(value.length > 0 && value !== getValueFromArrayTag(tagQuery, TagKey.search)){
        setQueryTag(key, value);
      }
      form.setFieldValue(key, StringConstants.EMPTY);
    }
  };

  const getValueFromArrayTag = (tags: ITagQuery[], key: string) => {
    const tag = tags.find((x) => x.key === key);

    if (!tag) {
      return StringConstants.EMPTY;
    }

    return tag.value.join(',');
  };

  const onGetNext = () => {
    setPage(page + 1);
  };

  return (
    <>
      <LoadingOnDemand 
        showLoading={onLoadSeeMore}
        deleyInMillisec={1000} />
      <div className='search'>
        <div className='container pt-5 banner-margin-t-20'>
          <h1>
            {getValueFromArrayTag(tagQuery, TagKey.search)}
          </h1>
          <Form
            form={form}
            layout='vertical'
            autoComplete='off'
            className='form-dark'
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                const query = getValueFromArrayTag(tagQuery, TagKey.search);
                const keyword = getValueFromArrayTag(tagQuery, TagKey.keyword);
                const color = getValueFromArrayTag(tagQuery, TagKey.colors);
                const artist = getValueFromArrayTag(tagQuery, TagKey.artists);
                const orientation = getValueFromArrayTag(tagQuery, TagKey.orientation);
                const assetType = getValueFromArrayTag(tagQuery, TagKey.assetType);
                const assetUsage = getValueFromArrayTag(tagQuery, TagKey.assetUsage);

                onNavigate(query, keyword, color, artist, orientation, assetType, assetUsage);
              }
            }}
          >
            <Row>
              <Col span={12} md={6} lg={4} className='ps-2'>
                <InputControl
                  label={TranslationKey('keywords')}
                  placeholder='Enter keywords'
                  icon={true}
                  className='input-radius-dark'
                  name={TagKey.keyword}
                  SizeType='large'
                  iconName='search'
                  onKeyPress={onInputKeyPress}
                />
              </Col>
              <Col span={12} md={6} lg={4} className='ps-2'>
                <InputControl
                  label={TranslationKey('artistName')}
                  placeholder='Enter artist name'
                  icon={true}
                  className='input-radius-dark'
                  name={TagKey.artists}
                  SizeType='large'
                  iconName='user'
                  onKeyPress={onInputKeyPress}
                />
              </Col>
              <Col span={12} md={6} lg={4} className='ps-2'>
                <Form.Item name={TagKey.orientation} label={TranslationKey('search.orientation')}>
                  <Select
                    size='large'
                    defaultValue=''
                    value={getValueFromArrayTag(tagQuery, TagKey.orientation)}
                    className='input-radius-dark'
                    onChange={onChangeOrientation}
                    options={[
                      {
                        value: '',
                        label: 'All',
                      },
                      {
                        value: OrientationType.HORIZONTAL,
                        label: OrientationType.HORIZONTAL,
                      },
                      {
                        value: OrientationType.VERTICAL,
                        label: OrientationType.VERTICAL,
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={6} lg={4} className='ps-2'>
                <Form.Item name={TagKey.assetType} label={TranslationKey('search.assetType')}>
                  <Select
                    size='large'
                    defaultValue=''
                    value={getValueFromArrayTag(tagQuery, TagKey.assetType)}
                    className='input-radius-dark'
                    onChange={onChangeAssetType}
                    options={[
                      {
                        value: '',
                        label: 'All',
                      },
                      {
                        value: AssetType.IMAGE,
                        label: AssetType.IMAGE,
                      },
                      {
                        value: AssetType.VIDEO,
                        label: AssetType.VIDEO,
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12} md={6} lg={4} className='ps-2'>
                <Form.Item name={TagKey.assetUsage} label={TranslationKey('usage')}>
                  <Select
                    size='large'
                    defaultValue=''
                    value={getValueFromArrayTag(tagQuery, TagKey.assetUsage)}
                    className='input-radius-dark'
                    onChange={onChangeAssetUsage}
                    options={[
                      {
                        value: '',
                        label: 'All',
                      },
                      {
                        value: AssetUsage.Commercial,
                        label: AssetUsage.Commercial,
                      },
                      {
                        value: AssetUsage.Editorial,
                        label: AssetUsage.Editorial,
                      },
                      {
                        value: AssetUsage.AiGenerated,
                        label: AssetUsage.AiGenerated,
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Flex wrap="wrap" gap="small" align="center">
            <Title level={5} className='mb-3 applied-filters text-white'>
              {TranslationKey('search.appliedFilters')}
              </Title>
            <p onClick={() => setShowAllTags(!showAllTags)} className="pointer px-2">
              {!showAllTags ? < UpOutlined className='me-2' /> : <DownOutlined className='me-2' />}
            </p>
          </Flex>
          <Row className={`box-filters ${!showAllTags && 'fix-height-box-filters'}`}>
            <Col span={24}>
              <Flex wrap="wrap" gap="small" align="center">
                {
                  tagQuery.filter(q => q.key !== TagKey.orientation && q.key !== TagKey.assetType && q.key !== TagKey.assetUsage).map((tag) => {
                    if (tag.value) {
                      return tag.value.map((value, index) => (
                        value &&
                        <Tag
                          key={`${tag.key}|${index}`}
                          closable
                          onClose={() => {
                            closeTag(tag.key, value);
                          }}
                          className='mx-1 pointer' >
                          {value}
                        </Tag>
                      ))
                    }

                    return <></>
                  })}
              </Flex>
            </Col>
          </Row>
          <Row justify={'space-between'} align={'middle'} className='d-flex align-middle'>
            <span>{TranslationKey('search.searchResult')} : {countItems.toLocaleString()}</span>
            <Button size='large' type='primary' shape='round' className='btn-primary mt-3 mb-3' onClick={() => closeAllTag()}>
              {TranslationKey('search.clearAll')}
            </Button>
          </Row>
          <div className='box-tabs'>
            <Row align='middle'>
              <Col span={24}>
                {
                  searchResult && searchResult.length > 0
                  ? 
                  <GallerySearch searchModel={searchResult} />
                  :
                  <div >
                    <Result
                      status='404'
                      title={`We 're sorry.`}
                      subTitle='Our research team is here to help you find the perfect image or video for your project and free of charge!'
                      className='result-error'
                      style={{height: 560}}
                      extra={[
                          <a href='/contactus' className='btn-orange-round' style={{ textDecoration: 'none' }}>
                            {TranslationKey('hContactUs')}
                          </a>,
                      ]}
                    />
                  </div>
                }
              </Col>
            </Row>
            <Row justify='center' className='mt-2'>
              <Col>
                {isCanNext && (
                  <Button type='primary' size='large' className='btn-primary'
                    onClick={onGetNext}>
                      {TranslationKey('search.seeMore')}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div >
    </>
  );
}