import { PageDocument } from 'src/constant/stringConstants';
import { Typography } from 'antd';
import { Content } from "antd/es/layout/layout";
import { useEffect } from 'react';
import TranslationKey from 'src/i18n/translation';
const { Title, Text } = Typography;
const imageLogo = require('../../assets/images/logo/white@3x.png');

export default function AboutUs() {
    useEffect(() => {
        document.title = `About Us - ${PageDocument.Title}`;
    }, []);

  return (
    <div className='container py-5 banner-margin-t-50'>
      <div className="flex-center">
        <img src={imageLogo} alt="" />
      </div>
      <Content className='text-center'>
        <Title className="text-light mb-0" level={2}>
          <span className="ms-2 text-gradient">{TranslationKey('aboutUs.uniqueCommunity')}</span>
        </Title>
        <Text className="text-gray">
          {TranslationKey('aboutUs.uniqueCommunityFor')}
        </Text>
      </Content>
      <Content className='text-start'>
        <Title className="text-light mb-0" level={3}>
          ALIVE UNITED
        </Title>
        <Text className="text-light fw-light">
        {TranslationKey('aboutUs.weAreExclusive')}
        </Text>
        <br /><br />
        <Title className="text-light mb-0" level={3}>
        {TranslationKey('aboutUs.premium')}
        </Title>
        <Text className="text-light fw-light">
        {TranslationKey('aboutUs.wePassionately')}
        </Text>
        <br /><br />
        <Title className="text-light mb-0" level={3}>
        {TranslationKey('aboutUs.collaborative')}
        </Title>
        <Text className="text-light fw-light">
        {TranslationKey('aboutUs.weAreCommunityOfArtist')}
        </Text>
      </Content>
    </div>
  );
}