import { BannerService } from '../../services/bannerService';
import { HttpStatus } from "../../constant/responseStatus";
import { useEffect, useState } from 'react';
import { AssetPublishModel } from "src/models/assetModel";
import { AssetType } from '../../constant/assetConstants';
import { Skeleton } from 'antd';
import GallerySearchItem from '../galleryItem/GallerySearchItem';

interface TypeInterface {
  assets: AssetPublishModel[];
}

export default function GalleryArtist({ assets }: TypeInterface) {

  return (
    <div className="gallery">
      {
        assets.map((d, index) => <GallerySearchItem isUseContainerImage={false} asset={d} index={index} />)
      }
      <div className="box-gradient" />
    </div>
  );
}