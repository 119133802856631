import Axios from '../helper/Axios';
import { OmiseCreateCardModel } from '../models/OrderModel';
import { Buffer } from 'buffer';
import { omise } from '../constant/omise';

function getOmiseTokenAsync(model: OmiseCreateCardModel) {
  const card = {
    'name': model.name,
    'number': model.number,
    'expiration_month': model.expirationMonth,
    'expiration_year': model.expirationYear,
    'security_code': model.securityCode,
    'city': model.city,
    'country': model.country,
    'postal_code': model.postalCode,
    'state': model.state,
    'street1': model.street1,
  };

  const base64data = Buffer.from(`${process.env.REACT_APP_OMISE_PUBLIC_KEY}`, 'utf8').toString('base64')

  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'Authorization': `Basic ${base64data}`,
  };

  return Axios({
    baseURL: `${omise.URL_OMISE}/tokens`,
    method: 'post',
    data: { card: card },
    headers: headers,
  });
}

function getCardAsync() {
  return Axios.get('api/payment');
}

function createCardAsync(token: string) {
  const body = { token };

  return Axios.post('api/payment', body);
}

function setDefaultCardAsync(cardId: string) {
  return Axios.post(`api/payment/${cardId}/default`);
}

function setInactiveCardAsync(cardId: string) {
  return Axios.post(`api/payment/${cardId}/inactive`);
}

function deleteCardAsync(cardId: string) {
  return Axios.delete(`api/payment/${cardId}`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getOmiseTokenAsync,
  getCardAsync,
  createCardAsync,
  setDefaultCardAsync,
  setInactiveCardAsync,
  deleteCardAsync,
}