import { Row, Col, Form, Input, Button, Divider, Typography, Result } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useSessionContext } from '../../context/session-context';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileObjModel, SignInModel } from '../../models/accountModel';
import { RouteNames } from '../../routes/routeName';
import { HttpStatus } from '../../constant/responseStatus';
import { useGoogleLogin } from '@react-oauth/google';
import { AccountService } from '../../../src/services/accountService';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { EyeInvisibleOutlined, EyeTwoTone, FacebookOutlined, GoogleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import MessageModal from 'src/components/modal/messageModal';
import { PageDocument, StringConstants } from 'src/constant/stringConstants';
import { RegexPattern } from 'src/constant/constants';
import TranslationKey from 'src/i18n/translation'

const { Title, Text } = Typography;

const year = new Date().getFullYear();

export default function Login() {
  const logo = require('../../assets/images/logo/white@3x.png');
  const bannerImg = require('../../assets/images/banner/Image.png');
  const { signInAsync, signInGoogleAsync, signInFacebookAsync } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [childModal, setChildModal] = useState<JSX.Element>(<></>);
  const navigate = useNavigate();

  const appId = process.env.REACT_APP_FACEBOOK_APP_ID ?? StringConstants.EMPTY;

  const handleCloseModal = () => setIsOpenModal(false);

  useEffect(() => {
    document.title = `Login - ${PageDocument.Title}`;
  }, []);

  const onSigInInfo = async (value: SignInModel) => {
    setLoading(true);
    if (!value) {
      setLoading(false);
      return;
    }

    const response = await signInAsync({ username: value.username, password: value.password, deviceCode: null });

    if (response.status !== HttpStatus.OK) {
      setLoading(false);
      errorPopup(
        response.msg!,
        <Button size='large' type='primary' shape='round' className='btn-primary' onClick={handleCloseModal}>
          Try again
        </Button>
      );

      return;
    }

    setLoading(false);
    navigate(RouteNames.default);
  };

  const onSignInGoogleAsync = async (tokenId: string, profileObj: ProfileObjModel) => {
    const responseGoogle = await signInGoogleAsync({ tokenId: tokenId, profileObj: profileObj });

    if (responseGoogle.status !== HttpStatus.OK) {
      errorPopup(
        'Google Authentication failed',
        <Button size='large' type='primary' shape='round' className='btn-primary' onClick={handleCloseModal}>
          Try again
        </Button>
      );

      return;
    }

    navigate(RouteNames.default);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async codeResponse => {
      var user = codeResponse;

      const response = await new AccountService().oauthGoogleAsyncUserData(user.access_token);

      if (response.status !== HttpStatus.OK) {
        errorPopup(
          'Google Authentication failed',
          <Button size='large' type='primary' shape='round' className='btn-primary' onClick={handleCloseModal}>
            Try again
          </Button>
        );

        return;
      }

      const userData = response.data;

      const result = {
        email: userData.email,
        familyName: userData.family_name ?? userData.given_name,
        givenName: userData.given_name,
        googleId: userData.sub ?? '',
        imageUrl: userData.picture,
        name: userData.name,
      } as ProfileObjModel;

      await onSignInGoogleAsync(user.access_token, result);
    },
    onError: () => {
      console.log('Login Failed');
    },
  });

  const signInFacebook = async (data: any) => {
    const responseFacebook = await signInFacebookAsync({
      email: data.email,
      accessToken: data.accessToken,
      userId: data.userID,
      name: data.name,
      imageUrl: data.picture.data.url,
    });

    if (responseFacebook.status !== HttpStatus.OK) {
      errorPopup(
        'Facebook Authentication failed',
        <Button size='large' type='primary' shape='round' className='btn-primary' onClick={handleCloseModal}>
          Try again
        </Button>
      );

      return;
    }

    navigate(RouteNames.default);
  };

  const errorPopup = (msg: string, extra: JSX.Element) => {
    setChildModal(<Result status='error' title={msg} className='result-message' extra={extra} />);
    setIsOpenModal(true);
    setLoading(false);
  };

  return (
    <>
      <Content className='login'>
        <Row>
          <Col xs={0} xl={12}>
            <Link to={RouteNames.default}>
              <img src={logo} alt='alive logo' className='img-logo' />
            </Link>
            <p className='copy-right'>All contents &copy; copyright {year} Alive United v1.0.56</p>
            <img src={bannerImg} alt='alive banner' className='img' />
          </Col>
          <Col xs={24} xl={12} className='flex-center-items'>
            <Form
              name='form_item_path'
              layout='vertical'
              onFinish={onSigInInfo}
              className='form-input'
              requiredMark={false}
            >
              <Title level={2} className='text-light mb-0'>
                {TranslationKey('login.title')}
              </Title>
              <Text className='text-gray'>
                {TranslationKey('login.pleaseSignIn')}
                </Text>
              <Form.Item
                name='username'
                className='mt-4 text-light'
                label={TranslationKey('email')}
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid Email !',
                  },
                ]}
              >
                <Input className='input-radius-dark w-100' placeholder='Email' size='large' />
              </Form.Item>
              <Form.Item
                name='password'
                className='mb-0 text-light'
                label={TranslationKey('password')}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    pattern: RegexPattern.password,
                    message: `Password Use 8+ characters, uppercase, lowercase, and at least one number`,
                  },
                ]}
              >
                <Input.Password
                  placeholder='Password'
                  className='input-radius-dark w-100'
                  size='large'
                  iconRender={(visible) =>
                    visible ? (
                      <EyeTwoTone style={{ color: '#FFF' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: '#FFF' }} />
                    )
                  }
                />
              </Form.Item>
              <div className='text-end mb-3 mt-3'>
                <Link to={RouteNames.forgotPassword} className='px-1 btn-link'>
                {TranslationKey('login.forgotPassword')}
                </Link>
              </div>
              <Row justify='center'>
                <Button
                  shape='round'
                  type='primary'
                  className='btn btn-primary w-100'
                  htmlType='submit'
                  size='large'
                  loading={loading}
                >
                  {TranslationKey('signIn')}
                </Button>
              </Row>
              <Divider className='text-gray'>{TranslationKey('or')}</Divider>
              <Row gutter={5}>

                <Col
                  xs={{ span: 24 }} className='mt-2 btn-google'>
                  <Button
                    id='google-login'
                    type='primary'
                    shape='round'
                    className='btn-outline-border icon-fix'
                    size='large'
                    onClick={() => loginGoogle()}
                    block
                    icon={<GoogleOutlined />}
                  >
                  {TranslationKey('google')}
                  </Button>

                </Col>
                <Col xs={{ span: 24 }} className='mt-2'>
                  <FacebookLogin
                    appId={appId}
                    fields='name,email,picture'
                    onClick={signInFacebook}
                    callback={signInFacebook}
                    render={(renderProps) => (
                      <Button
                        type='primary'
                        shape='round'
                        className='btn-outline-border icon-fix'
                        size='large'
                        onClick={renderProps.onClick}
                        block
                        icon={<FacebookOutlined />}
                      >
                        {TranslationKey('facebook')}
                      </Button>
                    )}
                  />
                </Col>
              </Row>
              <div className='text-center mt-4'>
                <Text className='text-light'>{TranslationKey('login.noAccount')}</Text>
                <Link to='/register' className='px-1 btn-link'>
                {TranslationKey('login.registerForFree')}
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Content>
      <MessageModal isModalOpen={isOpenModal} onClose={handleCloseModal} children={childModal} />
    </>
  );
}