import { AxiosResponse } from 'axios';
import { HeaderConstants } from '../constant/headerConstant';
import { Session } from '../constant/sesstion';
import Axios from '../helper/Axios';
import {
  ArtistCreatingModel,
  ArtistProfileModel,
  FollowCreateModel,
  SignInFacebookModel,
  SignInGoogleModel,
} from '../models/accountModel';
import IChangePasswordRequest from '../models/request/changePasswordRequestModel';
import ICompanyRequest from '../models/request/companyRequest';
import IIndividualRequest from '../models/request/individualRequest';
import RegisterModel from '../models/request/registerRequestModel';
import { UserProfileResponse } from '../models/responses/userProfileResponse';
import sessionStore from '../store/sessionStore';
import axios from 'axios';
import { CollectionAssetModel } from '../models/CollectionModel';
import { AssetPublishModel } from '../models/assetModel';
import { IArtistProfileResponse } from '../models/responses/artistResponse';
import IChangeEmailRequest from '../models/request/changeEmailRequestModel';

const applicationUserApi = '/ApplicationUser';
export class AccountService {
  private _qs: any = require('qs');

  registerAsync(registerModel: RegisterModel): Promise<AxiosResponse> {
    return Axios.post<string>('/ApplicationUser/register', JSON.stringify(registerModel));
  }

  signInAsync(email: string, password: string): Promise<AxiosResponse> {
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    };

    return Axios.post(
      '/ApplicationUser/signin',
      this._qs.stringify({
        email: email,
        password: password,
        deviceCode: HeaderConstants.deviceCode,
        scope: HeaderConstants.scope,
        clientId: HeaderConstants.clientId,
      }),
      config
    );
  }

  oauthGoogleAsyncUserData(accessToken: string): Promise<AxiosResponse> {
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      },
    };

    return axios.get(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`,
          config);
  }

  sigInGoogleAsync(data: SignInGoogleModel): Promise<AxiosResponse> {
    data.profileObj.tokenId = data.tokenId;
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    };

    return Axios.post(
      '/ApplicationUser/signInGoogle',
      this._qs.stringify({
        deviceCode: HeaderConstants.deviceCode,
        scope: HeaderConstants.scope,
        clientId: HeaderConstants.clientId,
        profileObj: data.profileObj,
      }),
      config
    );
  }

  sigInFacebookAsync(data: SignInFacebookModel): Promise<AxiosResponse> {
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    };

    return Axios.post(
      '/ApplicationUser/signInFacebook',
      this._qs.stringify({
        scope: HeaderConstants.scope,
        clientId: HeaderConstants.clientId,
        email: data.email,
        accessToken: data.accessToken,
        userId: data.userId,
        name: data.name,
        imageUrl: data.imageUrl,
      }),
      config
    );
  }

  changePasswordAsync(request: IChangePasswordRequest): Promise<AxiosResponse> {
    return Axios.post('/ApplicationUser/change-password', JSON.stringify(request));
  }

  confirmEmailAsync(userId: string, code: string): Promise<AxiosResponse> {
    return Axios.get(`/ApplicationUser/?userId=${userId}&code=${encodeURIComponent(code)}`);
  }

  changeEmailAsync(request: IChangeEmailRequest): Promise<AxiosResponse> {
    return Axios.post('/ApplicationUser/change-email', JSON.stringify(request));
  }

  confirmChangeEmailAsync(userId: string, newEmail: string, code: string): Promise<AxiosResponse> {
    return Axios.get(
      `/ApplicationUser/confirm-change-email?userId=${userId}&newEmail=${newEmail}&code=${encodeURIComponent(code)}`
    );
  }

  getUserProfileAsync(): Promise<AxiosResponse> {
    return Axios.get(`/ApplicationUser/profile`);
  }

  updateIndividualAsync(individual: IIndividualRequest): Promise<AxiosResponse> {
    const userProfile = sessionStore.get(Session.SESSION_USER) as UserProfileResponse;

    return Axios.put(`/ApplicationUser/${userProfile.userId}/individual`, this.mapIndividualToJson(individual));
  }

  updateCompanyAsync(company: ICompanyRequest): Promise<AxiosResponse> {
    const userProfile = sessionStore.get(Session.SESSION_USER) as UserProfileResponse;

    return Axios.put(`/ApplicationUser/${userProfile.userId}/company`, this.mapCompanyToJson(company));
  }

  updateArtistAsync(artist: ArtistCreatingModel, id: string) {
    return Axios.put(`Artist/${id}/status`, JSON.stringify(artist));
  }

  private mapIndividualToJson(individual: IIndividualRequest) {
    return {
      firstName: individual.firstName,
      lastName: individual.lastName,
      email: individual.email,
      phone: individual.phone,
      address: {
        address: individual.address,
        postcode: individual.postcode,
        provinceCode: individual.provinceCode,
        districtCode: individual.districtCode,
        subDistrictCode: individual.subDistrictCode,
      },
    };
  }

  private mapCompanyToJson(company: ICompanyRequest) {
    return {
      companyName: company.companyName,
      taxId: company.taxId,
      branch: company.branch,
      companyWebsite: company.companyWebsite,
      address: {
        address: company.address,
        postcode: company.postcode,
        provinceCode: company.provinceCode,
        districtCode: company.districtCode,
        subDistrictCode: company.subDistrictCode,
      },
      contactPerson: {
        firstname: company.contactFirstName,
        lastName: company.contactLastName,
        email: company.contactEmail,
        phone: company.contactPhone,
        jobTitle: company.jobTitle,
        department: company.department,
        purchaseFor: company.purchasingFor,
      },
    };
  }

  getAccountInfo<T>(): Promise<AxiosResponse<T>> {
    return Axios.get(`/ApplicationUser/account-info`);
  }

  getListUserFollowAsync(artistName: string | null): Promise<AxiosResponse> {
    return Axios.get(`${applicationUserApi}/user-follow`, { params: { artistName: artistName } });
  }

  deletedUserFollowAsync(artistId: string): Promise<AxiosResponse> {
    return Axios.delete(`${applicationUserApi}/user-follow/${artistId}`);
  }
  getArtistById(id: string): Promise<AxiosResponse<ArtistProfileModel>> {
    return Axios.get<ArtistProfileModel>(`${applicationUserApi}/${id}/artist`);
  }

  RegisterNewArtist(data: ArtistCreatingModel, id: string): Promise<AxiosResponse> {
    return Axios.post(`${applicationUserApi}/${id}/artist`, JSON.stringify(data));
  }

  getAssetByArtistId(id: string): Promise<AxiosResponse<AssetPublishModel[]>> {
    return Axios.get<AssetPublishModel[]>(`${applicationUserApi}/${id}/artist/assets`);
  }

  followByArtistId(data: FollowCreateModel): Promise<AxiosResponse> {
    return Axios.post(`${applicationUserApi}/user-follow`, JSON.stringify(data));
  }

  unfollowByArtistId(artistId: string): Promise<AxiosResponse> {
    return Axios.delete(`${applicationUserApi}/user-follow/${artistId}`);
  }

  getFollower(id: string): Promise<AxiosResponse> {
    return Axios.get(`${applicationUserApi}/user-follow/${id}`);
  }

  getListMyCollections(): Promise<AxiosResponse<CollectionAssetModel[]>> {
    return Axios.get<CollectionAssetModel[]>(`${applicationUserApi}/my-collections`);
  }

  getArtistProfile(id: string): Promise<AxiosResponse<IArtistProfileResponse>> {
    return Axios.get(`${applicationUserApi}/${id}/artist`);
  }

  uploadProfileImageAsync(id: string, url: string): Promise<AxiosResponse> {
    const model: IUploadAvatarModel = {
      avatarUrl: url,
    } as IUploadAvatarModel;
    return Axios.post<string>(`${applicationUserApi}/${id}/avatar`, JSON.stringify(model));
  }

  deleteProfileAsync(id: string): Promise<AxiosResponse> {
    return Axios.delete(''); //TODO: IF API success test send it to API
  }

  getPromotionByDiscountCode(discountCode: string): Promise<AxiosResponse> {
    return Axios.get(`${applicationUserApi}/promotion/${discountCode}`);
  }

  getForgotPassword(email: string): Promise<AxiosResponse> {
    return Axios.get(`${applicationUserApi}/forgot-password`, { params: { email: email } });
  }
}

export default interface IUploadAvatarModel {
  avatarUrl: string;
}