import AccountInfo from '../pages/account';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages';
import Login from '../pages/login';
import ArtistUpload from '../pages/artist/upload';
import Search from '../pages/search';
import Register from '../pages/register';
import ProductDetail from '../pages/product/detail';
import { RouteNames } from './routeName';
import OrderHistory from '../pages/order-history';
import AccountInfoAddress from '../pages/account-info';
import AppLayout from '../components/layout/appLayout';
import PrivacyPolicy from '../pages/privacy-policy';
import TermOfService from '../pages/term-of-service';
import ContactUs from '../pages/contact-us';
import AboutUs from '../pages/about-us';
import Faq from '../pages/faq';
import Order from '../pages/order';
import Components from '../pages/components';
import MyFavorite from '../pages/my-favorite';
import MyAsset from '../pages/my-assets';
import ChangePassword from '../pages/change-password';
import ConfirmEmail from '../pages/confirm-email';
import Cart from '../pages/cart/cart';
import GuardedRoute from 'src/routes/GuardRoute';
import SuccessOrder from '../pages/order/success-order';
import SuccessPaymentTerm from '../pages/order/success-paymentTerm';
import MyUpload from '../pages/artist/my-upload';
import Pricing from '../pages/pricing';
import Collection from '../pages/collection';
import RegisterArtist from '../pages/register/register-artist';
import DownloadHistory from '../pages/download-history';
import ArtistFollowing from '../pages/artist-following/aritstFollowing';
import CustomLayout from '../components/custom-layout';
import ArtistProfile from '../pages/artist-profile';
import Financial from '../pages/artist/financial';
import PayoutInfo from '../pages/artist/payout-info';
import MyReleases from '../pages/artist/my-releases';
import MyReleasesDetail from '../pages/artist/my-releases/detail';
import ChangeEmail from '../pages/change-email';
import EarningReport from '../pages/artist/earning-report';
import Dashboard from '../pages/artist/dashboard';
import MyCollections from '../pages/artist/my-collection';
import MyCollectionDetail from '../pages/artist/my-collection/detail';
import MyPublish from '../pages/artist/my-publish';
import Payment from '../pages/order/payment';
import ConfirmChangeEmail from '../pages/confirm-email/confrim-change-email';
import FailedOrder from '../pages/order/failed-order';
import ForgotPassword from '../pages/forgot-password';
import WelcomeArtist from '../pages/artist/welcome-artist';
import ArtistTermOfService from '../pages/artist/term-of-service';
import ArtistPrivacyPolicy from '../pages/artist/privacy-policy';
import TaxCenter from '../pages/artist/taxCenter';
import FavoriteCollection from '../pages/favorite-collection';

export default function AppRoutes() {
  return (
    <>
      <Router>
        <Routes>
          <Route path={RouteNames.login} element={<Login />} />
          <Route path={RouteNames.register} element={<Register />} />
          <Route path={RouteNames.forgotPassword} element={<ForgotPassword />} />
          <Route path='/components' element={<Components />} />
          <Route path='/' element={<AppLayout isShowSearch={false} />}>
            <Route path={RouteNames.default} element={<Home />} />
          </Route>

          <Route path='/' element={<AppLayout />}>
            <Route path={RouteNames.search} element={<Search />} />
            <Route path={RouteNames.assetDetail} element={<ProductDetail />} />
            <Route path={RouteNames.cart} element={<Cart />} />
            <Route path={RouteNames.pricing} element={<Pricing />} />
            <Route path={RouteNames.faq} element={<Faq />} />
            <Route path={RouteNames.aboutUs} element={<AboutUs />} />
            <Route path={RouteNames.contactus} element={<ContactUs />} />
            <Route path={RouteNames.termofservice} element={<TermOfService />} />
            <Route path={RouteNames.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={RouteNames.order} element={  <GuardedRoute>
                  <Order />
                </GuardedRoute>} />
            <Route path={RouteNames.successOrder} element={<SuccessOrder />} />
            <Route path={RouteNames.successPaymentTerm} element={<SuccessPaymentTerm />} />
            <Route path={RouteNames.failedOrder} element={<FailedOrder />} />
            <Route path={RouteNames.collection} element={<Collection />} />
            <Route path={RouteNames.favoriteCollection} element={<FavoriteCollection />} />
            <Route
              path={RouteNames.registerArtist}
              element={
                <GuardedRoute>
                  <RegisterArtist />
                </GuardedRoute>
              }
            />
            <Route path={RouteNames.artistProfile} element={<ArtistProfile />} />
            <Route path={RouteNames.confirmEMail} element={<ConfirmEmail />} />
            <Route path={RouteNames.confirmChangeEMail} element={<ConfirmChangeEmail />} />
            <Route path={RouteNames.payment} element={<Payment />} />
            <Route path={RouteNames.welcomeArtist} element={<WelcomeArtist />} />
            <Route path={RouteNames.termOfServiceArtist} element={<ArtistTermOfService />} />
            <Route path={RouteNames.privacyPolicyArtist} element={<ArtistPrivacyPolicy />} />
          </Route>

          <Route path='/' element={<CustomLayout />}>
            <Route path={RouteNames.myFavorite} element={<MyFavorite />} />
            <Route path={RouteNames.changePassword} element={<ChangePassword />} />
            <Route path={RouteNames.upload} element={<ArtistUpload />} />
            <Route path={RouteNames.account} element={<AccountInfo />} />
            <Route path={RouteNames.myAsset} element={<MyAsset />} />
            <Route path={RouteNames.changePassword} element={<ChangePassword />} />
            <Route path={RouteNames.orderHistory} element={<OrderHistory />} />
            <Route path={RouteNames.accountInfo} element={<AccountInfoAddress />} />
            <Route path={RouteNames.myUpload} element={<MyUpload />} />
            <Route path={RouteNames.downloadHistory} element={<DownloadHistory />} />
            <Route path={RouteNames.follows} element={<ArtistFollowing />} />
            <Route path={RouteNames.financial} element={<Financial />} />
            <Route path={RouteNames.payoutInfo} element={<PayoutInfo />} />
            <Route path={RouteNames.releases} element={<MyReleases />} />
            <Route path={RouteNames.releaseDetail} element={<MyReleasesDetail />} />
            <Route path={RouteNames.changeEmail} element={<ChangeEmail />} />
            <Route path={RouteNames.earningReport} element={<EarningReport />} />
            <Route path={RouteNames.dashboard} element={<Dashboard />} />
            <Route path={RouteNames.myCollections} element={<MyCollections />} />
            <Route path={RouteNames.myCollectionDetail} element={<MyCollectionDetail />} />
            <Route path={RouteNames.myPublish} element={<MyPublish />} />
            <Route path={RouteNames.taxCenter} element={<TaxCenter />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
