import { useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import 'animate.css';

interface Props {
  children?: JSX.Element;
  isLoading: boolean;
}

export const Loading = ({ isLoading, children }: Props) => {
  const [fadeIn, setFadeIn] = useState<boolean>(false);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      openLoading();
    } 
    else {
      closeLoading();
    }
  }, [isLoading]);

  const openLoading = useCallback(() => {
    setFadeIn(true);
    setShow(true);
  }, []);

  const closeLoading = useCallback(() => {
    setFadeIn(false);
    setTimeout(() => setShow(false), 500);
  }, []);

  return (
    <>
      <Modal
        id="loading-modal"
        className={`
          loading
          animate__animated
          animate__faster
          ${fadeIn ? 'animate__fadeIn' : 'animate__fadeOut'}`}
        backdropClassName={`
          animate__animated
          animate__faster
          ${fadeIn ? 'animate__fadeIn' : 'animate__fadeOut'}`}
        show={show}
        centered
        animation={false}
        size="sm">
        <Modal.Body className="d-flex justify-content-center">
          {
            children ? children : <Spinner animation="border" />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};