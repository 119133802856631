import { RouteNames } from 'src/routes/routeName';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';

function BreadcrumbAlive() {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumb = [
    { url: RouteNames.myAsset, name: 'My Asset' },
    { url: RouteNames.dashboard, name: 'Dashboard' },
    { url: RouteNames.financial, name: 'Financial' },
    { url: RouteNames.payoutInfo, name: 'Payout Info' },
    { url: RouteNames.upload, name: 'Upload' },
    { url: RouteNames.myUpload, name: 'My Upload' },
    { url: RouteNames.myCollections, name: 'My Collections' },
    { url: RouteNames.myCollectionDetail, name: 'My Collection Detail' },
    { url: RouteNames.releases, name: 'Releases' },
    { url: RouteNames.releaseDetail, name: 'Release Detail' },
    { url: RouteNames.earningReport, name: 'Earning Report' },
    { url: RouteNames.myAsset, name: 'My Asset' },
    { url: RouteNames.myFavorite, name: 'My Favorite' },
    { url: RouteNames.orderHistory, name: 'Order History' },
    { url: RouteNames.downloadHistory, name: 'Download History' },
    { url: RouteNames.follows, name: 'Following' },
    { url: RouteNames.accountInfo, name: 'Account Info' },
    { url: RouteNames.changePassword, name: 'Change Password' },
    { url: RouteNames.changeEmail, name: 'Change Email' },
    { url: RouteNames.myPublish, name: 'My Publish' },
    { url: RouteNames.taxCenter, name: 'Tax Center' },
  ];

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    return {
      key: url,
      title: breadcrumb.filter((x) => x.url === url)[0].name,
    };
  });

  const breadcrumbItems = [
    {
      title: 'Home',
      key: RouteNames.dashboard,
    },
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {extraBreadcrumbItems.map((d, index) => {
        return extraBreadcrumbItems[extraBreadcrumbItems.length - 1].key === d.key ? (
          <Breadcrumb.Item key={index}>{d.title}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index} href={d.key} className='link-nonUnderline'>
            {d.title}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}

export default BreadcrumbAlive;
