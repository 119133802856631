import { ContactUsModel } from 'src/models/contactUsModel';
import { Button, Col, Divider, Form, Input, Modal, Row, Typography, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSessionContext } from 'src/context/session-context';
import { ContactService } from 'src/services/contactService';
import { HttpStatus } from 'src/constant/responseStatus';
import { RouteNames } from 'src/routes/routeName';
import { PageDocument } from 'src/constant/stringConstants';
import saleforceService from 'src/services/saleforceService';
import TranslationKey from 'src/i18n/translation';
const { Text } = Typography;

export default function ContactUs() {
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const [contactUsData] = Form.useForm<ContactUsModel>();
    const { userProfile, getUserProfileAsync } = useSessionContext();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        contactUsData.setFieldsValue({
            message: state?.message,
        });
        document.title = `Contact Us - ${PageDocument.Title}`;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userProfile && userProfile.email) {
            contactUsData.setFieldsValue({
                email: userProfile.email,
                userId: userProfile.userId,
                firstname: userProfile.firstname,
                lastname: userProfile.lastname,
                phone: userProfile.phone,
            });
        }
        else {
            getUserProfileAsync();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    const enterLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const onFinish = async (values: ContactUsModel) => {
        enterLoading();
        if (userProfile && userProfile.email) {
            values.userId = userProfile.userId;
        }

        const response = await new ContactService().createContact(values);

        if (response.status !== HttpStatus.CREATED) {
            setLoading(false);
            messageApi.open({
                type: 'error',
                content: 'Save contact-us failed.',
            });

            return;
        }

        await saleforceService.postContactSaleforceAsync(values);
        setLoading(false);

        Modal.success({
            content: 'Submit contact success.',
            onOk: () => {
                window.location.href = RouteNames.contactus;
            },
        });
    };

    return (
        <>
            {contextHolder}
            <div className='container pt-5 banner-margin-t-50'>
            <Row>
                <Col span={24} sm={24} lg={8}>
                    <Typography.Title className='text-light'>{TranslationKey("contactUs.title")}</Typography.Title>
                </Col>
                <Col span={24} sm={12} lg={8}>
                    <Typography.Title className='text-light' level={3}>
                        {TranslationKey("contactUs.support")}
                    </Typography.Title>
                    <Text className='text-gray'>{TranslationKey("contactUs.faqTechnical")}</Text>
                    <br />
                    <Text className='text-light'>LINE: @ALIVEUNITED</Text>
                    <br />
                    <Text className='text-light'>FACEBOOK: ALIVE.UNITED</Text>
                </Col>
                <Col span={24} sm={12} lg={8}>
                    <Typography.Title className='text-light' level={3}>
                        {TranslationKey("contactUs.partnerShip")}
                    </Typography.Title>
                    <Text className='text-gray'>{TranslationKey("contactUs.businessCommercial")}</Text>
                    <br />
                    <Text className='text-light'>LINE: @ALIVEUNITED</Text>
                    <br />
                    <Text className='text-light'>FACEBOOK: ALIVE.UNITED</Text>
                </Col>
                </Row>
                <Divider className='text-gray mt-5'>{TranslationKey("contactUs.contactDetail")}</Divider>
                <Form
                    name='form_item_path'
                    form={contactUsData}
                    onFinish={onFinish}
                    layout='vertical'
                    className='form-input-contact'
                >
                    <Row gutter={16}>
                        <Col span={24} md={6}>
                            <Form.Item
                                name='firstname'
                                className='mt-4 text-light'
                                label={TranslationKey("firstName")}
                                rules={[{ required: true, message: 'Please input your First Name!' }]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='First Name' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={6}>
                            <Form.Item
                                name='lastname'
                                className='mt-4 text-light'
                                label={TranslationKey("lastName")}
                                rules={[{ required: true, message: 'Please input your Last Name!' }]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='Last Name' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name='phone'
                                className='mt-4 text-light'
                                label={TranslationKey("phoneNumber")}
                                rules={[{ required: true, message: 'Please input your Phone Number!' }]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='Phone Number' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name='email'
                                className='mt-4 text-light'
                                label={TranslationKey("email")}
                                rules={[
                                    { required: true, message: 'Please input your Email!' },
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                      },
                                ]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='Email' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name='message'
                                className='mt-4 text-light'
                                label={TranslationKey("contactUs.message")}
                                rules={[{ required: true, message: 'Please type  your message here' }]}
                            >
                                <TextArea
                                    className='input-radius-dark w-100'
                                    rows={5}
                                    style={{ borderRadius: '10px' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='jobTitle'
                                className='mt-4 text-light'
                                label={TranslationKey("contactUs.jobTitle")}
                                rules={[{ required: false, message: 'Please input your Customer Name!' }]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='Job Title' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='company'
                                className='mt-4 text-light'
                                label={TranslationKey("contactUs.company")}
                                rules={[{ required: false, message: 'Please input your Customer Name!' }]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='Company' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='country'
                                className='mt-4 text-light'
                                label={TranslationKey("contactUs.country")}
                                rules={[{ required: false, message: 'Please input your Customer Name!' }]}
                            >
                                <Input className='input-radius-dark w-100' placeholder='Country' size='large' />
                            </Form.Item>
                        </Col>
                        <Col span={24} className='text-end'>
                            <Button
                                shape='round'
                                type='primary'
                                className='btn btn-primary'
                                htmlType='submit'
                                size='large'
                                loading={loading}
                            >
                                 {TranslationKey("submit")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}