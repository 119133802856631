import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { AssetPublishModel, AssetRejectedResponse, PagingRequest, SearchRequestModel } from 'src/models/assetModel';
import UploadAssetModel from '../models/request/uploadAssetRequestModel';
import EditingAssetModel from '../models/request/editingAssetRequestModel';
import AssetSearchResultModel from '../models/responses/assetSearchResultModel';

const api = '/Asset';
const requestConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export class AssetService {
  private _qs: any = require('qs');
  createAsync(uploadAssetModel: UploadAssetModel): Promise<AxiosResponse> {
    return Axios.post<string>(api, JSON.stringify(uploadAssetModel));
  }

  deleteAsync(assetId: string): Promise<AxiosResponse> {
    return Axios.delete<string>(`${api}/${assetId}`);
  }

  getCountUploadPerWeekAsync(): Promise<AxiosResponse> {
    return Axios.get<number>(`${api}/countPerWeek`);
  }

  getAssetPublishAsync(assetId: string): Promise<AxiosResponse<AssetPublishModel>> {
    return Axios.get<AssetPublishModel>(`${api}/published/${assetId}`);
  }

  getMyUpload(status: string): Promise<AxiosResponse<EditingAssetModel[]>> {
    return Axios.get<EditingAssetModel[]>(`${api}/myUpload?status=${status}`);
  }

  getMyUploadRejected(searchModel: PagingRequest): Promise<AxiosResponse<AssetRejectedResponse>> {
    const param = {
      'Paging.Page': searchModel.page,
      'Paging.Size': searchModel.size,
    };

    return Axios.get<AssetRejectedResponse>(`${api}/myUploadRejected`, { params: param });
  }

  deleteMultipleAsync(deleteIds: string[]): Promise<AxiosResponse> {
    const payload = JSON.stringify(deleteIds);
    return Axios.delete<AxiosResponse>(`${api}/deleteMultiple`, { data: payload });
  }

  updateDetailMultipleAsync(models: EditingAssetModel[]): Promise<AxiosResponse<any[]>> {
    return Axios.put(`${api}/updateDetailMultiple`, JSON.stringify(models), requestConfig);
  }

  submitMultipleAsync(models: EditingAssetModel[]): Promise<AxiosResponse<any[]>> {
    return Axios.put(`${api}/submitMultiple`, JSON.stringify(models), requestConfig);
  }

  getSimilarAssets(
    request: SearchRequestModel,
    page: number,
    size: number): Promise<AxiosResponse<AssetSearchResultModel>> {
    var params = new URLSearchParams();
    
    if (request.search) {
      params.append('search', request.search.toString());
    }

    if (request.colors) {
      params.append('colors', request.colors.toString());
    }

    if (request.keywords) {
      request.keywords.forEach((keyword, index) => {
        params.append(`keywords[${index}]`, keyword.toString());
      });
    }

    if (request.artist) {
      params.append('artist', request.artist.toString());
    }

    if (request.orientation) {
      params.append('orientation', request.orientation.toString());
    }

    if (request.assetType) {
      params.append('assetType', request.assetType.toString());
    }

    if (request.assetUsage) {
      params.append('assetUsage', request.assetUsage.toString());
    }

    params.append('page', page.toString());
    params.append('size', size.toString());
    params.append('isSimilar', 'true');

    return Axios.get<AssetSearchResultModel>(`${api}/snapshots`, { params });
  }

  getSnapshots(
    request: SearchRequestModel,
    page: number,
    size: number): Promise<AxiosResponse<AssetSearchResultModel>> {
    var params = new URLSearchParams();
    
    if (request.search) {
      params.append('search', request.search.toString());
    }

    if (request.colors) {
      params.append('colors', request.colors.toString());
    }

    if (request.keywords) {
      request.keywords.forEach((keyword, index) => {
        params.append(`keywords[${index}]`, keyword.toString());
      });
    }

    if (request.artist) {
      params.append('artist', request.artist.toString());
    }

    if (request.orientation) {
      params.append('orientation', request.orientation.toString());
    }

    if (request.assetType) {
      params.append('assetType', request.assetType.toString());
    }

    if (request.assetUsage) {
      params.append('assetUsage', request.assetUsage.toString());
    }

    params.append('page', page.toString());
    params.append('size', size.toString());

    return Axios.get<AssetSearchResultModel>(`${api}/snapshots`, { params });
  }
}
