import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import '../../styles/fix/input-dark.scss';
import { useParams } from "react-router-dom";
import { HttpStatus } from 'src/constant/responseStatus';
import GalleryCollection from 'src/components/galleryCollection/GalleryCollection';
import { CollectionAssetModel } from 'src/models/CollectionModel';
import { CollectionService } from 'src/services/collectionService';
import { PageDocument } from 'src/constant/stringConstants';

export default function Collection() {
  const [collection, setCollection] = useState<CollectionAssetModel>({} as CollectionAssetModel);
  const { id } = useParams();

  useEffect(() => {
    document.title = `Collection - ${PageDocument.Title}`;
    if (id) {
      onGetCollectionAsync();
    }
  }, [id]);

  const onGetCollectionAsync = async () => {
    const { data: responseData, status: responseStatus } =
      await new CollectionService().GetCollectionByAssetId(id!);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setCollection(responseData);
  };

  return (
    <div className='search pt-5 banner-margin-t-20'>
      <div className='container'>
        {
          collection && (
            <>
              <h1>{collection.name}</h1>
              <Row align="middle">
                <Col span={24}>
                  {
                    collection.collectionItems && (
                      <><GalleryCollection assetCollectionItems={collection.collectionItems} /></>
                    )
                  }
                </Col>
              </Row>
            </>
          )
        }
      </div>
    </div>
  );
}