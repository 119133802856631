import { IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import { Text, View, StyleSheet} from '@react-pdf/renderer';

interface ReportInterface {
    title: string;
    order: IOrderHistoryResponse;
}

const styles = StyleSheet.create({
    container:{
        borderTop: 1,
        marginBottom: 0,
    },
    margin: {
      marginTop: 5,
    },
    title1: {
      textAlign: 'left',
      fontWeight: 'black',
      fontSize: 16,
    },
    text: {
      textAlign: 'left',
      fontSize: 14,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center'
    },
  });

export default function Title({title, order}: ReportInterface)  {
  return (
    <View style={styles.container}>
        <View style={styles.margin}>
          <Text style={styles.title1}>{title}</Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.title1}>Order Number : </Text>
            <Text style={styles.text}>{order.orderCode}</Text>
        </View>
    </View>
  );
}