import { SessionTimeOut } from '../constant/sesstion';
import Cookies, { CookieChangeListener } from 'universal-cookie';

const cookies = new Cookies();

function calculateDate(time: number) {
  return new Date(Date.now() + time);
}

const set = (name: string, data: any, timeOut: number = SessionTimeOut.TWENTY_MINUTE) => cookies.set(name, data, {path: '/', expires: calculateDate(timeOut)});

const get = (name: string) => cookies.get(name);

const remove = (name: string) => cookies.remove(name , { path: '/' });

const addChangeListener = (callback: CookieChangeListener) => cookies.addChangeListener(callback);

export default {
  set,
  get,
  remove,
  addChangeListener,
}