import { Col, Row, Form, Input, Button, Card, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import IUserFollowResponse from '@/src/models/responses/userFollowResponse';
import { HttpStatus } from '../../../src/constant/responseStatus';
import { AccountService } from '../../../src/services/accountService';
import CardArtistFollow from 'src/components/card/CardArtistFollow';
import TranslationKey from 'src/i18n/translation';


export default function ArtistFollowing() {
  const accountService = new AccountService();
  const [userFollows, setUserFollow] = useState<IUserFollowResponse[]>([]);
  const [form] = Form.useForm();
  const [messageApi] = message.useMessage();

  const onSearchAsync = async (data: any) => {
    await getUserFollowsAsync(data.artistName);
  };

  const getUserFollowsAsync = async (artistName: string | null) => {
    const response = await accountService.getListUserFollowAsync(artistName);

    if (response.status !== HttpStatus.OK) {
      return;
    }

    setUserFollow(response.data);
  };

  const onReset = () => {
    form.resetFields();
    getUserFollowsAsync(null);
  };

  const onDelete = (artistId: string) => {
    if (artistId) {
      messageApi.open({
        type: 'success',
        content: 'UnFollow success.',
      });
      getUserFollowsAsync(null);
    }
  };

  useEffect(() => {
    getUserFollowsAsync(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={TranslationKey('follows.artistFollowing')} bordered={false}>
        <Card className='card-form'>
          <Form form={form} onFinish={onSearchAsync} layout='vertical' autoComplete='off'>
            <Row justify='center'>
              <Col xs={24} lg={6}>
                <Form.Item
                  name='artistName'
                  className=' text-light w-100'
                  label={TranslationKey('artistName')}
                  rules={[{ required: false, message: 'Please input your name!' }]}
                >
                  <Input className='w-100' placeholder='Name' size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='end'>
              <Col span={24} className='text-right'>
                <Space wrap>
                  <Button type='primary' size='large' className='px-5' htmlType='submit'>
                  {TranslationKey('button.search')}
                  </Button>
                  <Button type='default' size='large' className='px-5' onClick={onReset}>
                    {TranslationKey('button.clear')}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
        <Row gutter={[8, 8]} className='mt-5'>
          {userFollows.map((uf, index) => {
            return (
              <>
                <Col xs={24} sm = {24} md={8} lg={8}  xl = {8} >
                  <CardArtistFollow artist={uf} unfollowAction={onDelete}></CardArtistFollow>
                </Col>
              </>
            );
          })}
        </Row>
      </Card>
    </>
  );
}
