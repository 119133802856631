interface SearchQueryString {
  q: string;
  [key: string]: string;
}

export class SetNavigateParams {
  setNavbarQueryString(searchQuery: string, searchTag: string, searchColor: string, searchArtis: string, searchOrientation: string, searchAssetType: string, searchAssetUsage: string)  {
    let queryParams: SearchQueryString = {} as SearchQueryString;
    queryParams = this.setQueryParamsString(searchQuery,'q', queryParams);
    queryParams = this.setQueryParamsString(searchTag,'k', queryParams);
    queryParams = this.setQueryParamsString(searchColor,'c', queryParams);
    queryParams = this.setQueryParamsString(searchArtis,'a', queryParams);
    queryParams = this.setQueryParamsString(searchOrientation,'o', queryParams);
    queryParams = this.setQueryParamsString(searchAssetType,'t', queryParams);
    queryParams = this.setQueryParamsString(searchAssetUsage,'u', queryParams);

    return queryParams;
  }

  private setQueryParamsString = (stringParam: string, key: string, query: SearchQueryString) => {
    if (!stringParam) {
      return query;
    }

    query[key] = stringParam;

    return query;
  }
}