import { useSessionContext } from 'src/context/session-context';
import { Button, Col, Divider, Row } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import iconEdit from '../../assets/images/banner/icon-edit.svg';
import iconCalendar from '../../assets/images/banner/icon-calendar.svg';
import iconPictures from '../../assets/images/banner/icon-pictures.svg';
import iconArtist from '../../assets/images/banner/icon-artist-paid.svg';
import TranslationKey from 'src/i18n/translation';

export default function WelcomeArtist() {
  const { userProfile } = useSessionContext();
  const navigate = useNavigate();
  const imageLogo = require('../../assets/images/logo/white@3x.png');
  const bgWelcomeArtist = require('../../assets/images/banner/bg-welcome-artist.jpg');

  useEffect(() => {
  }, []);

  const onClickRegisterArtist = () => {
    if(userProfile && userProfile.userId !== undefined){
      navigate(RouteNames.registerArtist);
    } else {
      navigate(RouteNames.login);
    }
  }

  return (
    <>
    <Row>
      <Col span={24}>
        <video
          src={'https://alive-united-v2-dev.s3.ap-southeast-1.amazonaws.com/alive__newsContent_202431572727259_KOL-ALIVE-UNITED-BRAND-VIDEO_FULL-VERSION.mp4'}
          autoPlay
          muted
          loop
          style={{width: '100%', objectFit: 'contain'}} />
      </Col>
    </Row>
    <Row className='my-5'>
      <Col span={24} className='text-center'>
      <h2>{TranslationKey('welcomeArtist.title')}</h2>
          <h2><b>{TranslationKey('welcomeArtist.welcome')}</b> {TranslationKey('welcomeArtist.exclusive')}</h2>
          <h6>{TranslationKey('welcomeArtist.deliver')}</h6>
      </Col>
    </Row>
    <Row className='d-flex justify-content-center'>
      <Col md={4} sm={24} className='text-center'>
        <Button type="primary" className="btn-primary-large w-100" onClick={() => onClickRegisterArtist()}>
        {TranslationKey('button.applyNow')}
        </Button>
      </Col>
    </Row>
    <Row className='d-flex justify-content-center my-4'>
      <Col md={4} sm={24} className='text-center'>
        <img src={imageLogo} alt='logo' />
      </Col>
    </Row>
    <Row className='my-5 p-2'>
      <Col span={24} className='text-center'>
        <span className='h6'>
         {TranslationKey('welcomeArtist.weAreCreate')}<br />
            {TranslationKey('welcomeArtist.andHearts')}<br />
            {TranslationKey('welcomeArtist.content')}<br />
            {TranslationKey('welcomeArtist.captures')}<br />
            {TranslationKey('welcomeArtist.selected')}<br />
        </span>
      </Col>
    </Row>
    <Row className='my-5 py-5 justify-content-center'
        style={{
          backgroundImage: `url(${bgWelcomeArtist})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'}}>
      <Col span={24} className='text-center'>
      <h2>{TranslationKey('welcomeArtist.exclusivePrivileges')}</h2>
      </Col>
      <Col span={24} className='mt-4'>
          <Row className='d-flex justify-content-center gap-4'>
            <Col md={4} className='d-flex justify-content-center'>
              <div className='circle-welcome-artist text-center gap-2'>
                <div className='fw-bold'>{TranslationKey('welcomeArtist.getPaid')}</div>
                <div className='m-0 p-0 fw-bold h1'>40%</div>
                <span className='h6 p-0 m-0'>{TranslationKey('welcomeArtist.ofEach')}</span>
              </div>
            </Col>
            <Col md={4} className='d-flex justify-content-center'>
              <div className='circle-welcome-artist text-center gap-2'>
                <div className='fw-bold'>KEYWORD</div>
                <span className='h6 p-0 m-0'>{TranslationKey('welcomeArtist.support')}</span>
              </div>
            </Col>
            <Col md={4} className='d-flex justify-content-center'>
              <div className='circle-welcome-artist text-center gap-2'>
                <div className='fw-bold'>BLOG</div>
                <div className='fw-bold'>{TranslationKey('welcomeArtist.interview')}</div>
              </div>
            </Col>
            <Col md={4} className='d-flex justify-content-center'>
              <div className='circle-welcome-artist text-center gap-2'>
                <div className='fw-bold'>{TranslationKey('welcomeArtist.creative')}</div>
                <span className='h6 p-0 m-0'>{TranslationKey('welcomeArtist.guideline')}</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={16} className='my-4'>
          <div className='fw-bold h6'>{TranslationKey('welcomeArtist.limited')}</div>
          <div className='h6'>{TranslationKey('welcomeArtist.toMaximize')}</div>
        </Col>
    </Row>
    <Row className='my-5 py-5 justify-content-center'>
      <Col span={24} className='text-center'>
      <h2>{TranslationKey('welcomeArtist.interested')}</h2>
      </Col>
    </Row>
    <Row className='my-5 py-5 justify-content-center gap-4'>
      <Col span={4} className='d-flex flex-column text-center gap-4'>
        <div><img src={iconEdit} style={{height: 80}} alt='icon edit'/></div>
        <div className='h3' style={{ height: 80 }}>{TranslationKey('welcomeArtist.fill')}</div>
        <div className='h6'>{TranslationKey('welcomeArtist.tell')}</div>
      </Col>
      <Col span={4} className='d-flex flex-column text-center gap-4'>
        <div><img src={iconCalendar} style={{height: 80}} alt='icon calendar'/></div>
        <div className='h3' style={{ height: 80 }}>{TranslationKey('welcomeArtist.stand')}</div>
          <div className='h6'>{TranslationKey('welcomeArtist.ourFriendly')}</div>
        </Col>
      <Col span={4} className='d-flex flex-column text-center gap-4'>
        <div><img src={iconPictures} style={{height: 80}} alt='icon pictures'/></div>
        <div className='h3' style={{ height: 80 }}>{TranslationKey('welcomeArtist.uploadYourWork')}</div>
        <div className='h6'>{TranslationKey('welcomeArtist.whenYouApplication')}</div>
      </Col>
      <Col span={4} className='d-flex flex-column text-center gap-4'>
        <div><img src={iconArtist} style={{height: 80}} alt='icon artist'/></div>
        <div className='h3' style={{ height: 80 }}>{TranslationKey('welcomeArtist.worksDistribution')}</div>
        <div className='h6 justify-content-start'>{TranslationKey('welcomeArtist.selected')}</div>
      </Col>
    </Row>
    <Row className='d-flex justify-content-center'>
      <Col md={4} sm={24} className='text-center'>
        <Button type="primary" className="btn-primary-large w-100" onClick={() => onClickRegisterArtist()}>
        {TranslationKey('button.applyNow')}
        </Button>
      </Col>
    </Row>
    <Row className='d-flex justify-content-center mt-5 px-4'>
      <Col span={24} className='text-center'>
      <h2>{TranslationKey('welcomeArtist.whatType')}</h2>
      </Col>
      <Col span={24} className='my-4 text-center'>
      <h6>{TranslationKey('welcomeArtist.joinOur')}</h6>
            <h6>{TranslationKey('welcomeArtist.representative')}</h6>
            <h6>{TranslationKey('welcomeArtist.thatExpress')}</h6>
      </Col>
      <Col span={24} className='my-4 text-center'>
      <h2>{TranslationKey('welcomeArtist.keepPassion')}</h2>
      </Col>
      <Col span={24} className='my-4 text-center'>
      <h4>{TranslationKey('welcomeArtist.application')}</h4>
      </Col>
    </Row>
    <Row className='d-flex justify-content-center gap-4'>
          <Col md={8} className='bg-outline-gray'>
            <h3 className='text-white'>{TranslationKey('welcomeArtist.image.title')}</h3>
            <Divider style={{ borderColor: 'orange' }} />
            <ul>
              <li>{TranslationKey('welcomeArtist.image.jpeg')}</li>
              <li>{TranslationKey('welcomeArtist.image.files')}</li>
              <li>{TranslationKey('welcomeArtist.image.minimum')}</li>
              <li>{TranslationKey('welcomeArtist.image.film')}</li>
              <li>{TranslationKey('welcomeArtist.image.retouching')}</li>
              <li>{TranslationKey('welcomeArtist.image.photosMustContain')}</li>
              <li>{TranslationKey('welcomeArtist.image.photosWith')}</li>
              <li>{TranslationKey('welcomeArtist.image.photosContaining')}</li>
              <li>{TranslationKey('welcomeArtist.image.photosMustBe')}</li>
              <li>{TranslationKey('welcomeArtist.image.allContent')}</li>
            </ul>
          </Col>
          <Col md={8} className='bg-outline-gray'>
            <h3 className='text-white'>{TranslationKey('welcomeArtist.video.title')}</h3>
            <Divider style={{ borderColor: 'orange' }} />
            <ul>
              <li>{TranslationKey('welcomeArtist.video.move')}</li>
              <li>{TranslationKey('welcomeArtist.video.noSound')}</li>
              <li>{TranslationKey('welcomeArtist.video.videoLength')}</li>
              <li>{TranslationKey('welcomeArtist.video.resolution')}</li>
              <li>{TranslationKey('welcomeArtist.video.minimum')}</li>
              <li>{TranslationKey('welcomeArtist.video.retouching')}</li>
              <li>{TranslationKey('welcomeArtist.video.videosMustContain')}</li>
              <li>{TranslationKey('welcomeArtist.video.videosWithPeople')}</li>
              <li>{TranslationKey('welcomeArtist.video.videosContaining')}</li>
              <li>{TranslationKey('welcomeArtist.video.videosMustTell')}</li>
              <li>{TranslationKey('welcomeArtist.video.allContent')}</li>
            </ul>
          </Col>
        </Row>
    </>
  );
}
