import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { ArtistCreatingModel } from '../models/accountModel';
import { PayoutInfoCreatingModel, PayoutInfoModel } from '../models/PayoutInfoModel';
import { PayoutRequestCreatingModel, PayoutRequestModel } from '../models/PayoutRequestModel';
import { EarningReportModel } from '../models/earningReportModel';

export class ArtistService {
    private _qs: any = require('qs');
    private artistPath = '/Artist';

    GetIdByCode(assetId?: string): Promise<AxiosResponse> {
      return Axios.get(`${this.artistPath}/${assetId}/artist-Id`,);
  }

    UpdateArtistPending(id: string, data: ArtistCreatingModel): Promise<AxiosResponse> {
        return Axios.put(`${this.artistPath}/${id}`, JSON.stringify(data));
    }

    UpdateArtistStatus(id: string, data: ArtistCreatingModel): Promise<AxiosResponse> {
        return Axios.put(`${this.artistPath}/${id}/status`, JSON.stringify(data));
    }

    UpdateArtistIdCard(id: string, url: string): Promise<AxiosResponse> {
      const params = {
        attachmentUrl: url,
      }
      return Axios.put(`${this.artistPath}/${id}/id-card`, JSON.stringify(params));
    }

    UpdateArtistBookBank(id: string, url: string): Promise<AxiosResponse> {
      const params = {
        attachmentUrl: url,
      }
      return Axios.put(`${this.artistPath}/${id}/book-bank`, JSON.stringify(params));
    }

    getPayoutInfoAsync(id: string): Promise<AxiosResponse<PayoutInfoModel>> {
        return Axios.get<PayoutInfoModel>(`${this.artistPath}/${id}/payout-info`);
    }

    getPayoutRequestAsync(id: string, year: number): Promise<AxiosResponse<PayoutRequestModel[]>> {
        return Axios.get<PayoutRequestModel[]>(`${this.artistPath}/${id}/payout-request?year=${year}`);
    }

    CreatePayoutInfoAsync(id: string, request: PayoutInfoCreatingModel): Promise<AxiosResponse<PayoutRequestModel[]>> {
        return Axios.post<PayoutRequestModel[]>(`${this.artistPath}/${id}/payout-info/bank-transfer`, JSON.stringify(request));
    }

    UpdatePayoutInfoAsync(id: string, request: PayoutInfoCreatingModel): Promise<AxiosResponse<PayoutRequestModel[]>> {
      return Axios.put<PayoutRequestModel[]>(`${this.artistPath}/${id}/payout-info/bank-transfer`, JSON.stringify(request));
    }

    getBalanceAsync(id: string): Promise<AxiosResponse> {
        return Axios.get(`${this.artistPath}/${id}/balance`);
    }

    getEarningReportAsync(page: number, size: number, assetCode?: string, startDate?: Date, endDate?: Date): Promise<AxiosResponse<EarningReportModel[]>> {

        // return Axios.get<EarningReportModel[]>(`${this.artistPath}/earning/report?Paging.Page=${page}&Paging.Size=${size}&AssetCode=${assetCode}&StartDate=${startDate}&EndDate=${endDate}`);
        return Axios.get<EarningReportModel[]>(`${this.artistPath}/earning/report`,
        { params: { page, size, assetCode, startDate, endDate }}
        );
    }

    createPayoutRequest(id: string, request : PayoutRequestCreatingModel): Promise<AxiosResponse> {
        return Axios.post(`${this.artistPath}/${id}/balance/withdraw`, JSON.stringify(request));
    }

    getListMostDownloadAsync(): Promise<AxiosResponse> {
        return Axios.get(`${this.artistPath}/dashboard/mostSelling`);
    }

    getExportEarningReportAsync(assetCode?: string, startDate?: Date, endDate?: Date): Promise<AxiosResponse> {
      return Axios.get(`${this.artistPath}/earning/report/export`,
      {
        params: { assetCode, startDate, endDate },
        responseType: 'blob',
      });
  }
}