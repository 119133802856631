import { Row, Col, Typography, Card } from "antd";
import { FileImageOutlined, VideoCameraOutlined, PlusOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

interface Props {
  head?: string;
  title?: string;
  price?: string;
  type1?: string;
  priceType1?: string;
  type2?: string;
  priceType2?: string;
  icon?: string;
}


function generateIcon(icon: string) {
  switch (icon) {
    case 'img':
      return <FileImageOutlined />
    case 'vdo':
      return <VideoCameraOutlined />
    case 'plus':
      return <PlusOutlined />
    default:
    // code block
  }
}

function CardStandardLicenses(props: Props) {
  return (
    <Card className="card-licensing bg-outline-gray">
      <div className="flex-center mb-1">
        <div className="circle">
          {generateIcon(props.icon ?? '')}
        </div>
      </div>
      <div className="text-center">
        <Title className="text-light text-center my-0" level={4}>
          {props.head}
        </Title>
        <Text className="text-gray">{props.title}</Text>
        <Title className="text-light text-center my-2" level={1}>
          <sup className="text-light fs-xs">฿</sup>{props.price}
        </Title>
      </div>
      <Row gutter={16} className='mt-2'>
        <Col span={12}>
          <Text className="text-light">{props.type1}</Text>
        </Col>
        <Col span={12} className='text-end'>
          <Text className="text-gray"> {props.priceType1}</Text>
        </Col>
        <Col span={12}>
          <Text className="text-light">{props.type2}</Text>
        </Col>
        <Col span={12} className='text-end'>
          <Text className="text-gray">{props.priceType2}</Text>
        </Col>
      </Row>
    </Card>
  )
}

export default CardStandardLicenses;