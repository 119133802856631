import { RecommendAssetItemModel } from '../../models/BannerModel';
import { useEffect, useState } from 'react';
import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import { AnimationSliderClassName } from '../../constant/assetConstants';
import { Skeleton, message } from 'antd';
import { shuffle } from 'lodash';
import { useSessionContext } from 'src/context/session-context';
import RecommendItem from './recommendItem';

export default function Recommended() {
    const [recommendAsset1, setRecommendAsset1] = useState<RecommendAssetItemModel[]>([]);
    const [recommendAsset2, setRecommendAsset2] = useState<RecommendAssetItemModel[]>([]);
    const [activeRecommend, setActiveRecommend] = useState(false);
    const { getOrderAssets, getCountOrder } = useSessionContext();

    useEffect(() => {
        onGetRecommendAssetAsync();
        getOrderAssets();
        getCountOrder();
    }, []);
 
    const onGetRecommendAssetAsync = async () => {
        const {
            data: responseData,
            status: responseStatus 
        } = await new BannerService().GetRecommendAsset();

        if (responseStatus !== HttpStatus.OK) {
            return;
        }

        const half = responseData.recommendAssetItems.length / 2;

        if (half > 0 && responseData.recommendAssetItems.length > 0) {
            setRecommendAsset1(shuffle(responseData.recommendAssetItems.slice(0, half)));
            setRecommendAsset2(shuffle(responseData.recommendAssetItems.slice(half, responseData.recommendAssetItems.length)));
            setActiveRecommend(true);
        }
    };

    return (
        <>
            <div className='sliders'>
                <div className={AnimationSliderClassName.Fast} id='slider-recomment-0'>
                    {
                        activeRecommend
                            ?
                            recommendAsset1.map((recommentItem, recommentItemIndex) => (
                                <RecommendItem
                                    sliderId='slider-recomment-0'
                                    recommentAssetItem={recommentItem}
                                    recommentAssetItemIndex={recommentItemIndex} />
                            ))
                            :
                            Array.from({ length: 12 }).map((_, index) => (
                                <div className='slider' key={index}>
                                    <Skeleton.Image active />
                                </div>
                            ))
                    }
                </div>
                <div className='box-gradient' />
                <div className='box-gradient-end' />
            </div>
            <div className='sliders'>
                <div className={AnimationSliderClassName.Slow} id='slider-recommend-1'>
                    {
                        activeRecommend
                            ?
                            recommendAsset2.map((recommentItem, recommentItemIndex) => (
                                <RecommendItem
                                    sliderId='slider-recomment-1'
                                    recommentAssetItem={recommentItem}
                                    recommentAssetItemIndex={recommentItemIndex} />
                            ))
                            :
                            Array.from({ length: 12 }).map((_, index) => (
                                <div className='slider' key={index}>
                                    <Skeleton.Image active />
                                </div>
                            ))
                    }
                </div>
                <div className='box-gradient' />
                <div className='box-gradient-end' />
            </div>
        </>
    );
}
