import { Page, Document, StyleSheet, Image, Font, View, Text } from '@react-pdf/renderer';
import { IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import logo from '../../../assets/images/logo/alive-white2.png';
import ItemTable from './component/ItemTable';
import ThankYouMsg from './component/ThankYouMsg';
import Title from './component/Title';
import NotoSerifThai from '../../../assets/fonts/Noto_Serif_Thai/NotoSerifThai.ttf'
import FooterReport from './component/footer';

Font.register({ 
    family: 'NotoSerifThai',
    src: NotoSerifThai,
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        fontWeight: 'thin',
    }, 
    row: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    start: {
        height: '100%',
        width: '50%',
        textAlign: 'left',
        paddingLeft: 0,
        paddingRight: 10,
        margin: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
     },
    end: {
        height: '100%',
        width: '50%',
        textAlign: 'right',
        paddingRight: 0,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
      text: {
        fontSize: 12,
      },
    logo: {
        height: 40,
        marginRight: 20
    },
});

interface ReportInterface {
    order: IOrderHistoryResponse;
}

export default function ReceiptReport({ order } : ReportInterface) {

  return (
    <Document>
    <Page size="A4" style={styles.page}>
        <View style={styles.row}>
            <View style={styles.start}>
                <Image style={[styles.logo]} src={logo} />
            </View>
            <View style={styles.end}>
                <Text style={styles.text}>{'ALIVE UNITED COMPANY LIMITED'}</Text>
            </View>
        </View>
        <Title title='Invoice / Receipt' order={order} />
        <ItemTable order={order}/>
        <ThankYouMsg order={order}/>
        <FooterReport />
    </Page>
  </Document>
  );
}