import { AssetType } from "src/constant/assetConstants";
import { Card, Checkbox, Col, Row, Watermark } from "antd";
import EditingAssetModel from "../../../models/request/editingAssetRequestModel";
import HoverVideoPlayer from "react-hover-video-player";
import { useState } from "react";
import { CheckboxProps } from "antd/lib/checkbox";

interface Props {
  watermaskContent: string;
  myUpload: EditingAssetModel[];
  onSelectedItem: (item: EditingAssetModel) => void;
  onSelectedItemAll: (item: EditingAssetModel[]) => void;
}

export default function AssetThumnail(
  {
    watermaskContent,
    myUpload,
    onSelectedItem,
    onSelectedItemAll,
  }: Props) {
  const [isCheckAll, setIsCheckAll] = useState(false);

  const placeholder = require('../../../assets/images/my-upload/video_thumnail.png');
  const watermarkPropsConfig = {
    content: watermaskContent,
    font: {
      color: `rgba(255,255,255,0.4)`,
      fontSize: 20,
    },
    rotate: 0,
    zIndex: 11,
    gap: [32, 32] as [number, number],
  };
 
  const updateIsCheckedAll = () => {
    const isCheckedAll = 
      myUpload &&
      myUpload.length > 0 &&
      myUpload.length === myUpload.filter(m => m.isSelected).length;

    setIsCheckAll(isCheckedAll);
  }

  const handleSelectItem = function (e: any, index: number) {
    myUpload[index].isSelected = e.target.checked;

    onSelectedItem(myUpload[index]);
    updateIsCheckedAll();
  }

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setIsCheckAll(e.target.checked);

    onSelectedItemAll(e.target.checked ? myUpload : []);

    if (myUpload && myUpload.length > 0) {
      myUpload.forEach(data => {
        data.isSelected = e.target.checked;
      });
    }
  };

  return (
    <>
      <Row justify={"end"}>
        <Col>
          <Checkbox
            checked={isCheckAll}
            onChange={onCheckAllChange}>
            Select All
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ display: 'contents' }}>
          {
            myUpload.map((mu, muIndex) =>
              <Card key={'asset_item_' + muIndex} 
                className="card-thumnail"
                bodyStyle={{ padding: '0px' }}>
                <Watermark {...watermarkPropsConfig}>
                  {
                    mu.thumbnailImageInfo ? (
                      mu.assetType === AssetType.IMAGE
                        ?
                        <img alt={mu.fileName}
                          className="card-cover-image"
                          src={mu.thumbnailImageInfo.imageUrl} />
                        :
                        <HoverVideoPlayer
                          videoSrc={mu.thumbnailImageInfo.vdoUrl}
                          restartOnPaused
                          overlayTransitionDuration={500}
                          videoStyle={{
                            height: '150px',
                            width: '150px',
                            objectFit: 'cover',
                            borderRadius: '8px'
                          }}
                          pausedOverlay={
                            <img src={mu.thumbnailImageInfo.vdoCoverUrl}
                              style={{ objectPosition: 'center' }}
                              className="gallery-img"
                              alt={mu.fileName} />
                          }
                          loadingOverlay={
                            <div className="loading-overlay">Loading...</div>
                          }
                        />
                    ) : (
                      <img
                        alt={mu.fileName}
                        className="card-cover-image"
                        src={placeholder} />
                    )
                  }
                </Watermark>
                <div className="checkbox-thumnail">
                  <Checkbox
                    checked={mu.isSelected}
                    onChange={(e) => { handleSelectItem(e, muIndex) }} />
                </div>
              </Card>)
          }
        </Col>
      </Row>
    </>
  );
}