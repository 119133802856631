import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Col, Form, Input, InputRef, Radio, RadioChangeEvent, Row, Space, message } from 'antd';
import { MasterDataService } from 'src/services/masterDataService';
import { MasterDataType, TopicReportAsset } from 'src/constant/masterDataConstants';
import { HttpStatus } from 'src/constant/responseStatus';
import { MasterDataResponse } from 'src/models/responses/MasterDataResponse';
import TextArea from 'antd/es/input/TextArea';
import IReportAssetRequestModel from 'src/models/request/ReportAssetModel';
import { ReportAssetService } from 'src/services/ReportAssetService';

interface ModalInterface {
    isModalOpen: boolean;
    onClose: any;
    assetId: string;
}

export default function ReportAssetModal({ isModalOpen = false, onClose, assetId }: ModalInterface) {
    const [reportTopics, setReportTopics] = useState<MasterDataResponse[]>([]);
    const [topic, setTopic] = useState('');
    const [isShowOther, setIsShowOther] = useState(false);
    const [topicOtherId, setTopicOtherId] = useState(TopicReportAsset.Other);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        getReportAssetopicsAsync();
    }, []);

    useEffect(() => {
        if (!topic && reportTopics.length > 0) {
            setTopic(reportTopics[0].value);
        }
    }, [topic, reportTopics]);

    const getReportAssetopicsAsync = async () => {
        const { data, status } = await new MasterDataService().get(MasterDataType.REPORTASSET);

        if (status !== HttpStatus.OK) {
            return;
        }

        setReportTopics(data);
    };

    const onChange = (e: RadioChangeEvent) => {
        if (topicOtherId == e.target.value) {
            setIsShowOther(true);
        } else {
            setIsShowOther(false);
        }
        setTopic(e.target.value);
    };

    const onFinish = async (values: any) => {
        const ReportAssetCreate = {
            assetId: assetId,
            topicId: topic,
            other: values.other,
        } as IReportAssetRequestModel;

        const { data: responseData, status: responseStatus } = await new ReportAssetService().CreateReportAssetAsync(ReportAssetCreate);

        if (responseStatus !== HttpStatus.CREATED) {
            messageApi.open({
                type: 'error',
                content: 'Save report-asset failed.',
            });
            return;
        }

        messageApi.open({
            type: 'success',
            content: 'Save report-asset success.',
        });
        onClose();
    };

    return (
        <>
            {contextHolder}
            <Modal size='lg' show={isModalOpen} onHide={onClose} dialogClassName='fav-modal'>
                <Form form={form} name='form_item_path' layout='vertical' onFinish={onFinish} className='form-input'>
                    <Modal.Header><div className='text-light'>Report Topic</div></Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col span={24}>
                                {reportTopics && (
                                    <Radio.Group
                                        onChange={onChange}
                                        value={topic}
                                        className='radio-topic'
                                        optionType='button'
                                        size='large'
                                    >
                                        <Space direction='vertical'>
                                            {reportTopics
                                                .sort((a, b) => a.sequence - b.sequence)
                                                .map((item, index) => (
                                                    <Radio key={index} value={item.value} className='text-light w-100'>
                                                        <div>{item.name}</div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                    </Radio>
                                                ))}
                                        </Space>
                                    </Radio.Group>
                                )}
                            </Col>
                        </Row>
                        {isShowOther && (
                            <Row className='mt-2'>
                                <Col span={24}>
                                    <Form.Item
                                        name='other'
                                        className='text-light'
                                        rules={[{ required: false, message: 'Please input your name!' }]}
                                    >
                                        <TextArea
                                            className='input-radius-dark w-100'
                                            style={{ borderRadius: 5 }}
                                            placeholder='Pleases input other reason...'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='primary' size='large' shape='round' className='btn-primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
