import { AxiosResponse } from "axios";
import Axios from "../helper/Axios";
import { IFavoriteCreateRequest, IFavoriteItemCreateRequest } from '../models/request/favoriteRequestModel';
import { FavoriteResponseModel } from "../models/responses/favoriteModel";

const api = '/Favorite';

export class FavoriteService {
    createFavoriteAsync(request: IFavoriteCreateRequest): Promise<AxiosResponse> {
        return Axios.post<any>(`${api}`, JSON.stringify(request));
    }

    getFavoriteFolderAllAsync(): Promise<AxiosResponse<FavoriteResponseModel[]>> {
        return Axios.get<FavoriteResponseModel[]>(`${api}`);
    }

    getFavoriteByIdAsync(id: string): Promise<AxiosResponse<FavoriteResponseModel>> {
        return Axios.get<any>(`${api}/${id}`);
    }

    deleteFavoriteAsync(id: string): Promise<AxiosResponse> {
        return Axios.delete<any>(`${api}/${id}`);
    }

    addFavoriteItemAsync(favId: string, data: IFavoriteItemCreateRequest): Promise<AxiosResponse> {
        return Axios.post<any>(`${api}/${favId}/item`, JSON.stringify(data));
    }

    deleteFavoriteItemAsync(favId: string, assetId: string): Promise<AxiosResponse> {
        return Axios.delete<any>(`${api}/${favId}/item/${assetId}`);
    }

    getFavoriteByAssetIdAsync(assetId: string): Promise<AxiosResponse<FavoriteResponseModel>> {
        return Axios.get<FavoriteResponseModel>(`${api}/item/${assetId}`);
    }

    updateFavoriteNameAsync(favId: string, newFavoriteName: string): Promise<AxiosResponse> {
        return Axios.put<any>(`${api}/${favId}/changeName`, JSON.stringify({ newFavoriteName: newFavoriteName }));
    }
}