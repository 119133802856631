import { Col, Row, Typography, Card, Button, Descriptions, Drawer, List, Badge, Select, Form, Divider, message, Tag } from 'antd';
import { AssetPublishModel } from "src/models/assetModel";
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import { useEffect, useState } from 'react';
import { AccountService } from 'src/services/accountService';
import { HttpStatus } from 'src/constant/responseStatus';
import { CollectionAssetItemCreatingModel, CollectionAssetModel } from 'src/models/CollectionModel';
import { CollectionService } from 'src/services/collectionService';
import { AssetService } from 'src/services/assetService';
import GalleryItem from '../galleryItem/GalleryItem';
import AddNewCollection from './addNewCollection';

const { Title, Text } = Typography;

interface DrawerInterface {
    isOpen: boolean;
    onClose: any;
    assetId: string;
}

export default function PublishAssetDrawer({ isOpen, onClose, assetId }: DrawerInterface) {
    const [collections, setCollections] = useState<CollectionAssetModel[]>([]);
    const [asset, setAsset] = useState<AssetPublishModel>({} as AssetPublishModel);
    const [oldCollections, setOldCollections] = useState<CollectionAssetModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isOpenAddNewCollection, setIsOpenAddNewCollection] = useState(false);

    useEffect(() => {
        getMyCollectionsAsync();
        form.resetFields();

        if (assetId) {
            getAssetPublishAsync(assetId);
            getCollectionsByAssetIdAsync(assetId);
        }
    }, [assetId]);

    const getAssetPublishAsync = async (id: string) => {
        const response = await new AssetService().getAssetPublishAsync(id);

        if (response.status !== HttpStatus.OK) {
            return;
        }

        setAsset(response.data);
    };

    const getMyCollectionsAsync = async () => {
        const response = await new AccountService().getListMyCollections();

        if (response.status !== HttpStatus.OK) {
            return;
        }

        setCollections(response.data);
    };

    const getCollectionsByAssetIdAsync = async (id: string) => {
        const response = await new CollectionService().GetCollectionByAssetPath(id);

        if (response.status !== HttpStatus.OK) {
            return;
        }

        let oldVal: string[] = [];
        response.data.forEach((x) => {
            oldVal.push(x.id);
        });

        if (oldVal.length > 0) {
            form.setFieldValue('collection', oldVal.toString());
        }
        setOldCollections(response.data);
    };

    const onClick = (id: string) => {
        navigate(RouteNames.assetDetail.replace(':id', id));
    };

    const addCollectionItem = async (id: string) => {
        const data = {
            assetId: assetId,
        } as CollectionAssetItemCreatingModel;

        const response = await new CollectionService().addCollectionItem(id, data);

        if (response.status !== HttpStatus.CREATED) {
            messageApi.open({
                type: 'error',
                content: 'Add collection failed.',
            });

            return;
        }

        messageApi.open({
            type: 'success',
            content: 'Add collection success.',
        });
    };

    const enterLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const onFinish = async (values: any) => {
        enterLoading();
        const arr: string[] = values.collection;

        if (arr.length > 0) {
            arr.forEach(d => {
                if (oldCollections.filter(x => x.id === d).length === 0) {
                    addCollectionItem(d);
                }
            });
        }
    };

    const onDetail = (id?: string) => {
        if (id) {
          navigate(`${RouteNames.myCollectionDetail}?id=${id}`);
        } else {
          navigate(RouteNames.myCollectionDetail);
        }
      };

      const handleCloseCollectiontDrawer = () => setIsOpenAddNewCollection(false);

      const onClickItem = () => {
        setIsOpenAddNewCollection(true);
    };

    return (
        <>
            {contextHolder}
            {asset && (
                <Drawer
                    title='Asset Detail'
                    placement='right'
                    onClose={onClose}
                    open={isOpen}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <GalleryItem
                                index={1}
                                asset={asset}
                                width={'100%'}
                                height={'300px'}
                                onClickItem={() => ''}
                                btnDelmarginTop={10}
                                btnDelmarginRight={20}
                            />
                        </Col>
                    </Row>
                    <Divider />

                    <Form form={form} layout='vertical' onFinish={onFinish}>
                    <Row gutter={16} >
                            <Col span={12}>
                                <Text>Select Collection</Text>
                            </Col>
                            <Col span={12}>
                                <span onClick={() => onClickItem()}>
                                + New Collection
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={16} className='mt-2'>
                            <Col span={24}>
                                <Form.Item label='' name='collection'>
                                    <Select mode='tags' placeholder='Collection' className='w-100' size='large'>
                                        {collections.map((c) => (
                                            <Select.Option key={c.id} value={c.id}>
                                                {c.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} className='mb-2 '>
                            <Col span={24}>
                                <Button type='primary' size='large' htmlType='submit' loading={loading} block>
                                    Save Collection
                                </Button>
                            </Col>
                        </Row>
                        <Descriptions layout="vertical" colon={false}>
                            <Descriptions.Item label='Code' span={24}>
                              {asset.code}
                            </Descriptions.Item>
                            <Descriptions.Item label='Name' span={24}>
                              {asset.name ? asset.name : `-`}
                            </Descriptions.Item>
                            <Descriptions.Item label='Description' span={24}>
                              {asset.description}
                            </Descriptions.Item>
                            <Descriptions.Item label='Keywords' span={24}>
                              <Row>
                                <Col span={24}>
                                {
                                  asset.keywords && (
                                    asset.keywords.sort((a, b) => (a < b ? -1 : 1)).map((tag, index) => {
                                      return <Tag
                                        key={index}
                                        className='my-1'
                                        >
                                        {tag}
                                      </Tag>
                                    })
                                  )
                                }
                                </Col>
                              </Row>
                            </Descriptions.Item>
                          </Descriptions>
                    </Form>
                </Drawer>
            )}

        <AddNewCollection isOpen={isOpenAddNewCollection} onClose={handleCloseCollectiontDrawer} assetId={assetId} />
        </>
    );
}
