import { Col, Row, Typography, Card, Badge, Descriptions, Button } from 'antd';
import { IOrderDetail, IOrderHistoryResponse } from 'src/models/responses/orderHistoryResponse';
import moment from 'moment';
import { DateFormat } from 'src/constant/DateFormatConstants';
import GalleryItem from '../galleryItem/GalleryItem';
import { RouteNames } from 'src/routes/routeName';
import { usePDF } from '@react-pdf/renderer';
import ReceiptReport from '../report/ReceiptReport/ReceiptReport';
import { OrderStatus } from 'src/constant/statusConstants';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { UserAssetLicenseService } from 'src/services/userAssetLicenseService';
import { HttpStatus } from 'src/constant/responseStatus';
import { useState } from 'react';
import { AssetType } from 'src/constant/assetConstants';
import { BlobFileExtension } from 'src/constant/stringConstants';

const { Title, Text } = Typography;

interface OrderInterface {
  order: IOrderHistoryResponse;
}

export default function CardOrderItem({ order }: OrderInterface) {
  const [downloadLoadings, setDownloadLoadings] = useState<boolean>(false);
  const [instanceReceipt] = usePDF({ document: <ReceiptReport order={order} /> });

  const RenderBadgeStatus = (status: string) => {
    switch (status) {
      case 'Completed':
        return <Badge color='#52c41a' count={order.status} />;
      case 'Pending':
        return <Badge color='#d9d9d9' count={order.status} />;
      default:
        return <Badge color='#ff4d4f' count={order.status} />;
    }
  }

  const RenderPaymentLink = (id: string) => {
    return <a className='btn-link-blue' href={`order/payment?id=${id}`}>Click to Pay</a>;
  }

  const onClickItem = (assetId: string) => {
    window.location.href = RouteNames.assetDetail.replace(':id', assetId);
  };

  const onDownload = async (orderDetail: IOrderDetail) => {
    setDownloadLoadings(true);

    if (orderDetail.userAssetLicenseId) {
      const { data: responseData, status: responseStatus } = await new UserAssetLicenseService().donwloadByIdAsync(
        orderDetail.userAssetLicenseId
      );

      if (responseStatus !== HttpStatus.OK) {
        setDownloadLoadings(false);
        return;
      }

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(responseData);
      downloadLink.download = orderDetail.asset.assetType === AssetType.IMAGE
                              ? `alive_${orderDetail.asset!.code}${BlobFileExtension.Image}`
                              : `alive_${orderDetail.asset!.code}${BlobFileExtension.Video}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    setDownloadLoadings(false);
  };

  return (
    <>
      <Card
        className='card-order-history'
        title={
          <Row>
            <Col sm={24} md={18} className='d-flex gap-2'>
              <Typography.Title level={5} className='mb-0'>
                Order No. : {order.orderCode}
              </Typography.Title>
              <Typography.Text type='secondary'>
                {` on ${moment(order.orderDate).format(
                  DateFormat.DateTimeDash
                )}`}
              </Typography.Text>
            </Col>
            <Col xs={24} md={6} className='d-flex justify-content-end gap-2'>
              {
                (order.status === 'Pending' && order.order.id && order.order.paymentMethod === 'BankTransfer') && (
                  <div>{RenderPaymentLink(order.order.id)}</div>
                )
              }
              <div>{RenderBadgeStatus(order.status)}</div>
            </Col>
          </Row>
        }
        actions={[
          <>
            <Row gutter={16} style={{ paddingLeft: 16, paddingRight: 16 }}>
              <Col xs={24} md={24} style={{ display: 'grid', alignContent: 'end' }} className='text-right mt-2'>
                <Text>
                  {`Total Vat ${order.vatTotal
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}`}
                </Text>
                <Title level={5}>
                  {`Total THB ${order.netTotal
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}`}
                </Title>
              </Col>
            </Row>
            <Row gutter={16} style={{ paddingLeft: 16, paddingRight: 16 }}>
              <Col xs={24} md={{ span: 6, offset: 18 }} className='mt-2 d-flex gap-2'>
                {
                  order.status === OrderStatus.Completed && (
                    <a className='btn btn-primary text-white' 
                      href={instanceReceipt.url!}
                      download={`Receipt_${order.orderCode}.pdf`}>
                      Invoice/Receipt
                    </a>
                  )
                }
              </Col>
            </Row>
          </>
        ]}
      >
        <Row>
          <Col>
            <Descriptions>
              <Descriptions.Item label="Payment Method">
              <div style={{paddingRight: 4}}>
                {order.order.paymentMethod === 'BankTransfer' ?
                `${order.order.paymentMethod} (QR Code)`
                : order.order.paymentMethod}</div>
              </Descriptions.Item>
              <Descriptions.Item label="Type">
                {order.order.buyerType}
              </Descriptions.Item>
              {
                order.status === OrderStatus.Completed && (
                  <Descriptions.Item label="Paid Date">
                    {order.order.updatedInfo != undefined && (
                      moment(order.order.updatedInfo.at).format(DateFormat.DateTimeDash)
                    )}
                  </Descriptions.Item>
                )
              }
              {
                (order.status === OrderStatus.Pending && order.order.paymentMethod === 'PaymentTerm' && order.order.orderExpireDate) && (
                  <Descriptions.Item label="Payment Expire Date">
                    {order.order.orderExpireDate != undefined && (
                      moment(order.order.orderExpireDate).format(DateFormat.DateTimeDash)
                    )}
                  </Descriptions.Item>
                )
              }
            </Descriptions>
          </Col>
          {order.status == OrderStatus.Pending && (
            <Col>
              <Text type="danger">{` (Please contact our team to make payment.)`}</Text>
            </Col>
          )
          }
        </Row>
        {order.orderDetails &&
          order.orderDetails.map((d, index) => {
            return (
              <>
                <Row gutter={16}>
                  <Col flex='none' className='mt-3'>
                    <GalleryItem index={index} asset={d.asset} width={'70px'} height={'70px'} iconsize="2em" onClickItem={onClickItem} />
                  </Col>
                  <Col flex='auto' className='text-left' style={{ display: 'grid', alignContent: 'end' }}>
                    <div>
                      <Text strong>{`${d.asset.code} ${d.asset.name && ('- ' + d.asset.name)}`}</Text>
                    </div>
                    <div>
                      <Text type='secondary'>{`License: ${d.standardLicenseName}, ${d.extendedLicenseName}`}</Text>
                    </div>
                    {
                      d.userAssetLicenseId && (
                        <div>
                          <Button
                            type='primary'
                            size='small'
                            className='btn-primary pb-4'
                            icon={<CloudDownloadOutlined />}
                            onClick={() => onDownload(d)}
                            loading={downloadLoadings}>
                              Download
                            </Button>
                        </div>
                      )
                    }
                  </Col>
                  <Col xs={24} lg={4} style={{ display: 'grid', alignContent: 'end' }} className='text-right'>
                    <Title level={5}>
                      {
                        `THB ${(d.grossTotal - d.discountTotal).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}`
                      }
                    </Title>
                  </Col>
                </Row>
              </>
            );
          })}
      </Card>
    </>
  );
}