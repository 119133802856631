import { Col, Row, Tabs, TabsProps, Card } from 'antd';
import { useEffect, useState } from 'react';
import { FillterAssetType } from 'src/constant/assetConstants';
import GalleryItem from 'src/components/galleryItem/GalleryItem';
import { AccountService } from 'src/services/accountService';
import { useSessionContext } from 'src/context/session-context';
import { HttpStatusCode } from 'axios';
import { AssetPublishModel } from 'src/models/assetModel';
import PublishAssetDrawer from 'src/components/drawer/publishAssetDrawer';

export default function MyPublish() {
    const [, setTabValue] = useState<string>(FillterAssetType.All);
    const { userProfile, getUserProfileAsync } = useSessionContext();
    const [assets, setAssets] = useState<AssetPublishModel[]>([]);
    const [assetId, setAssetId] = useState('');
    const [isOpenAssetDrawer, setIsOpenAssetDrawer] = useState(false);

    const Tabitems: TabsProps['items'] = [
        {
            key: FillterAssetType.All,
            label: `All`,
            children: renderGallery(assets),
        },
        {
            key: FillterAssetType.IMAGE,
            label: `Photos`,
            children: renderGallery(assets.filter((x) => x.assetType === FillterAssetType.IMAGE)),
        },
        {
            key: FillterAssetType.VIDEO,
            label: `Videos`,
            children: renderGallery(assets.filter((x) => x.assetType === FillterAssetType.VIDEO)),
        },
    ];

    useEffect(() => {
        if (userProfile.userId) {
            getAssetByArtistIdAsync(userProfile.userId);
        }
        else {
            getUserProfileAsync();
        }
    }, [getUserProfileAsync, userProfile]);

    const getAssetByArtistIdAsync = async (userId: string) => {
        const { data, status } = await new AccountService().getAssetByArtistId(userId);

        if (status !== HttpStatusCode.Ok) {
            return;
        }

        setAssets(data);
    };

    const onChange = (newActiveKey: string) => {
        setTabValue(newActiveKey);
    };

    const handleCloseAssetDrawer = () => setIsOpenAssetDrawer(false);

    function renderGallery(assets: AssetPublishModel[]) {
        
        const onClickItem = (assetId: string) => {
            setAssetId(assetId);
            setIsOpenAssetDrawer(true);
        };

        const onClickItemMyPublish = (assetId: string) => {
            console.log('test', assets)
            setAssetId(assetId);
            setIsOpenAssetDrawer(true);
        };


        return (
            <>
                <Row gutter={[16, 16]}>
                    {assets &&
                        assets.map((asset, index) => {
                            return (
                                <Col key={index} className='mb-3'>
                                    <GalleryItem
                                        index={index}
                                        asset={asset}
                                        width={'120px'}
                                        height={'120px'}
                                        onClickItem={onClickItem}
                                        onClickItemMyPublish={onClickItemMyPublish}
                                        btnDelmarginTop={10}
                                        btnDelmarginRight={20}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </>
        );
    }

    return (
        <>
            <Card bordered={false}>
                <div>
                    <Tabs
                        defaultActiveKey={FillterAssetType.All}
                        tabPosition={'top'}
                        style={{ height: '80vh' }}
                        onChange={onChange}
                        items={Tabitems}
                        className='fix-ma'
                    />
                </div>
            </Card>
            <PublishAssetDrawer isOpen={isOpenAssetDrawer} onClose={handleCloseAssetDrawer} assetId={assetId} />
        </>
    );
}
