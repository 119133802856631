import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { OrderCreatingModel, OrderDetailModel, OrderPromptpayResponseModel } from '../models/OrderModel';
import IOrderHistoryRequest from '../models/request/orderHistoryRequest';

export class OrderService {
  private _qs: any = require('qs');
  private orderPath = '/Order';

  createOrderCreditCard(request: OrderCreatingModel): Promise<AxiosResponse> {
    return Axios.post(`${this.orderPath}/credit-card`, JSON.stringify(request));
  }

  createOrderPrompayAsync(request: OrderCreatingModel): Promise<AxiosResponse<OrderPromptpayResponseModel>> {
    return Axios.post<OrderPromptpayResponseModel>(`${this.orderPath}/promptpay`, JSON.stringify(request));
  }

  createOrderManualAsync(request: OrderCreatingModel): Promise<AxiosResponse<OrderPromptpayResponseModel>> {
    return Axios.post<OrderPromptpayResponseModel>(`${this.orderPath}/manual`, JSON.stringify(request));
  }

  createOrderPaymentTermAsync(request: OrderCreatingModel): Promise<AxiosResponse<OrderPromptpayResponseModel>> {
    return Axios.post<OrderPromptpayResponseModel>(`${this.orderPath}/payment-term`, JSON.stringify(request));
  }

  getOrderHistoryAsync(search: IOrderHistoryRequest): Promise<AxiosResponse> {
    return Axios.get(`${this.orderPath}/history`, { params: search });
  }

  getOrderByIdAsync(orderId: string): Promise<AxiosResponse<OrderDetailModel>> {
    return Axios.get<OrderDetailModel>(`${this.orderPath}/${orderId}`);
  }

  getOrderPendingByIdAsync(orderId: string): Promise<AxiosResponse> {
    return Axios.get(`${this.orderPath}/${orderId}/promptpay/pending`);
  }
}