import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import MasterDataModel from '../models/request/masterDataModel';

const api = '/MasterData';

export class MasterDataService {
    get(type: number): Promise<AxiosResponse> {
        return Axios.get<MasterDataModel[]>(`${api}?masterDataType=${type}`);
    }

    getGeneticAsync<T>(type: number): Promise<AxiosResponse<T>> {
        return Axios.get(`${api}?masterDataType=${type}`);
    }
}