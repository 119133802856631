import { CollectionAssetItemModel } from 'src/models/CollectionModel';
import { useEffect, useState } from 'react';
import GalleryCollectItems from './GalleryCollectionItems';

interface TypeInterface {
  assetCollectionItems: CollectionAssetItemModel[];
}

export default function GalleryCollection({ assetCollectionItems }: TypeInterface) {
  const [collectionAssetItem, setCollectionAssetItem] = useState<CollectionAssetItemModel[]>([]);

  useEffect(() => {
    if(assetCollectionItems.length > 0){
      setCollectionAssetItem(assetCollectionItems);
    }
  }, [assetCollectionItems])

  return (
    <>
      <div className="gallery">
        <div className='gallery'>
        {collectionAssetItem.map((d, index) => (
          <GalleryCollectItems asset={d.asset} index={index} />
        ))}
      </div>
        <div className="box-gradient" />
      </div>
    </>
  );
}