import { Button, Card, Col, Form, message, Row, Tabs, Upload, UploadProps, } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { TabAccountInfoConstants } from '../../../src/constant/tabConstants';
import { Constants } from '../../../src/constant/constants';
import { AccountService } from '../../../src/services/accountService';
import { HttpStatus } from '../../../src/constant/responseStatus';
import IIndividualResponse from '../../models/responses/individualResponse';
import ICompanyResponse from '../../models/responses/companyResponse';
import { IArtistResponse } from '../../models/responses/artistResponse';
import IAccountInfoResponse from '../../models/responses/accountInfoResponse';
import FormIndividual from '../../components/account-info/FormIndividual';
import FormArtist from '../../components/account-info/FormArtist';
import FormCompany from '../../components/account-info/FormCompany';
import { useSessionContext } from '../../context/session-context';
import { ArtistCreatingModel } from '../../models/accountModel';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from '../../constant/uploadFileConstants';
import { StringConstants } from '../../constant/stringConstants';
import { S3Constants } from '../../constant/s3Constants';
import AWS from 'aws-sdk';
import { PlusOutlined } from '@ant-design/icons';
import { RoleType } from 'src/constant/roleType';
import { RouteNames } from 'src/routes/routeName';
import { ArtistStatus } from 'src/constant/statusConstants';
import TranslationKey from 'src/i18n/translation';

const tabsName = [
  { name: TabAccountInfoConstants.individual },
  { name: TabAccountInfoConstants.company },
];

const tabsArtistName = [
  { name: TabAccountInfoConstants.individual },
  { name: TabAccountInfoConstants.company },
  { name: TabAccountInfoConstants.artist },
];

export default function AccountInfoAddress() {
  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY
  });

  const [tabValue, setTabValue] = useState<string>(TabAccountInfoConstants.individual);
  const { artistProfile, getUserProfileAsync } = useSessionContext();
  const [avatarImgUrl, setAvatarImgUrl] = useState('');
  const [individual, setIndividual] = useState<IIndividualResponse>();
  const [company, setCompany] = useState<ICompanyResponse>();
  const [artist, setArtist] = useState<IArtistResponse>();
  const [, setHideButtonUpload] = useState(false);
  const [isArtist, setIsArtist] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const accountService = new AccountService();

  const { userProfile } = useSessionContext();

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [useFormIndividual] = Form.useForm();
  const [useFormCompany] = Form.useForm();
  const [useFormArtist] = Form.useForm();

  useEffect(() => {
    if (userProfile && userProfile.userId && userProfile.roles.length > 0) {
      setIsArtist(userProfile.roles.filter(r => r === RoleType.ARTIST).length > 0);
    }
  }, [userProfile]);

  const onChangeTabs = (index: string) => {
    let tabValue = Constants.stringEmpty;

    switch (index) {
      case Constants.number0String:
        tabValue = TabAccountInfoConstants.individual;
        break;
      case Constants.number1String:
        tabValue = TabAccountInfoConstants.company;
        break;
      case Constants.number2String:
        tabValue = TabAccountInfoConstants.artist;
        break;
    }

    setTabValue(tabValue);
  };

  const onSubmitIndividual = async (form: any) => {
    const response = await accountService.updateIndividualAsync(form);

    if (response.status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'Save Account info failed.',
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Save Account info success.',
    });

    getUserProfileAsync();
  };

  const onSubmitCompany = async (form: any) => {
    const response = await accountService.updateCompanyAsync(form);

    if (response.status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'Save Account info failed.',
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Save Account info success.',
    });
  };

  const onSubmitArtist = async (values: any) => {
    const artistUpdate = {
      nameInfoEN_Firstname: values.firstname,
      nameInfoEN_Lastname: values.lastname,
      nameInfoEN_Nickname: values.nickname,
      nameInfoTH_Firstname: values.firstnameTh,
      nameInfoTH_Lastname: values.lastnameTh,
      nameInfoTH_Nickname: values.nicknameTh,
      email: values.email,
      phone: values.phone,
      addressInfo_Address: values.address,
      addressInfo_Postcode: values.Postcode,
      addressInfo_ProvinceCode: values.ProvinceId,
      addressInfo_DistrictCode: values.DistrictId,
      addressInfo_SubDistrictCode: values.SubDistriceId,
      portfolio: values.portfolio,
      socialMediaInfo_Facebook: values.facebook,
      socialMediaInfo_Instagram: values.instagram,
      socialMediaInfo_Tiktok: values.tiktok,
      socialMediaInfo_Other: values.other,
      aboutYourSelf: values.aboutYourSelf,
      selfPromotion: values.selfPromotion,
      styleOfWork: JSON.stringify(values.styleOfWork),
      styleOfWork_Other: values.styleOfWorkOther,
      platform: JSON.stringify(values.platForm),
      platform_Mysite: values.platFormMySite,
      platform_Other: values.platFormOther,
      isAccept: true,
      Status: ArtistStatus.Pending,
    } as ArtistCreatingModel;

    const { status } = await accountService.updateArtistAsync(artistUpdate, artistProfile.id);

    if (status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'Save Account info failed.',
      });

      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Save Account info success.',
    });
  };

  const getAccountInfo = async () => {
    const { data, status } = await accountService.getAccountInfo<IAccountInfoResponse[]>();

    if (status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'get Account info failed.',
      });

      return;
    }

    if (data[0].avatarUrl) {
      setAvatarImgUrl(data[0].avatarUrl);
    }

    if (data[0].individualInfo) {
      const individual: IIndividualResponse = data[0].individualInfo;
      setIndividual(individual);


      useFormIndividual.setFieldsValue({
        firstName: individual.firstname,
        lastName: individual.lastName,
        email: individual.email,
        phone: individual.phone,
        address: individual.addressInfo?.address,
        postcode: individual.addressInfo?.postcode,
        provinceCode: individual.addressInfo?.provinceCode,
        districtCode: individual.addressInfo?.districtCode,
        subDistrictCode: individual.addressInfo?.subDistrictCode
      });
    }
    else {
      useFormIndividual.setFieldValue('email', userProfile.email);
    }

    if (data[0].companyInfo) {
      const companyInfo: ICompanyResponse = data[0].companyInfo;
      if(data[0].vipDownloads){
        companyInfo.vipDownloads = data[0].vipDownloads;
      }
      if(data[0].vipDownloadQty){
        companyInfo.vipDownloadQty = data[0].vipDownloadQty;
      }
      setCompany(companyInfo);

      useFormCompany.setFieldsValue({
        companyWebsite: companyInfo?.companyWebsite,
        branch: companyInfo?.branch,
        address: companyInfo?.addressInfo?.address,
        companyName: companyInfo?.companyName,
        contactEmail: companyInfo?.contactPerson.email,
        contactFirstName: companyInfo?.contactPerson.firstname,
        contactLastName: companyInfo?.contactPerson.lastName,
        contactPhone: companyInfo?.contactPerson.phone,
        department: companyInfo?.contactPerson.department,
        districtCode: companyInfo?.addressInfo?.districtCode,
        jobTitle: companyInfo?.contactPerson.jobTitle,
        postcode: companyInfo?.addressInfo?.postcode,
        provinceCode: companyInfo?.addressInfo?.provinceCode,
        purchasingFor: companyInfo?.contactPerson.purchaseFor,
        subDistrictCode: companyInfo?.addressInfo?.subDistrictCode,
        taxId: companyInfo?.taxId,
      });
    }

    if (data[0].artist) {
      const artist: IArtistResponse = data[0].artist;
      setArtist(artist);
      useFormArtist.setFieldsValue({
        email: artist.email,
        phone: artist.phone,
        firstname: artist.nameInfoEn.firstname,
        lastname: artist.nameInfoEn.lastname,
        nickname: artist.nameInfoEn.nickname,
        firstnameTh: artist.nameInfoTh.firstname,
        lastnameTh: artist.nameInfoTh.lastname,
        nicknameTh: artist.nameInfoTh.nickname,
        address: artist.addressInfo.address,
        ProvinceId: artist.addressInfo.provinceCode,
        DistrictId: artist.addressInfo.districtCode,
        SubDistriceId: artist.addressInfo.subDistrictCode,
        Postcode: artist.addressInfo.postcode,
        portfolio: artist.portfolio,
        facebook: artist.socialMediaInfo.facebook,
        instagram: artist.socialMediaInfo.instagram,
        tiktok: artist.socialMediaInfo.tiktok,
        other: artist.socialMediaInfo.other,
        aboutYourSelf: artist.aboutYourSelf,
        selfPromotion: artist.selfPromotion,
        styleOfWorkOther: artist.styleOfWork_Other,
        platFormMySite: artist.platform_Mysite,
        platFormOther: artist.platform_Other,
      });
    }
  };

  useEffect(() => {
    getAccountInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitByTab = useCallback((tabValue: string) => {
    switch (tabValue) {
      case TabAccountInfoConstants.individual:
        useFormIndividual.submit();
        break;
      case TabAccountInfoConstants.company:
        useFormCompany.submit();
        break;
      case TabAccountInfoConstants.artist:
        useFormArtist.submit();
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabItem = (tabValue: string) => {
    switch (tabValue) {
      case TabAccountInfoConstants.individual:
        return <FormIndividual individual={individual} useFormIndividual={useFormIndividual}
          onSubmitIndividual={onSubmitIndividual} />;
      case TabAccountInfoConstants.company:
        return <FormCompany companyInfo={company} useFormCompany={useFormCompany} onSubmitCompany={onSubmitCompany} />;
      case TabAccountInfoConstants.artist:
        return <FormArtist artist={artist} useFormArtist={useFormArtist} onSubmitArtist={onSubmitArtist} />;
      default:
        return <></>;
    }
  };

  const getValidationErrorMessage = (fileName: string, errMessage: string) => {
    return fileName + StringConstants.SPACE + errMessage;
  };

  const validateUploadFileAsync = async (
    file: File,
    onError: any,
  ) => {
    const fileTypeNotSupport = !(UploadFileConstants.MIME_ACCEPT_IMAGE.includes(file.type));
    //TODO: Validate file size and file width
    if (fileTypeNotSupport) {
      const errorMessage = getValidationErrorMessage(file.name, UploadValidationMessages.INVALID_FILE_TYPE);
      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const getFileName = (originalFileName: string) => {
    const now = new Date();
    return UploadFileConstants.FILE_NAME_PREFIX_PROFILE
      + userProfile.userId
      + StringConstants.UNDERSCORE
      + now.getUTCFullYear()
      + (now.getUTCMonth() + 1)
      + now.getUTCDate()
      + now.getUTCHours()
      + now.getUTCMinutes()
      + now.getUTCSeconds()
      + now.getUTCMilliseconds()
      + StringConstants.UNDERSCORE
      + originalFileName;
  };

 const checkSpecialCharacter = (name: string)=> {
  var array = name.split('');

  //eslint-disable-next-line
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  var st: string[] = [];

  array.forEach(element => {
    if(format.test(element)){
      st.push('_')
    } else {
      st.push(element)
    }
  });

  return st.join('');
 }

  const getS3FileUrl = (fileName: string) => {
    return `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
  };

  const addNewProfile = async (
    originalFile: any,
    fileName: string,
    onError: any,
    onSuccess: any
  ) => {
    const filePath = getS3FileUrl(fileName);
    console.log('filePath => ', filePath);

    const response = await accountService.uploadProfileImageAsync(userProfile.userId, filePath);

    if (response.status !== HttpStatus.OK) {
      const errorMessage = `File ${originalFile.name} : ${response.data.failedReason}`;

      message.error(errorMessage, 10);
      onError!(errorMessage);

      return;
    }

    onSuccess!(response.data);
    window.location.href = RouteNames.accountInfo;
  };

  const addFileToS3 = async (
    originalFile: any,
    onError: any,
    onSuccess: any,
    onProgress: any
  ) => {
    var checkFileName = checkSpecialCharacter(originalFile.name);

    const fileName = getFileName(checkFileName);

    const params = {
      ACL: S3Constants.ACL_TERM,
      Body: originalFile,
      Bucket: S3_BUCKET,
      Key: fileName,
      ContentType: originalFile.type
    };

    s3Bucket.putObject(params)
      .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
        const progressPercent = Math.round((evt.loaded / evt.total) * 100);
        onProgress!({ percent: progressPercent });
      })
      .promise()
      .then(async () => {
        await addNewProfile(
          originalFile,
          fileName,
          onError,
          onSuccess);
      }, err => {
        onError!(err);
        throw err;
      });
  };

  const uploadProps: UploadProps = {
    multiple: false,
    listType: UploadListType.PICTURE_CARD,
    accept: UploadFileConstants.MIME_ACCEPT_IMAGE.toString(),
    maxCount: 1,
    showUploadList: false,
    async onRemove(fileInfo) {
      setHideButtonUpload(false);

      if (fileInfo['response']) {
        const fileId = fileInfo['response']['id'];
        await accountService.deleteProfileAsync(fileId); //TODO: Change service to delete image profile
      }
    },
    async customRequest({
      file,
      onError,
      onSuccess,
      onProgress,
    }) {
      const originalFile = file as File;

      const errorMessage = await validateUploadFileAsync(
        originalFile,
        onError
      );

      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      setHideButtonUpload(true);

      await addFileToS3(
        originalFile,
        onError,
        onSuccess,
        onProgress);
    }
  };

  return (
    <>
      {contextHolder}
      <Card title={TranslationKey('accountInfo.title')} bordered={false}>
        <Row justify="center">
          <Col>
            <Upload className='fix-width' {...uploadProps}
            >
              {avatarImgUrl ? (
                <img src={avatarImgUrl} alt="avatar" style={{ position: 'relative', width: '90%', height: '90%', objectFit: 'cover', borderRadius: 10 }} />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{TranslationKey('accountInfo.upload')}</div>
                </div>
              )}
            </Upload>
          </Col>
        </Row>
        {
          isArtist ? (
            <Tabs
              tabBarExtraContent={
                (
                  <Button size='large' type="primary" onClick={() => submitByTab(tabValue)}>Save</Button>
                )
              }
              className='fix-ma'
              defaultActiveKey="0"
              tabPosition={'top'}
              onChange={onChangeTabs}
              items={tabsArtistName.map((tab, index) => {
                return {
                  label: `${tab.name}`,
                  key: String(index),
                  children: renderTabItem(tabValue)
                };
              })}
            />
          ) : (
            <Tabs
              tabBarExtraContent={
                (
                  <Button size='large' type="primary" onClick={() => submitByTab(tabValue)}>Save</Button>
                )
              }
              className='fix-ma'
              defaultActiveKey="0"
              tabPosition={'top'}
              onChange={onChangeTabs}
              items={tabsName.map((tab, index) => {
                return {
                  label: `${tab.name}`,
                  key: String(index),
                  children: renderTabItem(tabValue)
                };
              })}
            />
          )
        }
      </Card>
    </>
  );
}