export const enum StandardLicense {
  SM = "SM",
  XL = "XL",
  HD = "HD",
  UHD = "UHD"
};

export const enum ExtendedLicense {
  NONE = "NONE",
  MPFR = "MPFR",
  EX6M = "EX6M",
  EX1Y = "EX1Y",
  EX2Y = "EX2Y",
  EX3Y = "EX3Y",
  EX3PLUS = "EX3PLUS",
  VIP = "VIP"
};