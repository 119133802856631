export const enum StringConstants {
  EMPTY = '',
  SPACE = " ",
  COMMA = ",",
  UNDERSCORE = "_",
  BAHT = "฿",
  HYPHEN = "-"
};

export const enum BlobFileType {
    Image = "image/jpeg",
    Video = "video/mp4",
  };

  export const enum BlobFileExtension {
    Image = ".jpg",
    Video = ".mp4",
  };

  export const enum PageDocument {
    Title = "Alive United Plus",
  };