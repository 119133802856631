export const enum ArtistStatus {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected",
}

export const enum PayoutRequestStatus {
    Pending = "Pending",
    Processing = "Processing",
    Paid = "Paid",
    Cancelled = "Cancelled",
}

export const enum PayoutInfoStatus {
    Pending = "Pending",
    Verified = "Verified",
}

export const enum OrderStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
}