import React, { useEffect, useState } from 'react';
import { MenuProps } from 'antd';
import { Menu } from 'antd';
import { BankOutlined, BarChartOutlined, DollarOutlined, DownloadOutlined, FileDoneOutlined, FileImageOutlined, FilePdfOutlined, FileProtectOutlined, HeartOutlined, InsertRowBelowOutlined, LockOutlined, LogoutOutlined, MailOutlined, ShoppingOutlined, UploadOutlined, UserAddOutlined, UserOutlined, YoutubeOutlined } from '@ant-design/icons';
import { RouteNames } from 'src/routes/routeName';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'src/context/session-context';
import { RoleType } from 'src/constant/roleType';
import { UserProviderType } from 'src/constant/userProviderTypeConstant';

type MenuItem = Required<MenuProps>['items'][number];
interface Props {
  onCloseDrawer?: () => void;
}
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function CustomSider(props: Props) {
  const [menus, setMenus] = useState<MenuProps['items']>([]);
  const { signOut, userProfile, getUserProfileAsync } = useSessionContext();
  const navigate = useNavigate();
  const [activePath, setActivePath] = useState([window.location.pathname]);

  useEffect(() => {
    if (userProfile && userProfile.userId && userProfile.roles.length > 0) {
      if (userProfile.roles.filter(r => r == RoleType.ARTIST).length > 0) {
        if (userProfile.userProviderType === UserProviderType.UsernamePassword) {
          setMenus([...artistItems!, ...buyerItems!, ...settingItems!]);
        }
        else {
          setMenus([...artistItems!, ...buyerItems!, ...settingSocialItems!]);
        }
      }
      else {
        if (userProfile.userProviderType === UserProviderType.UsernamePassword) {
          setMenus([...buyerItems!, ...settingItems!]);
        }
        else {
          setMenus([...buyerItems!, ...settingSocialItems!]);
        }
      }
    }
    else {
      getUserProfileAsync();
    }
  }, [userProfile]);

  useEffect(() => {
    const parthName = window.location.pathname;

    if (parthName) {
      setActivePath([parthName]);
    }
  }, [window.location.pathname]);

  const artistItems: MenuProps['items'] = [
    getItem('Artist', 'artist', null, [
      getItem('Dashboard', RouteNames.dashboard, <BarChartOutlined />),
      getItem('Financial', RouteNames.financial, <DollarOutlined />),
      getItem('Payout Info', RouteNames.payoutInfo, <BankOutlined />),
      getItem('Upload', RouteNames.upload, <UploadOutlined />),
      getItem('My Upload', RouteNames.myUpload, <FileImageOutlined />),
      getItem('My Publish', RouteNames.myPublish, <YoutubeOutlined />),
      getItem('My Collections', RouteNames.myCollections, <InsertRowBelowOutlined />),
      getItem('Releases', RouteNames.releases, <FileProtectOutlined />),
      getItem('Earning Report', RouteNames.earningReport, <FilePdfOutlined />),
      getItem('Tax Center', RouteNames.taxCenter, <FileDoneOutlined />),
    ], 'group'),
  ];

  const buyerItems: MenuProps['items'] = [
    getItem('Buyer', 'buyer', null, [
      getItem('My Asset', RouteNames.myAsset, <FileImageOutlined />),
      getItem('My Favorite', RouteNames.myFavorite, <HeartOutlined />),
      getItem('Order History', RouteNames.orderHistory, <ShoppingOutlined />),
      getItem('Download History', RouteNames.downloadHistory, <DownloadOutlined />),
      getItem('Following', RouteNames.follows, <UserAddOutlined />),
    ], 'group'),
  ];

  const settingItems: MenuProps['items'] = [
    getItem('Setting', 'setting', null, [
      getItem('Account Info', RouteNames.accountInfo, <UserOutlined />),
      getItem('Change Email', RouteNames.changeEmail, <MailOutlined />),
      getItem('Change Password', RouteNames.changePassword, <LockOutlined />),
      getItem('Logout', 'logout', <LogoutOutlined />),
    ], 'group'),
  ];

  const settingSocialItems: MenuProps['items'] = [
    getItem('Setting', 'setting', null, [
      getItem('Account Info', RouteNames.accountInfo, <UserOutlined />),
      getItem('Logout', 'logout', <LogoutOutlined />),
    ], 'group'),
  ];

  const fnOnClick: MenuProps['onClick'] = (e) => {

    if (e.key !== 'logout') {
      navigate(e.key);
      props.onCloseDrawer && props.onCloseDrawer();
      return;
    }

    signOut();
  };

  return (
    <>
      <Menu
        mode="inline"
        onClick={(fnOnClick)}
        selectedKeys={activePath}
        items={menus}
        defaultSelectedKeys={['1']}
      />
    </>
  );
}
