import { Col, Divider, Form, FormInstance, Input, Row, Switch, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ICompanyResponse from '../../models/responses/companyResponse';
import { useCallback, useEffect, useState } from 'react';
import SelectProvince from '../../components/address/SelectProvince';
import SelectDistrict from '../../components/address/SelectDistrict';
import SelectSubDistrict from '../../components/address/SelectSubDistrict';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;
interface IFormCompany {
  useFormCompany: FormInstance<any>;
  onSubmitCompany: (data: any) => void;
  companyInfo: ICompanyResponse | undefined;
}

const FormCompany = ({ useFormCompany, onSubmitCompany, companyInfo }: IFormCompany) => {
  const [postcode, setPostCode] = useState('');
  const [districtId, setDistrictId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [ totalVipDownload, setTotalVipDownload ] = useState(0);
  const [ vipDownloadQty, setVipDownloadQty ] = useState(0);
  const [subDistrictId, setSubDistrictId] = useState(0);

  const [form] = Form.useForm(useFormCompany);

  useEffect(() => {
    if (companyInfo) {
      if(companyInfo?.vipDownloads){
        setTotalVipDownload(companyInfo?.vipDownloads.reduce((a,v) =>  a = a + v.quantity , 0 ));
      }
      if(companyInfo?.vipDownloadQty){
        setVipDownloadQty(companyInfo?.vipDownloadQty);
      }
      setProvinceId(companyInfo.addressInfo.provinceCode);
      setDistrictId(companyInfo.addressInfo.districtCode);
      setSubDistrictId(companyInfo.addressInfo.subDistrictCode);
      setPostCode(companyInfo.addressInfo.postcode.toString());
    }
  }, [companyInfo]);

  const validateCustomField = useCallback((isRequired: boolean, key: string) => {
    if (isRequired) {
      useFormCompany.setFields([
        {
          name: key,
          errors: [`${key} is required`],
        },
      ]);
    } else {
      useFormCompany.setFields([
        {
          name: key,
          errors: undefined,
        },
      ]);
    }
  }, [useFormCompany]);

  const onProvinceChange = (provinceId: number) => {
    setProvinceId(provinceId);
    useFormCompany.setFieldValue('provinceCode', provinceId);
    validateCustomField(!provinceId, 'provinceCode');
  };

  const onDistrictChange = (districtId: number) => {
    setDistrictId(districtId);
    useFormCompany.setFieldValue('districtCode', districtId);
    validateCustomField(!districtId, 'districtCode');
  };

  const onSubDistrictChange = (subDistrictId: number) => {
    setSubDistrictId(subDistrictId);
    useFormCompany.setFieldValue('subDistrictCode', subDistrictId);
    validateCustomField(!subDistrictId, 'subDistrictCode');
  };

  return (
    <Content>
      <Form
        form={form}
        onFinish={onSubmitCompany}
        wrapperCol={{ span: 23 }}
        name="form_item_path"
        layout="vertical"
        className="form-input">
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="companyName"
              className="w-100 text-light"
              label="Company Name"
              rules={[{ required: true, message: 'Please input your company name!' }]}>
              <Input
                className="w-100"
                placeholder="example"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="taxId"
              className="w-100 text-light"
              label="Tax ID"
              rules={[{ required: true, message: 'Please input your last Tax ID!' }]}>
              <Input
                className="w-100"
                placeholder="Tax ID"
                size="large" />
            </Form.Item>
          </Col>
          {
            companyInfo?.isVip && (
              <Col xs={24} lg={8} className="flex-center-items">
                <Form.Item
                  className="w-100"
                  label="VIP">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    disabled
                  />
                </Form.Item>
              </Col>
            )
          }
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="branch"
              className="w-100 text-light"
              label="Branch"
              rules={[{ required: true, message: 'Please input your Branch!' }]}>
              <Input
                className="w-100"
                placeholder="example"
                size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="companyWebsite"
              className="w-100 text-light"
              label="Company Website"
              rules={[{ required: true, message: 'Please input your Company Website!' }]}>
              <Input
                className="w-100"
                placeholder="example"
                size="large" />
            </Form.Item>
          </Col>
          {
            (companyInfo?.isVip && totalVipDownload > 0) && (
              <>
                <Col xs={24} lg={4} className="flex-center-items">
                  <Form.Item
                    className="w-100"
                    label="Payment Term">
                    {companyInfo.paymentTerm}
                  </Form.Item>
                </Col>
                <Col xs={24} lg={4} className="flex-center-items">
                  <Form.Item
                    className="w-100"
                    label="Total VIP Download">
                    {`${vipDownloadQty}/${totalVipDownload}`}
                  </Form.Item>
                </Col>
              </>
            )
          }
        </Row>
        <Divider />
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="address"
              className="w-100 text-light"
              label="Address "
              rules={[{ required: true, message: 'Please input your address!' }]}>
              <Input
                className="w-100"
                placeholder="Nexampleame"
                size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="provinceCode"
              className="w-100 text-light"
              label="Province"
              rules={[{ required: true, message: 'Please input your province!' }]}>
              <SelectProvince
                className="non-radius"
                provinceID={provinceId}
                sendProvinceID={onProvinceChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="districtCode"
              className="w-100 text-light"
              label="District"
              rules={[{ required: true, message: 'Please input your district!' }]}>
              <SelectDistrict
                className="non-radius"
                districtID={districtId}
                provinceID={provinceId}
                sendProvinceID={(id) => setProvinceId(id)}
                sendDistrictID={onDistrictChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="subDistrictCode"
              className="w-100 text-light"
              label="Sub District"
              rules={[{ required: true, message: 'Please input your sub district!' }]}>
              <SelectSubDistrict
                className="non-radius"
                postCode={postcode}
                districtID={districtId}
                provinceID={provinceId}
                subDistrictID={subDistrictId}
                sendPostCode={(value) => {
                  useFormCompany.setFieldValue('postcode', value);
                }}
                sendSubDistrictID={onSubDistrictChange}
                sendDistrictID={(id, subId) => {
                  setDistrictId(id);
                  setSubDistrictId(subId);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="postcode"
              className="w-100 text-light"
              label="Zip / Postcode"
              rules={[{
                required: true,
                message: 'Please input your Zip / Postcode!'
              }]}>
              <Input
                readOnly
                className="non-radius"
                size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Title level={3} className="mb-4">Contact Person Detail</Title>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactFirstName"
              className="w-100 text-light"
              label="First Name"
              rules={[{ required: true, message: 'Please input your first name!' }]}>
              <Input className="w-100" placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactLastName"
              className="w-100 text-light"
              label="Last Name"
              rules={[{ required: true, message: 'Please input your last name!' }]}>
              <Input className="w-100" placeholder="example" size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactEmail"
              className="w-100 text-light"
              label="Email"
              rules={[{
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your email!'
              }]}>
              <Input
                type="email"
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="contactPhone"
              className="w-100 text-light"
              label="Phone"
              rules={[{ required: true, message: 'Please input your phone!' }]}>
              <Input className="w-100" placeholder="example" size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="artist-upload">
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="jobTitle"
              className="w-100 text-light"
              label="Job Title"
              rules={[{ required: true, message: 'Please input your Job Title!' }]}>
              <Input
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="department"
              className="w-100 text-light"
              label="Department"
              rules={[{ required: true, message: 'Please input your Department!' }]}>
              <Input
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8} className="flex-center-items">
            <Form.Item
              name="purchasingFor"
              className="w-100 text-light"
              label="Purchasing For"
              rules={[{ required: true, message: 'Please input your Purchasing For!' }]}>
              <Input
                className="w-100"
                placeholder="example" size='large' />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Content>
  );
};

export default FormCompany;