import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { RecommendAssetModel, RecommendCollectionAssetModel } from '../models/BannerModel';
import AssetPublishResultModel from '../models/responses/assetPublishResultModel';

export class BannerService {
    private _qs: any = require('qs');
    private getActiveBannerPath = '/Banner/ActiveRandomBanner';
    private getRandomAssetPath = '/Asset/random';
    private getActiveRecommendCollectionPath = '/RecommendCollection/Active';
    private getActiveRecommendAssetPath = '/RecommendAsset/ActiveRecommendAsset';
    private getListNewsContentPath = '/NewsContent';

    activeBannerAsync(): Promise<AxiosResponse> {
        return Axios.get<string>(this.getActiveBannerPath);
    }

    GetRandomAsset(assetType?: string): Promise<AxiosResponse<AssetPublishResultModel>> {
      const param = {
        'assetType': assetType,
      };
        return Axios.get<AssetPublishResultModel>(`${this.getRandomAssetPath}`, { params: param });
    }

    GetRecommendCollection(): Promise<AxiosResponse<RecommendCollectionAssetModel>> {
        return Axios.get<RecommendCollectionAssetModel>(this.getActiveRecommendCollectionPath);
    }

    GetRecommendAsset(): Promise<AxiosResponse<RecommendAssetModel>> {
        return Axios.get<RecommendAssetModel>(this.getActiveRecommendAssetPath);
    }

    GetActiveNewsContent(size: number): Promise<AxiosResponse> {
        return Axios.get(`${this.getListNewsContentPath}/active?count=${size}`);
    }
}