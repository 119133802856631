import { PageDocument, StringConstants } from 'src/constant/stringConstants';
import { Col, Row, Typography } from 'antd';
import { Content } from "antd/es/layout/layout";
import { useEffect } from 'react';
import TranslationKey from 'src/i18n/translation';
const { Title, Text } = Typography;
export default function Faq() {
    const link =  `${process.env.LINK_WEB_CODEHARD ?? StringConstants.EMPTY}`;
    useEffect(() => {
        document.title = `FAQ - ${PageDocument.Title}`;
    }, []);
  return (
    <div className='container py-5 banner-margin-t-30'>
      <Title className="text-light text-center" level={3}>{TranslationKey('faq.becomingAliveUnitedBuyer')}
        <span className="ms-2 text-gradient">{TranslationKey('faq.title')}</span>
      </Title>
      <Content className='bg-outline-gray mt-2 radius-32 p-3 faq'>
        <Row gutter={16}>
          <Col xs={24} lg={5}>
            <Title className="text-primary text-start my-0" level={5}>
            {TranslationKey('faq.whatIsStockPhotos.title')}
            </Title>
          </Col>
          <Col xs={24} lg={19}>
            <Text className="text-light fw-light">{TranslationKey('faq.whatIsStockPhotos.forBand')}</Text>
            <ul className='question'>
              <li className="text-light fw-normal">{TranslationKey('faq.whatIsStockPhotos.Q')}</li>
              <li className="text-light fw-light">{TranslationKey('faq.whatIsStockPhotos.A')}
              </li>
            </ul>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}>
            <Text className="text-primary text-start">{TranslationKey('faq.whatIsAliveUnitedServices')}</Text>
          </Col>
          <Col xs={24} lg={19}>
            <Text className="text-light fw-light">{TranslationKey('faq.AliveUnitedServicesMean')}</Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}>
            <Text className="text-primary text-start">{TranslationKey('faq.aliveUnitedLicensingOptions.title')}</Text>
          </Col>
          <Col xs={24} lg={19}>
            <Text className="text-light fw-light">
            {TranslationKey('faq.aliveUnitedLicensingOptions.title')}
             <br />
              {/* <br /><Text className="text-light fw-normal"> 1.Standard License</Text> */}
              {TranslationKey('faq.aliveUnitedLicensingOptions.1')}
              <br />
              {/* <br /><Text className="text-light fw-normal">2.Merchandise and Product for Sale or Promotional Distribution License</Text> */}
              {TranslationKey('faq.aliveUnitedLicensingOptions.2')}
              <br />
              {/* <br /><Text className="text-light fw-normal">3.Exclusive Market Freeze License</Text> */}
              {TranslationKey('faq.aliveUnitedLicensingOptions.3')}</Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start"> {TranslationKey('faq.toUsePhotosORvideosInYourProject.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light"> {TranslationKey('faq.toUsePhotosORvideosInYourProject.allAliveUnitedPhotosAndVideoContent')}
            <br /> <br />{TranslationKey('faq.toUsePhotosORvideosInYourProject.ifYourPurchasedAMerchandise')}
            <br /> <br />{TranslationKey('faq.toUsePhotosORvideosInYourProject.toKnowMoreAboutLicense')} <a href={`${link}/termofservice`}>{TranslationKey('here')}</a></Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.whatIsEditorialUseOnly')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light"> {TranslationKey('faq.theseContentsContainPeople')}
          </Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start"> {TranslationKey('faq.demandToUsePhotosORVideoExclusively')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">{TranslationKey('faq.absolutelySure')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">{TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.theMoneyTransferAndPayableCheques')}
            <br /> <br />{TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.afterConfirmationOfComplete')}
            <br /> <br /> {TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.weAcceptedPayments')}
            <ol>
              <li> {TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.1')}</li>
              <li> {TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.2')}</li>
              <li> {TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.3')}</li>
            </ol>
            <br /> {TranslationKey('faq.unableToDownloadContentsAfterPurchasedByMoney.moneyTransferAndPayableCheques')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start"> {TranslationKey('faq.priceForPhotosAndVideo')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.thePriceForAllPhotosAndVideoOnAliveUnitedIsExactlyTheSame')}<a href={`${link}/pricing/`}>{TranslationKey('here')}.</a></Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start"> {TranslationKey('faq.resolutionOfPhotosAndVideo')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">{TranslationKey('faq.theFileSizeSubmittedByTheArtist')} <a href={`${link}/pricing`}>{TranslationKey('faq.pricingPage')}</a></Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start"> {TranslationKey('faq.valueAddedTax')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.weAreLocatedInThailand')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.paymentOptions.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.paymentOptions.weAcceptedPayments')}
            <ol>
              <li>{TranslationKey('faq.paymentOptions.1')}</li>
              <li> {TranslationKey('faq.paymentOptions.2')}</li>
              <li> {TranslationKey('faq.paymentOptions.3')} </li>
            </ol>
            <br />{TranslationKey('faq.paymentOptions.moneyTransferAndPayableCheques')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.myCreditCardBeingDeclined')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.ifYouHaveAProblem')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.doYouOfferSubscriptionPackages')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.thereAreNoAdvancePackage')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.howDoIDownloadContent')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.afterThePaymentIsComplete')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.havingProblemsWithCorruptedFile.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.havingProblemsWithCorruptedFile.whenThePaymentIsComplete')}

            <br /><br />{TranslationKey('faq.havingProblemsWithCorruptedFile.butIfWeFoundThat')}
          </Text></Col> </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.upgradeToLargerOrder')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">	{TranslationKey('faq.yesYouCanPurchaseItAgain')}</Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.canIEditingPhotos')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.yesDependsOnYOurCreativity')} <a href={`${link}/termofservice`}>{TranslationKey('here')}</a></Text></Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.doVideosCOmeWIthAudio')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.noOurVideosDoNotIncludeAudio')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.iNeedHelpToFindCertainPhotos')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.yesOfCourseJstTellUs')} <a href='mailto:support@aliveunited.com'>{TranslationKey('here')}</a>
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.purchaseInvoiceReceipt.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
            <Text className="text-light fw-normal">{TranslationKey('faq.purchaseInvoiceReceipt.invoice')}</Text><br />
            {TranslationKey('faq.purchaseInvoiceReceipt.weDoNotMakeInvoices')}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.purchaseInvoiceReceipt.receipt')}</Text><br />
            {TranslationKey('faq.purchaseInvoiceReceipt.youCanViewYurReceipt')}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.purchaseInvoiceReceipt.taxInvoice')}</Text><br />
            {TranslationKey('faq.purchaseInvoiceReceipt.weWillProvideYouTheFullTax')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.purchasingOnBehalfOfYourCustomer')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.ofCourseYesPurchasingOnBehalfOfYourCustomer')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.usingPhotosFOrMErchandiseAndProduct')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.ifYouPurchaseAMerchandiseAndProduct')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.unableToLoginOnWebsite.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.unableToLoginOnWebsite.ifYouAreHavingTroubleLogging')}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.unableToLoginOnWebsite.resetPassword')}</Text><br />
            {TranslationKey('faq.unableToLoginOnWebsite.ifYouHaveProblems')}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.unableToLoginOnWebsite.setCookiesForYourBrowser')}</Text><br />
            {TranslationKey('faq.unableToLoginOnWebsite.eachBrowserWorksSlightly')}<br />
            <a href="https://www.apple.com/support/mac-apps/safari/">Apple Safari </a><br />
            <a href="https://support.google.com/chrome/answer/95582">Google Chrome </a><br />
            <a href="http://support.microsoft.com/product/internet-explorer">Internet Explorer</a><br />
            <a href="https://support.mozilla.org/en-US/kb/how-clear-firefox-cache ">Mozilla Firefox</a><br />
            {TranslationKey('faq.unableToLoginOnWebsite.ifYouStillCannotLogin')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.forgetEmailPassword.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
            {TranslationKey('faq.forgetEmailPassword.doNotWorry')}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.forgetEmailPassword.ifYouForgetYourPassword')}</Text>
            {/* please click "forget password" on the signin page. We will send a reset link to your email that is associated with your account. The reset link will be active for 24 hours. If you don't see the reset email in your inbox. Please check your spam folder or junk folder. */}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.forgetEmailPassword.forgetEmail')}</Text><br />
            {TranslationKey('faq.forgetEmailPassword.doNotWorry')}
            <br />
            <Text className="text-light fw-normal">{TranslationKey('faq.forgetEmailPassword.alreadyLogIn')}</Text><br />
            {TranslationKey('faq.forgetEmailPassword.youCanResetYurPassword')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.changePassword.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
            {TranslationKey('faq.changePassword.toChangeThePassword')}
            <br />
            {TranslationKey('faq.changePassword.youShouldChange')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.updatingPersonalInformation')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.ifYouWantToChangeYourPersonalInformation')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.changingAddress')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.ifYouWantToChangeYOurAddress')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.ifYouAreHavingTroublesWithAliveUnitedWebsite')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.ifYouAreHavingTroublesUsingTheAliveUnitedWebsite')}
            <br />
            <a href="https://www.apple.com/support/mac-apps/safari/">Apple Safari </a><br />
            <a href="https://support.google.com/chrome/answer/95582">Google Chrome </a><br />
            <a href="http://support.microsoft.com/product/internet-explorer">Internet Explorer</a><br />
            <a href="https://support.mozilla.org/en-US/kb/how-clear-firefox-cache ">Mozilla Firefox</a><br />
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.errorMessage404.title')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.errorMessage404.thisCaseCanHappenInManyReasons')}<br />
            - {TranslationKey('faq.errorMessage404.itMightBe')}<br />
            - {TranslationKey('faq.errorMessage404.thePageWasMoved')}<br />
            - {TranslationKey('faq.errorMessage404.artistsMayPeriodically')}<br />
            - {TranslationKey('faq.errorMessage404.thisContentHasBeen')}
            <br /><br />
            {TranslationKey('faq.errorMessage404.ifYouNeedHelp')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.whatIsAnArtist')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.artistMeans')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.howToFollowAnArtist')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.youCanFollowYourFavoriteArtists')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.howToContactAnArtist')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.ifYourAreInterestedToHireOurAliveUnitedArtists')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
        <Row gutter={16}>
          <Col xs={24} lg={5}><Text className="text-primary text-start">{TranslationKey('faq.cancellingORDeActivating')}</Text></Col>
          <Col xs={24} lg={19}><Text className="text-light fw-light">
          {TranslationKey('faq.pleaseContactUsTOCanceling')}
          </Text>
          </Col>
        </Row>
        <hr className='hr-gray' />
      </Content>
    </div >
  );
}
