import { YoutubeOutlined } from '@ant-design/icons';
import { Button, Divider,Typography } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { Link, } from 'react-router-dom';
import TranslationKey from 'src/i18n/translation';

export default function Foot() {
  const imageLogo = require('../../../assets/images/logo/white@3x.png');
  const lineLogo = require('../../../assets/images/icon/line.png');
  const facebookLogo = require('../../../assets/images/icon/facebook.png');
  const instagramLogo = require('../../../assets/images/icon/intagram.png');
  const pinterestLogo = require('../../../assets/images/icon/pinterest.png');

  return (
    <>
      <Footer className='footer mt-5'>
        <div className='container pt-3'>
          <div className='footer-title'>
            <div className='logo'>
              <Link to='/'>
                <img src={String(imageLogo)} alt='alive united plus' width={150} />
              </Link>
            </div>
            <div className='menu-list-footer'>
              <ul>
                <li>
                  <a href='/faq'>{TranslationKey('faq.title')}</a>
                </li>
                <li>
                  <a href='/aboutus'>{TranslationKey('hAboutUs')}</a>
                </li>
                <li>
                  <a href='/contactus'>{TranslationKey('hContactUs')}</a>
                </li>
                <li>
                  <a href={`https://aliveunited.com/blog/th/`}>{TranslationKey('hBlog')}</a>
                </li>
              </ul>
            </div>
          </div>
          <Divider className='hr' />
          <div className='copyright'>
            <Typography.Title level={5} className='text-copyright'>
              All contents © copyright 2023 Alive United v1.0.56
            </Typography.Title>
            <div className='social'>
              <div className='menu-list-footer-term'>
                <ul>
                  <li>
                    <a href='/termofservice'>{TranslationKey('termOfService.title')}</a>
                  </li>
                  <li>
                    <a href='/privacypolicy'>{TranslationKey('privacyPolicy.title')}</a>
                  </li>
                </ul>
              </div>
              <div className='btn-social'>
                <a href='http://line.me/ti/p/~@aliveunited' target='_blank' rel="noreferrer">
                  <Button
                    size='large'
                    className='btn-icon-dark'
                    shape='circle'
                    icon={<img src={String(lineLogo)} alt='line logo' />}
                  />
                </a>
                <a href='https://www.facebook.com/alive.united' target='_blank' rel="noreferrer">
                  <Button
                    size='large'
                    className='btn-icon-dark ms-2'
                    shape='circle'
                    icon={<img src={String(facebookLogo)} alt='facebook logo' />}
                  />
                </a>
                <a href='https://www.instagram.com/alive.united' target='_blank' rel="noreferrer">
                  <Button
                    size='large'
                    className='btn-icon-dark ms-2'
                    shape='circle'
                    icon={<img src={String(instagramLogo)} alt='instagram logo' />}
                  />
                </a>
                <a href='https://www.pinterest.com/aliveunited/' target='_blank' rel="noreferrer">
                  <Button
                    size='large'
                    className='btn-icon-dark ms-2'
                    shape='circle'
                    icon={<img src={String(pinterestLogo)} alt='pinterest logo' />}
                  />
                </a>
                <a href='https://www.youtube.com/@alive.united' target='_blank' rel="noreferrer">
                  <Button
                    size='large'
                    className='btn-icon-dark ms-2'
                    shape='circle'
                    icon={<YoutubeOutlined />}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </>
  );
}
