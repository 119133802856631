import { Col, Row, Typography, Space, Radio, Form, Button } from 'antd';
import { HeartOutlined } from "@ant-design/icons";
import InputControl from '../components/controls/InputControl';
import InputCard from '../components/controls/InputCard';
import Selecter from '../components/controls/Selecter';

const { Text } = Typography;
export default function Components() {
  return (
    <div className='container py-5'>
      <Text className="text-gray">Colors</Text>
      <hr className='hr-gray' />
      <Text className="text-primary">.text-primary</Text><br />
      <Text className="text-light">.text-light</Text><br />
      <Text className="text-gray">.text-gray</Text><br /><br /><br />

      <Text className="text-gray mt-5">Buttons</Text>
      <hr className='hr-gray' />
      <Space wrap>
        <Button type="primary" shape="circle" className='btn-outline' icon={<HeartOutlined />} />
        <Button className="btn-icon-dark" shape="circle" icon={<HeartOutlined />} />
      </Space>
      <br />
      <Space wrap className='mt-3'>
        <Button type="primary" className="btn btn-primary">btn-primary</Button>
        <Button type="primary" className="btn btn-light">btn-light</Button>
        <Button type="primary" className="btn btn-outline">btn-outline</Button>
        <Button type="primary" className="btn btn-outline-border">btn-outline-border</Button>
      </Space>

      <br />
      <Space wrap className='mt-3'>
        <Button type="primary" shape="round" className="btn btn-primary">btn-primary</Button>
        <Button type="primary" shape="round" className="btn btn-light">btn-light</Button>
        <Button type="primary" shape="round" className="btn btn-outline">btn-outline</Button>
        <Button type="primary" shape="round" className="btn btn-outline-border">btn-outline-border</Button>
      </Space>

      <br /><br />
      <Text className="text-gray mt-5">Input</Text>
      <hr className='hr-gray' />
      <Form layout="vertical" autoComplete="off" className='form-dark'>
        <Row gutter={8}>
          <Col span={12}>
            <InputControl
              label='InputControl Radius'
              placeholder='InputControl Radius'
              icon={true}
              className='input-radius-dark non-radius'
              name='name'
              SizeType='middle'
            />
          </Col>
          <Col span={12} className='ps-2'>
            <InputControl
              label='icon'
              placeholder='Enter hex or select'
              icon={true}
              className='input-radius-dark non-radius'
              name='name'
              SizeType='middle'
              iconName='search' />
          </Col>
          <Col span={12}>
            <InputControl
              label='InputControl'
              placeholder='InputControl'
              icon={true}
              className='input-radius-dark'
              name='name'
              SizeType='middle'
            />
          </Col>
          <Col span={24} className='input-payment'>
            <InputCard />
          </Col>
        </Row>
      </Form>

      <br /><br />
      <Text className="text-gray mt-5">Selecter</Text>
      <hr className='hr-gray' />
      <Form layout="vertical" autoComplete="off" className='form-dark'>
        <Row gutter={8}>
          <Col xs={12} xl={2} className='ps-2'>
            <Selecter
              name="selecter"
              label="Selecter"
              SizeType='middle'
              className='input-radius-dark' />
          </Col>
        </Row>
      </Form>

      <br /><br />
      <Text className="text-gray mt-5">Input</Text>
      <hr className='hr-gray' />
      <Radio.Group className="custom-color-radio" size='large'>
        <Space direction="vertical">
          <Radio value={2} className='text-gray'>+<span className='text-light mx-1 fw-bold'> ฿5,000 </span>Market-Freeze 6 Months</Radio>
        </Space>
      </Radio.Group>

      <br /><br /><br /><br />
      <Text className="text-gray mt-5">Text Gradient</Text>
      <hr className='hr-gray' />
      <Typography.Title className="text-light" level={4}>
        Our terms of licensing <span className="text-gradient">in a nutshell</span>
      </Typography.Title>

      <br /><br /><br /><br />
      <Text className="text-gray mt-5">Text alignment</Text>
      <hr className='hr-gray' />
      <Typography.Title className="text-light text-start" level={5}>


        .text-start
      </Typography.Title>
      <Typography.Title className="text-light text-center" level={5}>
        .text-center
      </Typography.Title>
      <Typography.Title className="text-light text-end" level={5}>
        .text-end
      </Typography.Title>
    </div>
  )
}
