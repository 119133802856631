import { HttpStatus } from '../../constant/responseStatus';
import { ProfileModel, ResponseSignIn, SignInFacebookModel, SignInGoogleModel, SignInModel } from '../../models/accountModel';
import { useCallback, useEffect, useState } from 'react';
import auth from '../../helper/Authentication';
import { alertType } from '../../helper/alert';
import { SessionContext } from '../../context/session-context';
import { Constants } from 'src/constant/constants';
import { AssetPublishModel } from "src/models/assetModel";
import classStore from '../../store/classStore';
import { AccountService } from 'src/services/accountService';
import { UserProfile } from '@/src/models/responses/userProfile';
import { HttpStatusCode } from 'axios';
import sessionStore from 'src/store/sessionStore';
import { Session, SessionTimeOut } from 'src/constant/sesstion';
import { SessionStatus } from 'src/constant/sessionStatus';
import { IArtistResponse } from 'src/models/responses/artistResponse';
import { StringConstants } from 'src/constant/stringConstants';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const accountService = new AccountService();

export default function SessionComponent({ children }: IProps) {
  const [userProfile, setUserProfile] = useState<UserProfile>({} as UserProfile);
  const [artistProfile, setArtistProfile] = useState<IArtistResponse>({} as IArtistResponse);
  const [isAuthenticated, setIsAuthenticated] = useState(SessionStatus.LOADING);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string>(StringConstants.EMPTY);
  const [countOrder, setCountOrder] = useState(() => {
    const orders = classStore.get('orderAssets');
    const ordersItem = orders as AssetPublishModel[];

    if (!ordersItem) {
      return 0;
    }

    return ordersItem.length;
  });

  const getUserProfileAsync = useCallback(async () => {
    const { data, status } = await accountService.getUserProfileAsync();

    if (status === HttpStatusCode.Ok) {
      setUserProfile(data);
      sessionStore.set(Session.SESSION_USER, data);
      await getArtistProfile(data.userId);
    }
  }, []);

  const getArtistProfile = useCallback(async (userId: string) => {
    const { data, status } = await accountService.getArtistProfile(userId);

    if (status === HttpStatusCode.Ok) {
      setAvatarUrl(data.avatarUrl);
      setArtistProfile(data.artist);
    }
  }, []);

  const validateTokenExpire = useCallback(() => {
    const dateNow = new Date();
    const expireToken = sessionStore.get(Session.EXPIRE_SESSION);
    const accessToken = sessionStore.get(Session.SESSION_ACCESS_TOKEN);

    if (!accessToken) {
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return;
    }

    if (!expireToken) {
      auth.signOut();
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return;
    }

    const expireDate = new Date(Number(expireToken));

    if (dateNow.getTime() >= expireDate.getTime()) {
      auth.signOut();
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return;
    }

    const user = sessionStore.get(Session.SESSION_USER);

    if (user) {
      setUserProfile(user);
    }

    setIsAuthenticated(SessionStatus.AUTHENTICATED);
  }, []);

  const signOut = useCallback(() => {
    auth.signOut()
  }, []);

  const signInAsync = useCallback(async ({ username, password, deviceCode }: SignInModel) => {
    const data: SignInModel = {
      username, password, deviceCode,
    };

    const response = await auth.signInAsync(data);

    if (response.status !== HttpStatus.OK) {
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return response;
    }

    await getUserProfileAsync();
    setIsAuthenticated(SessionStatus.AUTHENTICATED);
    return { type: alertType.SUCCESS, msg: Constants.stringEmpty, status: HttpStatus.OK } as ResponseSignIn;
  }, []);

  const signInGoogleAsync = useCallback(async ({ tokenId, profileObj }: SignInGoogleModel) => {
    const data: SignInGoogleModel = {
      tokenId, profileObj,
    };

    const response = await auth.signInGoogleAsync(data);

    if (response.status !== HttpStatus.OK) {
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return response;
    }

    await getUserProfileAsync();
    setIsAuthenticated(SessionStatus.AUTHENTICATED);
    return { type: alertType.SUCCESS, msg: Constants.stringEmpty, status: HttpStatus.OK } as ResponseSignIn;
  }, []);

  const signInFacebookAsync = useCallback(async ({ email, accessToken, userId, name, imageUrl }: SignInFacebookModel) => {
    const data: SignInFacebookModel = {
      email,
      accessToken,
      userId,
      name, imageUrl,
    };

    const response = await auth.signInFacebookAsync(data);

    if (response.status !== HttpStatus.OK) {
      setIsAuthenticated(SessionStatus.UNAUTHENTICATED);

      return response;
    }

    await getUserProfileAsync();
    setIsAuthenticated(SessionStatus.AUTHENTICATED);
    return { type: alertType.SUCCESS, msg: Constants.stringEmpty, status: HttpStatus.OK } as ResponseSignIn;
  }, []);

  const onProfileAsync = async () => {
    const response = await auth.profileAsync();

    if (response.status !== HttpStatus.OK) {
      classStore.remove('userProfile')
      return response;
    }

    const profileResponse = {
      userId: response.data.userId,
      email: response.data.email,
      firstname: response.data.firstName,
      lastname: response.data.lastName,
      role: response.data.role,
    } as ProfileModel;

    classStore.set('userProfile', profileResponse, SessionTimeOut.ONE_DAY)
  };

  const getOrderAssets = useCallback(() => {
    const orders = classStore.get('orderAssets');
    return orders as AssetPublishModel[];
  }, []);

  const RemoveOrderAssets = useCallback(() => {
    classStore.remove('orderAssets');
    setCountOrder(0);
  }, []);

  const updateOrderAssets = useCallback((orders: AssetPublishModel[]) => {
    classStore.set('orderAssets', orders);

    setCountOrder(orders.length);
  }, []);

  const setDisableSearch = useCallback(() => {
    setIsShowSearch(false)
  }, []);

  const getCountOrder = useCallback(() => {
    const orders = classStore.get('orderAssets');
    const ordersItem = orders as AssetPublishModel[];

    if (!ordersItem) {
        setCountOrder(0);
        return;
    }

    setCountOrder(ordersItem.length);
  }, []);

  useEffect(() => {
    validateTokenExpire();
  }, [validateTokenExpire]);

  return (
    <SessionContext.Provider value={{
      signInAsync,
      signInGoogleAsync,
      signInFacebookAsync,
      getOrderAssets,
      countOrder,
      updateOrderAssets,
      userProfile,
      validateTokenExpire,
      isAuthenticated,
      signOut,
      getUserProfileAsync,
      avatarUrl,
      artistProfile,
      RemoveOrderAssets,
      isShowSearch,
      setDisableSearch,
      getCountOrder,
    }}>
      {children}
    </SessionContext.Provider>
  );
}