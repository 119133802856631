import { Row, Col, Skeleton } from 'antd';
import { useState } from 'react';
import SearchInput from '../search-input/search-input';
import { BannerService } from '../../services/bannerService';
import { useEffect } from 'react';
import { HttpStatus } from "../../constant/responseStatus";

export default function Banner() {
  const [bannerMedia, setBannerMedia] = useState();
  const [bannerType, setBannerType] = useState('');
  const [activeBanner, setActiveBanner] = useState(false);

  useEffect(() => {
    onGetBanner();
  }, []);

  const onGetBanner = async () => {
    const response = await new BannerService().activeBannerAsync();

    if (response.status !== HttpStatus.OK) {
      return;
    }
    if (/\.(jpe?g|mp4|mov)$/i.test(response.data?.bannerItems[0]?.mediaUrl)) {
      if (/\.(jpe?g)$/i.test(response.data?.bannerItems[0]?.mediaUrl)) {
        setBannerType('photo');
      }
      else {
        setBannerType('video');
      }
      setBannerMedia(response.data?.bannerItems[0]?.mediaUrl);
    }
    setActiveBanner(true);
  };

  return (
    <>
      <div className="bg-video ">
        {activeBanner ?
          bannerType === 'video' ? (
            <video className="bg-video__content" autoPlay muted loop>
              <source src={bannerMedia} type="video/mp4" />
              <source src={bannerMedia} type="video/webm" />
              Your browser is not supported!
            </video>
          )
            : (
              <img src={bannerMedia} className="bg-video__content" alt='bannerMedia' />
            )
          : <Skeleton.Image active />
        }
        <div className='linear-gradient' />
      </div>

      <div className='form-content'>
        <p className='title'>
          <span className='primary-title'>Exclusive </span>
          <span>Gallery Driven by Passion</span>
        </p>
        <p>Search authentic art forward stock photos and videos</p>
        <Row justify={"center"}>
          <Col xs={{ span: 20 }} md={{ span: 10 }} lg={{ span: 6 }}>
            <SearchInput />
          </Col>
        </Row>
      </div>
    </>
  );
}