import { Select } from 'antd';
import { useEffect, useState } from 'react';
import Bank from '../../constant/banks.json';

const { Option } = Select;

interface BankProps {
  bankCode: string,
  sendBankCode: (code: string) => void,
  className: string;
}

export default function SelectBank(props: BankProps) {
  const [bankCode, setBankCode] = useState("");

  useEffect(() => {
    setBankCode(props.bankCode);
  }, [props.bankCode]);

  const handleChangeBank = (value: string) => {
    setBankCode(value);

    props.sendBankCode(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      size="large"
      className={props.className}//"input-radius-dark non-radius"
      value={bankCode}
      onSelect={(value: string) => handleChangeBank(value)}
    >
      <Option value={0}>
        Select Bank
      </Option>
      {
        Bank.map((data, index) => (
            <Option value={data.value} key={`${data.value}-${index}`}>
            {data.nameEn}
          </Option>
        ))
      }
    </Select>
  );
}
