import { NotificationsModel } from '@/src/models/NotificationModel';
import Axios from '../helper/Axios';
import { AxiosResponse } from 'axios';

export class NotificationsService {
  getNotificationsAsync(page: number = 1, size: number = 20): Promise<AxiosResponse<NotificationsModel[]>> {
    const params = {
      page,
      size
    };

    return Axios.get<NotificationsModel[]>('/notifications', { params });
  }

  markAsReadAsync(id: string): Promise<AxiosResponse> {
    return Axios.post(`/notifications/${id}/read`);
  }

  markAllAsReadAsync(): Promise<AxiosResponse> {
    return Axios.post(`/notifications/read`);
  }
}