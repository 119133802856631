import AWS from 'aws-sdk';
import exifr from 'exifr';
import heic2any from 'heic2any';
import { HttpStatus } from '../../../constant/responseStatus';
import { AssetService } from '../../../services/assetService';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, UploadProps, Upload, Space, Alert, Modal } from 'antd';
import IUploadAssetModel from '../../../models/request/uploadAssetRequestModel';
import {
  ExifKey,
  MIMETYPE,
  UploadFileConstants,
  UploadListType,
  UploadValidationMessages
} from '../../../constant/uploadFileConstants';
import { AssetType, OrientationType } from '../../../constant/assetConstants';
import { StringConstants } from '../../../constant/stringConstants';
import { useEffect, useState } from 'react';
import { S3Constants } from '../../../constant/s3Constants';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';
import TranslationKey from 'src/i18n/translation';

const { v4: uuidv4 } = require('uuid');
var CryptoJS = require("crypto-js");

export default function FileUpload() {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const navigate = useNavigate();

  let localFileListHash: string[] = [];

  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const assetService = new AssetService();

  const [countUploadPerWeek, setCountUploadPerWeek] = useState(0);

  useEffect(() => {
    getCountUploadPerWeek();
  });

  const getCountUploadPerWeek = async () => {
    var response = await assetService.getCountUploadPerWeekAsync();

    if (response.data) {
      setCountUploadPerWeek(response.data);
    }
  };

  const getS3FileUrl = (fileName: string) => {
    const fileUrl = `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
    return fileUrl;
  };

  const getFileType = (fileType: string) => {
    if (fileType.match(MIMETYPE.ALL_VIDEO)) {
      return AssetType.VIDEO;
    }

    return AssetType.IMAGE;
  };

  const getImageEXIF = async (imageFile: File) => {
    const exifData = await exifr.parse(imageFile, true).then(exifData => exifData);

    if (!exifData || exifData === undefined) {
      return null;
    }

    return exifData;
  };

  const getImageOrientation = async (exifData: any) => {
    if (exifData) {
      const imageHeight = exifData[ExifKey.HEIGHT];
      const imageWidth = exifData[ExifKey.WIDTH];

      if (imageHeight > imageWidth) {
        return OrientationType.VERTICAL;
      }
    }

    return OrientationType.HORIZONTAL;
  };

  const getValidationErrorMessage = (fileName: string, errMessage: string) => {
    return fileName + StringConstants.SPACE + errMessage;
  };

  const validateUploadFileAsync = async (
    file: File,
    onError: any,
    isHeicFormat: boolean,
    exifData: any,
    fileType: string,
    hashFile: string
  ) => {
    const fileTypeNotSupport = !(UploadFileConstants.MIME_ACCEPT.includes(file.type));

    if (!isHeicFormat && fileTypeNotSupport) {
      const errorMessage = getValidationErrorMessage(file.name, UploadValidationMessages.INVALID_FILE_TYPE);
      onError(errorMessage);

      return errorMessage;
    }

    if (fileType === AssetType.IMAGE) {
      if (file.size > (UploadFileConstants.MAX_FILE_SIZE as number)) {
        const errorMessage = getValidationErrorMessage(file.name, UploadValidationMessages.IMAGE_MAX_FILE_SIZE);
        onError(errorMessage);

        return errorMessage;
      }

      if ((exifData[ExifKey.WIDTH] * exifData[ExifKey.HEIGHT]) < (UploadFileConstants.IMAGE_MIN_WIDTH * UploadFileConstants.IMAGE_MIN_HEIGHT)) {
        const errorMessage = getValidationErrorMessage(file.name, UploadValidationMessages.IMAGE_MIN_RESOLUTION);
        onError(errorMessage);

        return errorMessage;
      }
    }

    const duplicateHash = localFileListHash.some(hash => hash === hashFile);

    if (duplicateHash) {
      const errorMessage = getValidationErrorMessage(file.name, UploadValidationMessages.FILE_ALREADY_EXISTS);
      onError(errorMessage);

      return errorMessage;
    }

    localFileListHash.push(hashFile);

    return StringConstants.EMPTY.toString();
  };

  const addNewAsset = async (
    originalFile: any,
    fileName: string,
    onError: any,
    onSuccess: any,
    onProgress: any,
    exifData: any,
    fileType: string
  ) => {
    const filePath = getS3FileUrl(fileName);
    let imageOrientation = OrientationType.HORIZONTAL;
    let imageDPI = 0;

    if (fileType === AssetType.IMAGE) {
      imageOrientation = await getImageOrientation(exifData);
      imageDPI = exifData[ExifKey.X_RESOLUTION] as number;
    }

    const model: IUploadAssetModel = {
      assetType: fileType,
      photoInfoOrientation: imageOrientation,
      originalFileInfoFileName: originalFile.name,
      originalFileInfoPath: filePath,
      originalFileInfoSize: originalFile.size,
      originalFileInfoEncoding: StringConstants.EMPTY,
      dpi: imageDPI,
      colorInfoHues: [],
      colorInfoColors: [],
      s3FilePath: fileName
    } as IUploadAssetModel;

    const response = await assetService.createAsync(model);
    onProgress!({ percent: 100 });

    if (response.status !== HttpStatus.OK) {
      const errorMessage = `File ${originalFile.name} : ${response.data.failedReason}`;
      onError!(errorMessage);

      return;
    }

    onSuccess!(response.data);
  };

  const getFileName = (
    fileType: string,
    originalFileName: string) => {
    const uuid1 = uuidv4().replace(/-/g, '');
    const uuid2 = uuidv4().replace(/-/g, '');

    return `${fileType.toLocaleLowerCase()}/original/${uuid1}${uuid2}/${originalFileName}`;
  };

  const addFileToS3Chunks = async (
    originalFile: any,
    onError: any,
    onSuccess: any,
    onProgress: any,
    exifData: any,
    fileType: string
  ) => {
    if (!originalFile) return;

    const fileName = getFileName(fileType, originalFile.name);
    const chunkSize = 5 * 1024 * 1024;
    const numberOfChunks = Math.ceil(originalFile.size / chunkSize);

    let uploadedChunks = 0;
    let uploadId;
    let parts = [];

    try {
      // Create a multipart upload
      const createMultipartUploadResponse = await s3Bucket.createMultipartUpload({
        ACL: S3Constants.ACL_TERM,
        Bucket: S3_BUCKET,
        Key: fileName
      }).promise();

      if(!createMultipartUploadResponse.UploadId) return;

      uploadId = createMultipartUploadResponse.UploadId;

      // Upload each chunk
      for (let i = 0; i < numberOfChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, originalFile.size);
        const chunk = originalFile.slice(start, end);

        const uploadPartResponse = await s3Bucket.uploadPart({
          Bucket: S3_BUCKET,
          Key: fileName,
          PartNumber: i + 1,
          UploadId: uploadId!,
          Body: chunk
        }).promise();

        parts.push({
          ETag: uploadPartResponse.ETag,
          PartNumber: i + 1
        });

        uploadedChunks++;

        // Update progress
        const progress = Math.min(uploadedChunks / numberOfChunks * 95, 95);
        onProgress!({ percent: progress });
      }

      // Complete the multipart upload
      await s3Bucket.completeMultipartUpload({
        Bucket: S3_BUCKET,
        Key: fileName,
        MultipartUpload: {
          Parts: parts
        },
        UploadId: uploadId
      }).promise();

      await addNewAsset(
          originalFile,
          fileName,
          onError,
          onSuccess,
          onProgress,
          exifData,
          fileType);
    } catch (error) {
      if (uploadId) {
        // Abort the multipart upload if it fails
        await s3Bucket.abortMultipartUpload({
          Bucket: S3_BUCKET,
          Key: fileName,
          UploadId: uploadId
        }).promise();
      }
    }
  };

  const convertHeicToJpg = async (originalFile: File, originalFileName: string) => {
    const jpegBlob = await heic2any(
      {
        blob: originalFile,
        toType: UploadFileConstants.IMAGE_MIME_JPG.toString(),
        quality: 1
      }
    ) as Blob;

    const jpgConvertedFile = new File(
      [jpegBlob],
      `${originalFileName.replace(UploadFileConstants.IMAGE_FORMAT_HEIC, UploadFileConstants.IMAGE_FORMAT_JPG)}`,
      { type: UploadFileConstants.IMAGE_MIME_JPG.toString() }
    );

    return jpgConvertedFile;
  };

  const getHashFromFile = (file: File) => {
    var CHUNK_SIZE = 1 * 1024 * 1024; // 512KB
    var offset = 0;
    var hashObj = CryptoJS.algo['SHA256'].create();
    var fileSize = file.size;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        if (reader.error) {
          return reject(reader.error);
        }
        hashObj.update(CryptoJS.enc.Latin1.parse(reader.result));

        offset += CHUNK_SIZE;
        if (offset >= fileSize) {
          var hash = hashObj.finalize();
          var hashHex = hash.toString(CryptoJS.enc["Base64url"]);
          return resolve(hashHex);
        }
        readNext();
      };

      reader.onerror = function (err) {
        reject(err);
      };

      function readNext() {
        var fileSlice = file.slice(offset, offset + CHUNK_SIZE);
        reader.readAsBinaryString(fileSlice);
      }
      readNext();
    });
  };

  const uploadProps: UploadProps = {
    multiple: true,
    listType: UploadListType.PICTURE,
    accept: UploadFileConstants.MIME_ACCEPT.toString(),
    maxCount: UploadFileConstants.MAX_UPLOAD_PER_TIME as number,
    itemRender: (originNode, file, fileList) => {
      return (
        <div>
          {originNode}
          {file.status === 'error' && (
            <span className='text-danger'><InfoCircleOutlined /> {file.error ? file.error : 'Error occurred while uploading'}</span>
          )}
        </div>
      );
    },
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: true,
    },
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      strokeWidth: 5,
      showInfo: false,
      status: 'active',
    },
    async onChange(info) {
      const { file } = info;

      if (file.status === 'done') {
        setOpenSuccessModal(true);
      }
    },
    async onRemove(fileInfo) {
      if (fileInfo['response']) {
        const fileId = fileInfo['response']['id'];
        await assetService.deleteAsync(fileId);

        setCountUploadPerWeek(countUploadPerWeek - 1);
      }
    },
    async beforeUpload(file, fileList) {
      setCountUploadPerWeek(countUploadPerWeek + fileList.length);
    },
    async customRequest({
      file,
      onError,
      onSuccess,
      onProgress,
    }) {
      let originalFile = file as File;
      const originalFileName = originalFile.name.trim();
      const isHeicFormat = originalFileName.endsWith(UploadFileConstants.IMAGE_FORMAT_HEIC);
      const fileType = getFileType(originalFile.type);

      let exifData: any;

      if (fileType === AssetType.IMAGE) {
        exifData = await getImageEXIF(originalFile);
      }

      const hash = await getHashFromFile(originalFile);
      const errorMessage = await validateUploadFileAsync(
        originalFile,
        onError,
        isHeicFormat,
        exifData,
        fileType,
        hash + StringConstants.EMPTY
      );

      if (isHeicFormat) {
        originalFile = await convertHeicToJpg(originalFile, originalFileName);
      }

      if (errorMessage !== StringConstants.EMPTY) {
        return;
      }

      await addFileToS3Chunks(
        originalFile,
        onError,
        onSuccess,
        onProgress,
        exifData,
        fileType);
    }
  };

  return (

    <Space direction="vertical" style={{ display: 'flex' }}>
      <Row justify="center">
        <Col xs={{ span: 24 }}>
          {
            countUploadPerWeek >= UploadFileConstants.MAX_UPLOAD_PER_WEEK
              ? <Alert message="Weekly file uploads are full." type="warning" showIcon />
              : null
          }
          <Upload.Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {TranslationKey('upload.clickOrDrag')}
            </p>
            <p className="ant-upload-hint">
            {TranslationKey('upload.photosAndVideos')}
            </p>
          </Upload.Dragger>
        </Col>
      </Row>
      <Modal
        title="Upload Success"
        open={openSuccessModal}
        okText="Go to Submit"
        onOk={() => navigate(RouteNames.myUpload)}
        cancelText="Upload again"
        onCancel={() => setOpenSuccessModal(false)}>
        <p>
        {TranslationKey('upload.yourFileUpload')}
        </p>
      </Modal>
    </Space>
  );
}