import { SessionTimeOut } from '../constant/sesstion';

function calculateDate(time: number) {
  return new Date(Date.now() + time);
}

const set = (name: string, data: any, timeOut: number = SessionTimeOut.ONE_YEAR) => {
  const item = {
		data: data,
		expires: calculateDate(timeOut),
	}
  localStorage.setItem(name, JSON.stringify(item));
}

const get = <T>(name: string) => {
  const itemStr = localStorage.getItem(name)
  	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)

	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expires) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(name)
		return null
	}
	return item.data as T
};

const remove = (name: string) => localStorage.removeItem(name);

const classStore = {
  set,
  get,
  remove,
};

export default classStore;