import i18next from "i18next";

const TranslationKey = (key: string): string =>  {
    const result = i18next.t(key);

    const enObject = "key 'login (en)' returned an object instead of string.";
    const thObject = "key 'login (th)' returned an object instead of string.";


    return result  ===  enObject || result === thObject ? '' + key: result;;
}

export default TranslationKey;



