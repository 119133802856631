import { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Typography,
  Button,
  Space,
  Radio,
  Flex,
  Tag,
  Tooltip,
  Avatar,
  Skeleton,
  Result,
  message,
} from 'antd';
import {
  DownloadOutlined,
  ZoomInOutlined,
  PlusOutlined,
  HeartOutlined,
  ShareAltOutlined,
  UserOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  MinusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import GallerysCollection from '../../components/gallery/GallerysCollection';
import { AssetService } from '../../services/assetService';
import { HttpStatus } from '../../constant/responseStatus';
import { AssetPublishModel } from 'src/models/assetModel';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { LicenseService } from '../../services/licenseService';
import { ExtendedLicenseModel, StandardLicenseModel } from '@/src/models/LicenseModel';
import { CollectionService } from '../../services/collectionService';
import { StandardLicense, ExtendedLicense } from '../../constant/licenseConstants';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AssetType, AssetUsage, DownloadFileName } from '../../constant/assetConstants';
import { useSessionContext } from '../../context/session-context';
import { UserAssetLicenseResponse } from '../../models/responses/userAssetLicenseResponse';
import { UserAssetLicenseService } from '../../services/userAssetLicenseService';
import MyFavoriteModal from 'src/components/modal/myFavoriteModal';
import ShareModal from 'src/components/modal/shareModal';
import { ArtistProfileModel, FollowCreateModel } from 'src/models/accountModel';
import { AccountService } from 'src/services/accountService';
import { CollectionAssetModel } from 'src/models/CollectionModel';
import { FavoriteService } from 'src/services/favoriteService';
import { FavoriteResponseModel } from 'src/models/responses/favoriteModel';
import { BlobFileExtension, PageDocument, StringConstants } from 'src/constant/stringConstants';
import VideoControl from 'src/components/controls/VideoControl';
import { RouteNames } from 'src/routes/routeName';
import ReportAssetModal from 'src/components/modal/reportAssetModal';
import { ExclusiveLicenseModel } from 'src/models/ExclusiveLicenseModel';
import { ReportAssetService } from 'src/services/ReportAssetService';
import GallerysMoreLikeThis from 'src/components/gallery/GallerysMoreLikeThis';
import HelmetComponent from 'src/components/helmet/HelmetComponent';
import TranslationKey from 'src/i18n/translation';

const { Title, Text } = Typography;

export default function Product() {
  const navigate = useNavigate();
  const logoLink = `${process.env.REACT_APP_REDIRECT_PATH}/images/logo/logo_alive_square.png`;

  const [stdValue, setStdValue] = useState<StandardLicenseModel>();
  const [extValue, setExtValue] = useState<ExtendedLicenseModel>();
  const [totalPrice, setTotalPrice] = useState(0);
  const [tags, setTags] = useState<string[]>([]);
  const [relateKeywords, setRelateKeywords] = useState<string[]>([]);
  const { getOrderAssets, updateOrderAssets, userProfile, getCountOrder } = useSessionContext();
  const [asset, setAsset] = useState<AssetPublishModel>();
  const [orderAsset, setOrderAsset] = useState<AssetPublishModel>({} as AssetPublishModel);
  const [standardLicense, setStandardLicense] = useState<StandardLicenseModel[]>();
  const [extendedLicense, setExtendedLicense] = useState<ExtendedLicenseModel[]>();
  const [, setStandardLicenseVip] = useState<StandardLicenseModel>({} as StandardLicenseModel);
  const [extendedLicenseVip, setExtendedLicenseVip] = useState<ExtendedLicenseModel>({} as ExtendedLicenseModel);
  const [collections, setCollections] = useState<CollectionAssetModel[]>([]);
  const [favorite, setFavorite] = useState<FavoriteResponseModel>({} as FavoriteResponseModel);
  const [isShowContact, setIsShowContact] = useState(false);
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [cartLoadings, setCartLoadings] = useState<boolean>();
  const [userAssetLicenses, setUserAssetLicenses] = useState<UserAssetLicenseResponse[]>([]);
  const [userAssetLicenseId, setUserAssetLicenseId] = useState('');
  const [hasUserLicense, setHasUserLicense] = useState(false);
  const [downloadLoadings, setDownloadLoadings] = useState<boolean>();
  const [downloadVipLoadings, setDownloadVipLoadings] = useState<boolean>();
  const [isNotFound, setIsNotFound] = useState(false);
  const [isOpenFavorite, setIsOpenFavorite] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [artist, setArtist] = useState<ArtistProfileModel>({} as ArtistProfileModel);
  const [isFollow, setIsFollow] = useState(false);
  const [isSameAccount, setIsSameAccount] = useState(false);
  const [isVip, setIsVip] = useState(false);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [isExclusive, setIsExclusive] = useState(false);
  const [isNotReport, setIsNotReport] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [seeMore, setSeeMore] = useState(false);
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState<string>('');


  useEffect(() => {
    if (asset) {
      return;
    }

    onGetAssetPublishAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (asset) {
      setRelateKeywords(asset.name.split(' ').map(n => n.trim()));
      onGetLicenseAsync();
      onGetArtistByUserIdAsync(asset.userId);
      onGetCollectionByAssetAsync();
      onGetFavoriteByAssetAsync();
      onGetReportAssetByAssetAsync();
      setTags(asset.keywords);
      setIsVip(userProfile && userProfile.isVip);
      onCheckExclusiveAsync();

      var assetImage = asset?.thumbnailImageUrl ?? `${process.env.REACT_APP_REDIRECT_PATH}/images/logo/logo_alive_square.png`;
      
      if(assetImage.slice(-1) === '.'){
        assetImage = logoLink;
      }

      setThumbnailImageUrl(assetImage)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    if (stdValue && extValue) {
      calculateTotalPrice();

      if (userAssetLicenses) {
        checkLicense(stdValue.id!, extValue.id!);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stdValue, extValue, userAssetLicenses]);

  useEffect(() => {
    if (standardLicense) {
      onGetUserAssetLicenseAsync();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardLicense]);

  const handleClose = () => setShow(false);
  const handleCloseFav = () => {
    setIsOpenFavorite(false);
    onGetFavoriteByAssetAsync();
  };
  const handleCloseShare = () => setIsOpenShare(false);
  const handleCloseReport = () => {
    setIsOpenReport(false);
    onGetReportAssetByAssetAsync();
  };
  const handleShow = () => setShow(true);

  const calculateTotalPrice = () => {
    if (stdValue && extValue) {
      setOrderAsset((pre) => ({ ...pre, standardLicense: stdValue }));
      setOrderAsset((pre) => ({ ...pre, extendedLicense: extValue }));
      setOrderAsset((pre) => ({
        ...pre,
        price:
          userAssetLicenses.filter((x) => x.standardLicenseId === stdValue.id).length > 0
            ? extValue.price
            : stdValue.price + extValue.price,
        isHasLicense: userAssetLicenses.filter((x) => x.standardLicenseId === stdValue.id).length > 0,
      }));

      if (userAssetLicenses.filter((x) => x.standardLicenseId === stdValue.id).length > 0) {
        setTotalPrice(extValue.price);
      } else {
        setTotalPrice(stdValue.price + extValue.price);
      }
      setIsShowContact(extValue.code === ExtendedLicense.EX3PLUS);
    }
  };

  const onChangeStdLicense = ({ target: { value } }: RadioChangeEvent) => {
    const stdLicense = standardLicense?.filter(e => e.id === value)[0];

    if(stdLicense){
      setStdValue(stdLicense);
      checkLicense(value, extValue?.id!);
    }
  };

  const onChangeExtLicense = ({ target: { value } }: RadioChangeEvent) => {
    const extLicense = extendedLicense?.filter(e => e.id === value)[0];

    if(extLicense){
      setExtValue(extLicense);
    }
  };

  const canDownloadComp = () => {
    const hasLicense = userAssetLicenses.some(ual => !ual.extendedLicenses.some(e => e.code === ExtendedLicense.VIP));
    return isVip && !isExclusive && !asset?.isDisabled && !hasLicense && userProfile.isCanDownloadComp;
  };

  const canDownloadLicense = () => {
    const is3YPlus = extValue?.code === ExtendedLicense.EX3PLUS;
    const hasLicenseData = userAssetLicenses.some(x => x.standardLicenseId === stdValue?.id && x.extendedLicenses.some(ex => ex.id === extValue?.id));

    return userAssetLicenseId.length > 0 && !is3YPlus && hasLicenseData;
  };

  const onGetAssetPublishAsync = async () => {
    const { data: responseData, status: responseStatus } = await new AssetService().getAssetPublishAsync(id!);

    if (responseStatus !== HttpStatus.OK) {
      setIsNotFound(true);
      return;
    }

    onSetAssetToOrder(responseData);
    setAsset(responseData);
    onGetUserAssetLicenseAsync();
  };

  const onGetUserAssetLicenseAsync = async () => {
    const { data: responseData, status: responseStatus } = await new UserAssetLicenseService().getByAssetIdAsync(id!);

    if (responseStatus !== HttpStatus.OK) {
      setDefaultSelectLicense();
      return;
    }

    setUserAssetLicenses(responseData);
    if(responseData){
      setDefaultSelectLicense(responseData);
    }
  };

  const setDefaultSelectLicense = (datas?: UserAssetLicenseResponse[]) => {
    if(datas && datas.length > 0){
      const largeLicenses = datas.filter(x => (x.standardLicense.code === StandardLicense.XL || x.standardLicense.code === StandardLicense.UHD));
      const smallLicenses = datas.filter(x => (x.standardLicense.code === StandardLicense.SM || x.standardLicense.code === StandardLicense.HD));
      if (largeLicenses && largeLicenses.length > 0) {
        const maxExt = getMaxExtendedLicense(largeLicenses);
        setStdValue(maxExt!.standardLicense);
        setExtValue(maxExt!.extendedLicenses[0]);
        setUserAssetLicenseId(maxExt!.standardLicenseId);
        return;
      }

      if (smallLicenses && smallLicenses.length > 0) {
        const maxExt = getMaxExtendedLicense(smallLicenses);
        setStdValue(maxExt!.standardLicense);
        setExtValue(maxExt!.extendedLicenses[0]);
        setUserAssetLicenseId(maxExt!.standardLicenseId);
        return;
      }
    }
    else{
      const stdLarge = standardLicense?.filter(std => (std.code === StandardLicense.XL || std.code === StandardLicense.UHD) && std.sequence === 2)[0];
      if(stdLarge){
        setStdValue(stdLarge);
      }
      else{
        setStdValue(standardLicense?.filter(std => std.assetType === asset?.assetType)[0]);
      }
      setExtValue(extendedLicense?.filter((x) => x.assetType === asset?.assetType)[0]);
    }
  }

  const getMaxExtendedLicense = (userAssetLicenseResponses: UserAssetLicenseResponse[]) => {
    if (!userAssetLicenseResponses || userAssetLicenseResponses.length === 0) {
      return null;
    }

    let maxValue = userAssetLicenseResponses[0];

    for (const license of userAssetLicenseResponses) {
      if (license.extendedLicenses[0].sequence > maxValue.extendedLicenses[0].sequence) {
        maxValue = license;
      }
    }

    return maxValue;
  }

  const onGetArtistByUserIdAsync = async (userId: string) => {
    const { data: responseData, status: responseStatus } = await new AccountService().getArtistById(userId);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setArtist(responseData);

    if (responseData) {
      onCheckFollowArtistAsync();
      if (userProfile && userProfile.userId) {
        setIsSameAccount(userProfile.userId === responseData.artist.id);
      }
    }
  };

  const onFollowArtistAsync = async (id: string) => {
    const followCreate = {
      artistId: id,
    } as FollowCreateModel;
    const { status: responseStatus } = await new AccountService().followByArtistId(followCreate);

    if (responseStatus !== HttpStatus.CREATED) {
      messageApi.open({
        type: 'error',
        content: 'Please login to use this feature.',
      });
      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Follow success.',
    });
    onCheckFollowArtistAsync();
  };

  const onUnfollowArtistAsync = async (id: string) => {
    const { status: responseStatus } = await new AccountService().unfollowByArtistId(id);

    if (responseStatus !== HttpStatus.NO_CONTENT) {
      messageApi.open({
        type: 'error',
        content: 'Unfollow failed.',
      });
      return;
    }

    messageApi.open({
      type: 'success',
      content: 'UnFollow success.',
    });

    onCheckFollowArtistAsync();
  };

  const onCheckFollowArtistAsync = async () => {
    const { data: responseData, status: responseStatus } = await new AccountService().getFollower(asset?.userId!);

    if (responseStatus !== HttpStatus.OK) {
      setIsFollow(false);
      return;
    }

    setIsFollow(responseData.artistId === asset?.userId!);
  };

  const checkLicense = (stdId: string, extId: string) => {
    setHasUserLicense(false);

    if (userAssetLicenses) {
      const userStdLicenses = userAssetLicenses.filter((x) => x.standardLicenseId === stdId);
      const extLicense = extendedLicense?.filter(
        (x) =>
          x.id === extId &&
          x.assetType === asset?.assetType &&
          (x.code === ExtendedLicense.NONE || x.code === ExtendedLicense.VIP)
      )[0];
      const extLicenseMPFR = extendedLicense?.filter(
        (x) => x.id === extId && x.assetType === asset?.assetType && x.code === ExtendedLicense.MPFR
      )[0];
      if (userStdLicenses.length > 0) {
        setUserAssetLicenseId(userStdLicenses[0].id);

        //Check None Extended Licenses.
        if (
          extLicense ||
          (extLicenseMPFR &&
            userStdLicenses.filter((x) => x.extendedLicenses.filter((e) => e.id === extLicenseMPFR.id).length > 0)
              .length > 0)
        ) {
          setHasUserLicense(true);
          return;
        }
      } else {
        setUserAssetLicenseId('');
      }
    }

    setOrderAsset((pre) => ({
      ...pre,
      isHasLicense: userAssetLicenses.filter((x) => x.standardLicenseId === stdId).length > 0,
    }));
    calculateTotalPrice();
  };

  const onSetAssetToOrder = (data: AssetPublishModel) => {
    let orderAsset: AssetPublishModel;
    orderAsset = {
      id: data.id,
      assetType: data.assetType,
      code: data.code,
      name: data.name,
      description: data.description,
      thumbnailImageUrl: data.thumbnailImageUrl,
      thumbnailVideoUrl: data.thumbnailVideoUrl,
      thumbnailVideoCoverUrl: data.thumbnailVideoCoverUrl,
      hasSMLicense: data.hasSMLicense,
      hasXLLicense: data.hasXLLicense,
      hasHDLicense: data.hasHDLicense,
      hasUHDLicense: data.hasUHDLicense,
      publishDate: data.publishDate,
      hasCollection: data.hasCollection,
      keywords: data.keywords,
      userId: data.userId,
      standardLicense: data.standardLicense,
      extendedLicense: data.extendedLicense,
      price: data.price,
      isDisabled: data.isDisabled,
      isHasLicense: hasUserLicense,
      imageOrientation: data.imageOrientation,
      usage: data.usage,
    };

    setOrderAsset(orderAsset);
  };

  const onGetLicenseAsync = async () => {
    const { data: responseStdData, status: responseStdStatus } = await new LicenseService().GetStandardLicenseByType(
      asset?.assetType!
    );

    if (responseStdStatus !== HttpStatus.OK) {
      return;
    }

    const { data: responseExtData, status: responseExtStatus } = await new LicenseService().GetExtendedLicenseByType(
      asset?.assetType!
    );

    if (responseExtStatus !== HttpStatus.OK) {
      return;
    }

    setStandardLicense(responseStdData);
    if (asset?.assetType === AssetType.IMAGE) {
      setStandardLicenseVip(responseStdData.filter((x) => x.code === StandardLicense.SM)[0]);
      if (!asset.hasSMLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.SM));
      }

      if (!asset.hasXLLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.XL));
      } 

    } else {
      setStandardLicenseVip(responseStdData.filter((x) => x.code === StandardLicense.HD)[0]);

      if (!asset?.hasHDLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.HD));
      }

      if (!asset?.hasUHDLicense) {
        setStandardLicense(responseStdData.filter((x) => x.code !== StandardLicense.UHD));
      }
    }
    //TODO:filter not vip
    setExtendedLicense(responseExtData.filter((x) => x.code !== ExtendedLicense.VIP));
    setExtendedLicenseVip(responseExtData.filter((x) => x.code === ExtendedLicense.VIP)[0]);

    checkLicense(
      responseStdData.filter((x) => x.assetType === asset?.assetType)[0].id,
      responseExtData.filter((x) => x.assetType === asset?.assetType)[0].id
    );
  };

  const onGetCollectionByAssetAsync = async () => {
    const { data: responseData, status: responseStatus } = await new CollectionService().GetCollectionByAssetPath(id!);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    console.log('onGetCollectionByAssetAsync', responseData)

    setCollections(responseData);
  };

  const onGetFavoriteByAssetAsync = async () => {
    const { data: responseData, status: responseStatus } = await new FavoriteService().getFavoriteByAssetIdAsync(id!);

    if (responseStatus !== HttpStatus.OK) {
      setFavorite({} as FavoriteResponseModel);

      return;
    }

    setFavorite(responseData);
  };

  const onGetReportAssetByAssetAsync = async () => {
    const { status: responseStatus } = await new ReportAssetService().getReportAssetByAssetIdAsync(
      id!
    );

    if (responseStatus !== HttpStatus.OK) {
      setIsNotReport(false);
      return;
    }

    setIsNotReport(true);
  };

  const onCheckExclusiveAsync = async () => {
    const { data: responseData, status: responseStatus } =
      await new UserAssetLicenseService().getCalculateExclusiveAsync(id!);

    if (responseStatus !== HttpStatus.OK) {
      setIsExclusive(false);
      return;
    }

    const exclusiveData = responseData as ExclusiveLicenseModel;
    if (exclusiveData && exclusiveData.applicationUser) {
      const exclusiveDate = new Date(exclusiveData.extendedLicenseExpireDate);
      const nowDate = new Date();
      if (exclusiveData.exclusive > 0 && nowDate <= exclusiveDate) {
        setIsExclusive(true);

        if (userProfile && userProfile.userId === exclusiveData.applicationUser.id) {
          setIsExclusive(false);
        }
      }
    }
  };

  const onAddCart = () => {
    enterLoading();
    let orders = getOrderAssets();

    if (!orders) {
      orders = [];
    }
    //Check dubplicate
    const dubIndex = orders.findIndex((order) => order.id === orderAsset.id);

    const isExtendedLicense = orderAsset.extendedLicense.code !== ExtendedLicense.NONE;

    //Check Nolicense
    const nolicenseIndex = orders.findIndex(
      (order) =>
        order.id === orderAsset.id &&
        order.standardLicense.id === orderAsset.standardLicense.id &&
        order.extendedLicense.code === ExtendedLicense.NONE
    );

    //Check has Extendedlicense
    const hasExtlicense =
      orders.filter(
        (order) =>
          order.id === orderAsset.id &&
          order.standardLicense.id === orderAsset.standardLicense.id &&
          order.extendedLicense.code !== ExtendedLicense.NONE
      ).length > 0;

    //Check has add ExtendedLicense but not noneExtenededlicense.
    if (isExtendedLicense && nolicenseIndex > -1) {
      orders.splice(nolicenseIndex, 1);
    }

    //Check is select none but has extendedlicense or duplicate
    if ((!isExtendedLicense && hasExtlicense) || dubIndex > -1) {
      orders.splice(dubIndex, 1);
    }

    orders.push(orderAsset);
    updateOrderAssets(orders);
    messageApi.open({
      type: 'success',
      content: 'Add to cart success.',
    });
    getCountOrder();
  };

  const onDownload = async () => {
    setDownloadLoadings(true);

    if (userAssetLicenseId && userAssetLicenseId.length > 0) {
      const { data: responseData, status: responseStatus } = await new UserAssetLicenseService().donwloadByIdAsync(
        userAssetLicenseId!
      );

      if (responseStatus !== HttpStatus.OK) {
        setDownloadLoadings(false);
        return;
      }

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(responseData);
      downloadLink.download = asset?.assetType === AssetType.IMAGE
                              ? `${DownloadFileName.Prefix}${asset!.code}${BlobFileExtension.Image}`
                              : `${DownloadFileName.Prefix}${asset!.code}${BlobFileExtension.Video}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    setDownloadLoadings(false);
  };

  const onDownloadVip = async () => {
    setDownloadVipLoadings(true);

    const { data: responseData, status: responseStatus } = await new UserAssetLicenseService().downloadVipByIdAsync(
      asset?.id!,
      stdValue!.id,
      extendedLicenseVip.id
    );

    if (responseStatus !== HttpStatus.OK) {
      setDownloadVipLoadings(false);
      messageApi.open({
        type: 'error',
        content: 'download not found.',
      });
      return;
    }

    const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(responseData);
      downloadLink.download = asset?.assetType === AssetType.IMAGE
                              ? `${DownloadFileName.Prefix}${asset!.code}${BlobFileExtension.Image}`
                              : `${DownloadFileName.Prefix}${asset!.code}${BlobFileExtension.Video}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    setDownloadVipLoadings(false);

    messageApi.open({
      type: 'success',
      content: 'download success.',
    });
  };

  const enterLoading = () => {
    setCartLoadings(true);

    setTimeout(() => {
      setCartLoadings(false);
    }, 2000);
  };

  const onClickFavorite = () => {
    if(userProfile && userProfile.userId){
      setIsOpenFavorite(true);
    } else{
      messageApi.open({
        type: 'error',
        content: 'Please login to use this feature.',
      });
    }
  };

  const onClickToArtist = () => {
    navigate(`/artist/${artist.userId}`)
  };

  const onClickShare = () => {
    setIsOpenShare(true);
  };

  const onClickReport = () => {
    setIsOpenReport(true);
  };

  const onTryDownload = async () => {
    const { 
      data: responseData,
      status: responseStatus 
    } = await new UserAssetLicenseService().tryDownloadAsync(asset?.id!);

    if (responseStatus !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'download not found.',
      });
      return;
    }

    const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(responseData);
      downloadLink.download =
        asset?.assetType === AssetType.IMAGE
          ? `${DownloadFileName.Prefix}${asset!.code}${BlobFileExtension.Image}`
          : `${DownloadFileName.Prefix}${asset!.code}${BlobFileExtension.Video}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  };

  if (isNotFound || isExclusive) {
    return (
      <Row
        className='pt-5'
        justify='center'
      >
        <Col span={24}>
          <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            className='result-error'
            extra={[
              <a
                href='/'
                className='btn-orange-round'
                style={{ textDecoration: 'none' }}
              >
                Go to home
              </a>,
            ]}
          />
        </Col>
      </Row>
    );
  }

  const seeMoreKeywords = () => {
    setSeeMore(!seeMore);
  }
  
  return (
    <>
    <HelmetComponent
      title={`${PageDocument.Title} - ${asset ? asset.code + ' ' + asset.name : ''} `} 
      description={asset?.description}
      imageUrl = {thumbnailImageUrl} />
    <div className='price banner-margin-t-70'>
      
      {contextHolder}
      <div className='container'>
        <Row
          gutter={16}
          className='mt-2'
        >
          <Col
            className='gutter-row'
            span={24}
            xl={15}
          >
            <div className='img-info banner-margin-t-70'>
              {asset ? (
                asset?.assetType === AssetType.IMAGE ? (
                  <img
                    onClick={handleShow}
                    src={asset?.thumbnailImageUrl}
                    className='product-img'
                    alt={asset.name}
                  />
                ) : (
                  <VideoControl
                    src={asset?.thumbnailVideoUrl}
                    muted={true}
                    loop={true}
                    className='product-vdo'
                    controls={true}
                  />
                )
              ) : (
                <Skeleton.Image active />
              )}
              <Space className='but-img-zoom'>
                <ZoomInOutlined onClick={handleShow} />
                <Tooltip
                  title={
                    <span onClick={onClickToArtist}>
                      {
                        artist.artist &&
                        artist.artist.nameInfoEn.firstname + " " + artist.artist.nameInfoEn.lastname
                      }
                    </span>
                  }>
                </Tooltip>
              </Space>
            </div>
            <Row
              align='middle'
              className='mt-3'
            >
              {artist.artist ? (
                <div className='info-profile'>
                  <div className='info-name d-flex align-items-center'>
                    {artist.avatarUrl ? (
                      <Link
                        to={`/artist/${artist.userId}`}
                        className='text-light'
                      >
                        <Avatar
                          size='large'
                          src={
                            <img
                              src={artist.avatarUrl}
                              alt={artist.artist.nameInfoEn?.firstname + ' ' + artist.artist.nameInfoEn?.lastname}
                            />
                          }
                        />
                      </Link>
                    ) : (
                      <Link
                        to={`/artist/${artist.userId}`}
                        className='text-light'
                      >
                        <Avatar
                          size='large'
                          icon={<UserOutlined />}
                          alt='artist'
                          style={{ backgroundColor: 'rgb(255, 85, 1)' }}
                        />
                      </Link>
                    )}
                    <Title
                      level={4}
                      className='text-light my-0 me-3 ms-3'
                    >
                      <Link
                        to={`/artist/${artist.userId}`}
                        className='text-light'
                      >
                        {
                          artist.artist.nameInfoEn &&
                          artist.artist.nameInfoEn?.firstname + ' ' + artist.artist.nameInfoEn?.lastname
                        }
                      </Link>
                    </Title>
                  </div>
                  <div className='info-social'>
                    {isFollow ? (
                      <Button
                        type='primary'
                        shape='round'
                        onClick={() => onUnfollowArtistAsync(artist.artist.id)}
                        className={`btn-outline-border active`}
                        hidden={isSameAccount}
                        size='large'
                      >
                        {TranslationKey('unFollow')}
                      </Button>
                    ) : (
                      <Button
                        type='primary'
                        shape='round'
                        onClick={() => onFollowArtistAsync(artist.artist.id)}
                        className={`btn-outline-border`}
                        hidden={isSameAccount}
                        size='large'
                      >
                        {TranslationKey('follow')}
                      </Button>
                    )}
                    <Space
                      wrap
                      className='ms-2'
                    >
                      {favorite.id ? (
                        <Button
                          block
                          type='primary'
                          shape='circle'
                          size='large'
                          icon={<HeartOutlined />}
                          className='btn-primary icon-fix'
                          onClick={onClickFavorite}
                        />
                      ) : (
                        <Button
                          type='primary'
                          shape='circle'
                          size='large'
                          icon={<HeartOutlined />}
                          className='btn-outline icon-fix'
                          onClick={onClickFavorite}
                        />
                      )}
                      <Button
                        type='primary'
                        shape='circle'
                        size='large'
                        icon={<DownloadOutlined />}
                        className='btn-outline icon-fix'
                        onClick={onTryDownload}
                      />
                      <Button
                        type='primary'
                        shape='circle'
                        size='large'
                        icon={<ShareAltOutlined />}
                        className='btn-outline icon-fix'
                        onClick={onClickShare}
                      />
                      {userProfile && userProfile.userId && !isNotReport && (
                        <Button
                          type='primary'
                          shape='circle'
                          size='large'
                          icon={<InfoCircleOutlined />}
                          className='btn-outline icon-fix'
                          onClick={onClickReport}
                        />
                      )}
                    </Space>
                  </div>
                </div>
              ) : (
                <Skeleton active />
              )}
            </Row>
            <Row className='mt-3'>
              <Col>
                <span className='fw-bold text-primary me-2'>{TranslationKey('usage')} </span>
                <Tag
                  className='tag-outline'
                >
                  {asset?.usage === AssetUsage.AiGenerated ? 'AI Generated' : asset?.usage}
                </Tag>
              </Col>
            </Row>
            <Row
              align='middle'
              className='mt-3'
            >
              <Col>{asset && <>{asset.description}</>}</Col>
            </Row>
            <Row
              align='middle'
              className='mt-3'
            >
              <Col>
                {
                  asset?.usage === AssetUsage.Editorial && 
                  <div className='box-warning'>
                    <span><WarningOutlined style={{ paddingRight: '0.5rem' }} />For editorial use only. Can only be use once in context.</span>
                    <span>If you are interested in using editorial content for commercial purposes, please contact us at: support@aliveunited.com</span>
                  </div>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                <Flex className='mt-4' wrap="wrap" gap="small">
                  <p className='fw-bold text-primary mb-0 me-3'>{TranslationKey('keywords')} </p>
                  {seeMore ?
                    tags.sort((a, b) => (a < b ? -1 : 1)).map((tag, index) => {
                      return <Tag
                        key={index}
                        className='tag-outline pointer'
                        onClick={() => navigate(`${RouteNames.search}?q=${tag}`)}
                      >
                        {tag}
                      </Tag>
                    })
                    :
                    tags.sort((a, b) => (a < b ? -1 : 1)).map((tag, index) => {
                      return (
                        <>
                          {index < 3 ?
                            <Tag
                              key={index}
                              className='tag-outline pointer'
                              onClick={() => navigate(`${RouteNames.search}?q=${tag}`)}
                            >
                              {tag}
                            </Tag>
                            :
                            StringConstants.EMPTY
                          }
                        </>
                      );
                    })
                  }
                  <Button
                    type="text"
                    className='text-white'
                    icon={!seeMore ? <PlusOutlined /> : <MinusOutlined />}
                    size='small'
                    onClick={seeMoreKeywords}>
                      {!seeMore ? TranslationKey('assetDetail.showMore') : TranslationKey('productDetail.showLess')}
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col
            className='gutter-row product'
            span={24}
            xl={9}
          >
            {asset ? (
              <>
                <Title
                  level={5}
                  className='text-gray mt-4'
                >
                  {asset?.code}
                  {asset?.name && <> - {asset?.name}</>}
                </Title>
                <Title
                  level={5}
                  className='text-light mt-4 mb-0'
                >
                  {TranslationKey('assetDetail.totalPrice')}
                </Title>
                <Title
                  level={1}
                  className='text-light mt-2'
                >
                  {totalPrice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  <Text className='text-light'> THB (Excluding VAT)</Text>
                </Title>
                <Title
                  level={5}
                  className='text-light mt-4 mb-0'>
                  {TranslationKey('assetDetail.selectLicense')}
                  <Tooltip
                    title={
                      <div>
                        <div>
                          Use this license for unlimited copies, viewers, and worldwide, in perpetuity.</div>
                        <br />
                        <div>
                          This includes marketing materials, packaging of products, presentations, catalogues and e-catalogues, books and e-books, magazines and online magazines, newspapers and online newspaper, websites, mobile applications, albums and online albums, playlist covers, video games, and social media platforms.</div>
                      </div>
                    }>
                    <InfoCircleOutlined style={{paddingLeft: '0.5rem'}} />
                  </Tooltip>
                </Title>
                <Radio.Group
                  onChange={onChangeStdLicense}
                  value={stdValue?.id}
                  optionType='button'
                  className='mt-3'
                  size='large'
                >
                  {standardLicense?.map((s, index) => (
                    <Radio
                      value={s.id}
                      key={index}
                    >
                      <p className='fs-md'>{s.name}</p>
                      <p className='fs-sm'>
                        {StringConstants.BAHT +
                          ' ' +
                          s.price.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                      </p>
                    </Radio>
                  ))}
                </Radio.Group>
                <Title level={5}
                  className='text-light mt-4 mb-0'>
                    {TranslationKey('assetDetail.selectExtendedLicense')}
                  <Tooltip
                    title={
                      <div>
                        <div style={{fontWeight: 'bold'}}>Merchandise Resale/Distribution</div>
                        <br />
                        <div>Use this license for the product for sale or promotional distribution license package which allows incorporation of the Arts and Contents or its derivatives into products e.g. calendars, greeting cards, keyrings, mugs, postcards, templates, textiles, toys, wall art, and any other physical reproduction for resale or distribution whether for free or with remuneration.</div>
                        <br />
                        <br />
                        <div style={{fontWeight: 'bold'}}>Exclusive Market Freeze 6 Months to 3 Years</div>
                        <br />
                        <div>This guarantees exclusivity for a specified length of time from the date of the Arts and Contents purchase and includes free unlimited copies, viewers, and worldwide, in perpetuity. An Exclusive Market Freeze license is not refundable and is only offered as an add-on to the X-Large Standard License.</div>
                      </div>
                    }>
                    <InfoCircleOutlined style={{paddingLeft: '0.5rem'}} />
                  </Tooltip>
                </Title>
                
                <div className='bg-outline-gray boxradius mt-2'>
                  <Radio.Group
                    onChange={onChangeExtLicense}
                    value={extValue?.id}
                    className='custom-color-radio'
                    size='large'
                  >
                    <Space
                      direction='vertical'
                      size={'middle'}
                    >
                      {extendedLicense
                        ?.filter((x) => x.assetType === asset?.assetType)
                        .map((e, index) => (
                          <Radio
                            value={e.id}
                            className='text-gray'
                            key={index}
                          >
                            +
                            <span className='text-light mx-1 fw-bold'>
                              {e.price > 0
                                ? StringConstants.BAHT +
                                ' ' +
                                e.price.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })
                                : ''}
                            </span>
                            {e.price > 0 && e.name !== 'Merchandise Product for Resale' 
                            ? <Tooltip
                              title="This guarantees exclusivity for a specified length of time from the date of the Arts and Contents purchase and includes free unlimited copies, viewers, and worldwide, in perpetuity. An Exclusive Market Freeze license is not refundable and is only offered as an add-on to the X-Large Standard License.
                                  ">
                                  {e.name}
                            </Tooltip>: <>{e.name}</>}
                          </Radio>
                        ))}
                    </Space>
                  </Radio.Group>
                </div>
                <Row
                  gutter={[8, 8]}
                  className='mt-3'
                >
                  {isShowContact ? (
                    <Col span={canDownloadComp() ? 18 : 24}>
                      <Link
                        to={RouteNames.contactus}
                        state={{
                          message: `Market Freeze 3 Year+ , asset code:${asset.code}, link:${window.location.href}`,
                        }}
                      >
                        <Button
                          type='primary'
                          shape='round'
                          className='btn-primary'
                          size='large'
                          block
                        >
                          Contact Us
                        </Button>
                      </Link>
                    </Col>
                  ) : (
                    <>
                      {!asset.isDisabled && !hasUserLicense && !isExclusive && (
                        <>
                          <Col span={canDownloadComp() ? 9 : 12}>
                            <Button
                              type='primary'
                              shape='round'
                              className='btn-outline-border'
                              size='large'
                              onClick={onAddCart}
                              block
                              loading={cartLoadings}
                              hidden={isSameAccount}
                            >
                              Add to Cart
                            </Button>
                          </Col>
                          <Col span={canDownloadComp() ? 9 : 12}>
                            <Link
                              to={{ pathname: '/order' }}
                              state={{
                                assetId: orderAsset?.id,
                                standardLicenseId: orderAsset?.standardLicense?.id,
                                extendedLicenseId: orderAsset?.extendedLicense?.id,
                              }}
                            >
                              <Button
                                type='primary'
                                shape='round'
                                className='btn-primary'
                                size='large'
                                block
                                hidden={isSameAccount}
                              >
                                Buy Now
                              </Button>
                            </Link>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                  {canDownloadComp() && (
                    <Col span={6}>
                      <Button
                        type='primary'
                        shape='round'
                        className='btn-light'
                        size='large'
                        onClick={onDownloadVip}
                        loading={downloadVipLoadings}
                        block
                      >
                        COMP
                        <DownloadOutlined className='icon-fix' />
                      </Button>
                    </Col>
                  )}
                </Row>
                {canDownloadLicense() && (
                  <Row className='mt-3'>
                    <Col span={24}>
                      <Button
                        type='primary'
                        shape='round'
                        className='btn-primary'
                        size='large'
                        onClick={onDownload}
                        loading={downloadLoadings}
                        block
                      >
                        Download
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <Skeleton
                  active
                  paragraph={{ rows: 6 }}
                />
              </>
            )}
          </Col>
        </Row>
        {collections.length > 0 && (
          <>
            <div className='mt-5'>
              <h1 className='text-light collection'>In this Collection</h1>
              <GallerysCollection  
              isUseContainerImage = {true} 
              collectionAssetModel={collections[0]} />
            </div>
          </>
        )}
        
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName='img-modal'
        centered
      >
        <Modal.Body>
          <CloseOutlined
            className='button-close'
            onClick={() => setShow(!show)}
            style={{ fontSize: '2em' }}
          />
          {
            asset?.assetType === AssetType.IMAGE
            ?
            <img src={asset?.thumbnailImageUrl} alt={asset?.description}/>
            :
            <video
              muted
              loop
              className='product-vdo'
              controls
            >
              <source
                src={asset?.thumbnailVideoUrl}
                type='video/mp4'
              />
              <source
                src={asset?.thumbnailVideoUrl}
                type='video/webm'
              />
              Your browser is not supported!
            </video>
            }
        </Modal.Body>
      </Modal>
      <MyFavoriteModal
        isFavoriteModalOpen={isOpenFavorite}
        assetId={orderAsset.id!}
        onCloseFavoriteModal={handleCloseFav}
      />
      <ShareModal
        isModalOpen={isOpenShare}
        sharedUrl={window.location.href}
        onClose={handleCloseShare}
      />
      <ReportAssetModal
        isModalOpen={isOpenReport}
        onClose={handleCloseReport}
        assetId={asset?.id!}
      />
    </div>
    <div className='mt-5'>
          <h1 className='text-light'>
            {TranslationKey('assetDetail.moreLikeThis')}
          </h1>
          {
            relateKeywords.length > 0 && 
            <GallerysMoreLikeThis keywords={relateKeywords} />
          }
        </div>
    </>
  );
}
