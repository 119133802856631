import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'src/context/session-context';
import { SessionStatus } from 'src/constant/sessionStatus';
import { useEffect } from 'react';
import { RouteNames } from './routeName';

interface Props {
  children: JSX.Element;
  forceAuthentication?: boolean;
}

const GuardedRoute = ({ children, forceAuthentication = false }: Props) => {
  const { isAuthenticated, validateTokenExpire } = useSessionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === SessionStatus.UNAUTHENTICATED && !forceAuthentication) {
      navigate(RouteNames.login);
    }

    validateTokenExpire();
  }, [navigate, isAuthenticated, forceAuthentication]);

  if (forceAuthentication) {
    return children;
  }

  if (isAuthenticated === SessionStatus.AUTHENTICATED) {
    return children;
  }

  return null;
};

export default GuardedRoute;