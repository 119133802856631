import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import th from './thailand/th.json'
import en from './english/en.json'
import { LanguageConstants } from '../constant/language'
import classStore from '../store/classStore'


var language = classStore.get(LanguageConstants.key) as string;

i18next
    .use(XHR)
    .use(initReactI18next).init({
    lng: language ?? LanguageConstants.en, 
    resources: {
      en: {
        translation: en,
    },
      th: {
        translation: th,
    },
    },
  });