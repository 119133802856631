import { RouteNames } from '../../../src/routes/routeName';
import { Col, Result, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { AccountService } from '../../../src/services/accountService';
import { useEffect, useState } from 'react';
import { HttpStatus } from '../../../src/constant/responseStatus';
import TranslationKey from 'src/i18n/translation';

export default function ConfirmEmail() {
    const accountService = new AccountService();

    const [isVerify, setIsVerify] = useState(false);

    const [searchParams] = useSearchParams();

    const confirmEmailAsync = async (userId: string, code: string) => {
        const response = await accountService.confirmEmailAsync(userId, code);
        
        if (response.status !== HttpStatus.OK) {
            setIsVerify(false);
            return;
        } else if (response.status === HttpStatus.OK) {
            setIsVerify(true);
            return;
        }
    };

    useEffect(() => {
        const userId = searchParams.get('userId');
        const code = searchParams.get('code');
        confirmEmailAsync(userId!, code!);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row className='mt-4 mb-4'>
            <Col span={16} offset={4}>
                {isVerify ? (
                    <Result
                        status='success'
                        title={TranslationKey('confirmEmail.successfully')}
                        subTitle={TranslationKey('confirmEmail.welcome')}
                        className='result-order'
                        extra={[
                            <a href={RouteNames.login} className='btn-orange-round' style={{ textDecoration: 'none' }}>
                                {TranslationKey('signIn')}
                                
                            </a>,
                        ]}
                    />
                ) : (
                    <Result
                        status='error'
                        title={TranslationKey('confirmEmail.activateAccountFailed')}
                        subTitle={TranslationKey('confirmEmail.something')}
                        className='result-order'
                        extra={[
                            <a
                                href={RouteNames.default}
                                className='btn-orange-round'
                                style={{ textDecoration: 'none' }}
                            >
                                title={TranslationKey('hHome')}
                            </a>,
                        ]}
                    />
                )}
            </Col>
        </Row>
    );
}
