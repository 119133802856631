import { HttpStatus } from '../../constant/responseStatus';
import { useEffect, useState } from 'react';
import { SearchRequestModel } from 'src/models/assetModel';
import { Skeleton } from 'antd';
import { AssetService } from 'src/services/assetService';
import assetSearchModel from 'src/models/responses/assetSearchModel';
import GallerySearch from '../gallerySearch/GallerySearch';
import TranslationKey from 'src/i18n/translation';

interface TypeInterface {
  keywords: string[] | undefined;
}

export default function GallerysMoreLikeThis({ keywords }: TypeInterface) {
  const [assets, setAssets] = useState<assetSearchModel[]>([]);
  const [activeTrending, setActiveTrending] = useState(false);
  const [params, setParams] = useState('');

  useEffect(() => {
    onGetRandomAssetAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (keywords) {
      setParams(keywords.toString());
    }
  }, [keywords]);

  const onGetRandomAssetAsync = async () => {
    const request: SearchRequestModel = {
      search: undefined,
      keywords: keywords ? keywords : undefined,
      colors: undefined,
      artist: undefined,
      orientation: undefined,
      assetType: undefined,
      assetUsage: undefined,
    };
    
    const {
      data: responseData,
      status: responseStatus 
    } = await new AssetService().getSimilarAssets(request, 1, 15);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setActiveTrending(true);
    setAssets(responseData.data);
  };

  return (
    <div className='box-gallery'>
      <div className='gallery'>
        {activeTrending
        ? 
        (
          <GallerySearch searchModel={assets} />
        )
        :
        (
          Array.from({ length: 25 })
          .map((_, index) => (
            <div
              className='gallery-item'
              key={'more-like-this-asset-item-' + index}>
              <Skeleton.Image active />
            </div>
          ))
        )}
        <div className='box-gradient' />
        <div className='box-gradient-end' />
      </div>
      <div className='view-all'>
        {params.length > 0 ? (
          <a
            className='text-light'
            href={`/search?k=${params}`}
          >
            {TranslationKey('home.viewAll')}
          </a>
        ) : (
          <a
            className='text-light'
            href='/search'
          >
            {TranslationKey('home.viewAll')}
          </a>
        )}
      </div>
    </div>
  );
}
