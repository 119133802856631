import { Typography, Card } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { RouteNames } from "src/routes/routeName";
const { Title, Text } = Typography;

interface Props {
  head?: string;
  title?: string;
  price?: string;
  contact: boolean;
}

function CardAdditionalLicenses(props: Props) {
  return (
    <Card className="card-licensing-additional bg-outline-gray">
      <div>
        <div className="flex-center mb-1">
          <PlusOutlined />
        </div>
        <div className="text-center">
          <Text className="text-gray">{props.head}</Text><br />
          <Text className="text-light">{props.title}</Text>
        </div>
      </div>
      {props.contact ?
        <Link 
          to={RouteNames.contactus}
          state={{
          message: props.head,
        }} className="btn btn-primary" style={{borderRadius: 40}}>
          Contact Us
        </Link>
        :
        <Title className="text-light text-center my-2" level={4}>
          <sup className="text-light fs-xs">฿</sup>{props.price}
        </Title>
      }
    </Card>
  )
}

export default CardAdditionalLicenses;