import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { CreateCardModel } from '../models/OrderModel';
import { UserCreditCardModel } from '../models/PaymentModel';

export class PaymentService {
    private paymentPath = '/Payment';

    createCreditCard(data: CreateCardModel): Promise<AxiosResponse> {
        return Axios.post(`${this.paymentPath}/credit-card`, JSON.stringify(data));
    }

    getCreditCards(): Promise<AxiosResponse<UserCreditCardModel>> {
        return Axios.get<UserCreditCardModel>(`${this.paymentPath}/credit-card`);
    }

    deleteCreditCards(creditCardId: string): Promise<AxiosResponse> {
        return Axios.delete(`${this.paymentPath}/credit-card?creditCardId=${creditCardId}`);
    }
}