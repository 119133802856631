import { Col, Row, Typography, Card, Button, Avatar } from 'antd';
import Meta from 'antd/es/card/Meta';
import { AccountService } from 'src/services/accountService';
import { HttpStatus } from 'src/constant/responseStatus';
import IUserFollowResponse from 'src/models/responses/userFollowResponse';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { RouteNames } from 'src/routes/routeName';
import GalleryItem from '../galleryItem/GalleryItem';

const { Title } = Typography;

interface CardInterface {
  artist: IUserFollowResponse;
  unfollowAction: (artistId: string) => void;
}

export default function CardArtistFollow({ artist, unfollowAction }: CardInterface) {
  const navigate = useNavigate();

  const unFollowAsync = async (artistId: string) => {
    const response = await new AccountService().deletedUserFollowAsync(artistId);

    if (response.status !== HttpStatus.NO_CONTENT) {
      return;
    }

    unfollowAction(artistId);
  };
  
    const onClickItem = (assetId: string) => {
    };

  return (
    <>
      {artist && (
        <Card
          actions={[
            <Button type='link' onClick={() => navigate(`/artist/${artist.userId}`)} size='large'>Profile</Button>,
            <Button type='link' onClick={() => unFollowAsync(artist.artistId)} size='large'>
              Unfollow
            </Button>,
          ]}
        >
          <Row>
            <Col span={24} className='text-center'>
              {
                artist.userImageUrl ? (
                  <Avatar src={artist.userImageUrl} size={64} alt={artist.firstName + ' ' + artist.lastName} />
                ) : (
                  <Avatar
                    size={64}
                    icon={<UserOutlined />}
                    alt={artist.firstName + ' ' + artist.lastName}
                    style={{ backgroundColor: 'rgb(255, 85, 1)' }}
                  />
                )
              }
            </Col>
          </Row>
          <Meta
            className='mt-2 text-center'
            title={<Title level={5}>{artist.firstName + ' ' + artist.lastName}</Title>}
          />
          <Row justify={'center'} >
            <Col className='text-center  mt-2'>
             <Row gutter={[8, 8]} justify={'center'}>
              {
                artist.assetPublishDetailModels ? 
                artist.assetPublishDetailModels.map((asset, index) => {
                  return <>
                    <Col xs={24} sm = {8} md={8} lg={8}  xl = {8} className='px-2 py-2'>
                      <Link to={RouteNames.assetDetail.replace(':id', asset.id)} reloadDocument={true} >
                            <GalleryItem
                                          index={index}
                                          asset={asset}
                                          width={'100%'}
                                          height={'100px'}
                                          onClickItem={onClickItem}
                                          btnDelmarginTop={10}
                                          btnDelmarginRight={20}
                                      />
                        </Link>
                    </Col>
                  </>
                }) : 
                <></>
              }
              </Row>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}
