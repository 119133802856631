import { AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import { ContactUsModel } from '../models/contactUsModel';

export class ContactService {
    private _qs: any = require('qs');
    private contactPath = '/Contact';

    createContact(request: ContactUsModel): Promise<AxiosResponse> {
        return Axios.post(`${this.contactPath}`, JSON.stringify(request));
    }
}