export const enum omise {
  URL_OMISE = 'https://vault.omise.co',
  OMISE_PUBLIC_KEY = 'pkey_test_5td6t669uy860pkb1g5',
}

export const enum paymentType {
  CREDIT_DEBIT = 'CreditDebit',
  PROMPT_PAY_QR = 'PromptPayQr',
  FREE = 'Free',
}

export const enum propmtPayStatus {
  CREATED = 'Created',
  PAID = 'Completed',
  UNPAID = 'Error',
}

export const enum trackingStatus {
  CREATED = 'Created',
  PAID = 'Paid',
  UNPAID = 'Unpaid',
}

export const trackingCheckingLiftTime = 5000;

export const enum confirmReserveType {
  CREDIT_CARD = 'CreditCard',
  PROMPT_PAY = 'PromptPay',
}

export const omiseLimitAmount = 300000;
