import { BannerService } from '../../services/bannerService';
import { HttpStatus } from '../../constant/responseStatus';
import { useEffect, useState } from 'react';
import { AssetPublishModel } from 'src/models/assetModel';
import { AssetType } from '../../constant/assetConstants';
import { Skeleton } from 'antd';
import GallerySearchItem from '../galleryItem/GallerySearchItem';

interface TypeInterface {
  assetType: string;
  openTextAll?: boolean;
}

export default function GallerysTrending({ assetType, openTextAll = true }: TypeInterface) {
  const [assets, setAssets] = useState<AssetPublishModel[]>([]);
  const [activeTrending, setActiveTrending] = useState(false);

  useEffect(() => {
    if(assetType){
      onGetRandomAssetAsync(assetType);
    }
  }, [assetType]);

  const onGetRandomAssetAsync = async (assetType: string) => {
    setActiveTrending(false);

    const type =
      (assetType !== AssetType.IMAGE && assetType !== AssetType.VIDEO)
        ? undefined
        : assetType;
    
    const { 
      data: responseData,
      status: responseStatus 
    } = await new BannerService().GetRandomAsset(type);

    if (responseStatus !== HttpStatus.OK) {
      return;
    }

    setActiveTrending(true);
    setAssets(responseData.data);
  };


  return (
    <div className='box-gallery'>
      <div className='gallery'>
        {
          activeTrending
            ? assets.map((d, index) => (
              <>
              <GallerySearchItem
                    isUseContainerImage  = {true}
                    key={'gallery-search-item' + index}
                    asset={d}
                    index={index} />
                  { openTextAll? <div  className='view-all'>
                    <a className="text-light" href="/search">View all</a>
                  </div>:
                  null }
              </>
            ))
            : 
            Array
              .from({ length: 25 })
              .map((_, index) => 
              (
              <div
                className='gallery-item'
                key={'trending-asset-item2-' + index}>
                <Skeleton.Image 
                  key={'trending-asset-item2-skeleton-' + index}
                  active
                  style={{ height: '350px !important' }} />
              </div>
              ))
        }
        <div className='box-gradient' />
        <div className='box-gradient-end' />
      </div>
    </div>
  );
}
