import { Form, Input, Radio } from 'antd';
import { ScanOutlined } from '@ant-design/icons';
import { useState } from 'react';
import type { RadioChangeEvent } from 'antd';

const logoVisa = require('../../assets/images/visa.png');

const options = [
  { label: <img src={logoVisa} alt='' width={20} />, value: 'visa1' },
  { label: <img src={logoVisa} alt='' width={20} />, value: 'visa2' },
  { label: <img src={logoVisa} alt='' width={20} />, value: 'visa3' },
  { label: <img src={logoVisa} alt='' width={20} />, value: 'visa4' },
  { label: <img src={logoVisa} alt='' width={20} />, value: 'visa5' },
];

function InputCard() {
  const [value3, setValue3] = useState('visa1');
  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio3 checked', value);
    setValue3(value);
  };

  return (
    <>
      <Form.Item name='card'>
        <Input
          placeholder='1234 1234 1234 1234'
          className='input-radius-dark non-radius'
          prefix={<ScanOutlined />}
          suffix={<Radio.Group options={options} onChange={onChange3} value={value3} optionType="button" size='large' />}
        />
      </Form.Item>
    </>
  );
}

export default InputCard;