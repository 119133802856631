export class Constants {
  static readonly stringEmpty: string = '';
  static readonly number0String: string = '0';
  static readonly number1String: string = '1';
  static readonly number2String: string = '2';
}

export class DateLength {
  static readonly DAY_THREE_MONTH: number = 90;

  dayDiff(date1: Date, date2: Date) {
    const diffInMilliseconds = date2.getTime() - date1.getTime();

    return Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }
}

export class RegexPattern {
  static readonly password: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/;
  static readonly creditcardnumber: RegExp =
    /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
  static readonly phone: RegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
}
