import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from '../helper/Axios';
import ReleaseModel, { IReleaseResponseModel } from '../models/request/releaseModel';

const api = '/Release';
const requestConfig: AxiosRequestConfig =
{
    headers: {
        "Content-Type": "application/json"
    }
};

export class ReleasesService {
    getByArtist(): Promise<AxiosResponse<ReleaseModel[]>> {
        return Axios.get<ReleaseModel[]>(`${api}`);
    }

    createAsync(model: ReleaseModel): Promise<AxiosResponse> {
        return Axios.post<any>(
            `${api}`,
            JSON.stringify(model),
            requestConfig);
    }

    getAllByArtist(): Promise<AxiosResponse> {
        return Axios.get<IReleaseResponseModel[]>(`${api}`);
    }

    getReleaseById(id: string): Promise<AxiosResponse<ReleaseModel>> {
        return Axios.get<ReleaseModel>(`${api}/${id}`);
    }

    deleteReleaseById(id: string): Promise<AxiosResponse> {
      return Axios.delete(`${api}/${id}`);
  }
}