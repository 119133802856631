interface Props {
    src: string;
    muted?: boolean;
    loop?: boolean;
    controls?: boolean;
    className?: string;
}

export default function VideoControl({ src, muted, loop, controls, className }: Props) {
  
  return (
    <video muted={muted} loop={loop} className={className} controls={controls}>
        <source src={src} type='video/mp4' />
        <source src={src} type='video/webm' />
        Your browser is not supported!
    </video>
  );
}