import { AxiosResponse } from "axios";
import Axios from "../helper/Axios";
import IReportAssetRequestModel from "../models/request/ReportAssetModel";

const api = '/ReportAssetInfo';

export class ReportAssetService {

    CreateReportAssetAsync(request: IReportAssetRequestModel): Promise<AxiosResponse> {
        return Axios.post(`${api}`, JSON.stringify(request));
    }

    getReportAssetByAssetIdAsync(assetId: string): Promise<AxiosResponse> {
        return Axios.get<any>(`${api}/asset/${assetId}`);
    }
}