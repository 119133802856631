export class RouteNames {
  static readonly default: string = '/';
  static readonly account: string = '/account';
  static readonly register: string = '/register';
  static readonly upload: string = '/upload';
  static readonly login: string = '/login';
  static readonly myFavorite: string = '/my-favorite';
  static readonly myAsset: string = '/my-asset';
  static readonly assetDetail: string = '/asset/:id';
  static readonly cart: string = '/cart';
  static readonly pricing: string = '/pricing';
  static readonly search: string = '/search';
  static readonly faq: string = '/faq';
  static readonly contactus: string = '/contactus';
  static readonly termofservice: string = '/termofservice';
  static readonly order: string = '/order';
  static readonly changePassword: string = '/change-password';
  static readonly confirmEMail: string = '/confirm-email/*';
  static readonly orderHistory: string = '/order-history';
  static readonly accountInfo: string = '/account-info';
  static readonly aboutUs: string = '/aboutus';
  static readonly privacyPolicy: string = '/privacypolicy';
  static readonly successOrder: string = '/success-order';
  static readonly successPaymentTerm: string = '/success-paymentTerm';
  static readonly failedOrder: string = '/failed-order';
  static readonly myUpload: string = '/my-upload';
  static readonly collection: string = '/collection/:id';
  static readonly favoriteCollection: string = '/favorite-collection/:id';
  static readonly registerArtist: string = '/register-artist';
  static readonly downloadHistory: string = '/download-history';
  static readonly financial: string = '/financial';
  static readonly payoutInfo: string = '/payoutinfo';
  static readonly earningReport: string = '/earningreport';
  static readonly releases: string = '/releases';
  static readonly follows: string = '/follows';
  static readonly dashboard: string = '/dashboard';
  static readonly artistProfile: string = '/artist/:id';
  static readonly releaseDetail: string = '/releases/detail';
  static readonly changeEmail: string = '/change-email';
  static readonly myCollections: string = '/my-collections';
  static readonly myCollectionDetail: string = '/my-collections/detail';
  static readonly myPublish: string = '/my-publish';
  static readonly payment: string = '/order/payment';
  static readonly confirmChangeEMail: string = '/confirm-change-email/*';
  static readonly forgotPassword: string = '/forgot-password';
  static readonly welcomeArtist: string = '/welcome-artist';
  static readonly termOfServiceArtist: string = '/term-of-service-artist';
  static readonly privacyPolicyArtist: string = '/privacy-policy-artist';
  static readonly taxCenter: string = '/tax-center';
}
