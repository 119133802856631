import { AssetType } from '../../constant/assetConstants';
import HoverVideoPlayer from 'react-hover-video-player';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { AssetPublishModel } from 'src/models/assetModel';
import { Link, useNavigate } from 'react-router-dom';
import { RouteNames } from 'src/routes/routeName';

interface GalleryInterface {
    index: number;
    asset: AssetPublishModel;
    width?: string;
    height?: string;
}

export default function GalleryCollectItems({ asset, index }: GalleryInterface) {
    return (
        <>
            {asset.assetType === AssetType.VIDEO ? (
                <Link to={RouteNames.assetDetail.replace(':id', asset.id)} reloadDocument={true} className={`${asset.imageOrientation}`}>
                    <HoverVideoPlayer
                        key={index}
                        videoSrc={asset.thumbnailVideoUrl!}
                        restartOnPaused
                        overlayTransitionDuration={500}
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            borderRadius: 5,
                        }}
                        videoStyle={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            borderRadius: 5,
                        }}
                        pausedOverlay={
                            asset.thumbnailVideoCoverUrl ? (
                                <>
                                    <img
                                        key={index}
                                        src={asset.thumbnailVideoCoverUrl}
                                        className='gallery-img'
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: 5,
                                        }}
                                        alt={asset.name}
                                    />
                                    <PlayCircleOutlined
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            color: '#FFF',
                                            fontSize: '3em',
                                            opacity: 0.7,
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                </>
                            ) : (
                                <Skeleton.Image />
                            )
                        }
                        loadingOverlay={<div className='loading-overlay'>Loading...</div>}
                    />
                </Link>
            ) : (
                <Link to={RouteNames.assetDetail.replace(':id', asset.id)} reloadDocument={true} className='box-images'>
                    <img key={index} src={asset.thumbnailImageUrl} className={`gallery-img`} alt={asset.name} />
                    <div className='overlay'></div>
                </Link>
            )}
        </>
    );
}
