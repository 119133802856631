import { Link } from 'react-router-dom';
import MenuSider from './MenuSider';
import { Margin } from '@mui/icons-material';

const imageLogo = require('../../assets/images/logo/white@3x.png');
const imageLogoMobile = require('../../assets/images/logo/logo-mobile.png');

interface Props {
  isCollapsed: boolean;
};

export default function CustomSider({ isCollapsed }: Props) {
  return (
    <>
      <div className="ch-logo">
        <Link to="/" className='ch-img'>
          <img src={String(imageLogo)} alt="alive united plus" className={`${isCollapsed ? 'd-none' : ''}`} style={{ marginLeft: '20px' }} />
          <img src={String(imageLogoMobile)} alt="alive united plus" className={`${!isCollapsed ? 'd-none' : ''}`} />
        </Link>
      </div>
      <MenuSider />
    </>
  );
}
