import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { HttpStatus } from '../../constant/responseStatus';
import { AccountService } from '../../../src/services/accountService';
import IChangePasswordRequest from '../../../src/models/request/changePasswordRequestModel';
import TranslationKey from 'src/i18n/translation'

export default function ChangePassword() {
  const accountService = new AccountService();
  const [messageApi, contextHolder] = message.useMessage();

  const onChangePassword = async (data: IChangePasswordRequest) => {
    if (data.newPassword !== data.confirmNewPassword) {
      messageApi.open({
        type: 'error',
        content: 'Password is incorrect.',
      });

      return;
    }

    const responseFacebook = await accountService.changePasswordAsync(data);

    if (responseFacebook.status !== HttpStatus.OK) {
      messageApi.open({
        type: 'error',
        content: 'Change password failed.',
      });
      return;
    }

    messageApi.open({
      type: 'success',
      content: 'Change password success.',
    });
  };

  return (
    <>
      {contextHolder}
      <Form name='form_item_path' layout='vertical' onFinish={onChangePassword} className='form-input'>
        <Card
          title={TranslationKey('changePassword.title')}
          bordered={false}
          extra={
            <Button type='primary' htmlType='submit' size='large'>
              {TranslationKey('button.submit')}
              Submit
            </Button>
          }
        >
          <Row>
            <Col span={24} lg={8}>
              <Form.Item
                name='currentPassword'
                className='mb-4 text-light'
                label={TranslationKey('changePassword.currentPassword')}
                rules={[{ required: true, message: 'Please input your current password!' }]}
              >
                <Input.Password placeholder={TranslationKey('changePassword.currentPassword')} className='w-100' size='large' />
              </Form.Item>
              <Form.Item
                name='newPassword'
                className='mb-4 text-light'
                label={TranslationKey('changePassword.newPassword')}
                rules={[{ required: true, message: 'Please input your new password!' }]}
              >
                <Input.Password placeholder={TranslationKey('changePassword.newPassword')} className='w-100' size='large' />
              </Form.Item>
              <Form.Item
                name='confirmNewPassword'
                className='mb-4 text-light'
                label={TranslationKey('changePassword.confirmNewPassword')}
                rules={[{ required: true, message: 'Please input your confirm new password!' }]}
              >
                <Input.Password placeholder={TranslationKey('changePassword.confirmNewPassword')} className='w-100' size='large' />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
}