import { ArtistTaxInfoAttachmentInit, ArtistTaxInfoFileType, ArtistTaxInfoProperty, NationalityType } from 'src/constant/artistTaxInfoConstants';
import { ArtistTaxInfoAttachmentModel } from 'src/models/TaxCenterModel';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Upload, UploadProps, message } from 'antd';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { UploadFileConstants, UploadListType, UploadValidationMessages } from 'src/constant/uploadFileConstants';
import { StringConstants } from 'src/constant/stringConstants';
import { S3Constants } from 'src/constant/s3Constants';
import AWS from 'aws-sdk';
import { useSessionContext } from 'src/context/session-context';
import { UploadOutlined } from '@ant-design/icons';
import TranslationKey from 'src/i18n/translation';

interface IndividualTaxCenterProps {
  nationalityType: string,
  idCardAttachment: ArtistTaxInfoAttachmentModel,
  certificationAttachment: ArtistTaxInfoAttachmentModel,
  onUploadFileChanged: (
    fileType: ArtistTaxInfoFileType,
    file: ArtistTaxInfoAttachmentModel) => void;
}

export default function IndividualTaxCenter(props: IndividualTaxCenterProps) {
  const [isThai, setIsThai] = useState(false);
  const { userProfile } = useSessionContext();
  const [idCardFile, setIdCardFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);
  const [certificationFile, setCertificationFile] = useState<ArtistTaxInfoAttachmentModel>(ArtistTaxInfoAttachmentInit.defaultFileValue);

  useEffect(() => {
    if (props.nationalityType) {
      setIsThai(props.nationalityType === NationalityType.Thai);
    }
  }, [props.nationalityType]);

  useEffect(() => {
    if (props.idCardAttachment) {
      setIdCardFile(props.idCardAttachment);
    }
  }, [props.idCardAttachment]);

  useEffect(() => {
    if (props.certificationAttachment) {
      setCertificationFile(props.certificationAttachment);
    }
  }, [props.certificationAttachment]);

  // #region : upload config
  AWS.config.update({
    accessKeyId: S3Constants.ACCESS_KEY_ID,
    secretAccessKey: S3Constants.SECRET_ACCESS_KEY,
  });

  const S3_BUCKET = S3Constants.BUCKET;
  const REGION = S3Constants.REGION;
  const s3Bucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const deleteFileFromS3 = async (fileName: string) => {
    const params = {
      Bucket: S3Constants.BUCKET,
      Key: fileName,
    };

    await s3Bucket.deleteObject(params, (err) => {
      if (err) {
        console.log(err, err.stack);
        message.error(`${fileName} file remove failed.`);
      }
    });
  };

  const getFileName = (originalFileName: string, fileType: ArtistTaxInfoFileType) => {
    const now = new Date();

    return (
      UploadFileConstants.FILE_NAME_PREFIX +
      userProfile.userId +
      StringConstants.UNDERSCORE +
      fileType +
      StringConstants.UNDERSCORE +
      now.getUTCFullYear() +
      (now.getUTCMonth() + 1) +
      now.getUTCDate() +
      now.getUTCHours() +
      now.getUTCMinutes() +
      now.getUTCSeconds() +
      now.getUTCMilliseconds() +
      StringConstants.UNDERSCORE +
      originalFileName
    );
  };

  const addFileToS3 = async (
    fileType: ArtistTaxInfoFileType,
    originalFile: any,
    onError: any,
    onSuccess: any,
    onProgress: any) => 
    {
      switch(fileType) { 
        case ArtistTaxInfoFileType.idCardAttachment: { 
          setIdCardFile(
            {
              url: StringConstants.EMPTY,
              fileNameOriginal: StringConstants.EMPTY,
              fileName: StringConstants.EMPTY
            });
          break; 
        } 
        case ArtistTaxInfoFileType.certification: { 
          setCertificationFile(
            {
              url: StringConstants.EMPTY,
              fileNameOriginal: StringConstants.EMPTY,
              fileName: StringConstants.EMPTY
            });
          break; 
        }
      }

      const fileName = getFileName(originalFile.name, fileType);
      const filePath = `${S3Constants.FILE_NAME_FORMAT}${fileName}`;
      const params = {
        ACL: S3Constants.ACL_TERM,
        Body: originalFile,
        Bucket: S3_BUCKET,
        Key: fileName,
        ContentType: originalFile.type,
      };

      s3Bucket
        .putObject(params)
        .on(S3Constants.EVENT_ON_UPLOAD, (evt) => {
          const progressPercent = Math.round((evt.loaded / evt.total) * 100);
          onProgress!({ percent: progressPercent });
        })
        .promise()
        .then(
          async () => {
            var fileUploaded = {
              url: filePath,
              fileNameOriginal: originalFile.name,
              fileName: fileName
            };

            switch(fileType) { 
              case ArtistTaxInfoFileType.idCardAttachment: { 
                setIdCardFile(fileUploaded);
                break; 
              } 
              case ArtistTaxInfoFileType.certification: { 
                setCertificationFile(fileUploaded);
                break; 
              }
            }

            onSuccess!(fileName);
            props.onUploadFileChanged(fileType, fileUploaded);
          },
          (err) => {
            onError!(err);
            throw err;
          }
        );
  };

  const validateUploadFile = async (file: File, onError: any) => {
    const unknowFileType = !file.type || file.type === StringConstants.EMPTY;
    const fileTypeNotSupport = !UploadFileConstants.MIME_RELEASES_ACCEPT.includes(file.type);

    if (unknowFileType || fileTypeNotSupport) {
      const errorMessage = file.name + StringConstants.SPACE + UploadValidationMessages.INVALID_FILE_TYPE;
      onError(errorMessage);

      return errorMessage;
    }

    return StringConstants.EMPTY.toString();
  };

  const uploadPropsIdCard: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(idCardFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.idCardAttachment, originalFile, onError, onSuccess, onProgress);
    },
  };

  const uploadPropsCertification: UploadProps = {
    multiple: false,
    listType: UploadListType.TEXT,
    accept: UploadFileConstants.MIME_RELEASES_ACCEPT.toString(),
    maxCount: 1,
    showUploadList: true,
    progress: {
      strokeColor: {
        '0%': '#DBDBFF',
        '100%': '#4D4DFF',
      },
      showInfo: false,
      status: 'active',
    },
    async onRemove() {
      deleteFileFromS3(certificationFile.fileName);
    },
    async customRequest({ file, onError, onSuccess, onProgress }) {
      const originalFile = file as File;
      let errorMessage = await validateUploadFile(originalFile, onError);
      if (errorMessage !== StringConstants.EMPTY) {
        message.error(errorMessage, 10);
        return;
      }

      await addFileToS3(ArtistTaxInfoFileType.certification, originalFile, onError, onSuccess, onProgress);
    },
  };
  // #endregion

  return (
    <>
      <Row>
        <Col span={24} className='mb-4'>
          <Title level={2}>{TranslationKey('taxCenter.pleaseComplete')}</Title>
          <p>{TranslationKey('taxCenter.basedOnTheInformation')}</p>
          <p>{TranslationKey('taxCenter.youMayWantToReview')}</p>
          <p><span className='text-danger'>*</span> {TranslationKey('taxCenter.youMayWantToReview')}</p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
        <Title level={3}>Part 1: {TranslationKey('taxCenter.identificationOfBeneficial')}</Title>
          <p>{TranslationKey('taxCenter.nameAs')}<span className='text-danger'>*</span></p>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.yourFullName')}
            name="fullName"
            rules={[{required: true, message: 'Please enter Full Name.'}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          {
            isThai
            ? (
              <Form.Item
                label={TranslationKey('taxCenter.idCardNumber')}
                name="idCardNumber"
                rules={[{required: true}]}>
                  <Input
                    size='large'
                    className='w-100'
                  />
              </Form.Item>
            )
            : (
              <Form.Item
                label="Country of citizenship"
                name="countryOfCitizenship">
                  <Input
                    size='large'
                    className='w-100'
                  />
              </Form.Item>
            )
          }
        </Col>
        <Col span={24}>
          <Title level={3}>{isThai ? `Address` : `Permanent residence address`}</Title>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.addressLine1')}
            name="address1"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.addressLine2')}
            name="address2"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.city')}
            name="city"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            label={TranslationKey('taxCenter.postal')}
            name="postcode"
            rules={[{required: isThai}]}>
              <Input
                size='large'
                className='w-100'
              />
          </Form.Item>
        </Col>
        {
          isThai
          ? (
            <>
              <Col md={12} sm={24}>
                <Form.Item
            label={TranslationKey('email')}
            name="email"
                  rules={[{required: true}]}>
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
              label={TranslationKey('phoneNumber')}
              name="phone"
                  rules={[{required: true}]}>
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={3}>{TranslationKey('taxCenter.uploadIDCard')}</Title>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={ArtistTaxInfoProperty.idCardAttachmentUrl}
                  className='mt-4 text-light '
                  label={TranslationKey('taxCenter.idCard')}>
                  <div className='upload-form-box'>
                  {
                    idCardFile && idCardFile.url !== StringConstants.EMPTY
                    ?
                    <div className='d-flex gap-2'>
                      <Button type='default' size='small'
                        onClick={() => {
                          setIdCardFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                          props.onUploadFileChanged(
                            ArtistTaxInfoFileType.idCardAttachment,
                            ArtistTaxInfoAttachmentInit.defaultFileValue);
                          }}
                          danger>
                          {TranslationKey('button.upload')}
                      </Button>
                      <a href={idCardFile.url} target='_blank' rel="noreferrer">
                        <span style={{color: 'black'}}>{idCardFile.fileNameOriginal}</span>
                      </a>
                    </div>
                    : 
                    <Upload {...uploadPropsIdCard}>
                      <Button icon={<UploadOutlined />}>{TranslationKey('button.clickToUpload')}</Button>
                    </Upload>
                  }
                  </div>
                </Form.Item>
                <Form.Item name={ArtistTaxInfoProperty.idCardAttachmentFileName} hidden></Form.Item>
                <Form.Item name={ArtistTaxInfoProperty.idCardAttachmentFileNameOriginal} hidden></Form.Item>
              </Col>
            </>
          )
          : (
            <>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Is this address a P.O. Box or in-care-of address ?"
                  name="poBoxType"
                  rules={[{required: true}]}>
                    <Radio.Group defaultValue={true}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={3}>{TranslationKey('taxCenter.mailingAddress')}</Title>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label={TranslationKey('taxCenter.address1')}
                  name="mailingAddress1">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label={TranslationKey('taxCenter.address2')}
                  name="mailingAddress2">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label={TranslationKey('taxCenter.city')}
                  name="mailingCity">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Postal"
                  name="mailingPostcode">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="U.S. Taxpayer identification number, if required"
                  name="usTaxPayerType"
                  rules={[{required: true}]}>
                    <Radio.Group defaultValue={'SSN'}>
                      <Radio value={'SSN'}>SSN</Radio>
                      <Radio value={'ITIN'}>ITIN</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="SSN or ITIN Number"
                  name="usTaxPayerNumber">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Foreign Tax Identification number"
                  name="taxIdNumber">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Reference Number(s), if applicable"
                  name="referenceNumber">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  label="Date of birth"
                  name="dateOfBirth">
                    <DatePicker
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={3}>Part 2: Claim of Tax Treaty Benefits</Title>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="I certify that the beneficial owner is a resident of ....."
                  name="claimOfTax">
                    <Input
                      size='large'
                      className='w-100'
                    />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Title level={3}>Part 3: Certification</Title>
                <p>Please download this file <a href='https://www.lanl.gov/business/vendors/_assets/docs/fw8ben-form.pdf'>https://www.lanl.gov/business/vendors/_assets/docs/fw8ben-form.pdf</a> and fill form and upload. </p>
              </Col>
              <Col md={12} sm={24}>
                <Form.Item
                  name={ArtistTaxInfoProperty.certificationAttachmentUrl}
                  className='mt-4 text-light '
                  label='Certification'>
                  <div className='upload-form-box'>
                  {
                    certificationFile && certificationFile.url !== StringConstants.EMPTY
                    ?
                    <div className='d-flex gap-2'>
                      <Button type='default' size='small'
                        onClick={() => {
                          setCertificationFile(ArtistTaxInfoAttachmentInit.defaultFileValue);
                          props.onUploadFileChanged(
                            ArtistTaxInfoFileType.certification,
                            ArtistTaxInfoAttachmentInit.defaultFileValue);
                          }}
                          danger>
                          {TranslationKey('button.upload')}
                      </Button>
                      <a href={certificationFile.url} target='_blank' rel="noreferrer">
                        <span style={{color: 'black'}}>{certificationFile.fileNameOriginal}</span>
                      </a>
                    </div>
                    : 
                    <Upload {...uploadPropsCertification}>
                      <Button icon={<UploadOutlined />}>{TranslationKey('button.clickToUpload')}</Button>
                    </Upload>
                  }
                  </div>
                </Form.Item>
                <Form.Item name={ArtistTaxInfoProperty.certificationAttachmentFileName} hidden></Form.Item>
                <Form.Item name={ArtistTaxInfoProperty.certificationAttachmentFileNameOriginal} hidden></Form.Item>
              </Col>
            </>
          )
        }
      </Row>
    </>
  );
}
