export const enum Session {
  SESSION_ACCESS_TOKEN = 'alive-access_token',
  SESSION_REFRESH_TOKEN = 'alive-refresh_token',
  SESSION_USER = 'alive-user',
  SESSION_HOST = 'alive-host',
  EXPIRE_SESSION = 'expireSession',
  ACCEPT_COOKIES = 'accept_cookies',
}

export const enum SessionTimeOut {
  TWENTY_MINUTE = 1200000,
  ONE_DAY = 86400000,
  ONE_WEEK = 604800000,
  FOURTEEN_DAY = 1209600000,
  ONE_YEAR = 31556952000,
}